const ttgTimeZones = [
	{ label: 'Africa/Abidjan (GMT +00:00)', value: 'Africa/Abidjan' },
	{ label: 'Africa/Accra (GMT +00:00)', value: 'Africa/Accra' },
	{ label: 'Africa/Addis_Ababa (GMT +03:00)', value: 'Africa/Addis_Ababa' },
	{ label: 'Africa/Algiers (GMT +01:00)', value: 'Africa/Algiers' },
	{ label: 'Africa/Asmara (GMT +03:00)', value: 'Africa/Asmara' },
	{ label: 'Africa/Asmera (GMT +03:00)', value: 'Africa/Asmera' },
	{ label: 'Africa/Bamako (GMT +00:00)', value: 'Africa/Bamako' },
	{ label: 'Africa/Bangui (GMT +01:00)', value: 'Africa/Bangui' },
	{ label: 'Africa/Banjul (GMT +00:00)', value: 'Africa/Banjul' },
	{ label: 'Africa/Bissau (GMT +00:00)', value: 'Africa/Bissau' },
	{ label: 'Africa/Blantyre (GMT +02:00)', value: 'Africa/Blantyre' },
	{ label: 'Africa/Brazzaville (GMT +01:00)', value: 'Africa/Brazzaville' },
	{ label: 'Africa/Bujumbura (GMT +02:00)', value: 'Africa/Bujumbura' },
	{ label: 'Africa/Cairo (GMT +02:00)', value: 'Africa/Cairo' },
	{ label: 'Africa/Casablanca (GMT +01:00)', value: 'Africa/Casablanca' },
	{ label: 'Africa/Ceuta (GMT +01:00)', value: 'Africa/Ceuta' },
	{ label: 'Africa/Conakry (GMT +00:00)', value: 'Africa/Conakry' },
	{ label: 'Africa/Dakar (GMT +00:00)', value: 'Africa/Dakar' },
	{ label: 'Africa/Dar_es_Salaam (GMT +03:00)', value: 'Africa/Dar_es_Salaam' },
	{ label: 'Africa/Djibouti (GMT +03:00)', value: 'Africa/Djibouti' },
	{ label: 'Africa/Douala (GMT +01:00)', value: 'Africa/Douala' },
	{ label: 'Africa/El_Aaiun (GMT +01:00)', value: 'Africa/El_Aaiun' },
	{ label: 'Africa/Freetown (GMT +00:00)', value: 'Africa/Freetown' },
	{ label: 'Africa/Gaborone (GMT +02:00)', value: 'Africa/Gaborone' },
	{ label: 'Africa/Harare (GMT +02:00)', value: 'Africa/Harare' },
	{ label: 'Africa/Johannesburg (GMT +02:00)', value: 'Africa/Johannesburg' },
	{ label: 'Africa/Juba (GMT +02:00)', value: 'Africa/Juba' },
	{ label: 'Africa/Kampala (GMT +03:00)', value: 'Africa/Kampala' },
	{ label: 'Africa/Khartoum (GMT +02:00)', value: 'Africa/Khartoum' },
	{ label: 'Africa/Kigali (GMT +02:00)', value: 'Africa/Kigali' },
	{ label: 'Africa/Kinshasa (GMT +01:00)', value: 'Africa/Kinshasa' },
	{ label: 'Africa/Lagos (GMT +01:00)', value: 'Africa/Lagos' },
	{ label: 'Africa/Libreville (GMT +01:00)', value: 'Africa/Libreville' },
	{ label: 'Africa/Lome (GMT +00:00)', value: 'Africa/Lome' },
	{ label: 'Africa/Luanda (GMT +01:00)', value: 'Africa/Luanda' },
	{ label: 'Africa/Lubumbashi (GMT +02:00)', value: 'Africa/Lubumbashi' },
	{ label: 'Africa/Lusaka (GMT +02:00)', value: 'Africa/Lusaka' },
	{ label: 'Africa/Malabo (GMT +01:00)', value: 'Africa/Malabo' },
	{ label: 'Africa/Maputo (GMT +02:00)', value: 'Africa/Maputo' },
	{ label: 'Africa/Maseru (GMT +02:00)', value: 'Africa/Maseru' },
	{ label: 'Africa/Mbabane (GMT +02:00)', value: 'Africa/Mbabane' },
	{ label: 'Africa/Mogadishu (GMT +03:00)', value: 'Africa/Mogadishu' },
	{ label: 'Africa/Monrovia (GMT +00:00)', value: 'Africa/Monrovia' },
	{ label: 'Africa/Nairobi (GMT +03:00)', value: 'Africa/Nairobi' },
	{ label: 'Africa/Ndjamena (GMT +01:00)', value: 'Africa/Ndjamena' },
	{ label: 'Africa/Niamey (GMT +01:00)', value: 'Africa/Niamey' },
	{ label: 'Africa/Nouakchott (GMT +00:00)', value: 'Africa/Nouakchott' },
	{ label: 'Africa/Ouagadougou (GMT +00:00)', value: 'Africa/Ouagadougou' },
	{ label: 'Africa/Porto-Novo (GMT +01:00)', value: 'Africa/Porto-Novo' },
	{ label: 'Africa/Sao_Tome (GMT +00:00)', value: 'Africa/Sao_Tome' },
	{ label: 'Africa/Timbuktu (GMT +00:00)', value: 'Africa/Timbuktu' },
	{ label: 'Africa/Tripoli (GMT +02:00)', value: 'Africa/Tripoli' },
	{ label: 'Africa/Tunis (GMT +01:00)', value: 'Africa/Tunis' },
	{ label: 'Africa/Windhoek (GMT +02:00)', value: 'Africa/Windhoek' },
	{ label: 'America/Adak (GMT -10:00)', value: 'America/Adak' },
	{ label: 'America/Anchorage (GMT -09:00)', value: 'America/Anchorage' },
	{ label: 'America/Anguilla (GMT -04:00)', value: 'America/Anguilla' },
	{ label: 'America/Antigua (GMT -04:00)', value: 'America/Antigua' },
	{ label: 'America/Araguaina (GMT -03:00)', value: 'America/Araguaina' },
	{ label: 'America/Argentina/Buenos_Aires (GMT -03:00)', value: 'America/Argentina/Buenos_Aires' },
	{ label: 'America/Argentina/Catamarca (GMT -03:00)', value: 'America/Argentina/Catamarca' },
	{ label: 'America/Argentina/ComodRivadavia (GMT -03:00)', value: 'America/Argentina/ComodRivadavia' },
	{ label: 'America/Argentina/Cordoba (GMT -03:00)', value: 'America/Argentina/Cordoba' },
	{ label: 'America/Argentina/Jujuy (GMT -03:00)', value: 'America/Argentina/Jujuy' },
	{ label: 'America/Argentina/La_Rioja (GMT -03:00)', value: 'America/Argentina/La_Rioja' },
	{ label: 'America/Argentina/Mendoza (GMT -03:00)', value: 'America/Argentina/Mendoza' },
	{ label: 'America/Argentina/Rio_Gallegos (GMT -03:00)', value: 'America/Argentina/Rio_Gallegos' },
	{ label: 'America/Argentina/Salta (GMT -03:00)', value: 'America/Argentina/Salta' },
	{ label: 'America/Argentina/San_Juan (GMT -03:00)', value: 'America/Argentina/San_Juan' },
	{ label: 'America/Argentina/San_Luis (GMT -03:00)', value: 'America/Argentina/San_Luis' },
	{ label: 'America/Argentina/Tucuman (GMT -03:00)', value: 'America/Argentina/Tucuman' },
	{ label: 'America/Argentina/Ushuaia (GMT -03:00)', value: 'America/Argentina/Ushuaia' },
	{ label: 'America/Aruba (GMT -04:00)', value: 'America/Aruba' },
	{ label: 'America/Asuncion (GMT -03:00)', value: 'America/Asuncion' },
	{ label: 'America/Atikokan (GMT -05:00)', value: 'America/Atikokan' },
	{ label: 'America/Atka (GMT -10:00)', value: 'America/Atka' },
	{ label: 'America/Bahia (GMT -03:00)', value: 'America/Bahia' },
	{ label: 'America/Bahia_Banderas (GMT -06:00)', value: 'America/Bahia_Banderas' },
	{ label: 'America/Barbados (GMT -04:00)', value: 'America/Barbados' },
	{ label: 'America/Belem (GMT -03:00)', value: 'America/Belem' },
	{ label: 'America/Belize (GMT -06:00)', value: 'America/Belize' },
	{ label: 'America/Blanc-Sablon (GMT -04:00)', value: 'America/Blanc-Sablon' },
	{ label: 'America/Boa_Vista (GMT -04:00)', value: 'America/Boa_Vista' },
	{ label: 'America/Bogota (GMT -05:00)', value: 'America/Bogota' },
	{ label: 'America/Boise (GMT -07:00)', value: 'America/Boise' },
	{ label: 'America/Buenos_Aires (GMT -03:00)', value: 'America/Buenos_Aires' },
	{ label: 'America/Cambridge_Bay (GMT -07:00)', value: 'America/Cambridge_Bay' },
	{ label: 'America/Campo_Grande (GMT -04:00)', value: 'America/Campo_Grande' },
	{ label: 'America/Cancun (GMT -05:00)', value: 'America/Cancun' },
	{ label: 'America/Caracas (GMT -04:00)', value: 'America/Caracas' },
	{ label: 'America/Catamarca (GMT -03:00)', value: 'America/Catamarca' },
	{ label: 'America/Cayenne (GMT -03:00)', value: 'America/Cayenne' },
	{ label: 'America/Cayman (GMT -05:00)', value: 'America/Cayman' },
	{ label: 'America/Chicago (GMT -06:00)', value: 'America/Chicago' },
	{ label: 'America/Chihuahua (GMT -07:00)', value: 'America/Chihuahua' },
	{ label: 'America/Coral_Harbour (GMT -05:00)', value: 'America/Coral_Harbour' },
	{ label: 'America/Cordoba (GMT -03:00)', value: 'America/Cordoba' },
	{ label: 'America/Costa_Rica (GMT -06:00)', value: 'America/Costa_Rica' },
	{ label: 'America/Creston (GMT -07:00)', value: 'America/Creston' },
	{ label: 'America/Cuiaba (GMT -04:00)', value: 'America/Cuiaba' },
	{ label: 'America/Curacao (GMT -04:00)', value: 'America/Curacao' },
	{ label: 'America/Danmarkshavn (GMT +00:00)', value: 'America/Danmarkshavn' },
	{ label: 'America/Dawson (GMT -07:00)', value: 'America/Dawson' },
	{ label: 'America/Dawson_Creek (GMT -07:00)', value: 'America/Dawson_Creek' },
	{ label: 'America/Denver (GMT -07:00)', value: 'America/Denver' },
	{ label: 'America/Detroit (GMT -05:00)', value: 'America/Detroit' },
	{ label: 'America/Dominica (GMT -04:00)', value: 'America/Dominica' },
	{ label: 'America/Edmonton (GMT -07:00)', value: 'America/Edmonton' },
	{ label: 'America/Eirunepe (GMT -05:00)', value: 'America/Eirunepe' },
	{ label: 'America/El_Salvador (GMT -06:00)', value: 'America/El_Salvador' },
	{ label: 'America/Ensenada (GMT -08:00)', value: 'America/Ensenada' },
	{ label: 'America/Fort_Nelson (GMT -07:00)', value: 'America/Fort_Nelson' },
	{ label: 'America/Fort_Wayne (GMT -05:00)', value: 'America/Fort_Wayne' },
	{ label: 'America/Fortaleza (GMT -03:00)', value: 'America/Fortaleza' },
	{ label: 'America/Glace_Bay (GMT -04:00)', value: 'America/Glace_Bay' },
	{ label: 'America/Godthab (GMT -03:00)', value: 'America/Godthab' },
	{ label: 'America/Goose_Bay (GMT -04:00)', value: 'America/Goose_Bay' },
	{ label: 'America/Grand_Turk (GMT -05:00)', value: 'America/Grand_Turk' },
	{ label: 'America/Grenada (GMT -04:00)', value: 'America/Grenada' },
	{ label: 'America/Guadeloupe (GMT -04:00)', value: 'America/Guadeloupe' },
	{ label: 'America/Guatemala (GMT -06:00)', value: 'America/Guatemala' },
	{ label: 'America/Guayaquil (GMT -05:00)', value: 'America/Guayaquil' },
	{ label: 'America/Guyana (GMT -04:00)', value: 'America/Guyana' },
	{ label: 'America/Halifax (GMT -04:00)', value: 'America/Halifax' },
	{ label: 'America/Havana (GMT -05:00)', value: 'America/Havana' },
	{ label: 'America/Hermosillo (GMT -07:00)', value: 'America/Hermosillo' },
	{ label: 'America/Indiana/Indianapolis (GMT -05:00)', value: 'America/Indiana/Indianapolis' },
	{ label: 'America/Indiana/Knox (GMT -06:00)', value: 'America/Indiana/Knox' },
	{ label: 'America/Indiana/Marengo (GMT -05:00)', value: 'America/Indiana/Marengo' },
	{ label: 'America/Indiana/Petersburg (GMT -05:00)', value: 'America/Indiana/Petersburg' },
	{ label: 'America/Indiana/Tell_City (GMT -06:00)', value: 'America/Indiana/Tell_City' },
	{ label: 'America/Indiana/Vevay (GMT -05:00)', value: 'America/Indiana/Vevay' },
	{ label: 'America/Indiana/Vincennes (GMT -05:00)', value: 'America/Indiana/Vincennes' },
	{ label: 'America/Indiana/Winamac (GMT -05:00)', value: 'America/Indiana/Winamac' },
	{ label: 'America/Indianapolis (GMT -05:00)', value: 'America/Indianapolis' },
	{ label: 'America/Inuvik (GMT -07:00)', value: 'America/Inuvik' },
	{ label: 'America/Iqaluit (GMT -05:00)', value: 'America/Iqaluit' },
	{ label: 'America/Jamaica (GMT -05:00)', value: 'America/Jamaica' },
	{ label: 'America/Jujuy (GMT -03:00)', value: 'America/Jujuy' },
	{ label: 'America/Juneau (GMT -09:00)', value: 'America/Juneau' },
	{ label: 'America/Kentucky/Louisville (GMT -05:00)', value: 'America/Kentucky/Louisville' },
	{ label: 'America/Kentucky/Monticello (GMT -05:00)', value: 'America/Kentucky/Monticello' },
	{ label: 'America/Knox_IN (GMT -06:00)', value: 'America/Knox_IN' },
	{ label: 'America/Kralendijk (GMT -04:00)', value: 'America/Kralendijk' },
	{ label: 'America/La_Paz (GMT -04:00)', value: 'America/La_Paz' },
	{ label: 'America/Lima (GMT -05:00)', value: 'America/Lima' },
	{ label: 'America/Los_Angeles (GMT -08:00)', value: 'America/Los_Angeles' },
	{ label: 'America/Louisville (GMT -05:00)', value: 'America/Louisville' },
	{ label: 'America/Lower_Princes (GMT -04:00)', value: 'America/Lower_Princes' },
	{ label: 'America/Maceio (GMT -03:00)', value: 'America/Maceio' },
	{ label: 'America/Managua (GMT -06:00)', value: 'America/Managua' },
	{ label: 'America/Manaus (GMT -04:00)', value: 'America/Manaus' },
	{ label: 'America/Marigot (GMT -04:00)', value: 'America/Marigot' },
	{ label: 'America/Martinique (GMT -04:00)', value: 'America/Martinique' },
	{ label: 'America/Matamoros (GMT -06:00)', value: 'America/Matamoros' },
	{ label: 'America/Mazatlan (GMT -07:00)', value: 'America/Mazatlan' },
	{ label: 'America/Mendoza (GMT -03:00)', value: 'America/Mendoza' },
	{ label: 'America/Menominee (GMT -06:00)', value: 'America/Menominee' },
	{ label: 'America/Merida (GMT -06:00)', value: 'America/Merida' },
	{ label: 'America/Metlakatla (GMT -09:00)', value: 'America/Metlakatla' },
	{ label: 'America/Mexico_City (GMT -06:00)', value: 'America/Mexico_City' },
	{ label: 'America/Miquelon (GMT -03:00)', value: 'America/Miquelon' },
	{ label: 'America/Moncton (GMT -04:00)', value: 'America/Moncton' },
	{ label: 'America/Monterrey (GMT -06:00)', value: 'America/Monterrey' },
	{ label: 'America/Montevideo (GMT -03:00)', value: 'America/Montevideo' },
	{ label: 'America/Montreal (GMT -05:00)', value: 'America/Montreal' },
	{ label: 'America/Montserrat (GMT -04:00)', value: 'America/Montserrat' },
	{ label: 'America/Nassau (GMT -05:00)', value: 'America/Nassau' },
	{ label: 'America/New_York (GMT -05:00)', value: 'America/New_York' },
	{ label: 'America/Nipigon (GMT -05:00)', value: 'America/Nipigon' },
	{ label: 'America/Nome (GMT -09:00)', value: 'America/Nome' },
	{ label: 'America/Noronha (GMT -02:00)', value: 'America/Noronha' },
	{ label: 'America/North_Dakota/Beulah (GMT -06:00)', value: 'America/North_Dakota/Beulah' },
	{ label: 'America/North_Dakota/Center (GMT -06:00)', value: 'America/North_Dakota/Center' },
	{ label: 'America/North_Dakota/New_Salem (GMT -06:00)', value: 'America/North_Dakota/New_Salem' },
	{ label: 'America/Nuuk (GMT -03:00)', value: 'America/Nuuk' },
	{ label: 'America/Ojinaga (GMT -07:00)', value: 'America/Ojinaga' },
	{ label: 'America/Panama (GMT -05:00)', value: 'America/Panama' },
	{ label: 'America/Pangnirtung (GMT -05:00)', value: 'America/Pangnirtung' },
	{ label: 'America/Paramaribo (GMT -03:00)', value: 'America/Paramaribo' },
	{ label: 'America/Phoenix (GMT -07:00)', value: 'America/Phoenix' },
	{ label: 'America/Port-au-Prince (GMT -05:00)', value: 'America/Port-au-Prince' },
	{ label: 'America/Port_of_Spain (GMT -04:00)', value: 'America/Port_of_Spain' },
	{ label: 'America/Porto_Acre (GMT -05:00)', value: 'America/Porto_Acre' },
	{ label: 'America/Porto_Velho (GMT -04:00)', value: 'America/Porto_Velho' },
	{ label: 'America/Puerto_Rico (GMT -04:00)', value: 'America/Puerto_Rico' },
	{ label: 'America/Punta_Arenas (GMT -03:00)', value: 'America/Punta_Arenas' },
	{ label: 'America/Rainy_River (GMT -06:00)', value: 'America/Rainy_River' },
	{ label: 'America/Rankin_Inlet (GMT -06:00)', value: 'America/Rankin_Inlet' },
	{ label: 'America/Recife (GMT -03:00)', value: 'America/Recife' },
	{ label: 'America/Regina (GMT -06:00)', value: 'America/Regina' },
	{ label: 'America/Resolute (GMT -06:00)', value: 'America/Resolute' },
	{ label: 'America/Rio_Branco (GMT -05:00)', value: 'America/Rio_Branco' },
	{ label: 'America/Rosario (GMT -03:00)', value: 'America/Rosario' },
	{ label: 'America/Santa_Isabel (GMT -08:00)', value: 'America/Santa_Isabel' },
	{ label: 'America/Santarem (GMT -03:00)', value: 'America/Santarem' },
	{ label: 'America/Santiago (GMT -03:00)', value: 'America/Santiago' },
	{ label: 'America/Santo_Domingo (GMT -04:00)', value: 'America/Santo_Domingo' },
	{ label: 'America/Sao_Paulo (GMT -03:00)', value: 'America/Sao_Paulo' },
	{ label: 'America/Scoresbysund (GMT -01:00)', value: 'America/Scoresbysund' },
	{ label: 'America/Shiprock (GMT -07:00)', value: 'America/Shiprock' },
	{ label: 'America/Sitka (GMT -09:00)', value: 'America/Sitka' },
	{ label: 'America/St_Barthelemy (GMT -04:00)', value: 'America/St_Barthelemy' },
	{ label: 'America/St_Johns (GMT -03:30)', value: 'America/St_Johns' },
	{ label: 'America/St_Kitts (GMT -04:00)', value: 'America/St_Kitts' },
	{ label: 'America/St_Lucia (GMT -04:00)', value: 'America/St_Lucia' },
	{ label: 'America/St_Thomas (GMT -04:00)', value: 'America/St_Thomas' },
	{ label: 'America/St_Vincent (GMT -04:00)', value: 'America/St_Vincent' },
	{ label: 'America/Swift_Current (GMT -06:00)', value: 'America/Swift_Current' },
	{ label: 'America/Tegucigalpa (GMT -06:00)', value: 'America/Tegucigalpa' },
	{ label: 'America/Thule (GMT -04:00)', value: 'America/Thule' },
	{ label: 'America/Thunder_Bay (GMT -05:00)', value: 'America/Thunder_Bay' },
	{ label: 'America/Tijuana (GMT -08:00)', value: 'America/Tijuana' },
	{ label: 'America/Toronto (GMT -05:00)', value: 'America/Toronto' },
	{ label: 'America/Tortola (GMT -04:00)', value: 'America/Tortola' },
	{ label: 'America/Vancouver (GMT -08:00)', value: 'America/Vancouver' },
	{ label: 'America/Virgin (GMT -04:00)', value: 'America/Virgin' },
	{ label: 'America/Whitehorse (GMT -07:00)', value: 'America/Whitehorse' },
	{ label: 'America/Winnipeg (GMT -06:00)', value: 'America/Winnipeg' },
	{ label: 'America/Yakutat (GMT -09:00)', value: 'America/Yakutat' },
	{ label: 'America/Yellowknife (GMT -07:00)', value: 'America/Yellowknife' },
	{ label: 'Antarctica/Casey (GMT +11:00)', value: 'Antarctica/Casey' },
	{ label: 'Antarctica/Davis (GMT +07:00)', value: 'Antarctica/Davis' },
	{ label: 'Antarctica/DumontDUrville (GMT +10:00)', value: 'Antarctica/DumontDUrville' },
	{ label: 'Antarctica/Macquarie (GMT +11:00)', value: 'Antarctica/Macquarie' },
	{ label: 'Antarctica/Mawson (GMT +05:00)', value: 'Antarctica/Mawson' },
	{ label: 'Antarctica/McMurdo (GMT +13:00)', value: 'Antarctica/McMurdo' },
	{ label: 'Antarctica/Palmer (GMT -03:00)', value: 'Antarctica/Palmer' },
	{ label: 'Antarctica/Rothera (GMT -03:00)', value: 'Antarctica/Rothera' },
	{ label: 'Antarctica/South_Pole (GMT +13:00)', value: 'Antarctica/South_Pole' },
	{ label: 'Antarctica/Syowa (GMT +03:00)', value: 'Antarctica/Syowa' },
	{ label: 'Antarctica/Troll (GMT +00:00)', value: 'Antarctica/Troll' },
	{ label: 'Antarctica/Vostok (GMT +06:00)', value: 'Antarctica/Vostok' },
	{ label: 'Arctic/Longyearbyen (GMT +01:00)', value: 'Arctic/Longyearbyen' },
	{ label: 'Asia/Aden (GMT +03:00)', value: 'Asia/Aden' },
	{ label: 'Asia/Almaty (GMT +06:00)', value: 'Asia/Almaty' },
	{ label: 'Asia/Amman (GMT +02:00)', value: 'Asia/Amman' },
	{ label: 'Asia/Anadyr (GMT +12:00)', value: 'Asia/Anadyr' },
	{ label: 'Asia/Aqtau (GMT +05:00)', value: 'Asia/Aqtau' },
	{ label: 'Asia/Aqtobe (GMT +05:00)', value: 'Asia/Aqtobe' },
	{ label: 'Asia/Ashgabat (GMT +05:00)', value: 'Asia/Ashgabat' },
	{ label: 'Asia/Ashkhabad (GMT +05:00)', value: 'Asia/Ashkhabad' },
	{ label: 'Asia/Atyrau (GMT +05:00)', value: 'Asia/Atyrau' },
	{ label: 'Asia/Baghdad (GMT +03:00)', value: 'Asia/Baghdad' },
	{ label: 'Asia/Bahrain (GMT +03:00)', value: 'Asia/Bahrain' },
	{ label: 'Asia/Baku (GMT +04:00)', value: 'Asia/Baku' },
	{ label: 'Asia/Bangkok (GMT +07:00)', value: 'Asia/Bangkok' },
	{ label: 'Asia/Barnaul (GMT +07:00)', value: 'Asia/Barnaul' },
	{ label: 'Asia/Beirut (GMT +02:00)', value: 'Asia/Beirut' },
	{ label: 'Asia/Bishkek (GMT +06:00)', value: 'Asia/Bishkek' },
	{ label: 'Asia/Brunei (GMT +08:00)', value: 'Asia/Brunei' },
	{ label: 'Asia/Calcutta (GMT +05:30)', value: 'Asia/Calcutta' },
	{ label: 'Asia/Chita (GMT +09:00)', value: 'Asia/Chita' },
	{ label: 'Asia/Choibalsan (GMT +08:00)', value: 'Asia/Choibalsan' },
	{ label: 'Asia/Chongqing (GMT +08:00)', value: 'Asia/Chongqing' },
	{ label: 'Asia/Chungking (GMT +08:00)', value: 'Asia/Chungking' },
	{ label: 'Asia/Colombo (GMT +05:30)', value: 'Asia/Colombo' },
	{ label: 'Asia/Dacca (GMT +06:00)', value: 'Asia/Dacca' },
	{ label: 'Asia/Damascus (GMT +02:00)', value: 'Asia/Damascus' },
	{ label: 'Asia/Dhaka (GMT +06:00)', value: 'Asia/Dhaka' },
	{ label: 'Asia/Dili (GMT +09:00)', value: 'Asia/Dili' },
	{ label: 'Asia/Dubai (GMT +04:00)', value: 'Asia/Dubai' },
	{ label: 'Asia/Dushanbe (GMT +05:00)', value: 'Asia/Dushanbe' },
	{ label: 'Asia/Famagusta (GMT +02:00)', value: 'Asia/Famagusta' },
	{ label: 'Asia/Gaza (GMT +02:00)', value: 'Asia/Gaza' },
	{ label: 'Asia/Harbin (GMT +08:00)', value: 'Asia/Harbin' },
	{ label: 'Asia/Hebron (GMT +02:00)', value: 'Asia/Hebron' },
	{ label: 'Asia/Ho_Chi_Minh (GMT +07:00)', value: 'Asia/Ho_Chi_Minh' },
	{ label: 'Asia/Hong_Kong (GMT +08:00)', value: 'Asia/Hong_Kong' },
	{ label: 'Asia/Hovd (GMT +07:00)', value: 'Asia/Hovd' },
	{ label: 'Asia/Irkutsk (GMT +08:00)', value: 'Asia/Irkutsk' },
	{ label: 'Asia/Istanbul (GMT +03:00)', value: 'Asia/Istanbul' },
	{ label: 'Asia/Jakarta (GMT +07:00)', value: 'Asia/Jakarta' },
	{ label: 'Asia/Jayapura (GMT +09:00)', value: 'Asia/Jayapura' },
	{ label: 'Asia/Jerusalem (GMT +02:00)', value: 'Asia/Jerusalem' },
	{ label: 'Asia/Kabul (GMT +04:30)', value: 'Asia/Kabul' },
	{ label: 'Asia/Kamchatka (GMT +12:00)', value: 'Asia/Kamchatka' },
	{ label: 'Asia/Karachi (GMT +05:00)', value: 'Asia/Karachi' },
	{ label: 'Asia/Kashgar (GMT +06:00)', value: 'Asia/Kashgar' },
	{ label: 'Asia/Kathmandu (GMT +05:45)', value: 'Asia/Kathmandu' },
	{ label: 'Asia/Katmandu (GMT +05:45)', value: 'Asia/Katmandu' },
	{ label: 'Asia/Khandyga (GMT +09:00)', value: 'Asia/Khandyga' },
	{ label: 'Asia/Kolkata (GMT +05:30)', value: 'Asia/Kolkata' },
	{ label: 'Asia/Krasnoyarsk (GMT +07:00)', value: 'Asia/Krasnoyarsk' },
	{ label: 'Asia/Kuala_Lumpur (GMT +08:00)', value: 'Asia/Kuala_Lumpur' },
	{ label: 'Asia/Kuching (GMT +08:00)', value: 'Asia/Kuching' },
	{ label: 'Asia/Kuwait (GMT +03:00)', value: 'Asia/Kuwait' },
	{ label: 'Asia/Macao (GMT +08:00)', value: 'Asia/Macao' },
	{ label: 'Asia/Macau (GMT +08:00)', value: 'Asia/Macau' },
	{ label: 'Asia/Magadan (GMT +11:00)', value: 'Asia/Magadan' },
	{ label: 'Asia/Makassar (GMT +08:00)', value: 'Asia/Makassar' },
	{ label: 'Asia/Manila (GMT +08:00)', value: 'Asia/Manila' },
	{ label: 'Asia/Muscat (GMT +04:00)', value: 'Asia/Muscat' },
	{ label: 'Asia/Nicosia (GMT +02:00)', value: 'Asia/Nicosia' },
	{ label: 'Asia/Novokuznetsk (GMT +07:00)', value: 'Asia/Novokuznetsk' },
	{ label: 'Asia/Novosibirsk (GMT +07:00)', value: 'Asia/Novosibirsk' },
	{ label: 'Asia/Omsk (GMT +06:00)', value: 'Asia/Omsk' },
	{ label: 'Asia/Oral (GMT +05:00)', value: 'Asia/Oral' },
	{ label: 'Asia/Phnom_Penh (GMT +07:00)', value: 'Asia/Phnom_Penh' },
	{ label: 'Asia/Pontianak (GMT +07:00)', value: 'Asia/Pontianak' },
	{ label: 'Asia/Pyongyang (GMT +09:00)', value: 'Asia/Pyongyang' },
	{ label: 'Asia/Qatar (GMT +03:00)', value: 'Asia/Qatar' },
	{ label: 'Asia/Qostanay (GMT +06:00)', value: 'Asia/Qostanay' },
	{ label: 'Asia/Qyzylorda (GMT +05:00)', value: 'Asia/Qyzylorda' },
	{ label: 'Asia/Rangoon (GMT +06:30)', value: 'Asia/Rangoon' },
	{ label: 'Asia/Riyadh (GMT +03:00)', value: 'Asia/Riyadh' },
	{ label: 'Asia/Saigon (GMT +07:00)', value: 'Asia/Saigon' },
	{ label: 'Asia/Sakhalin (GMT +11:00)', value: 'Asia/Sakhalin' },
	{ label: 'Asia/Samarkand (GMT +05:00)', value: 'Asia/Samarkand' },
	{ label: 'Asia/Seoul (GMT +09:00)', value: 'Asia/Seoul' },
	{ label: 'Asia/Shanghai (GMT +08:00)', value: 'Asia/Shanghai' },
	{ label: 'Asia/Singapore (GMT +08:00)', value: 'Asia/Singapore' },
	{ label: 'Asia/Srednekolymsk (GMT +11:00)', value: 'Asia/Srednekolymsk' },
	{ label: 'Asia/Taipei (GMT +08:00)', value: 'Asia/Taipei' },
	{ label: 'Asia/Tashkent (GMT +05:00)', value: 'Asia/Tashkent' },
	{ label: 'Asia/Tbilisi (GMT +04:00)', value: 'Asia/Tbilisi' },
	{ label: 'Asia/Tehran (GMT +03:30)', value: 'Asia/Tehran' },
	{ label: 'Asia/Tel_Aviv (GMT +02:00)', value: 'Asia/Tel_Aviv' },
	{ label: 'Asia/Thimbu (GMT +06:00)', value: 'Asia/Thimbu' },
	{ label: 'Asia/Thimphu (GMT +06:00)', value: 'Asia/Thimphu' },
	{ label: 'Asia/Tokyo (GMT +09:00)', value: 'Asia/Tokyo' },
	{ label: 'Asia/Tomsk (GMT +07:00)', value: 'Asia/Tomsk' },
	{ label: 'Asia/Ujung_Pandang (GMT +08:00)', value: 'Asia/Ujung_Pandang' },
	{ label: 'Asia/Ulaanbaatar (GMT +08:00)', value: 'Asia/Ulaanbaatar' },
	{ label: 'Asia/Ulan_Bator (GMT +08:00)', value: 'Asia/Ulan_Bator' },
	{ label: 'Asia/Urumqi (GMT +06:00)', value: 'Asia/Urumqi' },
	{ label: 'Asia/Ust-Nera (GMT +10:00)', value: 'Asia/Ust-Nera' },
	{ label: 'Asia/Vientiane (GMT +07:00)', value: 'Asia/Vientiane' },
	{ label: 'Asia/Vladivostok (GMT +10:00)', value: 'Asia/Vladivostok' },
	{ label: 'Asia/Yakutsk (GMT +09:00)', value: 'Asia/Yakutsk' },
	{ label: 'Asia/Yangon (GMT +06:30)', value: 'Asia/Yangon' },
	{ label: 'Asia/Yekaterinburg (GMT +05:00)', value: 'Asia/Yekaterinburg' },
	{ label: 'Asia/Yerevan (GMT +04:00)', value: 'Asia/Yerevan' },
	{ label: 'Atlantic/Azores (GMT -01:00)', value: 'Atlantic/Azores' },
	{ label: 'Atlantic/Bermuda (GMT -04:00)', value: 'Atlantic/Bermuda' },
	{ label: 'Atlantic/Canary (GMT +00:00)', value: 'Atlantic/Canary' },
	{ label: 'Atlantic/Cape_Verde (GMT -01:00)', value: 'Atlantic/Cape_Verde' },
	{ label: 'Atlantic/Faeroe (GMT +00:00)', value: 'Atlantic/Faeroe' },
	{ label: 'Atlantic/Faroe (GMT +00:00)', value: 'Atlantic/Faroe' },
	{ label: 'Atlantic/Jan_Mayen (GMT +01:00)', value: 'Atlantic/Jan_Mayen' },
	{ label: 'Atlantic/Madeira (GMT +00:00)', value: 'Atlantic/Madeira' },
	{ label: 'Atlantic/Reykjavik (GMT +00:00)', value: 'Atlantic/Reykjavik' },
	{ label: 'Atlantic/South_Georgia (GMT -02:00)', value: 'Atlantic/South_Georgia' },
	{ label: 'Atlantic/St_Helena (GMT +00:00)', value: 'Atlantic/St_Helena' },
	{ label: 'Atlantic/Stanley (GMT -03:00)', value: 'Atlantic/Stanley' },
	{ label: 'Australia/ACT (GMT +11:00)', value: 'Australia/ACT' },
	{ label: 'Australia/Adelaide (GMT +10:30)', value: 'Australia/Adelaide' },
	{ label: 'Australia/Brisbane (GMT +10:00)', value: 'Australia/Brisbane' },
	{ label: 'Australia/Broken_Hill (GMT +10:30)', value: 'Australia/Broken_Hill' },
	{ label: 'Australia/Canberra (GMT +11:00)', value: 'Australia/Canberra' },
	{ label: 'Australia/Currie (GMT +11:00)', value: 'Australia/Currie' },
	{ label: 'Australia/Darwin (GMT +09:30)', value: 'Australia/Darwin' },
	{ label: 'Australia/Eucla (GMT +08:45)', value: 'Australia/Eucla' },
	{ label: 'Australia/Hobart (GMT +11:00)', value: 'Australia/Hobart' },
	{ label: 'Australia/LHI (GMT +11:00)', value: 'Australia/LHI' },
	{ label: 'Australia/Lindeman (GMT +10:00)', value: 'Australia/Lindeman' },
	{ label: 'Australia/Lord_Howe (GMT +11:00)', value: 'Australia/Lord_Howe' },
	{ label: 'Australia/Melbourne (GMT +11:00)', value: 'Australia/Melbourne' },
	{ label: 'Australia/NSW (GMT +11:00)', value: 'Australia/NSW' },
	{ label: 'Australia/North (GMT +09:30)', value: 'Australia/North' },
	{ label: 'Australia/Perth (GMT +08:00)', value: 'Australia/Perth' },
	{ label: 'Australia/Queensland (GMT +10:00)', value: 'Australia/Queensland' },
	{ label: 'Australia/South (GMT +10:30)', value: 'Australia/South' },
	{ label: 'Australia/Sydney (GMT +11:00)', value: 'Australia/Sydney' },
	{ label: 'Australia/Tasmania (GMT +11:00)', value: 'Australia/Tasmania' },
	{ label: 'Australia/Victoria (GMT +11:00)', value: 'Australia/Victoria' },
	{ label: 'Australia/West (GMT +08:00)', value: 'Australia/West' },
	{ label: 'Australia/Yancowinna (GMT +10:30)', value: 'Australia/Yancowinna' },
	{ label: 'Brazil/Acre (GMT -05:00)', value: 'Brazil/Acre' },
	{ label: 'Brazil/DeNoronha (GMT -02:00)', value: 'Brazil/DeNoronha' },
	{ label: 'Brazil/East (GMT -03:00)', value: 'Brazil/East' },
	{ label: 'Brazil/West (GMT -04:00)', value: 'Brazil/West' },
	{ label: 'CET (GMT +01:00)', value: 'CET' },
	{ label: 'CST6CDT (GMT -06:00)', value: 'CST6CDT' },
	{ label: 'Canada/Atlantic (GMT -04:00)', value: 'Canada/Atlantic' },
	{ label: 'Canada/Central (GMT -06:00)', value: 'Canada/Central' },
	{ label: 'Canada/Eastern (GMT -05:00)', value: 'Canada/Eastern' },
	{ label: 'Canada/Mountain (GMT -07:00)', value: 'Canada/Mountain' },
	{ label: 'Canada/Newfoundland (GMT -03:30)', value: 'Canada/Newfoundland' },
	{ label: 'Canada/Pacific (GMT -08:00)', value: 'Canada/Pacific' },
	{ label: 'Canada/Saskatchewan (GMT -06:00)', value: 'Canada/Saskatchewan' },
	{ label: 'Canada/Yukon (GMT -07:00)', value: 'Canada/Yukon' },
	{ label: 'Chile/Continental (GMT -03:00)', value: 'Chile/Continental' },
	{ label: 'Chile/EasterIsland (GMT -05:00)', value: 'Chile/EasterIsland' },
	{ label: 'Cuba (GMT -05:00)', value: 'Cuba' },
	{ label: 'EET (GMT +02:00)', value: 'EET' },
	{ label: 'EST (GMT -05:00)', value: 'EST' },
	{ label: 'EST5EDT (GMT -05:00)', value: 'EST5EDT' },
	{ label: 'Egypt (GMT +02:00)', value: 'Egypt' },
	{ label: 'Eire (GMT +00:00)', value: 'Eire' },
	{ label: 'Etc/GMT (GMT +00:00)', value: 'Etc/GMT' },
	{ label: 'Etc/GMT+0 (GMT +00:00)', value: 'Etc/GMT+0' },
	{ label: 'Etc/GMT+1 (GMT -01:00)', value: 'Etc/GMT+1' },
	{ label: 'Etc/GMT+10 (GMT -10:00)', value: 'Etc/GMT+10' },
	{ label: 'Etc/GMT+11 (GMT -11:00)', value: 'Etc/GMT+11' },
	{ label: 'Etc/GMT+12 (GMT -12:00)', value: 'Etc/GMT+12' },
	{ label: 'Etc/GMT+2 (GMT -02:00)', value: 'Etc/GMT+2' },
	{ label: 'Etc/GMT+3 (GMT -03:00)', value: 'Etc/GMT+3' },
	{ label: 'Etc/GMT+4 (GMT -04:00)', value: 'Etc/GMT+4' },
	{ label: 'Etc/GMT+5 (GMT -05:00)', value: 'Etc/GMT+5' },
	{ label: 'Etc/GMT+6 (GMT -06:00)', value: 'Etc/GMT+6' },
	{ label: 'Etc/GMT+7 (GMT -07:00)', value: 'Etc/GMT+7' },
	{ label: 'Etc/GMT+8 (GMT -08:00)', value: 'Etc/GMT+8' },
	{ label: 'Etc/GMT+9 (GMT -09:00)', value: 'Etc/GMT+9' },
	{ label: 'Etc/GMT-0 (GMT +00:00)', value: 'Etc/GMT-0' },
	{ label: 'Etc/GMT-1 (GMT +01:00)', value: 'Etc/GMT-1' },
	{ label: 'Etc/GMT-10 (GMT +10:00)', value: 'Etc/GMT-10' },
	{ label: 'Etc/GMT-11 (GMT +11:00)', value: 'Etc/GMT-11' },
	{ label: 'Etc/GMT-12 (GMT +12:00)', value: 'Etc/GMT-12' },
	{ label: 'Etc/GMT-13 (GMT +13:00)', value: 'Etc/GMT-13' },
	{ label: 'Etc/GMT-14 (GMT +14:00)', value: 'Etc/GMT-14' },
	{ label: 'Etc/GMT-2 (GMT +02:00)', value: 'Etc/GMT-2' },
	{ label: 'Etc/GMT-3 (GMT +03:00)', value: 'Etc/GMT-3' },
	{ label: 'Etc/GMT-4 (GMT +04:00)', value: 'Etc/GMT-4' },
	{ label: 'Etc/GMT-5 (GMT +05:00)', value: 'Etc/GMT-5' },
	{ label: 'Etc/GMT-6 (GMT +06:00)', value: 'Etc/GMT-6' },
	{ label: 'Etc/GMT-7 (GMT +07:00)', value: 'Etc/GMT-7' },
	{ label: 'Etc/GMT-8 (GMT +08:00)', value: 'Etc/GMT-8' },
	{ label: 'Etc/GMT-9 (GMT +09:00)', value: 'Etc/GMT-9' },
	{ label: 'Etc/GMT0 (GMT +00:00)', value: 'Etc/GMT0' },
	{ label: 'Etc/Greenwich (GMT +00:00)', value: 'Etc/Greenwich' },
	{ label: 'Etc/UCT (GMT +00:00)', value: 'Etc/UCT' },
	{ label: 'Etc/UTC (GMT +00:00)', value: 'Etc/UTC' },
	{ label: 'Etc/Universal (GMT +00:00)', value: 'Etc/Universal' },
	{ label: 'Etc/Zulu (GMT +00:00)', value: 'Etc/Zulu' },
	{ label: 'Europe/Amsterdam (GMT +01:00)', value: 'Europe/Amsterdam' },
	{ label: 'Europe/Andorra (GMT +01:00)', value: 'Europe/Andorra' },
	{ label: 'Europe/Astrakhan (GMT +04:00)', value: 'Europe/Astrakhan' },
	{ label: 'Europe/Athens (GMT +02:00)', value: 'Europe/Athens' },
	{ label: 'Europe/Belfast (GMT +00:00)', value: 'Europe/Belfast' },
	{ label: 'Europe/Belgrade (GMT +01:00)', value: 'Europe/Belgrade' },
	{ label: 'Europe/Berlin (GMT +01:00)', value: 'Europe/Berlin' },
	{ label: 'Europe/Bratislava (GMT +01:00)', value: 'Europe/Bratislava' },
	{ label: 'Europe/Brussels (GMT +01:00)', value: 'Europe/Brussels' },
	{ label: 'Europe/Bucharest (GMT +02:00)', value: 'Europe/Bucharest' },
	{ label: 'Europe/Budapest (GMT +01:00)', value: 'Europe/Budapest' },
	{ label: 'Europe/Busingen (GMT +01:00)', value: 'Europe/Busingen' },
	{ label: 'Europe/Chisinau (GMT +02:00)', value: 'Europe/Chisinau' },
	{ label: 'Europe/Copenhagen (GMT +01:00)', value: 'Europe/Copenhagen' },
	{ label: 'Europe/Dublin (GMT +00:00)', value: 'Europe/Dublin' },
	{ label: 'Europe/Gibraltar (GMT +01:00)', value: 'Europe/Gibraltar' },
	{ label: 'Europe/Guernsey (GMT +00:00)', value: 'Europe/Guernsey' },
	{ label: 'Europe/Helsinki (GMT +02:00)', value: 'Europe/Helsinki' },
	{ label: 'Europe/Isle_of_Man (GMT +00:00)', value: 'Europe/Isle_of_Man' },
	{ label: 'Europe/Istanbul (GMT +03:00)', value: 'Europe/Istanbul' },
	{ label: 'Europe/Jersey (GMT +00:00)', value: 'Europe/Jersey' },
	{ label: 'Europe/Kaliningrad (GMT +02:00)', value: 'Europe/Kaliningrad' },
	{ label: 'Europe/Kiev (GMT +02:00)', value: 'Europe/Kiev' },
	{ label: 'Europe/Kirov (GMT +03:00)', value: 'Europe/Kirov' },
	{ label: 'Europe/Lisbon (GMT +00:00)', value: 'Europe/Lisbon' },
	{ label: 'Europe/Ljubljana (GMT +01:00)', value: 'Europe/Ljubljana' },
	{ label: 'Europe/London (GMT +00:00)', value: 'Europe/London' },
	{ label: 'Europe/Luxembourg (GMT +01:00)', value: 'Europe/Luxembourg' },
	{ label: 'Europe/Madrid (GMT +01:00)', value: 'Europe/Madrid' },
	{ label: 'Europe/Malta (GMT +01:00)', value: 'Europe/Malta' },
	{ label: 'Europe/Mariehamn (GMT +02:00)', value: 'Europe/Mariehamn' },
	{ label: 'Europe/Minsk (GMT +03:00)', value: 'Europe/Minsk' },
	{ label: 'Europe/Monaco (GMT +01:00)', value: 'Europe/Monaco' },
	{ label: 'Europe/Moscow (GMT +03:00)', value: 'Europe/Moscow' },
	{ label: 'Europe/Nicosia (GMT +02:00)', value: 'Europe/Nicosia' },
	{ label: 'Europe/Oslo (GMT +01:00)', value: 'Europe/Oslo' },
	{ label: 'Europe/Paris (GMT +01:00)', value: 'Europe/Paris' },
	{ label: 'Europe/Podgorica (GMT +01:00)', value: 'Europe/Podgorica' },
	{ label: 'Europe/Prague (GMT +01:00)', value: 'Europe/Prague' },
	{ label: 'Europe/Riga (GMT +02:00)', value: 'Europe/Riga' },
	{ label: 'Europe/Rome (GMT +01:00)', value: 'Europe/Rome' },
	{ label: 'Europe/Samara (GMT +04:00)', value: 'Europe/Samara' },
	{ label: 'Europe/San_Marino (GMT +01:00)', value: 'Europe/San_Marino' },
	{ label: 'Europe/Sarajevo (GMT +01:00)', value: 'Europe/Sarajevo' },
	{ label: 'Europe/Saratov (GMT +04:00)', value: 'Europe/Saratov' },
	{ label: 'Europe/Simferopol (GMT +03:00)', value: 'Europe/Simferopol' },
	{ label: 'Europe/Skopje (GMT +01:00)', value: 'Europe/Skopje' },
	{ label: 'Europe/Sofia (GMT +02:00)', value: 'Europe/Sofia' },
	{ label: 'Europe/Stockholm (GMT +01:00)', value: 'Europe/Stockholm' },
	{ label: 'Europe/Tallinn (GMT +02:00)', value: 'Europe/Tallinn' },
	{ label: 'Europe/Tirane (GMT +01:00)', value: 'Europe/Tirane' },
	{ label: 'Europe/Tiraspol (GMT +02:00)', value: 'Europe/Tiraspol' },
	{ label: 'Europe/Ulyanovsk (GMT +04:00)', value: 'Europe/Ulyanovsk' },
	{ label: 'Europe/Uzhgorod (GMT +02:00)', value: 'Europe/Uzhgorod' },
	{ label: 'Europe/Vaduz (GMT +01:00)', value: 'Europe/Vaduz' },
	{ label: 'Europe/Vatican (GMT +01:00)', value: 'Europe/Vatican' },
	{ label: 'Europe/Vienna (GMT +01:00)', value: 'Europe/Vienna' },
	{ label: 'Europe/Vilnius (GMT +02:00)', value: 'Europe/Vilnius' },
	{ label: 'Europe/Volgograd (GMT +03:00)', value: 'Europe/Volgograd' },
	{ label: 'Europe/Warsaw (GMT +01:00)', value: 'Europe/Warsaw' },
	{ label: 'Europe/Zagreb (GMT +01:00)', value: 'Europe/Zagreb' },
	{ label: 'Europe/Zaporozhye (GMT +02:00)', value: 'Europe/Zaporozhye' },
	{ label: 'Europe/Zurich (GMT +01:00)', value: 'Europe/Zurich' },
	{ label: 'GB (GMT +00:00)', value: 'GB' },
	{ label: 'GB-Eire (GMT +00:00)', value: 'GB-Eire' },
	{ label: 'GMT (GMT +00:00)', value: 'GMT' },
	{ label: 'GMT+0 (GMT +00:00)', value: 'GMT+0' },
	{ label: 'GMT-0 (GMT +00:00)', value: 'GMT-0' },
	{ label: 'GMT0 (GMT +00:00)', value: 'GMT0' },
	{ label: 'Greenwich (GMT +00:00)', value: 'Greenwich' },
	{ label: 'HST (GMT -10:00)', value: 'HST' },
	{ label: 'Hongkong (GMT +08:00)', value: 'Hongkong' },
	{ label: 'Iceland (GMT +00:00)', value: 'Iceland' },
	{ label: 'Indian/Antananarivo (GMT +03:00)', value: 'Indian/Antananarivo' },
	{ label: 'Indian/Chagos (GMT +06:00)', value: 'Indian/Chagos' },
	{ label: 'Indian/Christmas (GMT +07:00)', value: 'Indian/Christmas' },
	{ label: 'Indian/Cocos (GMT +06:30)', value: 'Indian/Cocos' },
	{ label: 'Indian/Comoro (GMT +03:00)', value: 'Indian/Comoro' },
	{ label: 'Indian/Kerguelen (GMT +05:00)', value: 'Indian/Kerguelen' },
	{ label: 'Indian/Mahe (GMT +04:00)', value: 'Indian/Mahe' },
	{ label: 'Indian/Maldives (GMT +05:00)', value: 'Indian/Maldives' },
	{ label: 'Indian/Mauritius (GMT +04:00)', value: 'Indian/Mauritius' },
	{ label: 'Indian/Mayotte (GMT +03:00)', value: 'Indian/Mayotte' },
	{ label: 'Indian/Reunion (GMT +04:00)', value: 'Indian/Reunion' },
	{ label: 'Iran (GMT +03:30)', value: 'Iran' },
	{ label: 'Israel (GMT +02:00)', value: 'Israel' },
	{ label: 'Jamaica (GMT -05:00)', value: 'Jamaica' },
	{ label: 'Japan (GMT +09:00)', value: 'Japan' },
	{ label: 'Kwajalein (GMT +12:00)', value: 'Kwajalein' },
	{ label: 'Libya (GMT +02:00)', value: 'Libya' },
	{ label: 'MET (GMT +01:00)', value: 'MET' },
	{ label: 'MST (GMT -07:00)', value: 'MST' },
	{ label: 'MST7MDT (GMT -07:00)', value: 'MST7MDT' },
	{ label: 'Mexico/BajaNorte (GMT -08:00)', value: 'Mexico/BajaNorte' },
	{ label: 'Mexico/BajaSur (GMT -07:00)', value: 'Mexico/BajaSur' },
	{ label: 'Mexico/General (GMT -06:00)', value: 'Mexico/General' },
	{ label: 'NZ (GMT +13:00)', value: 'NZ' },
	{ label: 'NZ-CHAT (GMT +13:45)', value: 'NZ-CHAT' },
	{ label: 'Navajo (GMT -07:00)', value: 'Navajo' },
	{ label: 'PRC (GMT +08:00)', value: 'PRC' },
	{ label: 'PST8PDT (GMT -08:00)', value: 'PST8PDT' },
	{ label: 'Pacific/Apia (GMT +13:00)', value: 'Pacific/Apia' },
	{ label: 'Pacific/Auckland (GMT +13:00)', value: 'Pacific/Auckland' },
	{ label: 'Pacific/Bougainville (GMT +11:00)', value: 'Pacific/Bougainville' },
	{ label: 'Pacific/Chatham (GMT +13:45)', value: 'Pacific/Chatham' },
	{ label: 'Pacific/Chuuk (GMT +10:00)', value: 'Pacific/Chuuk' },
	{ label: 'Pacific/Easter (GMT -05:00)', value: 'Pacific/Easter' },
	{ label: 'Pacific/Efate (GMT +11:00)', value: 'Pacific/Efate' },
	{ label: 'Pacific/Enderbury (GMT +13:00)', value: 'Pacific/Enderbury' },
	{ label: 'Pacific/Fakaofo (GMT +13:00)', value: 'Pacific/Fakaofo' },
	{ label: 'Pacific/Fiji (GMT +13:00)', value: 'Pacific/Fiji' },
	{ label: 'Pacific/Funafuti (GMT +12:00)', value: 'Pacific/Funafuti' },
	{ label: 'Pacific/Galapagos (GMT -06:00)', value: 'Pacific/Galapagos' },
	{ label: 'Pacific/Gambier (GMT -09:00)', value: 'Pacific/Gambier' },
	{ label: 'Pacific/Guadalcanal (GMT +11:00)', value: 'Pacific/Guadalcanal' },
	{ label: 'Pacific/Guam (GMT +10:00)', value: 'Pacific/Guam' },
	{ label: 'Pacific/Honolulu (GMT -10:00)', value: 'Pacific/Honolulu' },
	{ label: 'Pacific/Johnston (GMT -10:00)', value: 'Pacific/Johnston' },
	{ label: 'Pacific/Kanton (GMT +13:00)', value: 'Pacific/Kanton' },
	{ label: 'Pacific/Kiritimati (GMT +14:00)', value: 'Pacific/Kiritimati' },
	{ label: 'Pacific/Kosrae (GMT +11:00)', value: 'Pacific/Kosrae' },
	{ label: 'Pacific/Kwajalein (GMT +12:00)', value: 'Pacific/Kwajalein' },
	{ label: 'Pacific/Majuro (GMT +12:00)', value: 'Pacific/Majuro' },
	{ label: 'Pacific/Marquesas (GMT -09:30)', value: 'Pacific/Marquesas' },
	{ label: 'Pacific/Midway (GMT -11:00)', value: 'Pacific/Midway' },
	{ label: 'Pacific/Nauru (GMT +12:00)', value: 'Pacific/Nauru' },
	{ label: 'Pacific/Niue (GMT -11:00)', value: 'Pacific/Niue' },
	{ label: 'Pacific/Norfolk (GMT +12:00)', value: 'Pacific/Norfolk' },
	{ label: 'Pacific/Noumea (GMT +11:00)', value: 'Pacific/Noumea' },
	{ label: 'Pacific/Pago_Pago (GMT -11:00)', value: 'Pacific/Pago_Pago' },
	{ label: 'Pacific/Palau (GMT +09:00)', value: 'Pacific/Palau' },
	{ label: 'Pacific/Pitcairn (GMT -08:00)', value: 'Pacific/Pitcairn' },
	{ label: 'Pacific/Pohnpei (GMT +11:00)', value: 'Pacific/Pohnpei' },
	{ label: 'Pacific/Ponape (GMT +11:00)', value: 'Pacific/Ponape' },
	{ label: 'Pacific/Port_Moresby (GMT +10:00)', value: 'Pacific/Port_Moresby' },
	{ label: 'Pacific/Rarotonga (GMT -10:00)', value: 'Pacific/Rarotonga' },
	{ label: 'Pacific/Saipan (GMT +10:00)', value: 'Pacific/Saipan' },
	{ label: 'Pacific/Samoa (GMT -11:00)', value: 'Pacific/Samoa' },
	{ label: 'Pacific/Tahiti (GMT -10:00)', value: 'Pacific/Tahiti' },
	{ label: 'Pacific/Tarawa (GMT +12:00)', value: 'Pacific/Tarawa' },
	{ label: 'Pacific/Tongatapu (GMT +13:00)', value: 'Pacific/Tongatapu' },
	{ label: 'Pacific/Truk (GMT +10:00)', value: 'Pacific/Truk' },
	{ label: 'Pacific/Wake (GMT +12:00)', value: 'Pacific/Wake' },
	{ label: 'Pacific/Wallis (GMT +12:00)', value: 'Pacific/Wallis' },
	{ label: 'Pacific/Yap (GMT +10:00)', value: 'Pacific/Yap' },
	{ label: 'Poland (GMT +01:00)', value: 'Poland' },
	{ label: 'Portugal (GMT +00:00)', value: 'Portugal' },
	{ label: 'ROC (GMT +08:00)', value: 'ROC' },
	{ label: 'ROK (GMT +09:00)', value: 'ROK' },
	{ label: 'Singapore (GMT +08:00)', value: 'Singapore' },
	{ label: 'Turkey (GMT +03:00)', value: 'Turkey' },
	{ label: 'UCT (GMT +00:00)', value: 'UCT' },
	{ label: 'US/Alaska (GMT -09:00)', value: 'US/Alaska' },
	{ label: 'US/Aleutian (GMT -10:00)', value: 'US/Aleutian' },
	{ label: 'US/Arizona (GMT -07:00)', value: 'US/Arizona' },
	{ label: 'US/Central (GMT -06:00)', value: 'US/Central' },
	{ label: 'US/East-Indiana (GMT -05:00)', value: 'US/East-Indiana' },
	{ label: 'US/Eastern (GMT -05:00)', value: 'US/Eastern' },
	{ label: 'US/Hawaii (GMT -10:00)', value: 'US/Hawaii' },
	{ label: 'US/Indiana-Starke (GMT -06:00)', value: 'US/Indiana-Starke' },
	{ label: 'US/Michigan (GMT -05:00)', value: 'US/Michigan' },
	{ label: 'US/Mountain (GMT -07:00)', value: 'US/Mountain' },
	{ label: 'US/Pacific (GMT -08:00)', value: 'US/Pacific' },
	{ label: 'US/Samoa (GMT -11:00)', value: 'US/Samoa' },
	{ label: 'UTC (GMT +00:00)', value: 'UTC' },
	{ label: 'Universal (GMT +00:00)', value: 'Universal' },
	{ label: 'W-SU (GMT +03:00)', value: 'W-SU' },
	{ label: 'WET (GMT +00:00)', value: 'WET' },
	{ label: 'Zulu (GMT +00:00)', value: 'Zulu' },
];

export default ttgTimeZones;
