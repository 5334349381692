import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import RouteNames from '../router/RouteNames';
import { useCustomer } from './CustomerProvider';
import { Navigate } from 'react-router';

export const AuthProvider = ({ children }) => {
	const { customer } = useCustomer();
	const isLoggedIn = React.useMemo(() => customer?.customers_id, [customer]);
	const location = useLocation();
	const navigate = useNavigate();

	React.useEffect(() => {
		if (customer?.needs_password_change && location.pathname !== RouteNames.ChangePassword) {
			navigate(RouteNames.ChangePassword);
		}
	}, [customer, location, navigate]);

	if (!isLoggedIn && location.pathname !== RouteNames.Login) {
		const redirectParam = `${location.pathname}${location.search}`;
		return (
			<Navigate
				to={
					redirectParam
						? `${RouteNames.Login}?redirect=${encodeURIComponent(redirectParam)}`
						: RouteNames.Login
				}
				replace={true}
			/>
		);
	}

	return children;
};

export const AuthContextLayout = () => (
	<AuthProvider>
		<Outlet />
	</AuthProvider>
);
