import { emailRegex } from "./regex.helper";

export class EmailUtils {
  public static isValidEmail(val: string): boolean {

    if (!val) {
      return false;
    }

    return !(!val.match(emailRegex) || val?.match(emailRegex)?.[0] !== val);
  }

  public static getEmailDomain(val: string): string {

    if (!val) {
      return '';
    }

    return val?.split('@')?.pop();
  }
}
