import React from 'react';
import { AppDropdown, AppInput } from '../../../App';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { AssessmentSetupNavigation, DeleteDraftAssessment } from '../../index';
import { useNavigate } from 'react-router-dom';
import { useAssessments } from '../../../../providers/AssessmentProvider';
import AssessmentService from '../../../../services/AssessmentService';

const CompanyDetailsStep = ({ nextStep }) => {
	const navigate = useNavigate();

	const [setupOptions, setSetupOptions] = React.useState({industries: [], functions: [], companySizes: []});
	const [loadingOptions, setLoadingOptions] = React.useState(false);
	const { industries, functions, companySizes } = setupOptions;
	const { setupAssessment, setSetupAssessment } = useAssessments();

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		control,
		reset,
	} = useForm();

	React.useEffect(() => {
		if (industries.length === 0 || functions.length === 0 || companySizes.length === 0) {
			setLoadingOptions(true);
			AssessmentService.getSetupOptions()
				.then((res) => {
					setSetupOptions(res);
				})
				.finally(() => setLoadingOptions(false));
		}
		reset(setupAssessment?.company);
	}, []);

	const { isSubmitted } = useFormState({ control });

	const trimInputValue = (name, e) => {
		setValue(name, e.target.value.trim(), {
			shouldValidate: isSubmitted,
			shouldDirty: true,
		});
	};

	const _next = (payload) => {
		setSetupAssessment((prev) => ({ ...prev, company: payload }));
		nextStep();
	};

	return (
		<div className={'ttg-assessment-section flex-col'}>
			<div>
				<h1 className={'ttg-text-lg text-gray-900'}>Company details</h1>
				<p className={'ttg-text-sm text-gray-500'}>
					Enter the company details. This information will appear on the final report.
				</p>
			</div>
			<form onSubmit={handleSubmit(_next)} id={'ttg-team-assessment-setup-company-form'} className={'mt-4'}>
				<div className='short-form'>
					<AppInput
						id={'ttg-team-setup-company-details-company-name'}
						label={'Company Name'}
						errors={errors}
						{...register('company_name', {
							required: 'You must enter a company name.',
							maxLength: 255,
						})}
						data-ttg-max-length={255}
						onBlur={(e) => trimInputValue('company_name', e)}
					/>
				</div>
				<div className='flex flex-col md:flex-row'>
					<div className='flex flex-1 md:mr-2'>
						<Controller
							control={control}
							name={'industry'}
							render={({ field }) => (
								<AppDropdown
									{...field}
									label={
										<p>
											Industry <span className={'text-gray-500'}>(Optional)</span>
										</p>
									}
									formatOptionLabel={({ label }) => (
										<p dangerouslySetInnerHTML={{ __html: label }}></p>
									)}
									isLoading={loadingOptions}
									options={industries}
								/>
							)}
						/>
					</div>
					<div className='flex flex-1 md:mx-2'>
						<Controller
							control={control}
							name={'function_name'}
							render={({ field }) => (
								<AppDropdown
									{...field}
									label={
										<p>
											Function <span className={'text-gray-500'}>(Optional)</span>
										</p>
									}
									formatOptionLabel={({ label }) => (
										<p dangerouslySetInnerHTML={{ __html: label }}></p>
									)}
									isLoading={loadingOptions}
									options={functions}
								/>
							)}
						/>
					</div>
					<div className='flex flex-1 md:ml-2'>
						<Controller
							control={control}
							name={'company_size'}
							render={({ field }) => (
								<AppDropdown
									{...field}
									label={
										<p>
											Company Size <span className={'text-gray-500'}>(Optional)</span>
										</p>
									}
									formatOptionLabel={({ label }) => (
										<p dangerouslySetInnerHTML={{ __html: label }}></p>
									)}
									isLoading={loadingOptions}
									options={companySizes}
								/>
							)}
						/>
					</div>
				</div>
				<AssessmentSetupNavigation backText={'Cancel'} onBack={() => navigate(-1)} nextText={'Next'}>
					<DeleteDraftAssessment />
				</AssessmentSetupNavigation>
			</form>
		</div>
	);
};

export default CompanyDetailsStep;
