import React from 'react';
import UtilityService from '../../services/UtilityService';
import { AppButton, AppDropdown, AppReadMore } from '../App';

const Product = ({ product, loading }) => {
	const [selectedProduct, setSelectedProduct] = React.useState(product.variations[0]);

	const addToCart = () => window.open(product.url, '_self');

	return (
		<div className={'flex flex-col p-4 mb-4'}>
			<div className='flex flex-col flex-1'>
				<img src={product.image} alt='' className={'h-56 mx-auto'} />
				<div className={'flex items-center my-2'}>
					<p className={'flex flex-1 ttg-text-base text-rose-500 font-semibold mr-4'}>{product.title}</p>
					<p className={'ttg-text-lg text-gray-600 font-semibold'}>
						{UtilityService.formatPrice(selectedProduct.price)}
					</p>
				</div>
				<AppReadMore
					length={230}
					className={'ttg-text-sm text-gray-500 mb-3'}
					text={product.description}
					moreClassName={'ttg-text-sm text-rose-500 font-medium'}
				/>
			</div>
			<div>
				<AppDropdown
					value={selectedProduct}
					options={product.variations}
					onChange={(item) => {
						setSelectedProduct(item);
					}}
				/>

				<AppButton
					id={'ttg-video-product-add-to-cart'}
					text={'Add to Cart'}
					className={'btn btn-primary w-full'}
					onClick={addToCart}
					loading={loading}
				/>

				<div className='w-full mt-4 text-center'>
					<a
						id={'ttg-video-product-more-information'}
						href={product.url}
						className={'ttg-text-base font-medium hover:underline'}
					>
						More information
					</a>
				</div>
			</div>
		</div>
	);
};

export default Product;
