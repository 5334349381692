import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

const DropdownPortal = ({ children, triggerRef, isOpen, setIsOpen, space = 8 }) => {
	const [portalElement, setPortalElement] = useState(null);
	const [isVisible, setIsVisible] = useState(true);
	const dropdownRef = useRef(null);

	const createPortalElement = useCallback(() => {
		const el = document.createElement('div');
		el.style.position = 'absolute';
		el.style.zIndex = '10000';
		document.body.appendChild(el);
		return el;
	}, []);

	const updatePosition = useCallback(() => {
		if (triggerRef.current && portalElement) {
			const rect = triggerRef.current.getBoundingClientRect();
			const scrollLeft = window.offsetX || document.documentElement.scrollLeft;
			const scrollTop = window.offsetY || document.documentElement.scrollTop;
			const top = rect.bottom + scrollTop + space;
			let left = rect.right + scrollLeft;

			if (dropdownRef.current) {
				const dropdownRect = dropdownRef.current.firstChild.getBoundingClientRect();
				left -= dropdownRect.width;
			}

			const isFullyVisible =
				rect.top >= 0 &&
				rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
				rect.left >= 0 &&
				rect.right <= (window.innerWidth || document.documentElement.clientWidth);

			setIsVisible(isFullyVisible);

			if (portalElement) {
				portalElement.style.top = `${top}px`;
				portalElement.style.left = `${left}px`;
			}
		}
	}, [triggerRef, portalElement]);

	const handleClickOutside = useCallback(
		(event) => {
			if (portalElement && !portalElement.contains(event.target) && !triggerRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		},
		[portalElement, triggerRef, setIsOpen]
	);

	useEffect(() => {
		if (isOpen) {
			const el = createPortalElement();
			setPortalElement(el);
			return () => {
				document.body.removeChild(el);
			};
		}
	}, [isOpen, createPortalElement]);

	useEffect(() => {
		if (isOpen) {
			updatePosition();
			window.addEventListener('scroll', updatePosition, true);
			window.addEventListener('resize', updatePosition);
			document.addEventListener('mousedown', handleClickOutside);
			document.addEventListener('touchstart', handleClickOutside);
		}

		return () => {
			window.removeEventListener('scroll', updatePosition, true);
			window.removeEventListener('resize', updatePosition);
			document.removeEventListener('mousedown', handleClickOutside);
			document.removeEventListener('touchstart', handleClickOutside);
		};
	}, [isOpen, updatePosition]);

	if (!isOpen || !isVisible || !portalElement) {
		return null;
	}

	return ReactDOM.createPortal(<div ref={dropdownRef}>{children}</div>, portalElement);
};

export default DropdownPortal;
