import React from 'react';
import { AppPdfPage } from '../../../App';
import Images from '../../../../assets/Images';
import UtilityService from '../../../../services/UtilityService';

const CoverPage = ({ details }) => {
	const { child, parent } = details;

	const childCompletedAt = React.useMemo(() => {
		return UtilityService.formatDate(child.current_team.completed, 'MMMM DD, YYYY');
	}, [details]);

	const parentCompletedAt = React.useMemo(() => {
		return UtilityService.formatDate(parent.current_team.completed, 'MMMM DD, YYYY');
	}, [details]);

	return (
		<AppPdfPage sectionClassName={'CoverPage'}>
			<div className='CoverPage__layout'>
				<div className='CoverPage__logoContainer'>
					<img src={Images.logo} alt='Logo' />
				</div>
				<h1 className='CoverPage__title'>
					Team <br />
					Assessment
					<br />
					Progress Report
				</h1>

				<div className='CoverPage__tagline'>
					BASED ON THE MODEL IN THE BEST-SELLING <br />
					BOOK, <em>THE FIVE DYSFUNCTIONS OF A TEAM</em>
					<hr />
				</div>

				<div className='CoverPage__teamInfo'>
					{parent.current_team.team_type === 'individual' && (
						<span>
							Individual Assessment
							<br />
						</span>
					)}
					<span>{`${child.current_team.name} from ${child.current_team.company_name}`}</span> <br />
					<br />
					<span>{`Completed: ${childCompletedAt}`}</span> <br />
					<span>{`Original Assessment: ${parentCompletedAt}`}</span> <br />
				</div>

				<div className={'Page__contentBg Page__contentBg--pyramid'}>
					<img src={Images.report.pyramid} alt='Pyramid' />
				</div>
			</div>
		</AppPdfPage>
	);
};

export default CoverPage;
