import React from 'react';
import UtilityService from '../../services/UtilityService';
import { useLocation } from 'react-router-dom';
import { AccountLayout } from '../../components/Layout';
import { AppButton } from '../../components/App';
import ReCaptcha from '../../components/App/ReCaptcha';
import AssessmentService from '../../services/AssessmentService';

const SendReminder = () => {
	const [sent, setSent] = React.useState(false);
	const [sendingReminders, setSendingReminders] = React.useState(false);
	
	const captchaRef = React.useRef('captcha');
	
	const location = useLocation();

	const { team_id } = React.useMemo(() => {
		return UtilityService.getQueryParams(location.search);
	}, [location.search]);

	const send = async () => {
		const recaptchaValue = await captchaRef.current.getCaptchaValue();
		setSendingReminders(true);
		AssessmentService.sendReminders(team_id, recaptchaValue)
			.then(() => setSent(true))
			.finally(() => setSendingReminders(false));
	};

	return (
		<AccountLayout sidebar={false}>
			<div className={'flex flex-col items-center justify-center mx-auto mt-6'} style={{ maxWidth: '550px' }}>
				<h1 className={'ttg-text-lg text-gray-900 font-medium text-center'} id={'ttg-send-reminder-page-title'}>
					{sent ? 'Successfully Sent Reminders' : 'Send Reminders'}
				</h1>
				<p className='ttg-text-sm text-gray-500 text-center mb-4 mt-2' id={'ttg-send-reminder-page-message'}>
					{sent
						? 'Reminders have been sent to team members who have not yet completed the assessment.'
						: "By clicking the below button, reminders will be sent to all members who haven't completed your assessment yet."}
				</p>
				{!sent && (
					<AppButton
						id={'ttg-send-reminder-page-confirm-button'}
						text={'Send'}
						onClick={send}
						loading={sendingReminders}
					/>
				)}
				<ReCaptcha ref={captchaRef} />
			</div>
		</AccountLayout>
	);
};

export default SendReminder;
