import { useEffect } from 'react';

const MicrosoftLogout = () => {
	useEffect(() => {
		window.open(`/client/?logout`, '_self');
	}, []);

	return null;
};

export default MicrosoftLogout;
