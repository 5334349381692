import React from 'react';
import { FindAssessmentWizard } from '../../components/Assessments';
import AppTabTitle from '../../components/App/AppTabTitle';

const Find = () => {
	return (
		<>
			<AppTabTitle title={'Find Your Assessments | The Table Group'} />
			<FindAssessmentWizard />
		</>
	);
};

export default Find;
