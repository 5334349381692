export enum RUN_STATUS_ENUM {
  QUEUED = "QUEUED",
  IN_PROGRESS = "IN_PROGRESS",
  REQUIRES_ACTION = "REQUIRES_ACTION",
  CANCELLING = "CANCELLING",
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
  COMPLETED = "COMPLETED",
  INCOMPLETE = "INCOMPLETE",
  EXPIRED = "EXPIRED",
}

export enum RUN_STATUS_DISPLAY_ENUM {
  QUEUED = "Queued",
  IN_PROGRESS = "In Progress",
  REQUIRES_ACTION = "Requires Action",
  CANCELLING = "Cancelling",
  CANCELLED = "Cancelled",
  FAILED = "Failed",
  COMPLETED = "Completed",
  INCOMPLETE = "Incomplete",
  EXPIRED = "Expired",
}

export const RunStatusEnum: string[] = Object.values(RUN_STATUS_ENUM);

export const RunStatusFinishedEnum: string[] = [
  RUN_STATUS_ENUM.CANCELLED, RUN_STATUS_ENUM.FAILED, RUN_STATUS_ENUM.COMPLETED, RUN_STATUS_ENUM.EXPIRED,
];

export const RunStatusFailedEnum: string[] = [
  RUN_STATUS_ENUM.FAILED, RUN_STATUS_ENUM.EXPIRED, RUN_STATUS_ENUM.CANCELLED
];
