const CustomerIndustries = [
	{ label: 'Advertising/Marketing', value: 'ADVERTISING_MARKETING' },
	{ label: 'Aerospace', value: 'AEROSPACE' },
	{ label: 'Agriculture', value: 'AGRICULTURE' },
	{ label: 'Automotive', value: 'AUTOMOTIVE' },
	{ label: 'Computer/Technology', value: 'COMPUTER_TECHNOLOGY' },
	{ label: 'Construction/Engineering', value: 'CONSTRUCTION_ENGINEERING' },
	{ label: 'Consulting/Coaching', value: 'CONSULTING_COACHING' },
	{ label: 'Education - Post-Secondary', value: 'EDUCATION_POST_SECONDARY' },
	{ label: 'Education - Primary/Secondary', value: 'EDUCATION_PRIMARY_SECONDARY' },
	{ label: 'Energy', value: 'ENERGY' },
	{ label: 'Entertainment', value: 'ENTERTAINMENT' },
	{ label: 'Fashion', value: 'FASHION' },
	{ label: 'Finance', value: 'FINANCE' },
	{ label: 'Fitness/Nutrition', value: 'FITNESS_NUTRITION' },
	{ label: 'Food/Beverage', value: 'FOOD_BEVERAGE' },
	{ label: 'Government', value: 'GOVERNMENT' },
	{ label: 'Healthcare', value: 'HEALTHCARE_NON_HOSPITAL' },
	{ label: 'Hospitality', value: 'HOSPITALITY' },
	{ label: 'Hospitals', value: 'HOSPITALS' },
	{ label: 'Insurance', value: 'INSURANCE' },
	{ label: 'Law', value: 'LAW' },
	{ label: 'Manufacturing', value: 'MANUFACTURING' },
	{ label: 'Media/News', value: 'MEDIA_NEWS' },
	{ label: 'Mining', value: 'MINING' },
	{ label: 'Ministry/Churches', value: 'MINISTRY_CHURCHES' },
	{ label: 'Non-Profit Organizations', value: 'NON_PROFIT' },
	{ label: 'Other', value: 'OTHER' },
	{ label: 'Pharma/Biotechnology', value: 'PHARMA_BIOTECHNOLOGY' },
	{ label: 'Professional Services', value: 'PROFESSIONAL_SERVICES' },
	{ label: 'Real Estate', value: 'REAL_ESTATE' },
	{ label: 'Retail', value: 'RETAIL' },
	{ label: 'Staffing', value: 'STAFFING' },
	{ label: 'Student', value: 'STUDENT' },
	{ label: 'Telecommunications', value: 'TELECOMMUNICATIONS' },
	{ label: 'Transportation', value: 'TRANSPORTATION' },
];

export default CustomerIndustries;
