import React from 'react';
import { AppButton, AppInput } from '../App';
import UtilityService from '../../services/UtilityService';

const SingleEmailInput = ({
	name,
	min,
	limit,
	emails,
	register,
	errors,
	setError,
	clearErrors,
	onChange,
	onRemove,
	isSubmitted,
	setValue,
}) => {
	React.useEffect(() => {
		if (emails.length > limit) {
			const diff = emails.length - limit;
			emails.splice(emails.length - diff, diff);
			onChange(emails);
		} else {
			clearErrors(name);
			Object.keys(errors).map((key) => {
				setError(key, errors[key]);
			});
			let values = [...emails];
			for (let i = emails.length; i < limit; i++) {
				values.push({
					value: '',
					label: '',
					isValidEmail: false,
					fakeId: UtilityService.generateRandomString(30),
				});
			}
			onChange(values);
		}
	}, [emails.length, limit, isSubmitted]);

	const handleChange = (i, event, fakeId) => {
		let values = [...emails];
		let val = event.target.value;
		if (val) {
			val = val.trim().toLowerCase();
		}
		const idx = values.findIndex(({ value }) => value === val);
		if (idx > -1 && idx !== i && val) {
			event.preventDefault();
			setError(`tmp_email_${fakeId}`, {
				type: 'validate',
				message: `Email address already inserted`,
			});
		} else {
			if (val) {
				clearErrors(`tmp_email_${fakeId}`);
			} else if (isSubmitted) {
				setError(`tmp_email_${fakeId}`, {
					type: 'required',
					message: 'is required',
				});
			}
			setValue(`tmp_email_${fakeId}`, val);
			values[i] = {
				value: val,
				label: val,
				isValidEmail: UtilityService.isValidEmail(val),
				fakeId: values[i].fakeId,
			};
			onChange(values);
		}
	};

	return (
		<div>
			{emails.map((email, idx) => {
				return (
					<div key={idx} className='flex flex-row justify-between'>
						<div className={'flex flex-1'}>
							<AppInput
								{...register(`tmp_email_${email.fakeId}`, {
									required: true,
									validate: (value) =>
										UtilityService.isValidEmail(value) || 'should be a valid email',
									maxLength: 100,
								})}
								data-ttg-max-length={100}
								containerClassName={'w-full'}
								label={`Email Address #${idx + 1}`}
								errors={errors}
								onChange={(e) => handleChange(idx, e, email.fakeId)}
								value={email.value || ''}
								id={`ttg-single-email-input-${idx}`}
							/>
							{emails.length > min && (
								<div className={'ml-2'} style={{ marginTop: '23px' }}>
									<AppButton
										type={'button'}
										className={'btn btn-action'}
										icon={'delete-bin-line'}
										iconClass={'text-red-500'}
										onClick={() => {
											let values = [...emails];
											onRemove(email.fakeId, Math.max(limit - 1, emails.length - 1));
											values.splice(idx, 1);
											onChange(values);
										}}
									/>
								</div>
							)}
						</div>
					</div>
				);
			})}
			{errors[name] && errors[name].message && (
				<span className={'ttg-text-sm text-red-500'}>{errors[name].message}</span>
			)}
		</div>
	);
};

export default SingleEmailInput;
