import React from 'react';
import { Helmet } from 'react-helmet';

const AppTabTitle = ({ title, description }) => {
	return (
		<Helmet>
			<title>{title ? title : 'The Table Group '}</title>
			<meta name='title' content={title ? title : 'The Table Group '} data-react-helmet='true' />
			<meta
				name='description'
				content={description ? description : 'The Table Group '}
				data-react-helmet='true'
			/>
		</Helmet>
	);
};

export default AppTabTitle;
