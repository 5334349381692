import React from 'react';

const AddressInfo = ({ label, company, info }) => {
	return (
		<div className='flex flex-1 flex-col'>
			<h1 className='ttg-text-base font-medium text-gray-800'>{label}</h1>
			<p className={'ttg-text-sm font-semibold text-gray-800'}>{company}</p>
			<p className={'ttg-text-xs text-gray-600'} dangerouslySetInnerHTML={{ __html: info }} />
		</div>
	);
};

export default AddressInfo;
