import React from 'react';
import { AppButton, AppInput, AppDropdown } from '../../App';
import debounce from 'debounce-promise';

const options = [
	{
		value: 1,
		label: 'All license usage',
	},
	{
		value: 2,
		label: 'Transferred',
	},
	{
		value: 3,
		label: 'Pending',
	},
	{
		value: 4,
		label: 'Assigned',
	},
	{
		value: 5,
		label: 'Received',
	},
];

const licensesFilterOptions = {
	1: [{ name: 'status', value: '' }],
	2: [{ name: 'status', value: 'Transferred' }],
	3: [{ name: 'status', value: 'Pending' }],
	4: [{ name: 'status', value: 'Assigned' }],
	5: [{ name: 'status', value: 'Received' }],
};

const LicensesFilters = ({ filters, setFilters, setStatusFilterLicenses, setSearchFilterLicenses, searchFilterLicenses }) => {
	const [statusFilterLicensesOption, setStatusFilterLicensesOption] = React.useState(0);

	const changeHandler = (value) => {
		setFilters('search', value);
		setFilters('page', 0);
	};

	const debouncedChangeHandler = React.useMemo(() => {
		return debounce(changeHandler, 300, { leading: true });
	}, []);

	const onInputChange = (event) => {
		setSearchFilterLicenses(event.target.value);
		debouncedChangeHandler(event.target.value);
	};

	const onDropdownChange = (e) => {
		setStatusFilterLicenses(e.value - 1 );
		setStatusFilterLicensesOption(licensesFilterOptions[e.value][0].value );
		setFilters('page', 0);
		licensesFilterOptions[e.value].forEach((filter) => {
			setFilters(filter.name, filter.value);
		});
	};

	return (
		<div className={'flex flex-col md:flex-row w-full mt-4 -mb-4'}>
			<div className='flex flex-initial w-full md:w-7/12 md:mr-2 mt-4 md:mt-0'>
				<AppInput
					id={'ttg-licenses-table-search'}
					containerClassName={'no-margin w-full'}
					value={searchFilterLicenses}
					placeholder={'Search...'}
					onChange={(e) => onInputChange(e)}
					showErrorMessage={false}
				/>
			</div>
			<div className='flex flex-auto'>
				<AppDropdown
					id={'ttg-licenses-table-type-filter'}
					containerClassName={'no-margin w-full '}
					options={options}
					value={options[statusFilterLicensesOption]}
					onChange={(e) => onDropdownChange(e)}
					maxMenuHeight={400}
				/>
			</div>
			<div className='flex flex-initial'>
				{(statusFilterLicensesOption !== 0 || filters.search !== '') && (
					<AppButton
						id={'ttg-licenses-reset-button'}
						type={'button'}
						className={'btn btn-secondary w-28 !no-margin lg:ml-2  mb-4 !border-gray-300'}
						style={{ height: '38px' }}
						text={'Clear Filters'}
						onClick={() => {
							setSearchFilterLicenses('');
							setFilters('page', 0);
							setFilters('search', '');
							setStatusFilterLicensesOption(0);
							setStatusFilterLicenses('');
							licensesFilterOptions[1].forEach((filter) => {
								setFilters(filter.name, filter.value);
							});
						}}
					/>
				)}
			</div>
		</div>
	);
};
export default LicensesFilters;
