import React, { useEffect, useState } from 'react';
import CountryService from '../services/CountryService';
import NotificationService from '../services/NotificationService';

export const useCountries = () => {
	const [countries, setCountries] = useState([]);
	const [loadingCountries, setLoadingCountries] = useState(false);

	useEffect(() => {
		setLoadingCountries(true);
		CountryService.getCountries()
			.then((response) => setCountries(response))
			.catch((error) => NotificationService.error(error.message))
			.finally(() => setLoadingCountries(false));
	}, []);

	return { countries, loadingCountries };
};
