import React from 'react';
import { AppOutsideAlerter } from '.';

const Widget = ({ id, labelComponent, children, className }) => {
	const [isOpen, setIsOpen] = React.useState(false);

	return (
		<AppOutsideAlerter handleClickOutsideFunction={() => setIsOpen(false)}>
			<div className={`widget-container ${className ? className : ''}`}>
				<div id={`ttg-widget-handler-${id}`} className={'widget-label'} onClick={() => setIsOpen(!isOpen)}>
					{labelComponent}
				</div>
				<div className={`widget-content ${isOpen ? 'opened' : ''}`}>{children}</div>
			</div>
		</AppOutsideAlerter>
	);
};

export default Widget;
