import ApiService from './ApiService';

const groupAddresses = (addresses) => {
	const primary = addresses.find((address) => address.is_default);

	const alternates = addresses.filter((address) => !address.is_default);

	return { primary, alternates, addresses };
};

const getAddresses = () => {
	return new Promise((resolve, reject) => {
		ApiService.get('v1/addresses')
			.then(({ data }) => {
				resolve(AddressService.groupAddresses(data.addresses));
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const updateDefaultAddress = (current_addresses, address_book_id) => {
	const addresses = current_addresses.map((address) => {
		return { ...address, is_default: address.address_book_id === address_book_id ? 1 : 0 };
	});

	return AddressService.groupAddresses(addresses);
};

const setAddressAsDefault = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.put('v1/address/default', payload)
			.then((data) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const addAddress = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post('v1/address', payload)
			.then((data) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const deleteAddress = (address_book_id) => {
	return new Promise((resolve, reject) => {
		ApiService.delete('v1/address', {
			data: {
				address_book_id,
			},
		})
			.then((data) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const updateAddress = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.put('v1/address', payload)
			.then((data) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const AddressService = {
	groupAddresses,
	getAddresses,
	updateDefaultAddress,
	setAddressAsDefault,
	addAddress,
	deleteAddress,
	updateAddress,
};

export default AddressService;
