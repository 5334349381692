import React from 'react';
import { AppIcon, AppLoading } from '../App';
import UtilityService from '../../services/UtilityService';
import { Link } from 'react-router-dom';
import RouteNames from '../../router/RouteNames';

const ExpiredSlider = ({ videos, loading, productList }) => {
	const purchaseAgain = (video) =>
		window.open(
			productList.find((product) =>
				product.variations.find(
					(variation) => Number(variation.variation_legacy_id) === Number(video.product_id)
				)
			)?.url,
			'_self'
		);

	return (
		<div>
			{videos.map((video, index) => {
				const isLast = index === videos.length - 1;
				const containerClass = !isLast ? 'border-b-1 border-gray-200' : '';
				return (
					<div key={index} id={`ttg-expired-video-${index}`} className={`ml-4 py-4 ${containerClass}`}>
						<div className={'flex flex-row items-center'}>
							<AppIcon icon={'video-line'} className={'text-gray-800 text-lg mr-3'} />
							<h1 className={'text-gray-800 ttg-text-sm'}>{video.title}</h1>
						</div>
						<ul className={'list-outside list-disc ml-12 mb-2'}>
							<li className={'text-gray-500 ttg-text-sm'}>
								This video was purchased on{' '}
								<Link
									to={`${RouteNames.OrderDetails}?id=${video.order_id}`}
									className={'font-medium hover:text-rose-500 hover:underline'}
								>
									{UtilityService.formatDate(video.created, 'MMMM DD, YYYY')}
								</Link>
								.
							</li>
							<li className={'text-gray-500 ttg-text-sm'}>
								The subscription for this video has expired on{' '}
								<span className={'font-medium'}>
									{UtilityService.formatDate(video.expires, 'MMMM DD, YYYY')} at{' '}
									{UtilityService.formatDate(video.expires, 'hh:mm A')}
								</span>
								.
							</li>
						</ul>
						{loading ? (
							<div className={'w-8'}>
								<AppLoading height={'h-8'} size={'text-sm'} />
							</div>
						) : (
							<span
								id={'ttg-expired-video-purchase-again'}
								className='ttg-text-base text-rose-500 hover:text-rose-600 font-medium cursor-pointer'
								onClick={() => purchaseAgain(video)}
							>
								Purchase Again
							</span>
						)}
					</div>
				);
			})}
		</div>
	);
};
export default ExpiredSlider;
