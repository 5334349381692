import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('body');

const ModalComponent = ({ children, ...props }) => {
	return (
		<Modal closeTimeoutMS={300} {...props}>
			{children}
		</Modal>
	);
};

export default ModalComponent;
