import React from 'react';
import { VideoProduct } from './index';

const Products = ({ products, loading }) => {
	return (
		<div className={'flex flex-col lg:flex-row mx-4'}>
			{products.map((product, idx) => {
				return (
					<div
						className={`flex flex-1 border-1 border-gray-200 shadow-default mb-4 ${
							idx > 0 && idx < products.length - 1 ? 'lg:mx-4' : ''
						}`}
						key={product.id}
					>
						<VideoProduct product={product} loading={loading}/>
					</div>
				);
			})}
		</div>
	);
};

export default Products;
