export enum THEME_ENUM {
  Default = 'default',
  TTGApp = 'ttg-app',
  ITPApp = 'itp-app',
}

type Themes = {
  [key in THEME_ENUM]: string;
};

export const THEME: Themes = {
  [THEME_ENUM.Default]: 'default',
  [THEME_ENUM.TTGApp]: 'ttg-app',
  [THEME_ENUM.ITPApp]: 'itp-app'
}

export const ThemesEnum: string[] = Object.values(THEME);
