import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { AppButton, AppErrorAlert, AppInput } from '../../App';
import { PublicLayout } from '../../Layout';

import UtilityService from '../../../services/UtilityService';
import RouteNames from '../../../router/RouteNames';
import { useCaptchaProvider } from '../../../providers/CaptchaProvider';
import AssessmentService from '../../../services/AssessmentService';

const Find = ({ nextStep, setEmail }) => {
	const [showError, setShowError] = React.useState(false);
	const [finding, setFinding] = React.useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const { captchaRef, setShowCaptcha } = useCaptchaProvider();

	const _confirm = async ({ email }) => {
		const recaptchaValue = await captchaRef.current.getCaptchaValue();
		setFinding(true);
		AssessmentService.findAssessmentList({ email: email.trim(), captcha: recaptchaValue })
			.then((response) => {
				setEmail(email.trim());
				if (response?.available_teams) {
					setShowCaptcha(false);
					return nextStep();
				}
				setShowError(true);
			})
			.finally(() => setFinding(false));
	};

	const getPageHeaderTitle = () => {
		return (
			<h1 className='flex justify-center items-center text-center'>
				Take your Five Dysfunctions <br />
				of a Team Assessment
			</h1>
		);
	};

	const getPageHeaderStatus = () => {
		if (!!showError) {
			return (
				<div className={'mt-4'} id={'ttg-find-assessment-error-alert'}>
					<AppErrorAlert>
						<p>
							We are sorry but we were unable to find a Table Group Assessment associated with the email
							address.
						</p>
						<br />
						<p>Please enter your e-mail address in the field provided below and try again.</p>
					</AppErrorAlert>
				</div>
			);
		}
		return '';
	};

	const footer = (
		<Link to={RouteNames.Login} className='text-sm text-rose-500 font-medium' id={'ttg-login-signup-link'}>
			Back to Login
		</Link>
	);

	return (
		<PublicLayout headerTitle={getPageHeaderTitle()} headerStatus={getPageHeaderStatus()} footer={footer}>
			<div className='ttg-find-assessment-form-container'>
				<form id={'ttg-find-assessment-form'} onSubmit={handleSubmit(_confirm)}>
					<p className={'text-center text-gray-500 ttg-text-sm mb-6'}>
						If you have been invited to take a Team Assessment, enter your e-mail address below to begin.
					</p>
					<AppInput
						id={'ttg-find-assessment-email-input'}
						label={'Email address'}
						data-ttg-max-length={96}
						errors={errors}
						{...register('email', {
							required: 'You must enter an email address.',
							maxLength: 96,
							pattern: {
								value: UtilityService.emailRegex,
								message: 'You must enter a valid email address',
							},
						})}
						containerClassName={'w-full'}
					/>
					<AppButton text={'Continue'} className={'btn btn-primary w-full'} loading={finding} />
				</form>
			</div>
		</PublicLayout>
	);
};

export default Find;
