import React from 'react';
import { AppButton, AppIcon } from '../../../App';
import { useNavigate } from 'react-router-dom';
import RouteNames from '../../../../router/RouteNames';
import UtilityService from '../../../../services/UtilityService';
import { useAssessments } from '../../../../providers/AssessmentProvider';
import { useCustomer } from '../../../../providers/CustomerProvider';

const CompletionStep = () => {
	const navigate = useNavigate();

	const { setLicenses } = useCustomer();
	const { setupAssessment, newAssessmentId } = useAssessments();

	React.useEffect(() => {
		if (!setupAssessment.reminder.setup_date) {
			setLicenses((prev) => ({ ...prev, licenses: prev.licenses - setupAssessment.team.team_members.length }));
		}
	}, []);

	if (setupAssessment && setupAssessment.reminder && setupAssessment.reminder.setup_date) {
		return (
			<div className={'ttg-assessment-section flex-col'}>
				<div className='flex flex-col items-center justify-center text-center'>
					<div className='flex items-center justify-center w-16 h-16 rounded-full bg-green-100'>
						<AppIcon icon={'calendar-event-line'} className={'text-2xl text-green-500'} />
					</div>
					<p
						className='ttg-text-2xl text-gray-800 font-medium mb-5 mt-6'
						id={'ttg-team-assessment-schedule-date'}
					>
						Team assessment is scheduled to begin on{' '}
						{UtilityService.formatDate(setupAssessment.reminder.setup_date, 'MMMM DD YYYY, hh:mm A', true)}{' '}
						{setupAssessment.reminder.setup_timezone}
					</p>
					<p className={'text-gray-500 mt-4'}>
						The team{' '}
						<span className={'font-medium text-gray-700'} id={'ttg-team-assessment-completed-name'}>
							{setupAssessment.team.name}
						</span>{' '}
						for{' '}
						<span id={'ttg-individual-assessment-completed-company-name'}>
							{setupAssessment.company.company_name}
						</span>{' '}
						has been created. E-mails have been scheduled for all team members and the team assessment will
						be in process.
					</p>
					<p className={'text-gray-500 mt-4'}>
						In addition to accessing the assessment via email, team members have the ability to go to{' '}
						<a href={'/login'}>www.tablegroup.com/login</a> to complete their assessment. The email listed
						in the team setup will need to be entered to begin their assessment.
					</p>
					<p className={'text-gray-700 font-medium mt-4'}>
						VERY IMPORTANT: Please note that only the team members that you have signed up can use this
						“login” function. If you need to add someone to your team, please do so in your Administrator’s
						Console. Do not instruct them to use someone else’s e-mail. This will prevent the original team
						member from taking the assessment, as each link is customized for each user. Doing so will
						invalidate the test results.
					</p>
					<p className={'text-gray-500 mt-4'}>
						To check the status of your team or to make any necessary changes please click the “Assessments”
						link.
					</p>
					<p className={'text-gray-500 mt-4 mb-6'}>
						If you are participating in the assessment, you will find instructions in your e-mail.
					</p>
					<AppButton
						id={'ttg-setup-assessment-completed-button'}
						text={'Back to My Assessments'}
						onClick={() => navigate(RouteNames.Assessments, { replace: true })}
					/>
					<p id={'ttg-new-assessment-id'} className='invisible'>
						{newAssessmentId}
					</p>
				</div>
			</div>
		);
	}

	return (
		<div className={'ttg-assessment-section flex-col'}>
			<div className='flex flex-col items-center justify-center text-center'>
				<div className='flex items-center justify-center w-16 h-16 rounded-full bg-green-100'>
					<AppIcon icon={'check-fill'} className={'text-2xl text-green-500'} />
				</div>
				<p className='ttg-text-2xl text-gray-800 font-medium mb-5 mt-6'>Team setup complete</p>
				<p className={'text-gray-500 mt-4'}>
					The team{' '}
					<span className={'font-medium text-gray-700'} id={'ttg-team-assessment-completed-name'}>
						{setupAssessment.team.name}
					</span>{' '}
					for{' '}
					<span id={'ttg-individual-assessment-completed-company-name'}>
						{setupAssessment.company.company_name}
					</span>{' '}
					has been created. E-mails have been sent to all team members and the team assessment is now in
					process.
				</p>
				<p className={'text-gray-500 mt-4'}>
					In addition to accessing the assessment via email, team members have the ability to go to{' '}
					<a href={'/login'}>www.tablegroup.com/login</a> to complete their assessment. The email listed in
					the team setup will need to be entered to begin their assessment.
				</p>
				<p className={'text-gray-700 font-medium mt-4'}>
					VERY IMPORTANT: Please note that only the team members that you have signed up can use this “login”
					function. If you need to add someone to your team, please do so in your Administrator’s Console. Do
					not instruct them to use someone else’s e-mail. This will prevent the original team member from
					taking the assessment, as each link is customized for each user. Doing so will invalidate the test
					results.
				</p>
				<p className={'text-gray-500 mt-4'}>
					To check the status of your team or to make any necessary changes please click the “Assessments”
					link.
				</p>
				<p className={'text-gray-500 mt-4 mb-6'}>
					If you are participating in the assessment, you will find instructions in your e-mail.
				</p>
				<AppButton
					id={'ttg-setup-assessment-completed-button'}
					text={'Back to My Assessments'}
					onClick={() => navigate(RouteNames.Assessments, { replace: true })}
				/>
				<p id={'ttg-new-assessment-id'} className='invisible'>
					{newAssessmentId}
				</p>
			</div>
		</div>
	);
};

export default CompletionStep;
