import React from 'react';
import { AppPdfPage } from '../../../App';
import Images from '../../../../assets/Images';

const TeamCultureQuestionsTrust = ({ details, page }) => {
	const questions = React.useMemo(() => {
		return details.responses_multiple
			.filter(({ question }) => question === 38)
			.sort((a, b) => {
				return (
					parseInt(b.response_count) - parseInt(a.response_count) ||
					a.response_text.localeCompare(b.response_text)
				);
			});
	}, [details]);

	return (
		<AppPdfPage page={page} sectionClassName={'TeamCultureQuestions'}>
			<div className='TeamCultureQuestions__layout'>
				<div className='TeamCultureQuestions__heading'>
					<div className='PageHeading'>
						<div className='PageHeading__title'>Team Culture Questions</div>
						<div className='PageHeading__image'>
							<img width='82' src={Images.report.puzzlePieces} alt={''} />
						</div>
					</div>
				</div>
				<div className='TeamCultureQuestions__content'>
					<div className='TeamCultureQuestions__intro'>
						<div className='TeamCultureQuestions__introIcon'>
							<img src={Images.report.emblem} alt={''} />
						</div>
						<div className='TeamCultureQuestions__introContent'>
							The Five Dysfunctions of a Team model is the foundation for building cohesive and effective
							teams. However, the expression of these behaviors can vary from team to team depending on
							the team's unique culture. This section is intended to help tee up conversations to help
							your team overcome the five dysfunctions.
							<br />
							<br />
							No comparative analysis is included in the team culture section.
						</div>
					</div>

					<div className='TeamCultureQuestions__blurb Blurb'>
						<div className='Blurb__title'>Building Trust</div>
						<div className='Blurb__subTitle'>What Is Needed To Build Trust?</div>
						<div className='Blurb__content'>
							As part of the assessment, you and your team members were asked to identify specific areas
							to build more trust. The number of team members who selected each response appears below.
							You and your team members had the option to select all that apply.
						</div>
					</div>

					<div className='TeamCultureQuestions__questionReview'>
						<div className='TeamCultureQuestions__questionReviewTitle'>
							There would be more trust on our team if people:
						</div>

						<div className='TallyList'>
							{questions.map((q, idx) => (
								<div className='TallyList__item' key={idx}>
									<div className='TallyList__count'>{q.response_count}</div>
									<div
										className='TallyList__question'
										dangerouslySetInnerHTML={{ __html: q.response_text }}
									/>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</AppPdfPage>
	);
};

export default TeamCultureQuestionsTrust;
