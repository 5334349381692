import React from 'react';
import { AppButton, AppModalComponent } from '../App';
import NotificationService from '../../services/NotificationService';
import AddressService from '../../services/AddressService';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const DeleteModal = ({ isOpen, closeModal, address_id, setAddresses, addressesState }) => {
	const [loading, setLoading] = React.useState(false);

	const setAsDefault = () => {
		setLoading(true);
		AddressService.deleteAddress(address_id)
			.then(() => {
				setAddresses({
					...addressesState,
					addresses: addressesState.addresses.filter((address) => address.address_book_id !== address_id),
					alternateAddresses: addressesState.alternateAddresses.filter(
						(address) => address.address_book_id !== address_id
					),
				});
				closeModal();
				NotificationService.success('', 'Address successfully deleted!');
			})
			.catch((error) => NotificationService.error('', error))
			.finally(() => setLoading(false));
	};

	return (
		<AppModalComponent id={'ttg-delete-address-modal-id'} isOpen={isOpen} style={customStyles}>
			<div className='bg-white p-6' style={{ maxWidth: '512px' }}>
				<h1 className={'ttg-text-lg text-gray-900 font-medium'}>Delete Address</h1>
				<p className={'ttg-text-sm text-gray-500 mt-2 mb-4'}>Are you sure you want to delete this address?</p>
				<div className='flex justify-end'>
					<AppButton
						className={'btn btn-secondary mr-4'}
						text={'Cancel'}
						onClick={() => closeModal()}
						id={'ttg-delete-address-cancel-btn'}
					/>
					<AppButton
						className={'btn btn-primary'}
						text={'Yes'}
						onClick={() => setAsDefault()}
						id={'ttg-delete-address-yes-btn'}
						loading={loading}
					/>
				</div>
			</div>
		</AppModalComponent>
	);
};

export default DeleteModal;
