import React from 'react';
import { AddressAction } from '.';
import { AppCheckBox } from '../App';

const Information = ({ address, isDefault = false, checked = false, onEdit, onDelete, onSetAsPrimary }) => {
	return (
		<div className='ttg-address-information'>
			<div className='px-1'>
				<p className='!font-medium' id={'ttg-address-info-fullname-id'}>
					{address.entry_firstname + ' ' + address.entry_lastname}
				</p>
				<p id={'ttg-address-info-company-id'}>{address.entry_company ? address.entry_company : ''}</p>
				<p id={'ttg-address-info-street-id'}>{address.entry_street_address}</p>
				<p id={'ttg-address-info-suburb-id'}>{address.entry_suburb ? address.entry_suburb : ''}</p>
				<p id={'ttg-address-info-location-id'}>
					{address.entry_city + ', '} {address.entry_state ? address.entry_state : ''}{' '}
					{' ' + address.entry_postcode}
				</p>
				<p id={'ttg-address-info-country-id'}>{address.entry_country}</p>
				<p className='mb-2' id={'ttg-address-info-phone'}>
					{address.entry_phone}
				</p>
				{!isDefault && (
					<AppCheckBox
						id={'ttg-account-set-as-primary-id'}
						label={'Set as primary address'}
						checked={checked}
						onChange={(e) => {
							if (e.target.checked) {
								onSetAsPrimary(address.address_book_id);
							}
						}}
					/>
				)}
			</div>
			<div className={'ttg-address-actions'}>
				<AddressAction
					id={'ttg-address-edit-action'}
					text={'Edit'}
					color={'#1F2937'}
					icon={'pencil-line'}
					onClick={() => onEdit(address)}
				/>
				{!isDefault && (
					<AddressAction
						id={'ttg-address-delete-action'}
						text={'Delete'}
						color={'#EF4444'}
						icon={'delete-bin-line'}
						onClick={() => onDelete(address.address_book_id)}
					/>
				)}
			</div>
		</div>
	);
};
export default Information;
