import React from 'react';
import { AppPdfPage } from '../../../App';
import Images from '../../../../assets/Images';

const TeamCultureQuestionsAccountability = ({ details, page }) => {
	const questions = React.useMemo(() => {
		return details.responses_multiple
			.filter(({ question }) => question === 41)
			.sort((a, b) => {
				return (
					parseInt(b.response_count) - parseInt(a.response_count) ||
					a.response_text.localeCompare(b.response_text)
				);
			});
	}, [details]);

	return (
		<AppPdfPage page={page} sectionClassName={'TeamCultureQuestions'}>
			<div className='TeamCultureQuestions__layout'>
				<div className='TeamCultureQuestions__heading'>
					<div className='PageHeading'>
						<div className='PageHeading__title'>Team Culture Questions</div>
						<div className='PageHeading__image'>
							<img width='82' src={Images.report.puzzlePieces} />
						</div>
					</div>
				</div>
				<div className='TeamCultureQuestions__content'>
					<div className='TeamCultureQuestions__continuedText'>(Continued from the previous page.)</div>

					<div className='TeamCultureQuestions__blurb Blurb'>
						<div className='Blurb__title'>Embracing Accountability</div>
						<div className='Blurb__subTitle'>
							What Would Improve Your Team's Ability To Hold One Another Accountable?
						</div>
						<div className='Blurb__content'>
							As part of the assessment, you and your team members had an opportunity to identify areas
							where it would be possible to hold one another accountable more. The number of team members
							who selected each response appears below. You and your team members had the option to select
							all that apply.
						</div>
					</div>

					<div className='TeamCultureQuestions__questionReview'>
						<div className='TeamCultureQuestions__questionReviewTitle'>
							Our ability to hold one another accountable could improve if we challenged one another to:
						</div>

						<div className='TallyList'>
							{questions.map((q, idx) => (
								<div className='TallyList__item' key={idx}>
									<div className='TallyList__count'>{q.response_count}</div>
									<div
										className='TallyList__question'
										dangerouslySetInnerHTML={{ __html: q.response_text }}
									/>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</AppPdfPage>
	);
};

export default TeamCultureQuestionsAccountability;
