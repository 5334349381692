import React from 'react';
import { AppIcon } from '../App';

const NoData = ({ icon, message, className }) => {
	return (
		<div className={`flex flex-col items-center justify-center ${className}`}>
			<AppIcon icon={icon} className={'text-4xl text-rose-500'} />
			<p className={'w-2/3 text-center text-gray-800'}>{message}</p>
		</div>
	);
};

export default NoData;
