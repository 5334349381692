import React from 'react';
import { AppWizard } from '../../../App';
import {
	AssessmentSetupCompanyDetailsStep,
	AssessmentSetupCompletionStep,
	AssessmentSetupCustomEmailStep,
	AssessmentSetupProgress,
	AssessmentSetupSendReminderStep,
	AssessmentSetupSummaryStep,
	AssessmentSetupTeamDetailsStep,
} from '../../index';
import UtilityService from '../../../../services/UtilityService';

const Wizard = () => {
	return (
		<AppWizard
			className={'team-assessment-setup'}
			isLazyMount={true}
			nav={<AssessmentSetupProgress />}
			onStepChange={UtilityService.scrollToTop}
		>
			<AssessmentSetupCompanyDetailsStep />
			<AssessmentSetupTeamDetailsStep />
			<AssessmentSetupCustomEmailStep />
			<AssessmentSetupSendReminderStep />
			<AssessmentSetupSummaryStep />
			<AssessmentSetupCompletionStep />
		</AppWizard>
	);
};

export default Wizard;
