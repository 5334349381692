import React from 'react';
import RadioQuestionStep from './RadioQuestionStep';
import { TakeAssessmentProgress } from '../index';
import UtilityService from '../../../services/UtilityService';
import { AppLoading, AppWizard } from '../../App';
import Info from './Info';
import { useLocation } from 'react-router-dom';
import CheckboxQuestionStep from './CheckboxQuestionStep';
import ConfirmationStep from './ConfirmationStep';
import ChangeAddressStep from './ChangeAddressStep';
import RouteNames from '../../../router/RouteNames';
import { useNavigate } from 'react-router-dom';
import ChangeEmailModal from './ChangeEmailModal';
import AssessmentService from '../../../services/AssessmentService';
import { useAssessments } from '../../../providers/AssessmentProvider';

const Wizard = () => {
	const navigate = useNavigate();
	const [details, setDetails] = React.useState(null);
	const [questions, setQuestions] = React.useState([]);
	const [checkboxQuestions, setCheckboxQuestions] = React.useState([]);
	const [endingAssessment, setEndingAssessment] = React.useState(false);
	const [loadingNext, setLoadingNext] = React.useState(false);
	const [scales, setScales] = React.useState(null);

	const location = useLocation();
	
	const { loading, setLoading } = useAssessments();

	const getFilters = (t, p, r) =>
		t && p
			? {
					team_id: t,
					person_id: p,
			  }
			: {
					team_slug: t,
					team_roster_slug: r,
			  };

	const { t, p, r, reconstitute } = React.useMemo(() => {
		return UtilityService.getQueryParams(location.search);
	}, [location.search]);

	const _getDetails = React.useCallback(() => {
		setLoading(true);
		AssessmentService.takeAssessmentDetails({
			...getFilters(t, p, r),
			reconstitute,
		})
			.then((response) => {
				setDetails(response);
			})
			.finally(() => setLoading(false));
	}, [t, p, r, reconstitute]);

	const _getQuestions = React.useCallback(() => {
		AssessmentService.getQuestions({
			...getFilters(t, p, r),
			reconstitute,
		}).then((response) => {
			const { questions, checkboxQuestions, scales } = AssessmentService.prepareAssessmentQuestions(response);
			setQuestions(questions);
			setCheckboxQuestions(checkboxQuestions);
			setScales(scales);
		});
	}, [t, p, r, reconstitute]);

	React.useEffect(() => {
		if ((t && p) || (t && r)) {
			_getDetails();
			return _getQuestions();
		}

		navigate(RouteNames.FindAssessment, { replace: true });
	}, [_getDetails, _getQuestions, t, p, r]);

	React.useEffect(() => {
		document.title = 'Take Assessment | The Table Group';
	}, []);

	const [isOpenChangeEmailModal, setIsOpenChangeEmailModal] = React.useState(false);
	const [secondaryEmail, setSecondaryEmail] = React.useState('');
	const [optIn, setOptIn] = React.useState(null);

	const onChangeEmail = () => {
		setIsOpenChangeEmailModal(true);
	};

	const prepareV1Payload = () => {
		const allQuestions = {};
		questions.map((_questions) => {
			_questions.map((question) => {
				allQuestions[`q${question.sort_order}`] = question.answer;
			});
		});
		return allQuestions;
	};

	const prepareV2Payload = (sendEmptyAnswers) => {
		const allQuestions = [];
		questions.map((_questions) => {
			_questions.map((question) => {
				allQuestions.push({
					question: `${question.sort_order}`,
					value: question.answer,
				});
			});
		});
		checkboxQuestions.map((question) => {
			if (question.hasSubQuestions) {
				const subquestions = [];
				question.subQuestions.forEach((q) => {
					q.scales.forEach((scale) => {
						if (q.answer[scale] || sendEmptyAnswers) {
							subquestions.push({
								subquestion: q.sort,
								value: q.answer[scale],
							});
						}
					});
				});
				allQuestions.push({
					question: `${question.sort_order}`,
					subquestions,
				});
			} else {
				allQuestions.push({
					question: `${question.sort_order}`,
					values: question.answer,
				});
			}
		});
		return allQuestions;
	};

	const onComplete = async (nextStep) => {
		if (details.version === 1) {
			setEndingAssessment(true);
			AssessmentService.endTakeAssessment(
				{ ...prepareV1Payload(), email: secondaryEmail, opt_in: optIn },
				getFilters(t, p, r)
			)
				.then(nextStep)
				.finally(() => setEndingAssessment(false));
		} else {
			AssessmentService.endTakeAssessment(
				{
					responses: prepareV2Payload(),
					email: secondaryEmail,
					opt_in: optIn,
				},
				getFilters(t, p, r)
			)
				.then(nextStep)
				.finally(() => setEndingAssessment(false));
		}
	};

	const onProgress = async (step, nextStep) => {
		setLoadingNext(true);
		AssessmentService.updateAssessmentProgress(
			{
				step,
				responses: prepareV2Payload(true),
			},
			getFilters(t, p, r)
		)
			.then(nextStep)
			.finally(() => setLoadingNext(false));
	};

	if (loading || !details) {
		return <AppLoading />;
	}
	console.log(questions, checkboxQuestions);
	return (
		<>
			<AppWizard
				className={'team-assessment-setup'}
				isLazyMount={true}
				nav={
					<TakeAssessmentProgress
						questions={[...questions, ...checkboxQuestions, { question: 'Default change email question' }]}
						setQuestions={setQuestions}
						setCheckboxQuestions={setCheckboxQuestions}
						scales={scales}
					/>
				}
				onStepChange={UtilityService.scrollToTop}
				initialStep={1}
			>
				<Info team_id={t} person_id={p} optIn={optIn} setOptIn={setOptIn} details={details} />

				{questions.map((q, i) => {
					return (
						<RadioQuestionStep
							key={i}
							questions={q}
							allQuestions={questions}
							onAnswer={(questionIndex, answer) => {
								setQuestions(prevQuestions => {
									const newQuestions = [...prevQuestions];
									newQuestions[i][questionIndex].answer = answer;
									return newQuestions;
								});
							}}
							onComplete={onComplete}
							onProgress={onProgress}
							loading={endingAssessment}
							saveProgress={details.version === 2}
							step={i}
							updatingProgress={loadingNext}
						/>
					);
				})}

				{checkboxQuestions.map((question, i) => {
					return (
						<CheckboxQuestionStep
							key={i}
							question={question}
							onAnswer={(checked, score) => {
								setCheckboxQuestions((prev) =>
									prev.map((question, index) =>
										index === i
											? {
													...question,
													answer: checked
														? [...question.answer, score]
														: question.answer.filter((ans) => ans !== score),
											  }
											: question
									)
								);
							}}
							onSubAnswer={(subQuestionIndex, type, score) => {
								setCheckboxQuestions((prev) =>
									prev.map((question, qIdx) => {
										if (qIdx !== i) return question;
										return {
											...question,
											subQuestions: question.subQuestions.map((subQuestion, sqIdx) => {
												if (sqIdx !== subQuestionIndex) return subQuestion;
												return {
													...subQuestion,
													answer: {
														...subQuestion.answer,
														[type]: score,
													},
												};
											}),
										};
									})
								);
							}}
							onComplete={onComplete}
							onProgress={onProgress}
							loading={endingAssessment}
							saveProgress={details.version === 2}
							step={questions.length + i}
							scales={scales}
							updatingProgress={loadingNext}
						/>
					);
				})}
				<ChangeAddressStep
					allQuestions={questions}
					onComplete={onComplete}
					onProgress={onProgress}
					onChangeEmail={onChangeEmail}
					loading={endingAssessment}
					saveProgress={details.version === 2}
				/>
				<ConfirmationStep />
			</AppWizard>
			<ChangeEmailModal
				isOpen={isOpenChangeEmailModal}
				onClose={() => setIsOpenChangeEmailModal(false)}
				onConfirm={({ email }) => {
					setSecondaryEmail(email);
					setIsOpenChangeEmailModal(false);
				}}
				email={secondaryEmail}
			/>
		</>
	);
};

export default Wizard;
