import ApiService from './ApiService';

const getCountries = () =>
	ApiService.get('v1/address/countries')
		.then(({ data }) => mapCountriesOrZones(data.countries))
		.catch((error) => error);

const getZones = (countryCode) =>
	ApiService.get(`v1/address/country/${countryCode}/zones`)
		.then(({ data }) => mapCountriesOrZones(data.zones))
		.catch((error) => error);

const mapCountriesOrZones = (countriesOrZones) =>
	countriesOrZones.map((countryOrZone) => ({
		value: countryOrZone.code,
		label: countryOrZone.name,
	}));

const getByCode = (countriesOrZones, code) => {
	const countryOrZone = countriesOrZones.find((c) => c.value === code || c.label === code);
	return countryOrZone ? countryOrZone : code;
};

const CountryService = {
	getByCode,
	getCountries,
	mapCountriesOrZones,
	getZones,
};

export default CountryService;
