import React from 'react';
import { AppPdfPage } from '../../../App';
import ReportService from '../../../../services/ReportService';

const TheFiveBehaviors = ({ details }) => {
	const renderDescription = React.useCallback(
		(type) => {
			try {
				return ReportService.Behavior[type][
					ReportService.getLevelByValue(details.average_response_per_category[type])
				];
			} catch (e) {
				return '';
			}
		},
		[details]
	);

	const reverseOrdered5D = React.useMemo(() => {
		return [...ReportService.Ordered5DF].reverse();
	}, []);

	const reversePyramidItems = React.useMemo(() => {
		return [...ReportService.PyramidItems].reverse();
	}, []);

	const getLevelByProp = (prop) => {
		return ReportService.getLevelByValue(details.average_response_per_category[prop]);
	};

	const renderProgressBar = (type) => {
		let value = details.average_response_per_category[type];

		if (!value) {
			value = 1;
		}

		value = value.toFixed(2);

		const level = ReportService.getLevelByValue(value);

		const percentage = ((value - 1) / 4) * 100;

		return (
			<div className='ProgressBar'>
				<div className='ProgressBar__label'>{value}</div>
				<svg className='ProgressBar__meter'>
					<rect className='ProgressBar__meterBar' />
					<rect className={`ProgressBar__meterFill ProgressBar--${level}`} width={`${percentage}%`} />
				</svg>
			</div>
		);
	};

	const renderPyramid = (type) => {
		return (
			<svg className='teamPyramid' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 440.98 337.17'>
				<title>Pyramid</title>
				<g className={`ScoresPyramid ScoresPyramid--hideLabels`} data-name='Layer 2'>
					<g id='Layer_1-2' data-name='Layer 1'>
						<polygon
							fill='#F9FAFB'
							points='220.49 0 440.98 337.17 0 337.17 220.49 0'
							transform='translate(-15 0)'
						/>
						{reversePyramidItems.map((i) => (
							<g id={i.key} key={i.key}>
								<polygon
									className={`${i.key} ${i.key === type ? getLevelByProp(i.key) : ''}`}
									points={i.points}
								/>
								<text className='PyramidText' transform={i.transform} />
							</g>
						))}
					</g>
				</g>
			</svg>
		);
	};

	return (
		<AppPdfPage page={6} sectionClassName={'FiveBehaviors'}>
			<div className='FiveBehaviors__layout'>
				<div className='FiveBehaviors__heading'>
					<div className='PageHeading'>
						<div className='PageHeading__title'>The Five Behaviors</div>
					</div>
				</div>
				<div className='FiveBehaviors__content'>
					{ReportService.Ordered5DF.map((type) => {
						return (
							<div className='FiveBehaviors__summaryRow' key={type}>
								<div className='FiveBehaviors__summaryBehaviorColumn'>{type}</div>
								<div className='FiveBehaviors__summaryMeterColumn mb-1'>{renderProgressBar(type)}</div>
							</div>
						);
					})}

					<div className='FiveBehaviors__summaryRow'>
						<div className='FiveBehaviors__summaryBehaviorColumn'></div>

						<div className='FiveBehaviors__summaryMeterColumn'>
							<div className='ProgressScale ProgressScale--withLeftLabel'>
								<div className='ProgressScale__mark'>
									<div className='ProgressScale__markLabel MarkLabel'>
										<div className='MarkLabel__number'>1</div>
										<div className='MarkLabel__text'>Almost Never</div>
									</div>
								</div>
								<div className='ProgressScale__mark'>
									<div className='ProgressScale__markLabel MarkLabel'>
										<div className='MarkLabel__number'>2</div>
										<div className='MarkLabel__text'>Rarely</div>
									</div>
								</div>
								<div className='ProgressScale__mark'>
									<div className='ProgressScale__markLabel MarkLabel'>
										<div className='MarkLabel__number'>3</div>
										<div className='MarkLabel__text'>Sometimes</div>
									</div>
								</div>
								<div className='ProgressScale__mark'>
									<div className='ProgressScale__markLabel MarkLabel'>
										<div className='MarkLabel__number'>4</div>
										<div className='MarkLabel__text'>Usually</div>
									</div>
								</div>
								<div className='ProgressScale__mark'>
									<div className='ProgressScale__markLabel MarkLabel'>
										<div className='MarkLabel__number'>5</div>
										<div className='MarkLabel__text'>Almost Always</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='FiveBehaviors__descriptions'>
					{reverseOrdered5D.map((type) => {
						return (
							<div className='FiveBehaviors__descriptionRow pb-4' key={type}>
								<div className='FiveBehaviors__descriptionPyramidColumn'>{renderPyramid(type)}</div>

								<div className='FiveBehaviors__descriptionTextColumn'>
									<div className='FiveBehaviors__descriptionTitle'>{type}</div>
									<div className='FiveBehaviors__descriptionBody'>{renderDescription(type)}</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</AppPdfPage>
	);
};

export default TheFiveBehaviors;
