import React from 'react';
import { AppButton, AppDropdown, AppIcon, AppInput, AppRadioGroup, AppSwitch } from '../../../App';
import { Controller, useForm, useFormState } from 'react-hook-form';
import {
	AssessmentMultipleEmailInput,
	AssessmentSetupNavigation,
	AssessmentSingleEmailInput,
	DeleteDraftAssessment,
} from '../../index';
import UtilityService from '../../../../services/UtilityService';
import LargeTeamAlertModal from './LargeTeamAlertModal';
import AssessmentService from '../../../../services/AssessmentService';
import RouteNames from '../../../../router/RouteNames';
import { useNavigate } from 'react-router-dom';
import NotificationService from '../../../../services/NotificationService';
import { useAssessments } from '../../../../providers/AssessmentProvider';
import { useCustomer } from '../../../../providers/CustomerProvider';

const prepareTeamSizeOptions = (max, min = 2) => {
	return UtilityService.prepareNumberOptions(min, max);
};

const TeamDetailsStep = ({ previousStep, nextStep }) => {
	const navigate = useNavigate();

	const multipleRef = React.useRef();
	const multipleMembersRef = React.useRef();

	const { customer, licenses: { licenses } } = useCustomer();
	const { setupAssessment, setSetupAssessment, loading: settingUp } = useAssessments();

	const [isEditingTeamSize, setIsEditingTeamSize] = React.useState(false);
	const [teamSizeOptions, setTeamSizeOptions] = React.useState(prepareTeamSizeOptions(Math.min(20, licenses)));
	const [singleInputMode, setSingleInputMode] = React.useState(false);
	const [submittedForLater, setSubmittedForLater] = React.useState(false);

	const {
		register,
		unregister,
		handleSubmit,
		formState: { errors },
		setValue,
		getValues,
		watch,
		control,
		setError,
		clearErrors,
	} = useForm({
		defaultValues: {
			...setupAssessment.team,
			main_email: setupAssessment.team?.main_email
				? setupAssessment.team.main_email
				: customer?.customers_email_address,
		},
	});

	const unRegisterSingleEmailInput = (i) => {
		clearErrors(`tmp_email_${i}`);
		unregister(`tmp_email_${i}`);
	};

	React.useEffect(() => {
		if (!singleInputMode) {
			getValues('team_members').map((val) => {
				unRegisterSingleEmailInput(val.fakeId);
			});
		}
	}, [singleInputMode]);

	const { isSubmitted } = useFormState({ control });

	const trimInputValue = (name, e) => {
		setValue(name, e.target.value.trim(), {
			shouldValidate: isSubmitted || submittedForLater,
			shouldDirty: true,
		});
	};

	const _next = (payload) => {
		setSetupAssessment((prev) => ({ ...prev, team: payload }));
		nextStep();
	};

	const _schedule = async () => {
		const team = getValues();
		setSubmittedForLater(true);
		if (!team.name) {
			setError('name', {
				type: 'required',
				message: 'You must enter a team name.',
			});
			document.getElementById('ttg-team-assessment-setup-team-name').focus();
			document
				.getElementById('ttg-team-assessment-setup-team-name')
				.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
		} else if (team.team_members && team.team_members.length > licenses) {
			setError('team_members', {
				type: 'required',
				message: "You've added more team members, please enter an email address for each team member",
			});
		} else if (team.cc_contacts && team.cc_contacts.length > 3) {
			setError('cc_contacts', {
				type: 'required',
				message: 'You can not add more cc contacts that 3',
			});
		} else {
			setSetupAssessment((prev) => ({ ...prev, team }));
			const payload = AssessmentService.prepareTeamSetupPayload({
				company: setupAssessment.company,
				team,
				email: setupAssessment.email,
				reminder: setupAssessment.reminder,
			});
			AssessmentService.draftTeam({
				id: setupAssessment.retest ? null : setupAssessment.id,
				retest: setupAssessment.retest ? setupAssessment.retest : null,
				...payload,
			}).then(() => {
				navigate(RouteNames.Assessments, {
					state: { savedForLater: true },
				});
			});
		}
	};

	const findIfMainContactIsPartOf = (members) => {
		let mainEmail = getValues('main_email');
		if (typeof mainEmail === 'string') {
			mainEmail = mainEmail.toLowerCase();
		}
		return members.findIndex(({ value }) => value === mainEmail);
	};

	return (
		<div className={'ttg-assessment-section flex-col'}>
			<div>
				<h1 className={'ttg-text-lg text-gray-900'}>Team Details</h1>
				<p className={'ttg-text-sm text-gray-500'}>
					Enter team details. This information will appear on the final report.
				</p>
			</div>
			<form onSubmit={handleSubmit(_next)} id={'ttg-team-assessment-setup-team-form'} className={'mt-4'}>
				<div className='short-form mb-8'>
					<AppInput
						id={'ttg-team-assessment-setup-team-name'}
						label={'Team Name'}
						errors={errors}
						{...register('name', {
							required: 'You must enter a team name.',
							maxLength: 255,
						})}
						data-ttg-max-length={255}
						onBlur={(e) => trimInputValue('name', e)}
					/>
				</div>

				<div className='mb-8 border-t-1 border-gray-200' />

				<div className='short-form'>
					<p className={'ttg-text-sm text-gray-600 mb-2'}>
						The main contact will receive status updates as each member completes the assessment. The main
						contact will also receive the final team assessment report once the last team member completes
						the test. Please enter the e-mail address of the main contact for this team:
					</p>
					<AppInput
						id={'ttg-team-assessment-setup-main-contact'}
						label={'Main Contact'}
						errors={errors}
						data-ttg-max-length={96}
						{...register('main_email', {
							required: 'You must enter a main contact.',
							maxLength: 96,
							validate: (value) =>
								UtilityService.isValidEmail(value) || 'You must enter a valid email address',
						})}
						onBlur={(e) => trimInputValue('main_email', e)}
					/>
					<p className={'ttg-text-sm text-gray-800 font-medium mb-2'}>
						Is the main contact a member of the team and also taking the assessment?{' '}
					</p>
					<Controller
						control={control}
						name={'main_contact_participant'}
						render={({ field }) => (
							<AppRadioGroup
								{...field}
								id={'ttg-team-setup-main-contact-as-participant'}
								options={[
									{ label: 'YES', value: true },
									{ label: 'NO', value: false },
								]}
								onChange={(val) => {
									let mainEmail = getValues('main_email');
									if (!mainEmail || !UtilityService.isValidEmail(mainEmail)) {
										setError('main_email', {
											type: 'required',
											message: 'You should provide a valid main email first',
										});
										return '';
									} else {
										mainEmail = mainEmail.toLowerCase();
										clearErrors('main_email');
									}
									setValue('main_contact_participant', val);
									const members = getValues('team_members');
									const idx = findIfMainContactIsPartOf(members);
									if (val) {
										if (idx === -1) {
											const emptyIndex = members.findIndex(({ value }) => !value);
											if (emptyIndex > -1) {
												members[emptyIndex].label = mainEmail;
												members[emptyIndex].value = mainEmail;
												members[emptyIndex].isValidEmail =
													UtilityService.isValidEmail(mainEmail);
												clearErrors(`tmp_email_${members[emptyIndex].fakeId}`);
												setValue(`tmp_email_${members[emptyIndex].fakeId}`, mainEmail);
											} else {
												if (
													singleInputMode &&
													members.length === getValues('team_size').value
												) {
													NotificationService.error(
														"You can't add anymore emails to this assessment without increasing the team size first."
													);
													setValue('main_contact_participant', false);
												} else {
													members.push({
														label: mainEmail,
														value: mainEmail,
														isValidEmail: UtilityService.isValidEmail(mainEmail),
														fakeId: UtilityService.generateRandomString(30),
													});
												}
											}
										}
									} else {
										if (idx > -1) {
											if (singleInputMode) {
												members[idx].label = '';
												members[idx].value = '';
												members[idx].isValidEmail = false;
												setError(`tmp_email_${members[idx].fakeId}`, {
													type: 'required',
													message: `is required`,
												});
											} else {
												members.splice(idx, 1);
											}
										}
									}
									setValue('team_members', members);
								}}
							/>
						)}
					/>

					<p className={'ttg-text-sm text-gray-800 font-medium my-2'}>
						Should the main contact and any additional contacts added below be notified as each member
						completes the assessment?
					</p>

					<Controller
						control={control}
						name={'do_main_contact_updates'}
						render={({ field }) => (
							<AppRadioGroup
								{...field}
								id={'ttg-team-setup-main-contact-updates'}
								options={[
									{ label: 'YES', value: true },
									{ label: 'NO', value: false },
								]}
							/>
						)}
					/>
				</div>

				<div className='my-8 border-t-1 border-gray-200' />

				<div className='short-form'>
					<p className={'ttg-text-sm text-gray-600 mb-2'}>
						As each team member completes the assessment, an e-mail will be sent to the main contact and any
						specified additional contacts. This e-mail will provide a summary of which team members have
						completed the assessment and which members have yet to complete it. Enter up to 3 email
						addresses of the people who should receive status updates and the final assessment.
					</p>

					<AssessmentMultipleEmailInput
						name={'cc_contacts'}
						ref={multipleRef}
						label={
							<p>
								Additional Contacts <span className='text-gray-500'>(Optional)</span>
							</p>
						}
						onChange={(members) => {
							setValue('cc_contacts', members);
						}}
						values={watch('cc_contacts')}
						placeholder={'Enter additional contacts emails separated by a comma “,”'}
						errors={errors}
						limit={3}
						min={0}
						setError={setError}
						clearErrors={clearErrors}
						isSubmitted={!!isSubmitted}
					/>
				</div>

				<div className='my-8 border-t-1 border-gray-200' />

				<div className='short-form mb-4'>
					<p className={'ttg-text-sm text-gray-600 mb-2'}>
						Select the number of members who will participate in this team assessment. <br />
						You currently have {licenses} licenses available to assign.{' '}
						<a href={RouteNames.OnlineTeamAssessment} className={'hover:underline text-rose-500'}>
							Purchase additional licenses
						</a>
					</p>

					<Controller
						control={control}
						name={'team_size'}
						render={({ field }) => (
							<AppDropdown
								{...field}
								id={'ttg-team-setup-team-size-input'}
								inputContainerClassName={'!w-24'}
								options={teamSizeOptions}
							/>
						)}
					/>

					{licenses > 20 && (
						<div className='flex items-center mb-4'>
							<div
								id={'ttg-team-setup-increase-team-size'}
								className={
									'flex items-center cursor-pointer hover:text-rose-500 border-b-1 border-white hover:border-rose-500'
								}
								onClick={() => setIsEditingTeamSize(true)}
							>
								<AppIcon icon={'information-line'} className={'text-xl mr-1'} />
								<p className={'ttg-text-sm'}>What if my team has more than 20 members?</p>
							</div>
						</div>
					)}

					{singleInputMode ? (
						<AssessmentSingleEmailInput
							name={'team_members'}
							register={register}
							errors={errors}
							emails={watch('team_members')}
							onChange={(members) => {
								setValue('team_members', members);
								const idx = findIfMainContactIsPartOf(members);
								setValue('main_contact_participant', idx > -1);
							}}
							limit={watch('team_size').value}
							min={2}
							setError={setError}
							clearErrors={clearErrors}
							isSubmitted={!!isSubmitted}
							setValue={setValue}
							onRemove={(i, size) => {
								setValue('team_size', { label: size, value: size });
								unRegisterSingleEmailInput(i);
							}}
						/>
					) : (
						<AssessmentMultipleEmailInput
							ref={multipleMembersRef}
							name={'team_members'}
							label={'Team Member Emails'}
							values={watch('team_members')}
							onChange={(members) => {
								setValue('team_members', members);
								const idx = findIfMainContactIsPartOf(members);
								setValue('main_contact_participant', idx > -1);
							}}
							errors={errors}
							limit={watch('team_size').value}
							min={watch('team_size').value}
							placeholder={'Enter team members emails separated by a comma “,”'}
							setError={setError}
							clearErrors={clearErrors}
							isSubmitted={!!isSubmitted}
						/>
					)}

					<div className={'flex flex-row items-center mt-4'}>
						<AppSwitch value={singleInputMode} onChange={setSingleInputMode} />
						<p className={'ttg-text-sm text-gray-800 font-medium ml-2'}>
							I'd rather enter team members in separated fields
						</p>
					</div>
				</div>

				<AssessmentSetupNavigation
					backIcon={'arrow-left-s-line'}
					backText={'Back'}
					iconClass={'text-xl'}
					onBack={previousStep}
					nextText={'Next'}
					loading={settingUp}
				>
					<AppButton
						id={'ttg-team-setup-save-for-later'}
						type={'button'}
						className={'btn btn-secondary mt-2 md:mt-0'}
						text={'Save for later'}
						icon={'save-line'}
						iconRight={false}
						onClick={_schedule}
						loading={settingUp}
					/>
					<DeleteDraftAssessment />
				</AssessmentSetupNavigation>
			</form>
			<LargeTeamAlertModal
				isOpen={isEditingTeamSize}
				licenses={licenses}
				onClose={() => setIsEditingTeamSize(false)}
				onConfirm={(value) => {
					setTeamSizeOptions(prepareTeamSizeOptions(value));
					setIsEditingTeamSize(false);
					setValue('team_size', { value, label: value });
				}}
			/>
		</div>
	);
};

export default TeamDetailsStep;
