import React from 'react';
import { AppButton, AppIcon, AppModalComponent } from '../../App';
import RouteNames from '../../../router/RouteNames';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const NotEnoughLicensesModal = ({ isOpen, onClose }) => {
	return (
		<AppModalComponent
			id={'ttg-confirm-individual-assessment-modal'}
			isOpen={isOpen}
			style={customStyles}
			onRequestClose={onClose}
		>
			<div className='bg-white p-6' style={{ maxWidth: '592px' }}>
				<div className='flex'>
					<div className={'flex items-center justify-center w-10 h-10 rounded-full bg-yellow-100'}>
						<AppIcon icon={'file-shield-2-line'} className={'text-lg text-yellow-500'} />
					</div>
					<div className='flex flex-1 flex-col ml-4'>
						<h1 className={'ttg-text-lg text-gray-900 font-medium mb-2'}>
							You do not have enough licenses left in your account.
						</h1>
						<p className={'ttg-text-sm text-gray-500 mb-4'}>
							If you want you can get more licenses by clicking the button below in order to continue with
							your team assessment setup.
						</p>
						<div className={'flex flex-1 justify-end'}>
							<AppButton
								id={'ttg-confirm-individual-assessment-modal-cancel-button'}
								className={'btn btn-secondary mr-4'}
								text={'Cancel'}
								onClick={() => {
									onClose();
								}}
							/>
							<AppButton
								id={'ttg-confirm-individual-assessment-modal-purchase-button'}
								text={'Purchase Licenses'}
								icon={'share-box-line'}
								onClick={() => {
									window.open(RouteNames.OnlineTeamAssessment, '_self');
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</AppModalComponent>
	);
};

export default NotEnoughLicensesModal;
