import React from 'react';
import { AppButton, AppDatepicker, AppIcon, AppSlideOver } from '../../App';
import dayjs from 'dayjs';
import UtilityService from '../../../services/UtilityService';
import NotificationService from '../../../services/NotificationService';
import AssessmentService from '../../../services/AssessmentService';
import { useAssessments } from '../../../providers/AssessmentProvider';
import { useCustomer } from '../../../providers/CustomerProvider';

const ScheduleAssessmentSidebar = ({ isOpen, onClose, assessment }) => {
	const { customer } = useCustomer();
	const [savingReminders, setSavingReminders] = React.useState(false);
	const { setAssessmentDetails } = useAssessments();
	const [reminders, setReminders] = React.useState([
		{
			timezone: UtilityService.getTimezoneByValue(
				assessment.assessment_data.scheduled_process_timezone
					? assessment.assessment_data.scheduled_process_timezone
					: customer.default_timezone
			),
			date: dayjs().format(),
		},
	]);

	React.useEffect(() => {
		if (assessment.reminders && assessment.reminders.length) {
			const _reminders = assessment.reminders.map((reminder) => {
				return {
					...reminder,
					date: dayjs(reminder.raw_process_at).format(),
					timezone: UtilityService.getTimezoneByValue(reminder.raw_timezone),
				};
			});
			return setReminders(_reminders);
		}
		setReminders([
			{
				timezone: UtilityService.getTimezoneByValue(
					assessment.assessment_data.scheduled_process_timezone
						? assessment.assessment_data.scheduled_process_timezone
						: customer.default_timezone
				),
				date: (assessment.assessment_data.scheduled_process_at
					? dayjs(assessment.assessment_data.scheduled_process_at)
					: dayjs()
				)
					.add(1, 'day')
					.startOf('hour')
					.format(),
			},
		]);
	}, [assessment, isOpen]);

	const isAssessmentCompleted = React.useMemo(() => {
		return assessment.assessment_data && assessment.assessment_data.status === 'Complete';
	}, [assessment]);

	const save = async () => {
		const currentIDs = reminders.map(({ id }) => id);
		const toDelete = assessment.reminders.filter((reminder) => currentIDs.indexOf(reminder.id) === -1);

		const preparePayload = (_reminders) => {
			return {
				reminders: _reminders.map((r) => {
					return {
						id: r.id,
						date: dayjs(r.date).format('YYYY-MM-DD HH:mm'),
						timezone: r.timezone.value,
					};
				}),
			};
		};
		setSavingReminders(true);
		AssessmentService.syncReminders(
			isAssessmentCompleted ? 'assessment-retest-reminder' : 'assessment-reminder',
			assessment.assessment_data.team_id,
			{
				reminders: toDelete.map((r) => ({
					id: r.id,
				})),
			},
			preparePayload(reminders)
		)
			.then((response) => {
				setAssessmentDetails((prev) => ({ ...prev, reminders: response }));
				onClose();
				if (!reminders?.length) {
					return NotificationService.success('Reminders removed successfully');
				}
				return NotificationService.success(`Reminder${reminders.length > 1 ? 's' : ''} saved successfully`);
			})
			.finally(() => setSavingReminders(false));
	};

	return (
		<AppSlideOver isOpen={isOpen} closeModal={onClose} slideOverContentClass={'!bg-white'}>
			<div id={'ttg-assessment-reminders-sidebar'}>
				<div>
					<div className={'p-4'}>
						<h1 className={'ttg-text-lg font-medium text-gray-900'}>
							{isAssessmentCompleted ? 'Schedule Retest Reminders' : 'Schedule Reminders'}
						</h1>
						{isAssessmentCompleted ? (
							<p className={'ttg-text-sm text-gray-400 mb-5'}>
								Schedule retest reminders for you to retest this team. <br />
								You can add up to two reminders.
							</p>
						) : (
							<p className={'ttg-text-sm text-gray-400 mb-5'}>
								Schedule reminders for team members who have not yet completed their assessment. <br />
								You can add up to two reminders.
							</p>
						)}

						{reminders && reminders.map((reminder, index) => {
							return (
								<div key={index} className={'flex flex-row mb-4'}>
									<div className='w-full'>
										<AppDatepicker
											label={`Select date and time Reminder #${index + 1}`}
											value={reminder.date}
											onChange={(d) => {
												const _reminders = [...reminders];
												_reminders[index].date = dayjs(d).format();
												_reminders[index].raw_process_at = dayjs(d).format();
												setReminders(_reminders);
											}}
											min={
												assessment.assessment_data.scheduled_process_at
													? dayjs(assessment.assessment_data.scheduled_process_at).endOf(
															'day'
													  )
													: dayjs().endOf('day').add(-1, 'day')
											}
											timezone={reminder.timezone}
											timeSensitive
											onTimezoneChange={(timezone) => {
												const _reminders = [...reminders];
												_reminders[index].timezone = timezone;
												setReminders(_reminders);
											}}
										/>
									</div>
									{(reminders.length > 1 || reminder.id) && (
										<div className={'flex justify-end items-end'}>
											<AppButton
												id={`ttg-assessment-schedule-delete-button-${index}`}
												type={'button'}
												className={'btn btn-action ml-4'}
												icon={'delete-bin-line'}
												iconClass={'text-red-500'}
												onClick={() => {
													const _reminders = [...reminders];
													_reminders.splice(index, 1);
													setReminders(_reminders);
												}}
											/>
										</div>
									)}
								</div>
							);
						})}

						{reminders.length === 0 && (
							<p
								id={'ttg-schedule-reminder-remove-all-text'}
								className={'ttg-text-sm text-gray-800 mb-4'}
							>
								You are about to remove previously set reminders.
							</p>
						)}

						{reminders.length < 2 && (
							<div
								id={'ttg-assessment-add-new-schedule-button'}
								className={'flex flex-1 items-center cursor-pointer ttg-text-sm'}
								onClick={() => {
									let _reminders = [...reminders];
									if (_reminders[0]) {
										const { id, ...newReminder } = _reminders[0];
										_reminders.push(newReminder);
									} else {
										_reminders = [
											{
												timezone: UtilityService.getTimezoneByValue(
													assessment.assessment_data.scheduled_process_timezone
														? assessment.assessment_data.scheduled_process_timezone
														: customer.default_timezone
												),
												date: dayjs().add(1, 'day').startOf('hour').format(),
											},
										];
									}

									setReminders(_reminders);
								}}
							>
								<AppIcon icon={'add-circle-line'} className={'text-red-500'} />
								<span className={'text-red-500'}> Add new reminder date and time</span>
							</div>
						)}
						<hr className='text-gray-200 my-4' />

						<div className={'flex flex-1'}>
							<div className='flex flex-1'>
								<AppButton
									onClick={() => {
										if (UtilityService.checkReminder(reminders)) {
											return save();
										}
										NotificationService.show(
											'error',
											'Schedule Time',
											'You cannot select a time in the past.'
										);
									}}
									id={'ttg-assessment-schedule-confirm-button'}
									className={'btn btn-primary mr-2 w-full'}
									text={reminders.length === 1 ? 'Set Reminder' : 'Set Reminders'}
									loading={savingReminders}
								/>
							</div>
							<div className='flex flex-1'>
								<AppButton
									type={'button'}
									id={'ttg-assessment-schedule-cancel-button'}
									className={'btn btn-secondary ml-2 w-full'}
									text={'Cancel'}
									onClick={onClose}
									loading={savingReminders}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</AppSlideOver>
	);
};

export default ScheduleAssessmentSidebar;
