import React from 'react';
import { AppButton } from '../../App';
import ConfirmDeleteAssessmentModal from './ConfirmDeleteAssessmentModal';
import { useAssessments } from '../../../providers/AssessmentProvider';

const DeleteDraftAssessment = ({}) => {
	const [confirmDelete, setConfirmDelete] = React.useState(false);

	const { setupAssessment } = useAssessments();

	if (!setupAssessment?.is_draft) {
		return null;
	}

	return (
		<>
			<AppButton
				type={'button'}
				className={'btn btn-secondary danger md:ml-4 mt-2 md:mt-0'}
				text={'Delete'}
				icon={'delete-bin-line'}
				iconRight={false}
				onClick={() => setConfirmDelete(true)}
			/>
			<ConfirmDeleteAssessmentModal
				isOpen={confirmDelete}
				onClose={() => setConfirmDelete(false)}
				name={setupAssessment.team.name}
			/>
		</>
	);
};

export default DeleteDraftAssessment;
