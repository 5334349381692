import React from 'react';
import { ProductInfo } from './index';

const DetailsProducts = ({ products }) => {
	return (
		<div className='flex flex-1 flex-col'>
			{products.map((product, index) => (
				<ProductInfo product={product} key={index} />
			))}
		</div>
	);
};

export default DetailsProducts;
