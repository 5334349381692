import React from 'react';
import Images from '../../../../assets/Images';
import { AppPdfPage } from '../../../App';

const items = [
	{
		title: 'Absence of Trust',
		description:
			'This occurs when team members are reluctant to be vulnerable with one another, and are thus unwilling to admit their mistakes, acknowledge their weaknesses or ask for help. Without a certain comfort level among team members, a foundation of trust is impossible.',
	},
	{
		title: 'Fear of Conflict',
		description:
			'Trust is critical because without it, teams are unlikely to engage in unfiltered, passionate debate about key issues. This creates two problems. First, stifling conflict actually increases the likelihood of destructive, back-channel sniping. Second, it leads to sub-optimal decision-making because the team is not benefiting from the true ideas and perspectives of its members.',
	},
	{
		title: 'LACK OF COMMITMENT',
		description:
			'Without conflict, it is extremely difficult for team members to truly commit to decisions because they don’t feel that they are part of the decision. This often creates an environment of ambiguity and confusion in an organization, leading to frustration among employees, especially top performers.',
	},
	{
		title: 'AVOIDANCE OF ACCOUNTABILITY',
		description:
			'When teams don’t commit to a clear plan of action, peer-to-peer accountability suffers greatly. Even the most focused and driven individuals will hesitate to call their peers on counterproductive actions and behaviors if they believe those actions and behaviors were never agreed upon in the first place.',
	},
	{
		title: 'INATTENTION TO RESULTS',
		description:
			'When team members are not holding one another accountable, they increase the likelihood that individual ego and recognition will become more important than collective team results. When this occurs, the business suffers and the team starts to unravel.',
	},
];

const The5D = () => {
	const renderItem = (title, description, num) => {
		return (
			<div className='TheFiveDysfunctions__item__layout' key={num}>
				<div className='TheFiveDysfunctions__item__dysfunctionNum'>
					<img className='TheFiveDysfunctions__item__numUnderlay' src={Images.report.the5d[num]} alt={''} />
					<h3 className='TheFiveDysfunctions__item__dysfunctionNumTitle'>Dysfunction #{num}: </h3>
				</div>
				<div className='TheFiveDysfunctions__item__dysfunctionDesc'>
					<h3 className='TheFiveDysfunctions__item__dysfunctionDescTitle'>{title}</h3>
					<p className='TheFiveDysfunctions__item__dysfunctionDescParagraph'>{description}</p>
				</div>
			</div>
		);
	};

	return (
		<AppPdfPage page={3} sectionClassName={'TheFiveDysfunctions'}>
			<div className='TheFiveDysfunctions__layout'>
				<div className='TheFiveDysfunctions__heading'>
					<div className='PageHeading'>
						<div className='PageHeading__title'>The Five Behaviors</div>
					</div>
				</div>

				<div className='TheFiveDysfunctions__content'>
					{items.map(({ title, description }, index) => renderItem(title, description, index + 1))}
				</div>
			</div>
		</AppPdfPage>
	);
};

export default The5D;
