import React, { createContext, useContext } from 'react';
import { Outlet } from 'react-router-dom';

export const ProfileMenuContext = createContext({});

export const ProfileMenuProvider = ({ children }) => {
	const handleLogout = () => {
		window.open(`/client/?logout`, '_self');
	};

	return <ProfileMenuContext.Provider value={{ onLogout: handleLogout }}>{children}</ProfileMenuContext.Provider>;
};

export const useProfileMenu = () => useContext(ProfileMenuContext);

export const ProfileMenuContextLayout = () => (
	<ProfileMenuProvider>
		<Outlet />
	</ProfileMenuProvider>
);
