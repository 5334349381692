import React from 'react';
import PhoneInput from 'react-phone-number-input/react-hook-form';

import { AppIcon, AppLabel } from './index';
import renderErrorMessage from '../../constants/ErrorMessages';

const PhoneNumber = React.forwardRef(
	(
		{
			label = 'Phone number',
			placeholder = '',
			showErrorMessage = true,
			containerClassName,
			defaultErrorMessage = '',
			name,
			initialValue,
			value,
			onChange,
			control,
			errors = {},
			...props
		},
		ref
	) => {
		const hasError = errors && errors[name];

		const shouldShowErrorMessage = !!(showErrorMessage && hasError);

		const _onChange = (v) => {
			onChange({
				target: {
					name,
					value: v ? v : '',
				},
			});
		};

		return (
			<div
				className={`ttg-phone-number ${hasError ? 'ttg-phone-number-error' : ''} ${
					containerClassName ? containerClassName : ''
				}`}
			>
				<AppLabel label={label} />
				<div id={'ttg-phone-number-input-container'} className={'phone-input-container'}>
					<PhoneInput
						defaultCountry={'US'}
						placeholder={placeholder}
						value={value ? value : initialValue}
						onChange={_onChange}
						ref={ref}
						name={name}
						control={control}
						id={'ttg-phone-number-input'}
					/>
					{!!errors[name] && <AppIcon className={`ttg-input-icon error`} icon={'error-warning-fill'} />}
				</div>
				<span className={`app-input-error-message ${shouldShowErrorMessage ? 'visible' : 'invisible'}`}>
					{hasError &&
						renderErrorMessage(errors[name].type, props, defaultErrorMessage, errors[name].message)}
				</span>
			</div>
		);
	}
);

export default PhoneNumber;
