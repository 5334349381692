import React from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

import UtilityService from '../../services/UtilityService';
import { PublicLayout } from '../../components/Layout';

const RegisterInvitation = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { token } = UtilityService.getQueryParams(location.search);

	const handleRedirect = React.useCallback(() => {
		if (token) return navigate(`/register?license_token=${token}`, { replace: true });

		return navigate(`/register`, { replace: true });
	}, [navigate, token]);

	React.useEffect(handleRedirect, []);

	return (
		<PublicLayout headerTitle='' headerStatus={() => {}} footer={<></>}>
			<div className='absolute top-0 left-0 right-0 bottom-0 ttg-loader-bg flex justify-center items-center'>
				<div className='spinner'>
					<div className='double-bounce1' />
					<div className='double-bounce2' />
				</div>
			</div>
		</PublicLayout>
	);
};

export default RegisterInvitation;
