import React from 'react';
import { AppPdfPage } from '../../../App';
import UtilityService from '../../../../services/UtilityService';

const ItemRanking = ({ details, page }) => {
	const pages = React.useMemo(() => {
		const questions = details.questions
			.filter(({ type }) => type === 'radio')
			.sort((a, b) => {
				return details.raw_scores_average[b.sort - 1] - details.raw_scores_average[a.sort - 1];
			});
		return UtilityService.splitArray(questions, 10);
	}, []);

	const _ItemRanking = (questions, index) => (
		<AppPdfPage page={page + index} key={index} sectionClassName={'ItemRanking'}>
			<div className='ItemRanking'>
				<div className='ItemRanking__layout'>
					<div className='ItemRanking__heading'>
						<div className='PageHeading'>
							<div className='PageHeading__title'>Item Ranking</div>
						</div>
					</div>
					<div className='ItemRanking__content'>
						<div className='ItemRanking__summary'>
							{index === 0
								? 'These pages shows the ranking, from the most frequent to least frequent, of all 37 statements. A dotted red line separates the 10 least frequent behaviors from the others.'
								: '(Continued from the previous page.)'}
						</div>

						<div className='ItemRanking__questionReview'>
							{questions.map((question, idx) => (
								<React.Fragment key={question.sort}>
									{index === 2 && idx === 7 && (
										<div className='AreaQuestion ItemRanking--redline'></div>
									)}
									<div className='AreaQuestion AreaQuestion--condensed AreaQuestion--small'>
										<div className='AreaQuestion__number'>
											{UtilityService.padNumber(question.sort, '0')}.
										</div>
										<div className='AreaQuestion__content'>
											<div
												className='AreaQuestion__label'
												dangerouslySetInnerHTML={{ __html: question.question }}
											/>
											<div className='AreaQuestion__meta'>
												<div className='AreaQuestion__metaCol'>
													Behavior: <strong>{question.category}</strong>
												</div>
												<div className='AreaQuestion__metaCol'>
													Avg. Score:{' '}
													<strong>
														{details.raw_scores_average[question.sort - 1].toFixed(2)}
													</strong>
												</div>
											</div>
										</div>
									</div>
								</React.Fragment>
							))}
						</div>
					</div>
				</div>
			</div>
		</AppPdfPage>
	);

	return <>{pages.map(_ItemRanking)}</>;
};

export default ItemRanking;
