import React from 'react';

const Totals = ({ totals, order }) => {
	const item = (label, value) => {
		if (!value) {
			return null;
		}

		return (
			<div className={'flex flex-row items-center border-b-1 border-gray-200 py-1'}>
				<p className={'ttg-text-sm text-gray-800 font-medium'} id={`ttg-order-details-${label}-label`}>
					{label}
				</p>
				<p
					className={'flex flex-1 ttg-text-sm text-gray-500 text-right justify-end'}
					id={`ttg-order-details-${label}-value`}
				>
					{value}
				</p>
			</div>
		);
	};
	return (
		<div className={'flex flex-1 flex-col'}>
			{item('Subtotal', totals.subtotal)}

			{totals.discount && (
				<div className={'flex flex-row items-center border-b-1 border-gray-200 py-1'}>
					<p className={'text-gray-800 font-medium'}>
						<span className={'ttg-text-sm '} id={'ttg-order-details-discount-label'}>
							{' '}
							Discount coupon
						</span>
						{totals.coupon_code && (
							<span
								className={'text-xs leading-none font-normal'}
								id={'ttg-order-details-discount-coupon'}
							>
								<br />({totals.coupon_code})
							</span>
						)}
					</p>
					<p
						className={'flex flex-1 ttg-text-sm text-gray-500 text-right justify-end'}
						id={'ttg-order-details-discount-value'}
					>
						{totals.discountValue > 0 ? '-' : ''}
						{totals.discount}
					</p>
				</div>
			)}

			{totals.shipping && (
				<div className={'flex flex-row items-center border-b-1 border-gray-200 py-1'}>
					<p className={'text-gray-800 font-medium'}>
						<span className={'ttg-text-sm'} id={'ttg-order-details-shipping-label'}>
							Shipping Fee
						</span>
						{order.shipping_method && (
							<span
								className={'text-xs leading-none font-normal'}
								id={'ttg-order-details-shipping-method'}
							>
								<br />
								{order.shipping_method}
							</span>
						)}
					</p>
					<p
						className={'flex flex-1 ttg-text-sm text-gray-500 text-right justify-end'}
						id={'ttg-order-details-shipping-value'}
					>
						{totals.shipping}
					</p>
				</div>
			)}

			{item('Tax', totals.tax)}
			<div className={'flex flex-1 flex-row py-1'}>
				<p className={'ttg-text-sm text-gray-800 font-semibold'}>Total</p>
				<p className={'flex flex-1 ttg-text-sm text-gray-800 text-right justify-end font-semibold'}>
					{totals.total}
				</p>
			</div>
		</div>
	);
};

export default Totals;
