import React from 'react';
import { AppPdfPage } from '../../../App';
import ReportService from '../../../../services/ReportService';

const TeamSummary = ({ details }) => {
	const getLevelByProp = (prop) => {
		return ReportService.getLevelByValue(details.average_response_per_category[prop]);
	};

	const teamSummaryPyramid = () => {
		return (
			<svg className='teamPyramid' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 440.98 337.17'>
				<title>Pyramid</title>
				<g className={`ScoresPyramid`} data-name='Layer 2'>
					<g id='Layer_1-2' data-name='Layer 1'>
						<polygon
							fill='#F9FAFB'
							points='220.49 0 440.98 337.17 0 337.17 220.49 0'
							transform='translate(-15 0)'
						/>
						{ReportService.PyramidItems.map((i) => (
							<g id={i.key} key={i.key}>
								<polygon className={`${i.key} ${getLevelByProp(i.key)}`} points={i.points} />
								<text className='PyramidText' transform={i.transform}>
									{i.key}
								</text>
							</g>
						))}
					</g>
				</g>
			</svg>
		);
	};

	const teamSummaryLevel = () => {
		return ReportService.Ordered5DF.map((key) => (
			<div className='level' key={key}>
				<span>{getLevelByProp(key)} </span>
			</div>
		));
	};

	return (
		<AppPdfPage page={5} sectionClassName={'TeamSummary'}>
			<div className='TeamSummary__layout'>
				<div className='TeamSummary__heading'>
					<div className='PageHeading'>
						<div className='PageHeading__title'>Team Summary</div>
					</div>
				</div>
				<div className='TeamSummary__content'>
					<div className='TeamSummary__mainText'>
						<div className='Blurb'>
							<div className='Blurb__content'>
								<p>{ReportService.buildInitialString(getLevelByProp)}</p>
							</div>
						</div>
					</div>
					<div className='TeamSummary__pyramidLayout'>
						<div className='TeamSummary__pyramidLevels'>{teamSummaryLevel()}</div>
						<div className='TeamSummary__pyramid'>{teamSummaryPyramid()}</div>
					</div>
					<div className='TeamSummary__pyramidLayout'>
						<div className='TeamSummary__pyramidLevels'></div>
						<div className='TeamSummary__pyramid'>
							<div className='ScoresLegend'>
								<div className='ScoresLegend__column'>
									<div className='ScoresLegend__key ScoresLegend__key--red'></div>
									<div className='ScoresLegend__description'>
										<strong>Low</strong> (Less Than 3)
									</div>
								</div>

								<div className='ScoresLegend__column'>
									<div className='ScoresLegend__key ScoresLegend__key--yellow'></div>
									<div className='ScoresLegend__description'>
										<strong>Medium</strong> (3 — 3.99)
									</div>
								</div>

								<div className='ScoresLegend__column'>
									<div className='ScoresLegend__key ScoresLegend__key--green'></div>
									<div className='ScoresLegend__description'>
										<strong>High</strong> (4 & Above)
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</AppPdfPage>
	);
};

export default TeamSummary;
