import Images from '../../assets/Images';

const ResultsSummary = ({ humbleDetails, hungryDetails, smartDetails }) => {
	return (
		<div className={'flex-row survey-font'}>
			<div className={'ttg-text-2xl text-gray-500 font-medium'}>Result Summary</div>

			<div className={'flex-col mt-14'}>
				<div
					className={`ttg-text-lg float-left ml-18 font-semibold mt-10 itp-pdf-${humbleDetails.color} flex-row survey-font`}
				>
					Your Score : {humbleDetails.score}
					<div className='first-letter:uppercase'>{humbleDetails.icon}</div>
				</div>
				<div
					className={`ttg-text-lg float-right mr-10 font-semibold itp-pdf-${hungryDetails.color} mt-10 flex-row survey-font`}
				>
					Your Score : {hungryDetails.score}
					<div className='first-letter:uppercase'>{hungryDetails.icon}</div>
				</div>
			</div>

			<div className={'py-10 container h-128 '}>
				<div className={'inline-flex bg-blend-overlay -ml-4'}>
					<img
						className={'w-1/12 mt-18 -ml-10 absolute'}
						src={Images.itp_assessment.humble[humbleDetails.icon]}
						alt={humbleDetails.icon}
					/>

					<img className={'mt-18 w-1/2 -ml-8 absolute'} src={Images.itp_assessment.ven} alt='ven' />
					<img
						className={'w-1/12 mt-18 ml-80 absolute'}
						src={Images.itp_assessment.hungry[hungryDetails.icon]}
						alt={hungryDetails.icon}
					/>
				</div>
			</div>

			<img
				className={'w-1/12 mt-3 ml-80 absolute'}
				src={Images.itp_assessment.smart[smartDetails.icon]}
				alt={smartDetails.icon}
			/>

			<div
				className={`ttg-text-lg text-center mt-15 font-semibold flex-row itp-pdf-${smartDetails.color} survey-font`}
			>
				Your Score : {smartDetails.score}
				<div className={'first-letter:uppercase'}>{smartDetails.icon}</div>
			</div>

			<div className={'flex-row mt-5'}>
				<div className={'inline-flex py-3 survey-font'}>
					<div className={'itp-pdf-circle-green w-8 h-8 mx-3'}></div>
					<div className={'ttg-text-base font-semibold mt-1 w-10/12'}>
						High: A score of 18 or 17 is an indication that the virtue is a potential strength.
					</div>
				</div>

				<div className={'inline-flex survey-font'}>
					<div className={'itp-pdf-circle-yellow w-8 h-8 mx-3'}></div>
					<div className={'ttg-text-base font-semibold mt-1 w-10/12'}>
						Medium: A score of 16 to 14 is an indication that you most likely have some work to do around
						that virtue to become an ideal team player.
					</div>
				</div>

				<div className={'inline-flex py-3 survey-font'}>
					<div className={'itp-pdf-circle-red w-8 h-8 mx-3'}></div>
					<div className={'ttg-text-base font-semibold mt-1 w-10/12'}>
						Low: A score of 13 or lower is an indication that you need improvement around that virtue to
						become an ideal team player.
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResultsSummary;
