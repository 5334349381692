import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppButton, AppIcon } from '../../../App';
import RouteNames from '../../../../router/RouteNames';
import { useAssessments } from '../../../../providers/AssessmentProvider';
import { useCustomer } from '../../../../providers/CustomerProvider';

const Completed = () => {
	const navigate = useNavigate();

	const { setupAssessment, newAssessmentId } = useAssessments();
	const { setLicenses } = useCustomer();

	React.useEffect(() => {
		setLicenses((prev) => ({
			...prev,
			licenses: prev.licenses - 1,
		}));
	}, []);

	return (
		<div className={'ttg-assessment-section flex-col'}>
			<div className='flex flex-col items-center justify-center text-center'>
				<div className='flex items-center justify-center w-16 h-16 rounded-full bg-green-100'>
					<AppIcon icon={'check-fill'} className={'text-2xl text-green-500'} />
				</div>
				<p className='ttg-text-2xl text-gray-800 font-medium mb-5 mt-6'>
					Individual assessment setup is complete
				</p>
				<p className={'text-gray-500 mt-4'}>
					The individual assessment for{' '}
					<span className={'font-medium text-gray-700'} id={'ttg-individual-assessment-completed-name'}>
						{setupAssessment.individual.name}
					</span>{' '}
					of{' '}
					<span
						className={'font-medium text-gray-700'}
						id={'ttg-individual-assessment-completed-company-name'}
					>
						{setupAssessment.individual.company_name}
					</span>{' '}
					has been created. An e-mail has been sent to{' '}
					<span
						className={'font-medium text-gray-700'}
						id={'ttg-individual-assessment-completed-participant'}
					>
						{setupAssessment.individual.participant}
					</span>{' '}
					and the individual assessment is now in process.
				</p>
				<p className={'text-gray-500 mt-4'}>
					In addition to accessing the assessment via email, team members have the ability to go to{' '}
					<a href={'/login'}>www.tablegroup.com/login</a> to complete their assessment. The email listed in
					the team setup will need to be entered to begin their assessment.
				</p>
				<p className={'text-gray-500 mt-4'}>
					To check the status of your assessment or to make any necessary changes please click the
					“Assessments” link.
				</p>
				<p className={'text-gray-500 mt-4 mb-6'}>
					If you are participating in the assessment, you will find instructions in your e-mail.
				</p>
				<AppButton
					id={'ttg-setup-assessment-completed-button'}
					text={'Back to My Assessments'}
					onClick={() => navigate(RouteNames.Assessments, { replace: true })}
				/>
				<p id={'ttg-new-assessment-id'} className='invisible'>
					{newAssessmentId}
				</p>
			</div>
		</div>
	);
};

export default Completed;
