export enum ORDER_STATUS {
  Approved = "Approved",
  Cancelled = "Cancelled",
  Pending = "Pending",
  Processing = "Processing",
  Refunded = "Refunded",
  Update = "Update",
}

export const OrderStatusNumberToEnum = {
  3: ORDER_STATUS.Approved,
  6: ORDER_STATUS.Cancelled,
  1: ORDER_STATUS.Pending,
  2: ORDER_STATUS.Processing,
  5: ORDER_STATUS.Refunded,
  4: ORDER_STATUS.Update,
}
