import React from 'react';
import Images from '../../../../assets/Images';
import { AppPdfPage } from '../../../App';

const TheRewards = () => {
	return (
		<AppPdfPage page={4} sectionClassName={'TheRewards'}>
			<div className='TheRewards__layout'>
				<div className='TheRewards__heading'>
					<div className='PageHeading'>
						<div className='PageHeading__title'>The Rewards</div>
						<div className='PageHeading__image'>
							<img width='82' src={Images.report.flag} />
						</div>
					</div>
				</div>

				<div className='TheRewards__content'>
					<div className='TheRewards__mainText'>
						<div className='Blurb'>
							<div className='Blurb__content'>
								<p>
									Striving to create a functional, cohesive team is one of the few remaining
									competitive advantages available to any organization looking for a powerful point of
									differentiation.
								</p>
								<p>
									Functional teams get more accomplished in less time than other teams because they
									avoid wasting time on the wrong issues and revisiting the same topics again and
									again. They also make higher quality decisions and stick to those decisions by
									eliminating politics and confusion among themselves and the people they lead.
									Finally, functional teams keep their best employees longer because “A” players
									rarely leave organizations where they are part of, or being led by, a cohesive team.
								</p>
							</div>
						</div>
					</div>

					<div className='TheRewards__aside'>
						<div className='TheRewards__borderPanel'>
							<div className='TheRewards__miniEmblem'>
								<img src={Images.report.emblem} alt={''} />
							</div>
							<p className='TheRewards__aside__paragraph'>
								Functional teams get more accomplished in less time than other teams because they avoid
								wasting time on the wrong issues and revisiting the same topics again and again.
							</p>
						</div>
					</div>
				</div>
			</div>
		</AppPdfPage>
	);
};

export default TheRewards;
