import React from 'react';
import { AppWidget } from '../../App';
import { AppTools } from '../index';
import { useCustomer } from '../../../providers/CustomerProvider';

const ToolsWidget = () => {
	const { customer } = useCustomer();

	if (!customer || !customer.customers_email_address) {
		return null;
	}

	return (
		<AppWidget
			id={'tools'}
			labelComponent={
				<button id={'ttg-account-header-tools'} className={'nav-item'}>
					Tools
				</button>
			}
			className={'ttg-account-tools-widget'}
		>
			<AppTools />
		</AppWidget>
	);
};

export default ToolsWidget;
