import React from 'react';
import { AppButton, AppIcon, AppOutsideAlerter, AppTooltip } from './index';
import DropdownPortal from './DropdownPortal';

const DropdownButton = ({
	label,
	actions,
	icon = 'arrow-down-s-line',
	buttonClassName = 'btn btn-primary',
	isOpenClassName = 'dropdown-btn',
	loading,
	closeOnAction,
	actionContainerClass = 'max-w-64',
	side = 'left',
	id = 'app-actions-dropdown-button',
	usePortal = false,
	...props
}) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const dropdownRef = React.useRef(null);

	const renderButton = () => {
		return (
			<div ref={dropdownRef}>
				<AppButton
					id={id}
					text={label}
					icon={icon}
					iconClass='text-2xl'
					className={`${buttonClassName} ${isOpen ? isOpenClassName : ''}`}
					onClick={() => setIsOpen(!isOpen)}
					loading={loading}
					{...props}
				/>
			</div>
		);
	};

	const renderActionItem = (
		{ id, icon, iconClassName, label, labelClassName, action, disabled, disabledText },
		index
	) => (
		<div key={index}>
			<div
				id={id}
				onClick={() => {
					if (disabled) return;
					if (closeOnAction) setIsOpen(false);
					action();
				}}
				className={`flex flex-row items-center py-1 px-2 pr-4 hover:bg-gray-50 ${
					disabled ? 'cursor-not-allowed bg-gray-200' : 'cursor-pointer'
				}`}
				data-for={`ttg-dropdown-button-tooltip-${index}`}
			>
				<AppIcon icon={icon} className={`pr-2 text-2xl text-gray-600 ${iconClassName ?? ''}`} />
				<p className={`ttg-text-sm text-gray-800 ${labelClassName ?? ''}`}>{label}</p>
			</div>
			{disabled && disabledText && (
				<AppTooltip id={`ttg-dropdown-button-tooltip-${index}`} placement={'right'}>
					{disabledText}
				</AppTooltip>
			)}
		</div>
	);

	const dropdownContent = (
		<div className={`action-container ${side} ${isOpen ? '' : 'hidden'} ${actionContainerClass}`}>
			{actions.map(renderActionItem)}
		</div>
	);

	return (
		<div className={'flex flex-1'}>
			<div className='dropdown-container'>
				{usePortal ? (
					<>
						{renderButton()}
						<DropdownPortal triggerRef={dropdownRef} isOpen={isOpen} setIsOpen={setIsOpen}>
							{dropdownContent}
						</DropdownPortal>
					</>
				) : (
					<AppOutsideAlerter handleClickOutsideFunction={() => setIsOpen(false)} className={'flex relative'}>
						{renderButton()}
						{dropdownContent}
					</AppOutsideAlerter>
				)}
			</div>
		</div>
	);
};

export default DropdownButton;
