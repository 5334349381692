import React from 'react';
import { AppIcon } from '../App';

const Action = ({ icon, text, onClick, color, ...props }) => {
	return (
		<div className={'ttg-address-action'} onClick={onClick} {...props}>
			<AppIcon icon={icon} className={'mx-1'} style={{ color: color }} />
			<span className={'ttg-text-sm font-normal'} style={{ color: color }}>
				{text}
			</span>
		</div>
	);
};

export default Action;
