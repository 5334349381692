import { AppWizard } from '../App';
import React from 'react';
import SurveyInstructions from './SurveyInstructions';
import SurveyForm from './SurveyForm';

const SurveySetup = ({ type }) => {
	return (
		<AppWizard
			className={'team-assessment-setup'}
			onStepChange={() => {
				window.scrollTo(0, 0);
				window.postMessage({ name: 'surveyScroll', scrollTop: 0 }, window.origin);
			}}
			initialStep={1}
		>
			<SurveyForm surveyType={type} />
			<SurveyInstructions type={type} />
		</AppWizard>
	);
};

export default SurveySetup;
