import React from 'react';
import { AppButton, AppIcon, AppModalComponent } from '../../App';
import LicenseService from '../../../services/LicenseService';
import { useCustomer } from '../../../providers/CustomerProvider';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const ConfirmTransfer = ({ licenses, email, isOpen, onClose, onSuccessClose, existingAccount = true }) => {
	const [loadingTransfer, setLoadingTransfer] = React.useState(false);
	const { setLicenses } = useCustomer();

	const [transferred, setTransferred] = React.useState(false);

	const _transfer = () => {
		setLoadingTransfer(true);
		LicenseService.transferLicenses({ email, licenses })
			.then(() => {
				setLicenses((prev) => ({
					...prev,
					licenses: (prev.licenses -= licenses),
					used_licenses: (prev.used_licenses += licenses),
				}));
				setTransferred(true);
			})
			.catch(() => {
				onClose();
			})
			.finally(() => setLoadingTransfer(false));
	};

	return (
		<AppModalComponent isOpen={isOpen} closeModal={onClose} style={customStyles}>
			<div className='bg-white p-6' style={{ maxWidth: '612px' }}>
				{transferred ? (
					<div className='flex flex-1 flex-col ml-4'>
						{existingAccount ? (
							<div className='text-center'>
								<div
									className={
										'flex items-center justify-center rounded-full w-12 h-12 bg-green-100 mx-auto'
									}
								>
									<AppIcon icon={'check-fill'} className={'text-2xl text-green-500'} />
								</div>
								<h1 className={'ttg-text-lg text-gray-900 mt-6 mb-3 font-medium'}>
									Successfully Transferred!
								</h1>
								<p className={'text-sm text-gray-600 font-normal'}>
									You have successfully transferred <b>{licenses}</b>{' '}
									{licenses === 1 ? 'license ' : 'licenses '} to <b>{email}</b>.
								</p>
							</div>
						) : (
							<div className='text-center'>
								<div
									className={
										'flex items-center justify-center rounded-full w-12 h-12 bg-yellow-50 mx-auto'
									}
								>
									<AppIcon icon={'time-line'} className={'text-2xl text-yellow-500'} />
								</div>
								<h1 className={'ttg-text-lg text-gray-900 mt-6 mb-3 font-medium'}>Pending License</h1>
								<p className={'text-sm text-gray-600 font-normal'}>
									<b>{email}</b> has successfully been invited to accept <b>{licenses}</b>{' '}
									{licenses === 1 ? 'license ' : 'licenses '}. A pending transfer has been created,
									and will be completed as soon as <b>{email}</b> logs into their new account.
								</p>
							</div>
						)}
						<div className={'flex flex-1 justify-center mt-6'}>
							<AppButton
								id={'ttg-transfer-licenses-success-button'}
								className={'btn btn-primary w-48'}
								text={'Done'}
								onClick={onSuccessClose}
							/>
						</div>
					</div>
				) : (
					<div id='ttg-transfer-licenses-prompting-screen' className='flex flex-1 flex-col ml-4'>
						<h1 className={'ttg-text-lg text-gray-900 font-medium mb-2'}>Transfer licenses</h1>
						<p
							className={'ttg-text-sm text-gray-500 mb-4'}
							dangerouslySetInnerHTML={{
								__html: existingAccount
									? `Are you sure you wish to transfer <span className='font-medium'>${licenses}</span> ${
											licenses === 1 ? 'license ' : 'licenses '
									  } to <b>${email}</b>?`
									: `Looks like <b>${email}</b> does not have an account. Do you wish to proceed with the transfer and send an invitation to create an account?`,
							}}
						></p>
						<div className={'flex justify-end'}>
							<AppButton
								id={'ttg-confirm-transfer-cancel-button'}
								text={'Cancel'}
								className={'btn btn-secondary mr-4'}
								onClick={onClose}
								loading={loadingTransfer}
							/>
							<AppButton
								id={'ttg-confirm-transfer-submit-button'}
								text={existingAccount ? 'Yes, Transfer' : 'Send Invitation and Transfer'}
								onClick={_transfer}
								loading={loadingTransfer}
							/>
						</div>
					</div>
				)}
			</div>
		</AppModalComponent>
	);
};

export default ConfirmTransfer;
