import React from 'react';
import { AppPdfWrapper } from '../../../App';
import CoverPage from './CoverPage';
import Introduction from './Introduction';
import The5D from './The5D';
import TeamSummary from './TeamSummary';
import TheFiveBehaviors from './TheFiveBehaviors';
import Areas from './Areas';
import ReportService from '../../../../services/ReportService';
import Images from '../../../../assets/Images';
import KeyDifferences from './KeyDifferences';
import TeamCultureQuestionsTrust from './TeamCultureQuestionsTrust';
import TeamCultureQuestionsConflict from './TeamCultureQuestionsConflict';
import TeamCultureQuestionsCommitment from './TeamCultureQuestionsCommitment';
import TeamCultureQuestionsAccountability from './TeamCultureQuestionsAccountability';
import TeamCultureQuestionsResults from './TeamCultureQuestionsResults';
import TeamAverage from './TeamAverage';
import ItemRanking from './ItemRanking';
import TipsExercisesIntro from './TipsExercisesIntro';
import TipsExercises from './TipsExercises';

const Report = ({ details }) => {
	const { child, parent } = details;

	const { improvements, improvementsCount, declines, declinesCount } = React.useMemo(() => {
		return ReportService.highestDiff(child, parent);
	}, [child, parent]);

	const { grouped: strongAreas, total: strongAreasCount } = React.useMemo(() => {
		return ReportService.groupProgressAreaQuestions(details, 1, 5, 5);
	}, []);

	const { grouped: weakAreas, total: weakAreasCount } = React.useMemo(() => {
		return ReportService.groupProgressAreaQuestions(details, -1, 5, 5);
	}, []);

	const { common: commonDifferentAreas, child: childDifferentAreas } = React.useMemo(() => {
		return ReportService.calculateProgressDifferences(details);
	}, []);

	const declinePage = React.useMemo(() => {
		return 6 + improvements.length;
	}, [improvements.length]);

	const strongPage = React.useMemo(() => {
		return declinePage + declines.length;
	}, [declines.length]);

	const weakPage = React.useMemo(() => {
		return strongPage + strongAreas.length;
	}, [strongAreas.length]);

	const keyDiffPage = React.useMemo(() => {
		return weakPage + weakAreas.length;
	}, [weakAreas.length]);

	const TCQTrustPage = React.useMemo(() => {
		return keyDiffPage + (childDifferentAreas.length > 3 ? 2 : 1);
	}, [keyDiffPage]);

	const TCQConflictPage = React.useMemo(() => {
		return TCQTrustPage + 1;
	}, [TCQTrustPage]);

	const TCQCommitmentPage = React.useMemo(() => {
		return TCQConflictPage + 1;
	}, [TCQConflictPage]);

	const TCQAccountabilityPage = React.useMemo(() => {
		return TCQCommitmentPage + 1;
	}, [TCQCommitmentPage]);

	const TCQResultsPage = React.useMemo(() => {
		return TCQAccountabilityPage + 1;
	}, [TCQAccountabilityPage]);

	const TeamAverageTrustPage = React.useMemo(() => {
		return TCQResultsPage + 1;
	}, [TCQResultsPage]);

	const TeamAverageConflictPage = React.useMemo(() => {
		return TeamAverageTrustPage + 1;
	}, [TeamAverageTrustPage]);

	const TeamAverageCommitmentPage = React.useMemo(() => {
		return TeamAverageConflictPage + 1;
	}, [TeamAverageConflictPage]);

	const TeamAverageAccountabilityPage = React.useMemo(() => {
		return TeamAverageCommitmentPage + 1;
	}, [TeamAverageCommitmentPage]);

	const TeamAverageResultsPage = React.useMemo(() => {
		return TeamAverageAccountabilityPage + 1;
	}, [TeamAverageAccountabilityPage]);

	const ItemRankingPage = React.useMemo(() => {
		return TeamAverageResultsPage + 1;
	}, [TeamAverageResultsPage]);

	const TipsExercisesIntroPage = React.useMemo(() => {
		return ItemRankingPage + 4;
	}, [ItemRankingPage]);

	const TipsExercisesPage = React.useMemo(() => {
		return TipsExercisesIntroPage + 1;
	}, [TipsExercisesIntroPage]);

	return (
		<AppPdfWrapper className={'AssessmentProgressReport'}>
			<CoverPage details={details} />
			<Introduction />
			<The5D />
			<TeamSummary details={details} />
			<TheFiveBehaviors details={details} />
			{improvements.map((improvement, index) => (
				<Areas
					title={'Areas of Greatest Improvement'}
					key={index}
					questions={improvement}
					index={index}
					page={6 + index}
					details={details}
					icon={Images.report.arrow}
					description={
						improvement.length === 0
							? 'There were no areas of improvement.'
							: index === 0
							? `The questions listed below represent the top ${improvementsCount} areas where your team made the most improvement relative to other questions.`
							: '<em>(Continued from the previous page.)</em>'
					}
				/>
			))}
			{declines.map((improvement, index) => (
				<Areas
					title={'Areas of Greatest Decline'}
					key={index}
					questions={improvement}
					index={index}
					page={declinePage + index}
					details={details}
					icon={Images.report.arrow}
					iconClassName={'rotate60'}
					description={
						improvement.length === 0
							? 'There were no areas of decline.'
							: index === 0
							? `The questions listed below represent the top ${declinesCount} areas where your team fell behind relative to other questions.`
							: '<em>(Continued from the previous page.)</em>'
					}
				/>
			))}
			{strongAreas.map((areas, index) => (
				<Areas
					title={'The Strongest Areas'}
					key={index}
					questions={areas}
					index={index}
					page={strongPage + index}
					details={details}
					icon={Images.report.checkmark}
					description={
						index === 0
							? `The questions listed below represent the top ${strongAreasCount} areas where your team scored highest relative to other questions in the assessment, along with the corresponding score in the original assessment. Understanding and continuing to leverage your team&rsquo;s strengths is as important as identifying and correcting its weaknesses.`
							: '<em>(Continued from the previous page.)</em>'
					}
					showMetaInfo={false}
				/>
			))}
			{weakAreas.map((areas, index) => (
				<Areas
					title={'The Weakest Areas'}
					key={index}
					questions={areas}
					index={index}
					page={weakPage + index}
					details={details}
					icon={Images.report.close}
					description={
						index === 0
							? `The questions listed below represent the bottom ${weakAreasCount} areas where your team scored lowest relative to other questions in the assessment, along with the corresponding score in the original assessment. To improve the cohesiveness of your team, it is critical that you understand and address these areas.`
							: '<em>(Continued from the previous page.)</em>'
					}
					showMetaInfo={false}
				/>
			))}
			<KeyDifferences
				details={child}
				common={commonDifferentAreas}
				questions={childDifferentAreas}
				page={keyDiffPage}
			/>
			<TeamCultureQuestionsTrust details={child} page={TCQTrustPage} />
			<TeamCultureQuestionsConflict details={child} page={TCQConflictPage} />
			<TeamCultureQuestionsCommitment details={child} page={TCQCommitmentPage} />
			<TeamCultureQuestionsAccountability details={child} page={TCQAccountabilityPage} />
			<TeamCultureQuestionsResults details={child} page={TCQResultsPage} />

			<TeamAverage details={details} page={TeamAverageTrustPage} type={ReportService.TRUST} />
			<TeamAverage details={details} page={TeamAverageConflictPage} type={ReportService.CONFLICT} />
			<TeamAverage details={details} page={TeamAverageCommitmentPage} type={ReportService.COMMITMENT} />
			<TeamAverage details={details} page={TeamAverageAccountabilityPage} type={ReportService.ACCOUNTABILITY} />
			<TeamAverage details={details} page={TeamAverageResultsPage} type={ReportService.RESULTS} />

			<ItemRanking details={child} page={ItemRankingPage} />

			<TipsExercisesIntro details={details} page={TipsExercisesIntroPage} />
			{ReportService.TipExercisesPages.map((page, index) => (
				<TipsExercises page={TipsExercisesPage + index} {...page} key={index} />
			))}
		</AppPdfWrapper>
	);
};

export default Report;
