import React from 'react';
import { AppButton, AppIcon } from '../../App';
import RouteNames from '../../../router/RouteNames';
import Images from '../../../assets/Images';

const ConfirmationStep = () => {
	return (
		<div className={'ttg-account-section flex-col'}>
			<AppIcon icon={'file-text-line'} className={'text-7xl text-green-500 text-center mx-auto'} />
			<p className={'ttg-text-lg text-gray-900 font-medium text-center my-2'}>Assessment Complete</p>
			<p className={'ttg-text-sm text-gray-600 text-center'}>
				Thank you for completing the Team Assessment. A copy of your individual responses will be e-mailed to
				you shortly. In addition, your responses have been saved and will become part of your team’s report. We
				will provide your assessment administrator with a summary of the results once we have collected your
				entire team’s input. To ensure confidentiality, your results will be tabulated by The Table Group and
				presented in aggregate form.
			</p>

			<hr className='text-gray-200 my-6' />

			<div className={'flex flex-col md:flex-row mx-auto gap-4 items-center'}>
				<img src={Images.dysfunctions} alt='' className={'w-56'} />
				<div>
					<p className={'text-gray-900 font-medium'}>The Five Dysfunctions of a Team Assessment</p>
					<p className={'ttg-text-sm text-gray-900 my-6'}>Are you an ideal team player?</p>
					<AppButton
						id={'ttg-take-assessment-completed-see-model'}
						text={'SEE THE MODEL AND SUMMARY'}
						onClick={() =>
							window.open(
								'https://de7pikzj4hvyk.cloudfront.net/wp-content/uploads/2020/12/11224029/FiveDysfunctions.pdf'
							)
						}
					/>
				</div>
			</div>

			<hr className='text-gray-200 my-6' />

			<p className={'text-gray-900 font-medium mb-2 text-left'}>Data Privacy Statement</p>
			<p className={'ttg-text-sm text-gray-600'}>
				In order to generate the Team Assessment Report, The Table Group stores your email address and responses
				(in numerical form). We will not share your email address for any reason and you will only receive
				newsletter and marketing material from The Table Group if you have opted in to receive email
				communication. View our updated{' '}
				<a
					id={'ttg-take-assessment-completed-privacy'}
					href={RouteNames.Privacy}
					className={'hover:underline text-rose-500 font-medium'}
				>
					privacy policy
				</a>{' '}
				and{' '}
				<a
					id={'ttg-take-assessment-completed-terms'}
					href={RouteNames.TermsService}
					className={'hover:underline text-rose-500 font-medium'}
				>
					terms of service
				</a>{' '}
				for more information on processing and deletion of data.
			</p>
			<div className={'flex justify-center mt-6'}>
				<AppButton
					id={'ttg-take-assessment-completed-done-button'}
					text={'Done'}
					onClick={() => window.open('/', '_self')}
				/>
			</div>
		</div>
	);
};

export default ConfirmationStep;
