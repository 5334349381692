import React from 'react';
import { AppIcon } from './index';

const CheckBox = React.forwardRef(({ label, containerId, ...props }, ref) => {
	const renderLabel = () => {
		if (!label) {
			return null;
		}
		if (typeof label === 'string' || typeof label === 'number') {
			return label;
		}
		return label();
	};

	return (
		<label className={`app-checkbox-container ${props.disabled ? 'disabled' : ''}`} id={containerId}>
			<span className={`checklabel ${!label ? '' : 'pl-6'}`}>{renderLabel()}</span>
			<input type='checkbox' ref={ref} {...props} />
			<p className='checkmark'>
				<AppIcon icon={'check-line'} className={'checkmark-icon'} />
			</p>
		</label>
	);
});

export default CheckBox;
