import React from 'react';
import { AppPdfPage, AppPdfWrapper } from '../../components/App';
import UtilityService from '../../services/UtilityService';
import { isNull } from 'lodash/lang';
import Images from '../../assets/Images';
import Summary from './Summary';
import dayjs from 'dayjs';
import Scores from '../OhsPdf/Scores';
import { disciplines } from './constants';
import Discipline from './Discipline';
import SurveyService from '../../services/SurveyService';

const getScoreDetails = (score, lowThreshold, highThreshold) => {
	if (score < lowThreshold) {
		return {
			icon: 'low',
			score: score,
		};
	}

	if (score >= highThreshold) {
		return {
			icon: 'high',
			score: score,
		};
	}

	return {
		icon: 'medium',
		score: score,
	};
};
const Survey = () => {
	const [details, setDetails] = React.useState(null);
	const [filters, setFilters] = React.useState(null);
	const [disciplineOne, setDisciplineOne] = React.useState({});
	const [disciplineTwo, setDisciplineTwo] = React.useState({});
	const [disciplineThree, setDisciplineThree] = React.useState({});
	const [disciplineFour, setDisciplineFour] = React.useState({});

	const highThreshold = 4,
		lowThreshold = 3;
	const date = dayjs().format('MMMM D, YYYY');

	const _getDetails = React.useCallback(() => {
		const params = UtilityService.getQueryParams(window.location.search, true);

		Object.keys(params)
			.filter((k) => k.startsWith('question'))
			.forEach((k) => {
				params[k] = Number(params[k]);
			});

		setFilters(params);

		SurveyService.getSurveyPdfDetails(params, 'survey').then((response) => {
			const details = response.data;
			setDetails(details);

			setDisciplineOne(getScoreDetails(details.disciplines[0].average, lowThreshold, highThreshold));
			setDisciplineTwo(getScoreDetails(details.disciplines[1].average, lowThreshold, highThreshold));
			setDisciplineThree(getScoreDetails(details.disciplines[2].average, lowThreshold, highThreshold));
			setDisciplineFour(getScoreDetails(details.disciplines[3].average, lowThreshold, highThreshold));
		});
	}, [window.location.search]);

	React.useEffect(() => {
		_getDetails();
	}, [_getDetails, window.location.search]);

	return (
		!isNull(details) && (
			<AppPdfWrapper className={'AssessmentReport'}>
				<AppPdfPage page={1} sectionClassName={''}>
					<div className={'flex-row survey-font'}>
						<img className={'float-left'} src={Images.logo} alt='ttg-logo' />
						<div className={'ttg-text-md text-gray-500 font-medium w-6/12 pt-3 text-center float-right '}>
							The Source For Organizational Health
						</div>

						<div className={'pt-40 pl-10 flex w-5/12'}>
							<img src={Images.ohsPdf.book} className={'float-left'} alt='book' />
							<div className={'float-right flex-row pt-32 pl-10 survey-font'}>
								<div className={'ttg-text-3xl font-bold text-center'}> Organizational</div>
								<div className={'ttg-text-7xl font-bold'}>Health</div>
								<div className={'ttg-text-3xl font-bold float-right'}>Report</div>
							</div>
						</div>
						<div className={'py-10 ttg-text-lg text-center survey-font'}>
							<div className={'text-gray-500 font-medium py-8'}>
								{filters.first_name} {filters.last_name}
							</div>
							<div className={'text-gray-500 font-medium'}>{date}</div>
						</div>
					</div>
				</AppPdfPage>

				<AppPdfPage page={2} sectionClassName={''}>
					<div className={'py-10 px-10 flex-row survey-font'}>
						<div className={'text-red-700 ttg-text-3xl font-medium'}>Introduction</div>

						<div className={'py-6 ttg-text-lg font-medium'}>
							A healthy organization is one that has less politics and confusion, higher morale and
							productivity, lower unwanted turnover and lower recruiting costs than an unhealthy one. No
							leader would dispute the power of these qualities and every leader would love his or her
							organization to have them. Unfortunately, most of us struggle with how to make this happen.
						</div>

						<div className={'ttg-text-lg font-medium'}>
							The primary purpose of this report is to provide a simple indicator of the relative health
							of your organization as well as help you interpret and act on your results. Hopefully, this
							will relieve some of the struggle.
						</div>
					</div>
				</AppPdfPage>

				<AppPdfPage page={3} sectionClassName={''}>
					<div className={'pt-4 px-5 flex-row survey-font'}>
						<div className={'text-red-700 ttg-text-xl  font-medium'}>
							Breaking Down the Four Disciplines of a Healthy Organization
						</div>
						<div className={'ttg-text-sm pt-3 font-medium'}>
							The first step toward creating a healthy organization is to embrace the idea that, like so
							many other aspects of success, organizational health is simple in theory but difficult to
							put into practice. It requires extraordinary levels of courage, commitment and consistency.
							However, it does not require complex thinking and analysis; in fact, keeping things simple
							is critical.
						</div>

						<div className={'ttg-text-sm pt-3 font-medium'}>
							The second step is to master these fundamental disciplines and put them into practice on a
							daily basis. What follows is a summary to help you understand how to do just that.
						</div>

						<div className={'ttg-text-sm pt-3 font-medium survey-font'}>
							<b>Discipline 1: Build a Cohesive Leadership Team</b>
							<br />
							<div className={'pt-1'}>
								Cohesive teams build trust, eliminate politics, and increase efficiency by...
							</div>
						</div>

						<div className={'ttg-text-sm font-medium pt-2 pl-4 survey-font'}>
							<li>Knowing one another's unique strengths and weaknesses</li>
							<li>Openly engaging in constructive ideological conflict</li>
							<li>Holding one another accountable for behaviors and actions</li>
							<li>Committing to group decisions</li>
						</div>

						<div className={'ttg-text-sm pt-3 font-medium survey-font'}>
							<b>Discipline 2: Create Clarity</b>
							<br />
							<div className={'pt-1'}>
								Healthy organizations minimize the potential for confusion by clarifying...
							</div>
						</div>

						<div className={'ttg-text-sm font-medium pt-2 pl-4 survey-font'}>
							<li>Why do we exist?</li>
							<li>How do we behave?</li>
							<li>What do we do?</li>
							<li>How will we succeed?</li>
							<li>What is most important &mdash; right now?</li>
							<li>Who must do what?</li>
						</div>

						<div className={'ttg-text-sm pt-3 font-medium survey-font'}>
							<b>Discipline 3: Over-Communicate Clarity</b>
							<br />
							<div className={'pt-1'}>
								Healthy organizations align their employees around organizational clarity by
								communicating key messages through...
							</div>
						</div>

						<div className={'ttg-text-sm font-medium pt-2 pl-4 survey-font'}>
							<li>Repetition: Don't be afraid to repeat the same message, again and again</li>
							<li>
								Simplicity: The more complicated the message, the more potential for confusion and
								inconsistency
							</li>
							<li>
								Multiple mediums: People react to information in many ways; use a variety of mediums
							</li>
							<li>
								Cascading messages: Leaders communicate key messages to direct reports;
								<div className={'pl-5 inline-flex'}>
									the cycle repeats itself until the message is heard by all
								</div>
							</li>
						</div>

						<div className={'ttg-text-sm pt-3 font-medium survey-font'}>
							<b>Discipline 4: Reinforce Clarity</b>
							<br />
							<div>Organizations sustain their health by ensuring consistency in...</div>
						</div>

						<div className={'ttg-text-sm font-medium pt-2 pl-4 survey-font'}>
							<li>Hiring</li>
							<li>Managing performance</li>
							<li>Meeting structure</li>
							<li>Rewards and recognition</li>
							<li>Employee dismissal</li>
						</div>
					</div>
				</AppPdfPage>

				<AppPdfPage page={4} sectionClassName={''}>
					<Summary
						disciplineOne={disciplineOne}
						disciplineTwo={disciplineTwo}
						disciplineThree={disciplineThree}
						disciplineFour={disciplineFour}
					/>
				</AppPdfPage>

				<AppPdfPage page={5} sectionClassName={''}>
					<div className={'px-3 flex-row'}>
						<div className={'text-red-700 ttg-text-lg  font-medium survey-font'}>
							Organizational Health Survey: Your Scores
						</div>
						<Scores questions={details.questions.slice(0, 9)} disciplines={details.disciplines} />
					</div>
				</AppPdfPage>

				<AppPdfPage page={6} sectionClassName={''}>
					<div className={'pt-4 px-5 flex-row'}>
						<Scores
							questions={details.questions.slice(10)}
							disciplines={details.disciplines}
							page={2}
							start={0}
						/>
					</div>
				</AppPdfPage>

				<AppPdfPage page={7} sectionClassName={''}>
					<Discipline discipline={disciplines['1']} score={disciplineOne.icon} />
				</AppPdfPage>

				<AppPdfPage page={8} sectionClassName={''}>
					<Discipline discipline={disciplines['2']} score={disciplineTwo.icon} />
				</AppPdfPage>

				<AppPdfPage page={9} sectionClassName={''}>
					<Discipline discipline={disciplines['3']} score={disciplineThree.icon} />
				</AppPdfPage>

				<AppPdfPage page={10} sectionClassName={''}>
					<Discipline discipline={disciplines['4']} score={disciplineFour.icon} />
				</AppPdfPage>
			</AppPdfWrapper>
		)
	);
};

export default Survey;