import React from 'react';
import { AccountLayout } from '../../components/Layout';
import {
	AddressInfo,
	OrderDetailsHeader,
	OrderDetailsProducts,
	OrderHistory,
	OrderTotals,
} from '../../components/Orders';
import { useLocation, useNavigate } from 'react-router-dom';
import UtilityService from '../../services/UtilityService';
import RouteNames from '../../router/RouteNames';
import { AppLoadingModal } from '../../components/App';
import OrderService from '../../services/OrderService';

const OrderDetails = () => {
	const [details, setDetails] = React.useState({});
	const [loading, setLoading] = React.useState(true);
	const [printingReceipt, setPrintingReceipt] = React.useState({});
	const location = useLocation();

	const navigate = useNavigate();

	const { id, download } = UtilityService.getQueryParams(location.search);

	const printReceipt = () => {
		setPrintingReceipt({ ...printingReceipt, [id]: true });
		OrderService.printReceipt(id).finally(() => setPrintingReceipt({ ...printingReceipt, [id]: false }));
	};

	const _getOrderDetails = React.useCallback(() => {
		setLoading(true);
		OrderService.details(id)
			.then((response) => setDetails(response))
			.catch(() => navigate(RouteNames.Orders, { replace: true }))
			.finally(() => setLoading(false));
		if (download) {
			printReceipt(id);
		}
	}, [id, location, navigate]);

	React.useEffect(() => {
		_getOrderDetails();
	}, [_getOrderDetails]);

	return (
		<AccountLayout loading={loading} sidebar={false}>
			<OrderDetailsHeader order={details} printReceipt={printReceipt} loading={printingReceipt[id]} />

			<div className={'ttg-account-section flex-col'}>
				<OrderDetailsProducts products={details.items} />
				<div className='flex mt-4 flex-col md:flex-row border-b-1 border-gray-200 py-3'>
					<div className='flex flex-1 pb-6 border-b-1 border-gray-200 md:border-0'>
						<AddressInfo
							id={'ttg-order-details-billing-address'}
							label={'Billing Address'}
							address={details.billingAddress}
						/>
						<AddressInfo
							id={'ttg-order-details-shipping-address'}
							label={'Delivery Address'}
							address={details.shippingAddress}
						/>
					</div>
					<div className='flex w-full md:w-64 pt-6 md:pt-0'>
						<OrderTotals totals={details.totals} order={details} />
					</div>
				</div>
				<OrderHistory order={details} />
			</div>
			<AppLoadingModal isOpen={download && printingReceipt[id]} />
		</AccountLayout>
	);
};

export default OrderDetails;
