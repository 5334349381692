import React from 'react';
import { AppButton, AppInput, AppDropdown } from '../../App';
import { assessmentsFilterOptions } from '../../../constants/AssessmentsFilterOptions';
import debounce from 'debounce-promise';
import { useAssessments } from '../../../providers/AssessmentProvider';

const _options = [
	{
		value: 1,
		label: 'All Assessments',
	},
	{
		value: 2,
		label: 'Active Assessments',
	},
	{
		value: 3,
		label: 'Completed Assessments',
	},
	{
		value: 4,
		label: 'Not yet started Assessments',
	},
	{
		value: 5,
		label: 'All Individual Assessments',
	},
	{
		value: 6,
		label: 'Completed Individual Assessments',
	},
	{
		value: 7,
		label: 'Individual Assessments In Process',
	},
	{
		value: 8,
		label: 'Individual Assessments Saved',
	},
	{
		value: 9,
		label: 'Individual Assessments Scheduled',
	},
	{
		value: 10,
		label: 'All Team Assessments',
	},
	{
		value: 11,
		label: 'Completed Team Assessments',
	},
	{
		value: 12,
		label: 'Team Assessments In Process',
	},
	{
		value: 13,
		label: 'Team Assessments Saved',
	},
	{
		value: 14,
		label: 'Team Assessments Scheduled',
	},
];

const ListFilters = ({ filters, setFilters, assessmentCounts }) => {
	const { selectedFilter, setSelectedFilter } = useAssessments();
	const [query, setQuery] = React.useState(filters.having);

	const changeHandler = (value) => {
		setFilters('having', value);
		setFilters('page', 0);
	};

	const debouncedChangeHandler = React.useMemo(() => {
		return debounce(changeHandler, 300, { leading: true });
	}, []);

	const onInputChange = (event) => {
		setQuery(event.target.value);
		debouncedChangeHandler(event.target.value);
	};

	const onDropdownChange = (e) => {
		setFilters('page', 0);
		assessmentsFilterOptions[e.value].forEach((filter) => {
			setFilters(filter.name, filter.value);
		});
		setSelectedFilter(e.value - 1);
	};

	const options = React.useMemo(() => {
		return _options.map((o) => ({
			label: `${o.label} (${assessmentCounts?.filters?.[o.value] ?? 0})`,
			value: o.value,
			counter: assessmentCounts?.filters?.[o.value] ?? 0,
		}));
	}, [assessmentCounts]);

	return (
		<div className={'flex flex-col lg:flex-row w-full -mb-4'}>
			<div className='flex flex-initial w-full lg:w-5/12 lg:mr-2 mt-4 lg:mt-0'>
				<AppInput
					id={'ttg-assessments-table-search'}
					containerClassName={'no-margin w-full'}
					value={query}
					placeholder={'Search...'}
					onChange={(e) => onInputChange(e)}
					showErrorMessage={false}
				/>
			</div>
			<div className='flex flex-auto'>
				<AppDropdown
					id={'ttg-assessments-table-type-filter'}
					containerClassName={'no-margin w-full '}
					options={options}
					value={options[selectedFilter]}
					onChange={(e) => onDropdownChange(e)}
					maxMenuHeight={400}
					isOptionDisabled={(o) => {
						return o.counter === 0;
					}}
				/>
			</div>
			<div className='flex flex-initial'>
				{(selectedFilter !== 0 || filters.having !== '') && (
					<AppButton
						id={'ttg-assessments-reset-button'}
						type={'button'}
						className={'btn btn-secondary w-28 !no-margin lg:ml-2  mb-4 !border-gray-300'}
						style={{ height: '38px' }}
						text={'Clear Filters'}
						onClick={() => {
							setQuery('');
							setFilters('page', 0);
							setFilters('having', '');
							setSelectedFilter(0);
							assessmentsFilterOptions[1].forEach((filter) => {
								setFilters(filter.name, filter.value);
							});
						}}
					/>
				)}
			</div>
		</div>
	);
};
export default ListFilters;
