import ApiService from './ApiService';
import UtilityService from './UtilityService';
import dayjs from 'dayjs';

export const defaultAssessmentOptions = [
	{
		label: 'Almost Never',
		scale: 1,
		value: 1,
	},
	{
		label: 'Rarely',
		scale: 1,
		value: 2,
	},
	{
		label: 'Sometimes',
		scale: 1,
		value: 3,
	},
	{
		label: 'Usually',
		scale: 1,
		value: 4,
	},
	{
		label: 'Almost Always',
		scale: 1,
		value: 5,
	},
];

const getAssessments = (filters) => {
	return new Promise((resolve, reject) => {
		const query = UtilityService.serializeQueryString(filters);
		ApiService.get(`v1/assessments_paged?${query}`)
			.then(({ data }) => {
				resolve({
					assessments: data.data,
					meta: data.meta,
				});
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const getAssessmentCounts = () => {
	return new Promise((resolve, reject) => {
		ApiService.get(`v1/assessments/status/count`)
			.then(({ data }) => {
				resolve({
					assessment_counts: data.assessment_counts,
					assessment_status_count: data.assessment_status_count ?? [],
				});
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const getSetupOptions = () => {
	return new Promise((resolve, reject) => {
		ApiService.get('/v1/assessment/options')
			.then(({ data }) => {
				const { company_size, team_functions, team_industries } = data;
				resolve({
					companySizes: company_size.map((i) => ({ label: i.name, value: i.value })),
					functions: team_functions.map((i) => ({ label: i.name, value: i.value })),
					industries: team_industries.map((i) => ({ label: i.name, value: i.value })),
				});
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const setupTeam = (payload) => {
	return new Promise((resolve, reject) => {
		if (payload.id) {
			ApiService.put(`/v1/assessment/team/setup/${payload.id}`, payload).then(
				({ data }) => resolve(data),
				reject
			);
		} else {
			ApiService.post('/v1/assessment/team/setup', payload).then(({ data }) => resolve(data), reject);
		}
	});
};

const draftTeam = (payload) => {
	return new Promise((resolve, reject) => {
		if (payload.id) {
			ApiService.put(`v1/assessment/team/draft/${payload.id}`, payload).then(resolve, reject);
		} else {
			ApiService.post('/v1/assessment/team/draft', payload).then(resolve, reject);
		}
	});
};

const updateTeam = (teamId, payload) => {
	return new Promise((resolve, reject) => {
		ApiService.put(`/v1/assessment/team/${teamId}`, payload).then(resolve, reject);
	});
};

const updateIndividual = (id, payload) => {
	return new Promise((resolve, reject) => {
		ApiService.put(`/v1/assessment/individual/${id}`, payload).then(resolve, reject);
	});
};

const details = ({ id }) => {
	return new Promise((resolve, reject) => {
		ApiService.get(`/v1/assessment/${id}`).then(({ data }) => {
			resolve(data);
		}, reject);
	});
};

const deleteDraft = (id) => {
	return new Promise((resolve, reject) => {
		ApiService.delete(`/v1/assessment/${id}`).then(resolve, reject);
	});
};

const sendTestEmail = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/assessment/email/test/`, payload).then(resolve, reject);
	});
};

const draftIndividual = (payload) => {
	return new Promise((resolve, reject) => {
		if (payload.id) {
			ApiService.put(`v1/assessment/individual/draft/${payload.id}`, payload).then(resolve, reject);
		} else {
			ApiService.post('/v1/assessment/individual/draft', payload).then(resolve, reject);
		}
	});
};

const setupIndividual = (payload) => {
	return new Promise((resolve, reject) => {
		if (payload.id) {
			ApiService.put(`/v1/assessment/individual/setup/${payload.id}`, payload).then(
				({ data }) => resolve(data),
				reject
			);
		} else {
			ApiService.post('/v1/assessment/individual/setup', payload).then(({ data }) => resolve(data), reject);
		}
	});
};

const addTeamMembers = (team_id, emails) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/assessment/roster/${team_id}`, { emails }).then(({ data }) => {
			resolve(data);
		}, reject);
	});
};

const editTeamMember = (team_id, payload) => {
	return new Promise((resolve, reject) => {
		ApiService.put(`/v1/assessment/roster/${team_id}`, payload).then(({ data }) => {
			resolve(data);
		}, reject);
	});
};

const removeTeamMember = (team_id, data) => {
	return new Promise((resolve, reject) => {
		ApiService.delete(`/v1/assessment/roster/${team_id}`, { data }).then(({ data }) => {
			resolve(data);
		}, reject);
	});
};

const remindTeamMember = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/assessment/email/reminder/`, payload).then(({ data }) => {
			resolve(data);
		}, reject);
	});
};

const sendResponses = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/assessment/email/responses/`, payload).then(({ data }) => {
			resolve(data);
		}, reject);
	});
};

const sentInitialEmail = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/assessment/email/setup`, payload).then(({ data }) => {
			resolve(data);
		}, reject);
	});
};

const sendReport = (id) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/assessment/email/report/${id}/send`).then(({ data }) => {
			resolve(data);
		}, reject);
	});
};

const sendProgress = (id) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/assessment/email/progress/${id}/send`).then(({ data }) => {
			resolve(data);
		}, reject);
	});
};

const viewReport = (id) => {
	return new Promise((resolve, reject) => {
		ApiService.get(`/v1/assessment/email/report/${id}`).then(({ data }) => {
			UtilityService.downloadPdfFromBuffer(
				UtilityService.convertBase64ToBuffer(data.pdf_data),
				'Assessment Report'
			);
			resolve(data);
		}, reject);
	});
};

const viewProgress = (id) => {
	return new Promise((resolve, reject) => {
		ApiService.get(`/v1/assessment/email/progress/${id}`).then(({ data }) => {
			UtilityService.downloadPdfFromBuffer(
				UtilityService.convertBase64ToBuffer(data.pdf_data),
				'Progress Report'
			);
			resolve(data);
		}, reject);
	});
};

const syncReminders = (type, teamId, toDeletePayload, toSyncPayload) => {
	return new Promise(async (resolve, reject) => {
		if (toDeletePayload.reminders.length > 0 && toSyncPayload.reminders.length === 0) {
			try {
				await ApiService.delete(`v1/assessment/schedule/reminder/${type}/${teamId}`, {
					data: toDeletePayload,
				});
			} catch (e) {
				return reject(e);
			}
		}
		if (toSyncPayload.reminders.length > 0) {
			ApiService.post(`v1/assessment/schedule/reminder/${type}/${teamId}`, toSyncPayload).then(({ data }) => {
				resolve(data.final_schedules);
			}, reject);
		} else {
			resolve([]);
		}
	});
};

const sendReminders = (team_id, captcha) => {
	return new Promise(async (resolve, reject) => {
		ApiService.post('/v1/assessment/email/send-reminder/', {
			team_id,
			captcha,
		}).then(resolve, reject);
	});
};

const cancelSchedule = (team_id, query) => {
	return new Promise(async (resolve, reject) => {
		ApiService.delete(
			`/v1/assessment/schedule/setup/${team_id}?${UtilityService.serializeQueryString(query)}`
		).then(resolve, reject);
	});
};

const updateSchedule = (team_id, payload) => {
	return new Promise(async (resolve, reject) => {
		ApiService.put(`/v1/assessment/schedule/setup/${team_id}`, payload).then(({ data }) => {
			resolve(data.final_schedules[0]);
		}, reject);
	});
};

const prepareTeamSetupPayload = (data) => {
	const { company, team, email, reminder } = data;

	const payload = {
		company_name: company.company_name,
		industry: company.industry ? company.industry.value : '',
		function_name: company.function_name ? company.function_name.value : '',
		company_size: company.company_size ? company.company_size.value : '',

		name: team.name,
		main_email: team.main_email,
		version: 2,
		html_email: 1,
		language: 'en',
		main_contact_participant: team.main_contact_participant,
		do_main_contact_updates: team.do_main_contact_updates,
		custom_subject: email.custom_subject,
		custom_message: email.custom_message,
		custom_signature: email.custom_signature,

		reminders: reminder.reminders,
		setup_date: reminder.setup_date,
		setup_timezone: reminder.setup_timezone,
		send_comparison_reminder: reminder.send_comparison_reminder,
	};

	const cc_contacts = team.cc_contacts
		? team.cc_contacts.filter(({ isValidEmail }) => isValidEmail).map(({ value }) => value)
		: [];
	if (cc_contacts.length > 0) {
		payload.cc_contacts = cc_contacts;
	}

	const team_members = team.team_members
		? team.team_members.filter(({ isValidEmail }) => isValidEmail).map(({ value }) => value)
		: [];
	if (team_members.length > 0) {
		payload.team_members = team_members;
	}

	return payload;
};

const prepareAssessmentAsDraft = (data, retest) => {
	const { assessment_data, rosters, reminders } = data;
	const teamSize = Math.max(rosters.length, 2);

	const prepareEmailObj = (email) => ({
		label: email,
		value: email,
		isValidEmail: UtilityService.isValidEmail(email),
		fakeId: UtilityService.generateRandomString(30),
	});

	return {
		id: assessment_data.team_id,
		is_draft: !assessment_data.setup_complete,
		retest: retest,
		is_team: assessment_data.team_type === 'team',
		company: {
			company_name: assessment_data.company_name,
			industry:
				assessment_data.industry !== '-1'
					? { value: assessment_data.industry, label: assessment_data.industry }
					: null,
			function_name:
				assessment_data.function !== '-1'
					? { value: assessment_data.function, label: assessment_data.function }
					: null,
			company_size:
				assessment_data.company_size !== '-1'
					? { value: assessment_data.company_size, label: assessment_data.company_size }
					: null,
		},
		team: {
			name: retest ? `${assessment_data.name} Retest` : assessment_data.name,
			main_email: assessment_data.main_email,
			do_main_contact_updates: !!assessment_data.do_main_contact_updates,
			main_contact_participant: rosters.filter((r) => r.is_main_contact).length > 0,
			cc_contacts: assessment_data.cc_email
				? assessment_data.cc_email.split(',').map((e) => prepareEmailObj(e.trim()))
				: [],
			team_members: rosters.map((r) => {
				return prepareEmailObj(r.email_address ? r.email_address.trim() : '');
			}),
			team_size: { value: teamSize, label: teamSize },
		},
		email: {
			custom_subject: assessment_data.custom_subject,
			custom_message: assessment_data.custom_message,
			custom_signature: assessment_data.custom_signature,
		},
		reminder: {
			send_comparison_reminder: !!assessment_data.send_comparison_reminder,
			schedule: false,
			scheduleDate: false,
			timezone: false,
			reminders: reminders
				? reminders.map((r) => ({
						date: r.raw_process_at,
						timezone: r.raw_timezone,
				  }))
				: null,
		},
		individual: {
			company_name: assessment_data.company_name,
			name: assessment_data.name,
			participant: rosters.length > 0 ? rosters[0].email_address : '',
		},
	};
};

const getLicenseUsageByAssessment = (filters) => {
	return new Promise((resolve, reject) => {
		const query = UtilityService.serializeQueryString(filters);
		ApiService.get(`v1/user/assessments/licenses/list?${query}`)
			.then(({ data }) => {
				resolve({
					licensesList: data.data.map((i) => ({
						...i,
						local_id: `${i.id}-${i.team_id}-${i.team_type}-${i.created}`,
					})),
					meta: data.meta,
				});
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const find = ({ token }) => {
	return new Promise((resolve, reject) => {
		ApiService.get(`/v1/take-assessment/assessment/list`, {
			headers: {
				Token: token,
			},
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
const findAssessmentList = async (filters) => {
	const query = UtilityService.serializeQueryString(filters);

	return new Promise((resolve, reject) => {
		ApiService.get(`/v1/take-assessment/assessment/find_assessment_list?${query}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const sendFindAssessmentEmail = async ({ email, captcha = null }) =>
	await ApiService.post(`/v1/take-assessment/assessment/find/email`, { email, captcha }).catch((error) => error);

const takeAssessmentDetails = (filters) => {
	return new Promise((resolve) => {
		ApiService.get(`/v1/take-assessment/assessment/details?${UtilityService.serializeQueryString(filters)}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				resolve({ ...error, hasError: true });
			});
	});
};

const getQuestions = (filters) => {
	return new Promise((resolve, reject) => {
		ApiService.get(`/v1/take-assessment/assessment/questions?${UtilityService.serializeQueryString(filters)}`)
			.then((response) => {
				resolve({
					questions: response.data.questions,
					options: response.data.assessment_question_options,
					scales: response.data.scales,
					responses: AssessmentService.prefillTakeAssessmentResponses(response.data.responses),
				});
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const updateAssessmentProgress = (payload, filters) => {
	return new Promise((resolve, reject) => {
		ApiService.post(
			`/v1/take-assessment/assessment/progress?${UtilityService.serializeQueryString(filters)}`,
			payload
		)
			.then(() => {
				resolve({});
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const getReportData = (filters) => {
	return new Promise((resolve, reject) => {
		ApiService.get(`/v1/take-assessment/assessment/report?${UtilityService.serializeQueryString(filters)}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const getProgressReportData = (filters) => {
	return new Promise((resolve, reject) => {
		ApiService.get(`/v1/take-assessment/assessment/report/progress?${UtilityService.serializeQueryString(filters)}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const generateReport = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(
			`/v1/take-assessment/assessment/report/end?${UtilityService.serializeQueryString(payload)}`,
			payload
		)
			.then(({ data }) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const endTakeAssessment = (payload, filters) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/take-assessment/assessment/end?${UtilityService.serializeQueryString(filters)}`, payload)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const getAssessmentTemplate = () =>
	new Promise((resolve, reject) => {
		ApiService.get('/v1/assessment/template')
			.then((response) => resolve(response.data))
			.catch((error) => reject(error));
	});

const addOrUpdateAssessmentTemplate = (payload) =>
	new Promise((resolve, reject) => {
		ApiService.post('v1/assessment/template', payload)
			.then((response) => resolve(response.data))
			.catch((error) => reject(error));
	});

const getRosterExtraData = (roster, assessment) => {
	const cc_emails = assessment && assessment.cc_email ? assessment.cc_email.split(',') : [];
	const isOptionalEmail = cc_emails.indexOf(roster.email_address) > -1;
	if (roster.complete) {
		return {
			status: 'Completed',
			dateTimeCompleted: roster.end_time,
			isOptionalEmail,
		};
	}
	if (roster.start_time) {
		return {
			status: 'In Progress',
			dateTimeCompleted: '',
			isOptionalEmail,
		};
	}
	if (roster.read_email_date) {
		return {
			status: 'Email Read',
			dateTimeCompleted: '',
			isOptionalEmail,
		};
	}
	return {
		status: 'Not Yet Started',
		dateTimeCompleted: '',
		isOptionalEmail,
	};
};

const calculateRosterCounts = (rosters, avg_completion_time) => {
	const units = { 0: 'hour', 1: 'min', 2: 'sec' };
	const avgCompletionText = avg_completion_time
		? avg_completion_time
				.split(':')
				.map((p, idx) => ({
					val: parseInt(p),
					unit: units[idx],
				}))
				.filter((p) => p.val > 0)
				.map((p) => `${p.val}${p.unit}`)
				.join(' ')
		: '';
	return {
		emailsRead: rosters.filter((r) => r.status === 'Email Read').length,
		inProgress: rosters.filter((r) => r.status === 'In Progress').length,
		complete: rosters.filter((r) => r.status === 'Completed').length,
		avgCompletionTime: avg_completion_time ?? 0,
		avgCompletionText,
	};
};

const prepareCCEmailText = (emails) => {
	if (!emails || emails.length === 0) {
		return '';
	}
	const sentEmails = emails.slice(0, emails.length - 1).join(', ');
	const lastEmail = emails[emails.length - 1];
	return emails.length > 1
		? `<span class="font-medium" id="ttg-assessment-cc-emails">${sentEmails}</span> and <span class="font-medium" id="ttg-assessment-cc-last-email">${lastEmail}</span> have also been specified to receive updates.`
		: `<span class="font-medium" id="ttg-assessment-cc-email">${lastEmail}</span> has also been specified to receive updates.`;
};

const prepareAssessmentDetails = (data) => {
	let { assessment_data, rosters, retest_of, retest_from, reminders } = data;

	rosters = rosters.map((r) => {
		return {
			...r,
			...AssessmentService.getRosterExtraData(r, assessment_data),
			email_address: r.email_address ? r.email_address.trim() : '',
		};
	});

	const ccEmails = assessment_data.cc_email ? assessment_data.cc_email.split(',') : [];

	return {
		assessment_data: {
			...assessment_data,
			cc_email_text: AssessmentService.prepareCCEmailText(ccEmails),
		},
		counts: AssessmentService.calculateRosterCounts(rosters, data.avg_completion_time),
		rosters,
		reminders,
		isTeamAssessment: assessment_data && assessment_data.team_type === 'team',
		isRetest: !!retest_of && !!retest_of.name,
		isScheduled: !!(
			!!assessment_data.scheduled_process_at &&
			dayjs.utc(assessment_data.scheduled_processed_at).isAfter(dayjs().utc())
		),
		retest_of,
		retest_from,
	};
};

const prepareEditAssessmentData = ({ assessment_data, rosters }) => {
	return {
		company_name: assessment_data.company_name,
		industry:
			assessment_data.industry !== '-1'
				? { value: assessment_data.industry, label: assessment_data.industry }
				: null,
		function_name:
			assessment_data.function !== '-1'
				? { value: assessment_data.function, label: assessment_data.function }
				: null,
		company_size:
			assessment_data.company_size !== '-1'
				? { value: assessment_data.company_size, label: assessment_data.company_size }
				: null,
		name: assessment_data.name,
		main_email: assessment_data.main_email,
		do_main_contact_updates: !!assessment_data.do_main_contact_updates,
		custom_subject: assessment_data.custom_subject,
		custom_message: assessment_data.custom_message,
		custom_signature: assessment_data.custom_signature,
		send_comparison_reminder: !!assessment_data.send_comparison_reminder,
		version: 2,
		html_email: 1,
		language: 'en',
		cc_email: assessment_data.cc_email
			? assessment_data.cc_email.split(',').map((email) => ({
					label: email,
					value: email,
					isValidEmail: UtilityService.isValidEmail(email),
					fakeId: UtilityService.generateRandomString(30),
			  }))
			: [],
		participant: rosters && rosters[0] ? rosters[0].email_address : '',
	};
};

const prepareUpdateAssessmentPayload = (payload) => {
	return {
		...payload,
		industry: payload.industry ? payload.industry.value : '',
		function_name: payload.function_name ? payload.function_name.value : '',
		company_size: payload.company_size ? payload.company_size.value : '',
		cc_contacts: payload.cc_email ? payload.cc_email.map(({ value }) => value) : [],
	};
};

const prefillTakeAssessmentResponses = (jsonRes) => {
	const _responses = jsonRes ? JSON.parse(jsonRes) : [];
	const responses = {};
	_responses.forEach((res) => {
		if (res.question) {
			if (res.value) {
				responses[res.question] = res.value;
			} else if (res.subquestions) {
				responses[res.question] = res.subquestions;
			} else {
				responses[res.question] = res.values;
			}
		} else {
			if (res.name.indexOf('_') === -1) {
				const question = res.name.replace('q', '');
				if (responses[question]) {
					if (typeof responses[question] === 'object') {
						responses[question].push(parseInt(res.value));
					} else {
						responses[question] = [responses[question], parseInt(res.value)];
					}
				} else {
					responses[question] = parseInt(res.value);
				}
			} else {
				let [question, subQuestionIndex, binary] = res.name.split('_');

				const qSort = question.replace('q', '');

				if (!responses[qSort]) {
					responses[qSort] = [];
				}
				responses[qSort].push({
					subquestion: subQuestionIndex,
					value: binary ? (parseInt(res.value) === 1 ? 4 : 0) : parseInt(res.value),
				});
			}
		}
	});
	return responses;
};

const setAssessmentCounts = (assessment_counts, assessment_status_count) => {
	const all = assessment_counts.reduce((accumulator, object) => {
		return accumulator + object.total;
	}, 0);
	const teamAll = assessment_counts
		.filter((item) => item.team_type === 'team')
		.reduce((accumulator, object) => {
			return accumulator + object.total;
		}, 0);
	const teamSaved = assessment_counts.find(
		(item) => item.team_type === 'team' && item.status === 'Setup Incomplete'
	)?.total;
	const teamScheduled = assessment_counts.find(
		(item) => item.team_type === 'team' && item.status === 'Scheduled'
	)?.total;
	const teamInProcess =
		assessment_counts.find((item) => item.team_type === 'team' && item.status === 'In Process')?.total ?? 0;
	const teamSetupIncomplete =
		assessment_counts.find((item) => item.team_type === 'team' && item.status === 'Setup Complete')?.total ?? 0;
	const teamCompleted = assessment_counts.find(
		(item) => item.team_type === 'team' && item.status === 'Complete'
	)?.total;

	const individualAll = assessment_counts
		.filter((item) => item.team_type === 'individual')
		.reduce((accumulator, object) => {
			return accumulator + object.total;
		}, 0);

	const individualSaved = assessment_counts.find(
		(item) => item.team_type === 'individual' && item.status === 'Setup Incomplete'
	)?.total;
	const individualScheduled = assessment_counts.find(
		(item) => item.team_type === 'individual' && item.status === 'Scheduled'
	)?.total;
	const individualInProcess =
		assessment_counts.find((item) => item.team_type === 'individual' && item.status === 'In Process')?.total ?? 0;
	const individualSetupIncomplete =
		assessment_counts.find((item) => item.team_type === 'individual' && item.status === 'Setup Complete')?.total ??
		0;
	const individualCompleted = assessment_counts.find(
		(item) => item.team_type === 'individual' && item.status === 'Complete'
	)?.total;

	const filterCountObj = {};
	assessment_status_count.map((a) => {
		filterCountObj[`${a.team_type.toLowerCase()} ${a.status.toLowerCase()}`] = a.total;
	});
	return {
		all: all && !isNaN(all) ? all : 0,
		teamAll: teamAll && !isNaN(teamAll) ? teamAll : 0,
		teamSaved: teamSaved ?? 0,
		teamScheduled: teamScheduled ?? 0,
		teamInProcess: teamInProcess + teamSetupIncomplete,
		teamCompleted: teamCompleted ?? 0,
		individualAll: individualAll && !isNaN(individualAll) ? individualAll : 0,
		individualSaved: individualSaved ?? 0,
		individualScheduled: individualScheduled ?? 0,
		individualInProcess: individualInProcess + individualSetupIncomplete,
		individualCompleted: individualCompleted ?? 0,
		filters: {
			1: filterCountObj['all all'],
			2: filterCountObj['all active'],
			3: filterCountObj['all completed'],
			4: filterCountObj['all not yet started'],
			5: filterCountObj['individual all'],
			6: filterCountObj['individual complete'],
			7: filterCountObj['individual in process'],
			8: filterCountObj['individual setup incomplete'],
			9: filterCountObj['individual scheduled'],
			10: filterCountObj['team all'],
			11: filterCountObj['team complete'],
			12: filterCountObj['team in process'],
			13: filterCountObj['team setup incomplete'],
			14: filterCountObj['team scheduled'],
		},
	};
};

const resetTeamSetup = (customer) => {
	return {
		company: {},
		team: {
			main_contact_participant: false,
			do_main_contact_updates: true,
			team_size: { value: 2, label: 2 },
		},
		email: {
			custom_subject: 'Team Assessment',
			custom_message:
				"As a member of the team, I'm asking you to complete The Table Group's Online Team Assessment. This team assessment tool is based on the model found in the book, The Five Dysfunctions of a Team.",
			custom_signature: `Sincerely, \n${customer.customers_firstname} ${customer.customers_lastname}`,
			template_select: { value: 'default_template', label: 'Default template' },
		},
		template: {},
		reminder: {},
		individual: {},
		newAssessmentId: null,
	};
};

const getAssessmentDetails = (data, details) => {
	const { cc_email, ...payload } = data;
	return {
		...details,
		rosters: details.rosters.map((r) => {
			return {
				...r,
				...AssessmentService.getRosterExtraData(r, {
					cc_email: payload.cc_contacts.join(','),
				}),
			};
		}),
		assessment_data: {
			...details.assessment_data,
			...payload,
			cc_email: cc_email.map(({ value }) => value).join(','),
			cc_email_text: AssessmentService.prepareCCEmailText(payload.cc_contacts),
		},
	};
};

const addAssessmentTeamMembers = (payload, details) => {
	const addMemberRosters = payload.rosters.map((r) => {
		return {
			...r,
			...AssessmentService.getRosterExtraData(r, {
				cc_email: details.assessment_data.cc_email,
			}),
		};
	});
	return {
		...details,
		rosters: addMemberRosters,
		counts: AssessmentService.calculateRosterCounts(addMemberRosters, details.counts.avgCompletionTime),
		assessment_data: {
			...details.assessment_data,
			cc_email_text: AssessmentService.prepareCCEmailText(
				details.assessment_data.cc_email ? details.assessment_data.cc_email.split(',') : []
			),
		},
	};
};

const prepareDeleteMemberData = (payload, details) => {
	const rosters = [...details.rosters].filter((r) => {
		return payload.person_ids.indexOf(r.person_id) === -1;
	});
	return {
		...details,
		rosters,
		counts: AssessmentService.calculateRosterCounts(rosters, details.counts.avgCompletionTime),
		assessment_data: payload.ended_assessment
			? {
					...details.assessment_data,
					status: 'Complete',
					reminders: [],
			  }
			: details.assessment_data,
	};
};

const prepareEditTeamMemberData = (payload, data, details) => {
	if (!payload.warning) {
		const idx = details.rosters.findIndex((r) => {
			return payload.person_id === r.person_id;
		});
		const updatedRosters = [...details.rosters];
		updatedRosters[idx] = {
			...updatedRosters[idx],
			email_address: payload.email,
			status: 'Not Yet Started',
			dateTimeCompleted: '',
			person_id: data.people[0].person_id,
			isOptionalEmail: details.assessment_data.cc_email
				? details.assessment_data.cc_email.split(',').indexOf(payload.email) > -1
				: updatedRosters[idx].isOptionalEmail
		};

		return {
			...details,
			rosters: updatedRosters,
			counts: AssessmentService.calculateRosterCounts(updatedRosters, details.counts.avgCompletionTime),
		};
	}
};

const prepareAssessmentQuestions = (payload) => {
	let { questions, options, scales, responses } = payload;
	questions = questions.map((q) => {
		const sort_order = q.sort ? q.sort : q.sort_order;
		const title = q.question ? q.question : q.question_text;
		let _options = defaultAssessmentOptions;
		if (q.scale && scales && scales[q.scale]) {
			_options = scales[q.scale];
		}
		const extraProps = {};
		if (q.type === 'checkbox') {
			if (q.subtype === 'options-scaled') {
				const questionScales = UtilityService.groupByProp(scales[q.scale], 'type');
				const requiredScales = Object.keys(questionScales);
				extraProps.hasSubQuestions = true;
				extraProps.subQuestions = options
					.filter(({ question }) => question === sort_order)
					.map((subQ) => {
						const answer = {};
						if (responses[sort_order]) {
							const [radio, checkbox] = responses[sort_order]
								.filter(({ subquestion }) => parseInt(subquestion) === subQ.sort)
								.map(({ value }) => value);
							answer.radio = radio;
							answer.checkbox = checkbox;
						}

						return {
							...subQ,
							scales: requiredScales,
							questionScales: questionScales,
							answer,
						};
					});
			} else {
				_options = options.filter(({ question }) => question === sort_order);
			}
		}
		return {
			id: q.id,
			sort_order,
			type: q.type ? q.type : 'radio',
			subtype: q.subtype,
			scale: q.scale,
			category: q.category,
			language: q.language,
			title,
			subtext: q.subtext,
			options: _options,
			answer: responses[sort_order] ? responses[sort_order] : q.type === 'checkbox' ? [] : -1,
			...extraProps,
		};
	});
	return {
		questions: UtilityService.splitArray(
			questions.filter(({ type }) => type === 'radio'),
			5
		),
		checkboxQuestions: questions.filter(({ type }) => type === 'checkbox'),
		scales,
	};
};

const prepareRandomAnswers = (questions, scales) => {
	const updatedRadioQuestions = questions.map((_questions) =>
		_questions.map((q) => {
			let _options = defaultAssessmentOptions;
			if (scales && q.scale && scales[q.scale]) {
				_options = scales[q.scale];
			}
			const possibleAnswers = _options.map(({ value }) => value);
			return {
				...q,
				answer: Math.ceil(possibleAnswers.length * Math.random()),
			};
		})
	);

	const checkboxQuestions = questions.slice(updatedRadioQuestions.length).map(question => {
		const numberOfPossibleAnswers = question.options.length;
		const randomAnswers = [];
		for (let i = 0; i < numberOfPossibleAnswers; i++) {
			if (Math.random() > 0.5) {
				randomAnswers.push(i + 1);
			}
		}

		return {
			...question,
			answer: randomAnswers
		};
	});

	return [...updatedRadioQuestions, ...checkboxQuestions];
};

const AssessmentService = {
	getAssessments,
	getAssessmentCounts,
	getSetupOptions,
	setupTeam,
	draftTeam,
	updateTeam,
	updateIndividual,
	details,
	deleteDraft,
	sendTestEmail,
	draftIndividual,
	setupIndividual,
	addTeamMembers,
	editTeamMember,
	removeTeamMember,
	remindTeamMember,
	sendResponses,
	sentInitialEmail,
	sendReport,
	sendProgress,
	viewReport,
	viewProgress,
	syncReminders,
	sendReminders,
	cancelSchedule,
	updateSchedule,
	prepareTeamSetupPayload,
	prepareAssessmentAsDraft,
	getLicenseUsageByAssessment,
	find,
	findAssessmentList,
	sendFindAssessmentEmail,
	takeAssessmentDetails,
	getQuestions,
	updateAssessmentProgress,
	getReportData,
	getProgressReportData,
	generateReport,
	endTakeAssessment,
	getRosterExtraData,
	getAssessmentTemplate,
	addOrUpdateAssessmentTemplate,
	calculateRosterCounts,
	prepareCCEmailText,
	prepareAssessmentDetails,
	prepareEditAssessmentData,
	prepareUpdateAssessmentPayload,
	prefillTakeAssessmentResponses,
	setAssessmentCounts,
	resetTeamSetup,
	getAssessmentDetails,
	addAssessmentTeamMembers,
	prepareDeleteMemberData,
	prepareEditTeamMemberData,
	prepareAssessmentQuestions,
	prepareRandomAnswers,
};

export default AssessmentService;
