export const generateQuestions = (questions) => {
	const tmp = [];
	for (let i = 0; i < questions.length; i += 5) {
		tmp.push(questions.slice(i, i + 5));
	}
	return tmp;
};
export const validateStep = (stepData) => stepData.filter((q) => q < 0);
export const handleProgress = (currentStep, questions, totalSteps) => {
	if (currentStep <= 1) {
		return '0%';
	}
	if (currentStep === totalSteps) {
		return '100%';
	}
	return `${Math.ceil((((currentStep - 1) * 5) / questions) * 100)}%`;
};
