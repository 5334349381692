import React from 'react';
import Images from '../../assets/Images';

const Summary = ({ disciplineOne, disciplineTwo, disciplineThree, disciplineFour }) => {
	return (
		<div className={'pt-4 px-5 flex-row survey-font'}>
			<div className={'text-red-700 ttg-text-2xl  font-medium '}>Organizational Health Summary</div>

			<div className={'float-left pt-24 ttg-text-lg text-gray-500 font-medium '}>
				Your Score: {disciplineOne.score}
			</div>
			<div className={'float-right mt-24 ttg-text-lg text-gray-500 font-medium '}>
				Your Score: {disciplineTwo.score}
			</div>
			<div className={'h-128'}>
				<div className={'pt-24 ml-15'}>
					<img
						className={'ohs-frame-top-left'}
						src={Images.ohsPdf.disciplines.large['1'][disciplineOne.icon]}
						alt={disciplineOne.icon}
					/>
					<img
						className={'ohs-frame-top-right'}
						src={Images.ohsPdf.disciplines.large['2'][disciplineTwo.icon]}
						alt={disciplineTwo.icon}
					/>
					<img
						className={'ohs-frame-bottom-left'}
						src={Images.ohsPdf.disciplines.large['3'][disciplineFour.icon]}
						alt={disciplineFour.icon}
					/>
					<img
						className={'ohs-frame-bottom-right'}
						src={Images.ohsPdf.disciplines.large['4'][disciplineThree.icon]}
						alt={disciplineThree.icon}
					/>
					<img className={'w-8/12 absolute'} src={Images.ohsPdf.disciplines.framework} alt='framework' />
				</div>
			</div>

			<div className={'float-left pt-24 ttg-text-lg text-gray-500 font-medium '}>
				Your Score: {disciplineFour.score}
			</div>
			<div className={'float-right mt-24 ttg-text-lg text-gray-500 font-medium '}>
				Your Score: {disciplineThree.score}
			</div>

			<img className={'pt-32'} src={Images.ohsPdf.disciplines.legend} alt='legend' />
		</div>
	);
};

export default Summary;
