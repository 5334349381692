import React from 'react';
import { assessmentsFilterOptions } from '../../../constants/AssessmentsFilterOptions';
import { AppTabs } from '../../App';
import { useAssessments } from '../../../providers/AssessmentProvider';

const AssessmentsInfo = ({ setFilters, assessmentCounts }) => {
	const [selectedType, setSelectedType] = React.useState('team');
	const { setSelectedFilter } = useAssessments();

	const onFilterClick = (e) => {
		setFilters('page', 0);
		setSelectedFilter(e - 1);
		assessmentsFilterOptions[e].forEach((filter) => {
			setFilters(filter.name, filter.value);
		});
	};

	const total = React.useMemo(() => {
		if (selectedType === 'team') {
			return `Total team assessments: ${assessmentCounts.teamAll || 0}`;
		}
		return `Total individual assessments: ${assessmentCounts.individualAll || 0}`;
	}, [selectedType, assessmentCounts]);

	const counters = React.useMemo(() => {
		if (selectedType === 'team') {
			return [
				{ label: 'Saved', count: assessmentCounts.teamSaved, filterName: 13 },
				{ label: 'Scheduled', count: assessmentCounts.teamScheduled, filterName: 14 },
				{ label: 'In Process', count: assessmentCounts.teamInProcess, filterName: 12 },
				{ label: 'Completed', count: assessmentCounts.teamCompleted, filterName: 11 },
			];
		}
		return [
			{ label: 'Saved', count: assessmentCounts.individualSaved, filterName: 8 },
			{ label: 'Scheduled', count: assessmentCounts.individualScheduled, filterName: 9 },
			{ label: 'In Process', count: assessmentCounts.individualInProcess, filterName: 7 },
			{ label: 'Completed', count: assessmentCounts.individualCompleted, filterName: 6 },
		];
	}, [selectedType, assessmentCounts]);

	return (
		<div className='container'>
			<AppTabs
				active={selectedType}
				tabs={[
					{ title: 'Team Assessments', key: 'team' },
					{ title: 'Individual Assessments', key: 'individual' },
				]}
				onClick={setSelectedType}
			/>

			<p className={'ttg-text-sm text-gray-900 font-medium my-6'}>{total}</p>

			<div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
				{counters.map((counter, idx) => (
					<div
						id={`ttg-assessment-counter-${idx}`}
						className={
							'flex flex-1 flex-col border-1 border-gray-200 justify-center items-center p-4 cursor-pointer'
						}
						key={counter.label}
						onClick={() => onFilterClick(counter.filterName)}
					>
						<p className={'ttg-text-sm text-gray-600 text-center'}>{counter.label}</p>
						<p className={'ttg-text-2xl text-gray-800 font-medium text-center'}>{counter.count}</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default AssessmentsInfo;
