import React from 'react';
import { AccountLayout } from '../../components/Layout';
import { AppTable } from '../../components/App';
import { useNavigate } from 'react-router-dom';
import RouteNames from '../../router/RouteNames';
import OrderService from '../../services/OrderService';

const Orders = () => {
	const [filters, setFilters] = React.useState({ page: 0 });
	const [orders, setOrders] = React.useState([]);
	const [meta, setMeta] = React.useState({});
	const [loading, setLoading] = React.useState(true);
	const [printingReceipt, setPrintingReceipt] = React.useState({});

	const navigate = useNavigate();

	const _getOrders = React.useCallback(() => {
		setLoading(true);
		OrderService.get(filters)
			.then(({ meta, orders }) => {
				setOrders((prev) => (meta.page === 0 ? orders : [...prev, ...orders]));
				setMeta(meta);
			})
			.finally(() => setLoading(false));
	}, [filters]);

	React.useEffect(() => {
		_getOrders();
	}, [_getOrders]);

	const onPrintReceipt = (item) => {
		setPrintingReceipt({ ...printingReceipt, [item.id]: true });
		OrderService.printReceipt(item.id).finally(() => setPrintingReceipt({ ...printingReceipt, [item.id]: false }));
	};

	return (
		<div className='ttg-orders-layout'>
			<AccountLayout>
				<div className={'ttg-account-section orders-main-table'}>
					<AppTable
						title={'Order History'}
						info={
							'Below is a listing of all orders for this account. To view the details of an order, simply click on its associated link.'
						}
						columns={[
							{ name: 'Order ID', key: 'id', identifier: true },
							{ name: 'Date', key: 'date' },
							{ name: 'Status', key: 'status' },
							{ name: 'Amount', key: 'total' },
						]}
						noDataIcon={'coupon-line'}
						noDataMessage={'You do not have any orders'}
						actions={[{ label: 'Print receipt', action: 'onPrintReceipt' }]}
						data={orders}
						loading={loading}
						actionLoaders={printingReceipt}
						onItemClick={(item) => {
							navigate(`${RouteNames.OrderDetails}?id=${item.id}`);
						}}
						onPrintReceipt={(item) => onPrintReceipt(item)}
						canLoadMore={orders.length < meta.totalResults}
						onLoadMore={() => {
							setFilters({
								page: filters.page + 1,
							});
						}}
					/>
				</div>
			</AccountLayout>
		</div>
	);
};

export default Orders;
