import React from 'react';
import { AppButton, AppIcon, AppInput, AppModalComponent } from '../../../App';
import { useForm } from 'react-hook-form';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const LargeTeamAlertModal = ({ isOpen, onClose, onConfirm, licenses }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();

	React.useEffect(() => {
		if (!isOpen) {
			reset({
				size: undefined,
			});
		}
	}, [isOpen]);

	const _confirm = ({ size }) => {
		onConfirm(size);
	};

	return (
		<AppModalComponent
			id={'ttg-setup-assessment-large-team-alert-modal'}
			isOpen={isOpen}
			style={customStyles}
			onRequestClose={onClose}
		>
			<div className='bg-white p-6' style={{ maxWidth: '612px' }}>
				<form onSubmit={handleSubmit(_confirm)}>
					<div className='text-center'>
						<div className={'flex items-center justify-center rounded-full w-12 h-12 bg-blue-100 mx-auto'}>
							<AppIcon icon={'information-line'} className={'text-2xl text-blue-500'} />
						</div>
						<h1 className={'ttg-text-lg text-gray-900 mt-6 mb-3'}>
							Note regarding teams of greater than 20 members
						</h1>
						<p className={'ttg-text-sm text-gray-500 mb-4'}>
							We limit the assessment to 20 because of our definition of a team which states, “A team is a
							relatively small number of people (anywhere from 3 to 12) that shares common goals as well
							as the rewards and responsibilities for achieving them. Team members readily set aside their
							individual or personal needs for the greater good of the group.”
						</p>
						<p className={'ttg-text-sm text-gray-500 mb-4'}>
							If your “team” doesn't meet these criteria, you might want to consider whether you have a
							smaller subset of the group that is a real team. Or maybe the group is simply a collection
							of people who report to the same manager, but with relatively little interdependence and
							mutual accountability.
						</p>
						<p className={'ttg-text-sm text-gray-500 mb-4'}>
							If you would like to continue with setting up the larger team, please note that electing a
							team of greater than 20 members may render the results of the team assessment less valuable.
						</p>

						<AppInput
							{...register('size', {
								required: true,
								min: 21,
								max: licenses,
							})}
							label={'Enter the number of team members you want to include on this team'}
							type={'number'}
							placeholder={0}
							data-ttg-min={21}
							max={licenses}
							data-ttg-max={licenses}
							errors={errors}
						/>
					</div>
					<div className={'flex justify-end'}>
						<AppButton
							id={'ttg-setup-team-large-team-cancel-button'}
							type={'button'}
							className={'btn btn-secondary mr-4'}
							text={'Cancel'}
							onClick={onClose}
						/>
						<AppButton id={'ttg-setup-team-large-team-confirm-button'} text={'Done'} />
					</div>
				</form>
			</div>
		</AppModalComponent>
	);
};

export default LargeTeamAlertModal;
