import React from 'react';
import { BrowserRouter as AppRouter, Outlet, Route, Routes } from 'react-router-dom';

import {
	Account,
	AddressBook,
	AssessmentDetails,
	AssessmentReport,
	AssessmentProgressReport,
	AssessmentGenerateReport,
	Assessments,
	CapaProListing,
	ChangePassword,
	FindAssessment,
	Forbidden,
	Help,
	IndividualAssessment,
	Licenses,
	Login,
	NotFound,
	SignUp,
	OrderAutoDownload,
	OrderDetails,
	OrderPrintReceipt,
	Orders,
	SendAssessmentReminder,
	TakeAssessment,
	TeamAssessment,
	VideoDetails,
	VideoList,
	ForgotPassword,
	ResetPassword,
	FindAssessmentList,
} from '../pages';
import RouteNames from './RouteNames';
import { Navigate } from 'react-router';

import EmailVerification from '../pages/Public/EmailVerification';
import SurveyTake from '../components/Survey/SurveyTake';
import SurveySetup from '../components/Survey/SurveySetup';
import SurveyPdf from '../pages/ItpPdf/SurveyPdf';
import Survey from '../pages/OhsPdf/Survey';
import RegisterInvitation from '../pages/License/RegisterInvitation';
import { AuthContextLayout } from '../providers/AuthProviderContext';
import { ProfileMenuContextLayout } from '../providers/ProfileMenuProvider';
import MicrosoftLogout from '../pages/Public/MicrosoftLogout';
import PodcastPdf from '../pages/Public/PodcastPdf';
import { SurveyContextLayout } from '../providers/SurveyProvider';
import { ItpProviderLayout } from '../providers/ItpProviderLayout';
import { ItpView } from '../pages/Itp/ItpView';

const Router = () => (
	<AppRouter>
		<Routes>
			{/* Not Authenticated Routes */}
			<Route exact path={RouteNames.LoginEmailVerification} element={<EmailVerification />} />
			<Route exact path={RouteNames.ForgotPassword} element={<ForgotPassword />} />
			<Route exact path={RouteNames.ResetPassword} element={<ResetPassword />} />
			<Route exact path={RouteNames.Login} element={<Login />} />
			<Route exact path={RouteNames.SSOLogin} element={<Login />} />
			<Route exact path={RouteNames.SignUp} element={<SignUp />} />
			<Route exact path={RouteNames.ClaimLicenses} element={<RegisterInvitation />} />
			<Route exact path={RouteNames.Forbidden} element={<Forbidden />} />
			<Route exact path={RouteNames.MicrosoftLogout} element={<MicrosoftLogout />} />
			<Route exact path={RouteNames.OrderPrintReceipt} element={<OrderPrintReceipt />} />
			<Route element={<SurveyContextLayout><Outlet /></SurveyContextLayout>}>
				<Route path={RouteNames.Survey} element={<SurveySetup type={'survey'} />} />
				<Route exact path={RouteNames.SurveyTake} element={<SurveyTake surveyType={'survey'} />} />
				<Route exact path={RouteNames.SurveyPdf} element={<Survey />} />
				<Route exact path={RouteNames.ItpAssessment} element={<SurveySetup type={'assessment'} />} />
				<Route exact path={RouteNames.ItpAssessmentPdf} element={<SurveyPdf />} />
				<Route exact path={RouteNames.ItpAssessmentTake} element={<SurveyTake surveyType={'assessment'} />} />
			</Route>
			<Route path={RouteNames.PodcastPdf} element={<PodcastPdf />} />
			{/*Authenticated Routes*/}
			<Route exact path={RouteNames.BaseAccount} element={<Navigate to={RouteNames.Account} replace />} />
			<Route element={<AuthContextLayout />}>
				<Route element={<ProfileMenuContextLayout />}>
					<Route exact path={RouteNames.Account} element={<Account />} />
					<Route exact path={RouteNames.ChangePassword} element={<ChangePassword />} />
					<Route exact path={RouteNames.AddressBook} element={<AddressBook />} />
					<Route exact path={RouteNames.CapaProListing} element={<CapaProListing />} />
					<Route exact path={RouteNames.Orders} element={<Orders />} />
					<Route exact path={RouteNames.OrderDetails} element={<OrderDetails />} />
					<Route exact path={RouteNames.OrderAutoDownload} element={<OrderAutoDownload />} />
					<Route exact path={RouteNames.VideoList} element={<VideoList />} />
					<Route exact path={RouteNames.VideoDetails} element={<VideoDetails />} />
					<Route exact path={RouteNames.Assessments} element={<Assessments />} />
					<Route exact path={RouteNames.IndividualAssessment} element={<IndividualAssessment />} />
					<Route exact path={RouteNames.TeamAssessmentSetup} element={<TeamAssessment />} />
					<Route exact path={RouteNames.Licenses} element={<Licenses />} />
					<Route exact path={RouteNames.Help} element={<Help />} />
					<Route path={RouteNames.AssessmentDetails} element={<AssessmentDetails />} />
					<Route exact path={RouteNames.Orders} element={<Orders />} />
					<Route exact path={RouteNames.OrderDetails} element={<OrderDetails />} />
					<Route element={<ItpProviderLayout />}>
						<Route exact path={RouteNames.ItpView} element={<ItpView />} />
					</Route>
				</Route>
			</Route>
			<Route path={RouteNames.FindAssessment} element={<FindAssessment />} />
			<Route path={RouteNames.FindAssessmentList} element={<FindAssessmentList />} />
			<Route path={RouteNames.TakeAssessment} element={<TakeAssessment />} />
			<Route path={RouteNames.SendAssessmentReminder} element={<SendAssessmentReminder />} />
			<Route path={RouteNames.AssessmentReport} element={<AssessmentReport />} />
			<Route path={RouteNames.AssessmentProgressReport} element={<AssessmentProgressReport />} />
			<Route path={RouteNames.AssessmentGenerateReport} element={<AssessmentGenerateReport />} />
			{/* NotFound Error page */}
			<Route path={'*'} element={<NotFound />} />/
		</Routes>
	</AppRouter>
);

export default Router;
