import React from 'react';
import { AppButton } from '../App';
import { useSwiper } from 'swiper/react';

const SliderNavigation = ({ canGoLeft, canGoRight, hasNavigation }) => {
	const swiper = useSwiper();

	if (!hasNavigation) {
		return null;
	}

	const navigationClass = 'btn btn-action';

	return (
		<>
			{canGoLeft && (
				<AppButton
					id={'ttg-video-slider-left'}
					icon={'arrow-left-line'}
					iconClass={'block ttg-text-2xl'}
					className={navigationClass}
					onClick={() => swiper.slidePrev()}
				/>
			)}

			{canGoRight && (
				<AppButton
					id={'ttg-video-slider-right'}
					icon={'arrow-right-line'}
					iconClass={'block ttg-text-2xl'}
					className={navigationClass}
					onClick={() => swiper.slideNext()}
				/>
			)}
		</>
	);
};

export default SliderNavigation;
