import React from 'react';
import { AppModalComponent } from './index';
import Images from '../../assets/Images';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const LoadingModal = ({ isOpen, text = 'One moment please...' }) => {
	return (
		<AppModalComponent isOpen={isOpen} style={customStyles}>
			<div className='flex flex-col items-center justify-center' style={{ maxWidth: '512px' }}>
				<img src={Images.whiteSmallTTG} alt='Logo' width='50' height='50' className={'filter'} />
				<p className={'ttg-text-xl text-white mt-4'}>{text}</p>
			</div>
		</AppModalComponent>
	);
};

export default LoadingModal;
