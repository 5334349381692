import React from 'react';
import { AppPdfPage } from '../../../App';
import Images from '../../../../assets/Images';

const TipsExercisesIntro = ({ page }) => {
	return (
		<AppPdfPage page={page} sectionClassName={'TipsExercises'}>
			<div className='Introduction__layout'>
				<div className='PageHeading'>
					<div className='PageHeading__title'>Tips & Exercises</div>
					<div className='PageHeading__image'>
						<img width='110' src={Images.report.lightbulb} alt={''} />
					</div>
				</div>

				<div className='Introduction__mainText'>
					<div className='Introduction__subHeading'>
						Practical Guidelines
						<br />
						for Overcoming
						<br />
						the Five Dysfunctions
					</div>
				</div>
			</div>

			<div className='Page__contentBg Page__contentBg--pyramid'>
				<img src={Images.report.pyramidGrayedOut} alt='Pyramid' />
			</div>
		</AppPdfPage>
	);
};

export default TipsExercisesIntro;
