import { Dayjs } from "dayjs";

import { APPLICATION_INTERFACE } from "./health-status.dto";
import { APPLICATION_ENUM } from "../application";

export enum SEVERITY {
  CRITICAL = "CRITICAL",
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
}
export interface IncidentDto {
  id: string;
  startDate: Dayjs;
  endDate: Dayjs;
  name: string;
  description: string;
  severity: SEVERITY;
  resolveDescription: string;
}

export interface AppInterfaceStatus {
  name: string;
  history: IncidentDto[];
}

export interface HealthStatus {
  application: APPLICATION_ENUM | string;
  interfaces: {
    [APPLICATION_INTERFACE.APP]: AppInterfaceStatus;
    [APPLICATION_INTERFACE.API]?: AppInterfaceStatus;
    [APPLICATION_INTERFACE.THIRD_PARTY]?: AppInterfaceStatus;
  }
}
