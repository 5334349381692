import React from 'react';
import renderErrorMessage from '../../constants/ErrorMessages';

const RadioGroup = React.forwardRef(
	(
		{
			id = 'ttg-app-radio',
			value,
			onChange,
			showErrorMessage = true,
			errors,
			options,
			containerClass = 'flex flex-row',
			itemClass,
			...props
		},
		ref
	) => {
		const { name } = props;
		const shouldShowErrorMessage = !!(showErrorMessage && errors && errors[name]);

		return (
			<div>
				<div className={containerClass}>
					{options.map((option, key) => {
						const selected = value === option.value;
						const customClass = selected ? 'border-4 border-rose-500' : 'border-1 border-gray-200';
						return (
							<div
								id={`ttg-radio-group-option-${id}-${key}`}
								className={`flex flex-row items-center mr-4 ${itemClass} ${
									option.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
								}`}
								key={key}
								onClick={() => {
									if (!option.disabled) {
										onChange(option.value);
									}
								}}
							>
								<div className={`w-4 h-4 rounded-full ${customClass}`} />
								<p
									className={`ttg-text-sm ml-2 ${
										option.disabled ? ' text-gray-500' : ' text-gray-900'
									} `}
								>
									{option.label}
								</p>
							</div>
						);
					})}
				</div>

				<span className={`app-input-error-message ${shouldShowErrorMessage ? 'visible' : 'invisible'}`}>
					{errors && errors[name] && renderErrorMessage(errors[name].type, props, '', errors[name].message)}
				</span>
			</div>
		);
	}
);

export default RadioGroup;
