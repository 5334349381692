import React from 'react';
import { Link } from 'react-router-dom';

import Images from '../../../assets/Images';
import { AppIcon, AppSlideOver } from '../../App';
import { AppTools } from '..';
import RouteNames from '../../../router/RouteNames';
import { useProfileMenu } from '../../../providers/ProfileMenuProvider';
import { useCustomer } from '../../../providers/CustomerProvider';

const MobileSidebar = ({ ...props }) => {
	const { customer } = useCustomer();
	const { onLogout } = useProfileMenu();
	const email = customer && customer.customers_email_address;

	const [showTools, setShowTools] = React.useState(false);

	return (
		<AppSlideOver {...props}>
			<div
				className={`sidebar-mobile-view p-8 pb-15 h-full flex flex-col justify-between ${
					!showTools ? 'opened' : 'closed'
				}`}
			>
				<div>
					<div className={'logo'} id={'ttg-account-header-logo'}>
						<a href='/'>
							<img src={Images.logo} alt='Logo' />
						</a>
					</div>
					{props.sidebar && props.sidebarContent}
				</div>
				<div>
					<div className={'nav-mobile'}>
						<a id={'ttg-account-header-store'} className={'nav-item'} href='/store'>
							Store
						</a>
						{!!email && (
							<button
								id={'ttg-account-header-tools'}
								className={'nav-item flex items-center justify-between'}
								onClick={() => setShowTools(true)}
							>
								Tools
								<AppIcon icon='arrow-right-s-line' className={'text-2xl'} />
							</button>
						)}
					</div>
					{!!email && (
						<div className='ttg-account-sidebar'>
							<div className='ttg-account-info'>
								<Link to={RouteNames.Account}>
									<p className='ttg-text-sm font-normal'>Logged in as</p>
									<p className='ttg-text-sm font-medium'>{email}</p>
								</Link>
							</div>
							<div
								id={'ttg-account-logout-id'}
								onClick={onLogout}
								className={'ttg-account-logout-action'}
							>
								<AppIcon icon={'logout-box-r-line'} />
								<span className='ttg-text-sm'>Logout</span>
							</div>
						</div>
					)}
				</div>
			</div>
			<div className={`tools-mobile-view px-6 py-8 h-full ${showTools ? 'opened' : 'closed'}`}>
				<button
					id={'ttg-account-header-tools-back'}
					className={'flex items-center w-full text-base font-medium'}
					onClick={() => setShowTools(false)}
				>
					<AppIcon icon='arrow-left-s-line' className='text-2xl' />
					Tools
				</button>
				<AppTools />
			</div>
		</AppSlideOver>
	);
};

export default MobileSidebar;
