export enum APPLICATION_ENUM {
  TTG = 'TTG',
  WG = 'WG',
  SM = 'SM',
}

type Applications = {
  [key in APPLICATION_ENUM]: string;
};

export const APPLICATION: Applications = {
  [APPLICATION_ENUM.WG]: 'WG',
  [APPLICATION_ENUM.TTG]: 'TTG',
  [APPLICATION_ENUM.SM]: 'SM'
}

export const ApplicationsEnum: string[] = Object.values(APPLICATION_ENUM);
