import ApiService from './ApiService';
import RouteNames from '../router/RouteNames';

const get = () => {
	return new Promise((resolve, reject) => {
		ApiService.get(`/v1/videos`).then(({ data }) => {
			resolve(data);
		}, reject);
	});
};

const getDetails = (id) => {
	return new Promise((resolve, reject) => {
		ApiService.get(`/v1/video/${id}`).then(({ data }) => {
			if (data.valid_videos[0]) {
				resolve({
					...data.valid_videos[0],
					description:
						data.video_products && data.video_products[0] && data.video_products[0].description
							? data.video_products[0].description
							: '',
				});
			} else {
				reject('no valid video');
			}
		}, reject);
	});
};

const startSubscription = (video_id) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/videos/mark_start`, { video_id }).then(resolve, reject);
	});
};

const audit = (video_id, type) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/videos/audit`, { video_id, type }).then(resolve, reject);
	});
};

const addToCart = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/legacy/add_to_cart`, payload).then(resolve, reject);
	});
};

const getFile = (name) => {
	return `${RouteNames.AssetsBasePath}/video/pdf/${name}`;
};

const getSlidesNumber = () => {
	if (window.innerWidth > 992) {
		return 3;
	}
	if (window.innerWidth > 768) {
		return 2;
	}
	return 1;
};

const prepareVideos = (payload) => {
	const { valid_videos, video_products, expired_videos } = payload;
	const mappedVariations = {};
	const mappedProducts = {};
	return {
		videos: valid_videos,
		products: video_products.map((p) => ({
			...p,
			variations: p.variations.map((v) => {
				mappedProducts[v.variation_legacy_id] = p.id;
				mappedVariations[v.variation_legacy_id] = v.id;
				return {
					...v,
					value: v.id,
					label: v.name,
				};
			}),
		})),
		expired: expired_videos.map((v) => ({
			...v,
			wp_product_id: mappedProducts[v.product_id],
			wp_variation_id: mappedVariations[v.product_id],
		})),
	};
};

const VideoService = {
	get,
	getDetails,
	startSubscription,
	audit,
	addToCart,
	getFile,
	getSlidesNumber,
	prepareVideos,
};

export default VideoService;
