export enum RUN_NAME_ENUM {
  REARRANGE = 'REARRANGE',
  TASK_DESCRIPTION = 'TASK_DESCRIPTION',
  DETAILED = 'DETAILED',
  SUMMARY = 'SUMMARY',
  UNKNOWN = 'UNKNOWN'
}

export const StepLevelOrder = {
  1: RUN_NAME_ENUM.REARRANGE,
  2: RUN_NAME_ENUM.TASK_DESCRIPTION,
  3: RUN_NAME_ENUM.DETAILED,
  4: RUN_NAME_ENUM.SUMMARY
};

export const RunStepTransformer = {
  toEnum: (step: number): RUN_NAME_ENUM => {
    return Object.keys(StepLevelOrder).includes(`${step}`) ?  StepLevelOrder[step]: RUN_NAME_ENUM.UNKNOWN;
  }
}
