import React from 'react';
import Images from '../../../../assets/Images';
import { AppPdfPage } from '../../../App';

const Introduction = ({ details }) => {
	return (
		<AppPdfPage page={1} sectionClassName={'Introduction'}>
			<div className='Introduction__layout'>
				<div className='Introduction__heading'>
					<div className='PageHeading'>
						<div className='PageHeading__title'>Introduction</div>
						<div className='PageHeading__image'>
							<img width='82' src={Images.report.chatBox} />
						</div>
					</div>
				</div>

				<div className='Introduction__mainText'>
					<div className='Blurb'>
						<div className='Blurb__content'>
							{details.current_team.team_type === 'individual' ? (
								<>
									<p id={'ttg-tar-intro-individual-1'}>
										The primary purpose of this report is to provide you with a sense of your
										team&rsquo;s unique strengths and areas for improvement. For a more accurate and
										holistic analysis, you are encouraged to have your entire team take the
										Assessment. While the Assessment itself provides an interesting perspective, its
										most important aspect is the discussion it may provoke around specific issues.
									</p>
									<p id={'ttg-tar-intro-individual-2'}>
										If you have not yet read the book, <em>The Five Dysfunctions of a Team</em>, the
										following pages are included to give you a summary of the teamwork model that
										this Assessment is based upon. This will help you interpret your results.
									</p>
								</>
							) : (
								<>
									<p id={'ttg-tar-intro-team-1'}>
										The primary purpose of this report is to provide your team with a sense of its
										strengths and areas for improvement. While the Assessment itself is certainly
										quantitative and data-driven, its most important aspect is the qualitative
										perspective it provides for your team and the discussion that it provokes around
										specific issues.
									</p>

									<p id={'ttg-tar-intro-team-2'}>
										If you have not yet read the book, <em>The Five Dysfunctions of a Team</em>, the
										following pages are included to give you a summary of the teamwork model that
										this Assessment is based upon. This will help you interpret your team’s results.
									</p>
								</>
							)}
						</div>
					</div>

					<div className='Introduction__mainTextAside'>
						<div className='BracketPanel'>
							<div className='BracketPanel__content'>
								*If you are familiar with the dysfunctions model, you can skip ahead to page 5, and
								investigate the interpretations of your scores.
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='Page__contentBg Page__contentBg--pyramid'>
				<img src={Images.report.pyramidGrayedOut} alt='' />
			</div>
		</AppPdfPage>
	);
};

export default Introduction;
