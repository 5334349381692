import { useNavigate } from 'react-router-dom';
import React from 'react';
import RouteNames from '../../router/RouteNames';
import Button from '../App/Button';
import { useSurveys } from '../../providers/SurveyProvider';

const SurveyInstructions = ({ type }) => {
	const navigate = useNavigate();
	const { surveyDetails: { title, instructions, response_values }} = useSurveys();

	return (
		<div
			className={
				'flex items-center flow-root container mx-auto p-4 md:w-auto max-w-6xl min-w-0 bg-white max-h-full'
			}
		>
			<div className={'ttg-text-3xl font-medium p-4 flex-col'}>
				<div>Welcome to the {`${title}`}</div>
			</div>

			<div className={'ttg-text-lg p-4'}>
				<div>
					<div className={'font-bold'}>Instructions:</div>
					{`${instructions}`}
				</div>
			</div>

			<div className={'ttg-text-lg p-4 font-semibold'}>
				<div className={'h-15'}>The Rating Scale:</div>
				{response_values.map((el, i) => (
					<li key={i} className={'ttg-text-base p-2 m-2'}>{`${el.label}`}</li>
				))}
			</div>

			<Button
				text={'BEGIN'}
				className={'btn btn-primary uppercase w-40 h-10 flow-root md: items-center float-right p-4'}
				onClick={() => {
					navigate(type === 'assessment' ? RouteNames.ItpAssessmentTake : RouteNames.SurveyTake);
					window.scrollTo(0, 0);
					window.postMessage({ name: 'surveyScroll', scrollTop: 0 }, window.origin);
				}}
			/>
		</div>
	);
};

export default SurveyInstructions;
