import React from 'react';
import { AccountLayout } from '../../components/Layout';
import { VideoSlider, VideoProducts, ExpiredVideos } from '../../components/Video';
import RouteNames from '../../router/RouteNames';
import { useNavigate } from 'react-router-dom';
import { AppAccordion } from '../../components/App';
import VideoService from '../../services/VideoService';

const List = () => {
	const navigate = useNavigate();
	
	const [videos, setVideos] = React.useState([]);
	const [products, setProducts] = React.useState([]);
	const [expired, setExpired] = React.useState([]);
	const [loading, setLoading] = React.useState(true);

	const _getVideos = React.useCallback(() => {
		setLoading(true);
		VideoService.get()
			.then((response) => {
				const { videos, products, expired } = VideoService.prepareVideos(response);
				setVideos(videos);
				setProducts(products);
				setExpired(expired);
			})
			.finally(() => setLoading(false));
	}, []);

	React.useEffect(() => {
		_getVideos();
	}, [_getVideos]);

	const renderExpiredVideos = () => {
		if (expired && expired.length > 0) {
			return (
				<AppAccordion
					isOpen={true}
					title={
						<div className={'mx-4 my-2'}>
							<h1 className={'ttg-text-2xl text-gray-900 font-medium'}>Expired Videos</h1>
							<p className={'ttg-text-sm text-gray-500'}>
								The subscription period for the following videos has expired.
							</p>
						</div>
					}
				>
					<ExpiredVideos videos={expired} loading={loading} productList={products} />
				</AppAccordion>
			);
		}
		return null;
	};

	return (
		<AccountLayout loading={loading} sidebar={false}>
			<div className={'ttg-account-section flex-col'}>
				<h1 className={'ttg-text-2xl text-gray-900 font-medium mx-4'}>
					Welcome to the Video Section of the Client Console
				</h1>
				{videos.length === 0 && (
					<p className={'ttg-text-sm text-gray-500 mx-4 mb-4'}>
						You currently do not have access to streaming video. See below for pricing and options.
					</p>
				)}
				<VideoSlider videos={videos} onClick={({ id }) => navigate(`${RouteNames.VideoDetails}?id=${id}`)} />

				{videos.length > 0 && (
					<div className={'m-4'}>
						<h1 className={'ttg-text-2xl text-gray-900 font-medium'}>Get more Streaming Videos</h1>
						<p className={'ttg-text-sm text-gray-500'}>See below for pricing and options.</p>
					</div>
				)}

				<VideoProducts products={products} loading={loading}/>

				{renderExpiredVideos()}
			</div>
		</AccountLayout>
	);
};

export default List;
