import React from 'react';
import Images from '../../../../assets/Images';
import { AppPdfPage } from '../../../App';

const TheModel = () => {
	return (
		<AppPdfPage page={2} sectionClassName={'TheModel'}>
			<div className='TheModel__layout'>
				<div className='TheModel__heading'>
					<div className='PageHeading'>
						<div className='PageHeading__title'>The Model</div>
						<div className='PageHeading__image'>
							<img width='82' src={Images.report.gears} alt={''} />
						</div>
					</div>
				</div>

				<div className='TheModel__content'>
					<div className='TheModel__mainText'>
						<div className='Blurb'>
							<div className='Blurb__content'>
								<p>
									Like it or not, all teams are potentially dysfunctional. This is inevitable because
									they are made up of fallible, imperfect human beings. From the basketball court to
									the executive suite, politics and confusion are more the rule than the exception.
								</p>
								<p>
									But the power of teamwork is great. The founder of a billion dollar company best
									expressed that power when he once said, "If you could get all the people in an
									organization rowing in the same direction, you could dominate any industry, in any
									market, against any competition, at any time."
								</p>
								<p>
									Whenever a group of leaders hears this adage, they immediately nod their heads, but
									in a desperate sort of way. They seem to grasp the truth of it while simultaneously
									surrendering to the impossibility of actually making it happen.
								</p>
								<p>
									Fortunately, the causes of dysfunction are both identifiable and curable. However,
									they don’t die easily. Making a team functional and cohesive requires extraordinary
									levels of courage and discipline.{' '}
								</p>
								<p>
									The following section provides an overview of the five behavioral challenges all
									teams must continuously work to avoid.
								</p>
							</div>
						</div>
					</div>

					<div className='TheModel__aside'>
						<div className='TheModel__borderPanel'>
							<div className='TheModel__miniEmblem'>
								<img src={Images.report.emblem} alt={''} />
							</div>
							<p className='TheModel__aside__paragraph'>
								Fortunately,
								<br /> the causes of dysfunction are both identifiable and curable. However, they don’t
								die easily. Making a team functional and cohesive requires extraordinary levels of
								courage and discipline.
							</p>
						</div>
					</div>
				</div>
			</div>
		</AppPdfPage>
	);
};

export default TheModel;
