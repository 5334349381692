import React from 'react';

import { AppWizard } from '../../App';
import Find from './Find';
import Request from './Request';
import EmailStep from './EmailStep';
import ReCaptcha from '../../App/ReCaptcha';

import UtilityService from '../../../services/UtilityService';
import { CaptchaProvider } from '../../../providers/CaptchaProvider';

const Wizard = () => {
	const captchaRef = React.useRef('captcha');
	const [email, setEmail] = React.useState('');

	return (
		<>
			<CaptchaProvider captchaRef={captchaRef}>
				<AppWizard
					className={'team-find-assessment'}
					isLazyMount={true}
					onStepChange={UtilityService.scrollToTop}
				>
					<Find setEmail={setEmail}/>
					<Request email={email} />
					<EmailStep email={email}/>
				</AppWizard>
			</CaptchaProvider>

			<ReCaptcha ref={captchaRef} />
		</>
	);
};

export default Wizard;
