import React from 'react';
import { AppIcon } from '.';

const Alert = ({ title, children }) => {
	return (
		<div className='app-alert flex items-row p-4 pl-2 bg-yellow-50'>
			<AppIcon icon={'alert-fill'} className={'mr-2 text-xl text-yellow-500'} />
			<div className='flex flex-col'>
				<span className='text-sm font-medium text-yellow-900 mb-2'>{title}</span>
				<div className='text-sm font-normal text-yellow-700'>{children}</div>
			</div>
		</div>
	);
};

export default Alert;
