import React from 'react';
import { AppButton, AppModalComponent } from '../App';
import NotificationService from '../../services/NotificationService';
import AddressService from '../../services/AddressService';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const SetAsPrimaryModal = ({ isOpen, closeModal, address_id, setAddresses, addressesState }) => {
	const [loading, setLoading] = React.useState(false);

	const setAsDefault = () => {
		setLoading(true);
		AddressService.setAddressAsDefault({ address_book_id: address_id })
			.then(() => {
				closeModal();
				NotificationService.success('', 'Primary Address Successfully changed!');
				const { primary, alternates, addresses } = AddressService.updateDefaultAddress(addressesState.addresses, address_id);
				setAddresses({ ...addressesState, primaryAddress: primary, alternateAddresses: alternates, addresses });
			})
			.catch((error) => NotificationService.error('', error))
			.finally(() => setLoading(false));
	};

	return (
		<AppModalComponent id={'ttg-primary-address-modal-id'} isOpen={isOpen} style={customStyles}>
			<div className='bg-white p-6' style={{ maxWidth: '512px' }}>
				<h1 className={'ttg-text-lg text-gray-900 font-medium'}>Primary Address</h1>
				<p className={'ttg-text-sm text-gray-500 mt-2 mb-4'}>
					Are you sure you want to make this your primary address?
				</p>
				<div className='flex justify-end'>
					<AppButton
						className={'btn btn-secondary mr-4'}
						text={'Cancel'}
						onClick={() => closeModal()}
						id={'ttg-primary-address-cancel-btn'}
					/>
					<AppButton
						className={'btn btn-primary'}
						text={'Yes'}
						onClick={() => setAsDefault()}
						id={'ttg-primary-address-yes-btn'}
						loading={loading}
					/>
				</div>
			</div>
		</AppModalComponent>
	);
};

export default SetAsPrimaryModal;
