import React from 'react';
import { AppButton, AppModalComponent } from '../../App';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const BulkConfirmActionModal = ({ isOpen, onClose, onConfirm, data }) => {
	const allTexts = {
		initial: {
			title: 'SEND TEAM ASSESSMENT EMAIL',
			text: `Are you sure you wish to send the initial team assessment email to the following members from the <span class="font-medium">${data.team_name}</span> team?`,
			notice: `The following members from the <span class="font-medium">${data.team_name}</span> have already completed their assessment, and will not be sent the assessment email:`,
		},
		reminder: {
			title: 'SEND REMINDERS',
			text: `Are you sure you wish to send assessment reminders to the following members from the <span class="font-medium">${data.team_name}</span> team?`,
			notice: `The following members from the <span class="font-medium">${data.team_name}</span> have already completed their assessment, and will not be sent reminders:`,
		},
		responses: {
			title: 'SEND REMINDERS',
			text: `Are you sure you wish to send assessment responses to the following members from  <span class="font-medium">${data.team_name}</span> team?`,
			notice: `The following members from the <span class="font-medium">${data.team_name}</span> have not yet completed their assessment, and will not be sent responses:`,
		},
	};

	const texts = React.useMemo(() => {
		return allTexts[data.type] ? allTexts[data.type] : {};
	}, [data]);

	return (
		<AppModalComponent isOpen={isOpen} closeModal={onClose} style={customStyles} onRequestClose={onClose}>
			<div className='bg-white p-6' style={{ maxWidth: '512px' }}>
				<div className='flex'>
					<div className='flex flex-1 flex-col ml-4'>
						<h1 className={'ttg-text-lg text-gray-900 font-medium mb-1'}>{texts.title}</h1>
						{data.emails.length > 0 && (
							<>
								<p
									className={'ttg-text-sm text-gray-700 mb-2'}
									dangerouslySetInnerHTML={{ __html: texts.text }}
								/>
								<ul className={'list-disc ml-3'}>
									{data.emails.map((e, i) => {
										return (
											<li key={i} className={'ttg-text-sm text-gray-700 font-medium'}>
												{e}
											</li>
										);
									})}
								</ul>
							</>
						)}
						<p
							className={'ttg-text-sm text-gray-700 mt-4 mb-2'}
							dangerouslySetInnerHTML={{ __html: texts.notice }}
						/>
						<ul className='list-disc ml-3'>
							{data.noticeEmails.map((e, i) => {
								return (
									<li key={i} className={'ttg-text-sm text-gray-700 font-medium'}>
										{e}
									</li>
								);
							})}
						</ul>
						<div className={'flex justify-end'}>
							<AppButton
								id={'ttg-roster-bulk-cancel-button'}
								text={'No'}
								className={'btn btn-secondary mr-4'}
								onClick={onClose}
							/>
							<AppButton
								id={'ttg-roster-bulk-confirm-button'}
								text={'Yes, Confirm'}
								onClick={onConfirm}
							/>
						</div>
					</div>
				</div>
			</div>
		</AppModalComponent>
	);
};

export default BulkConfirmActionModal;
