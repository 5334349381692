import React from 'react';
import { useForm, useFormState } from 'react-hook-form';
import UtilityService from '../../../services/UtilityService';
import { AppButton, AppInput, AppSlideOver } from '../../App';
import NotificationService from '../../../services/NotificationService';
import AssessmentService from '../../../services/AssessmentService';
import { useAssessments } from '../../../providers/AssessmentProvider';

const EditTeamMemberSidebar = ({ teamId, roster, isOpen, onClose }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
		control,
	} = useForm();

	const { isDirty } = useFormState({ control });
	const { loading: editingMembers, setLoading, setAssessmentDetails } = useAssessments();

	React.useEffect(() => {
		reset(roster);
	}, [roster]);

	const save = async (data) => {
		setLoading(true);
		const payload = {
			email: data.email_address.toLowerCase(),
			person_id: data.person_id,
		};
		AssessmentService.editTeamMember(teamId, payload)
			.then((response) => {
				setAssessmentDetails((prev) => AssessmentService.prepareEditTeamMemberData(payload, response, prev));
				if (response.warning) {
					return setError('email_address', {
						type: 'validate',
						message: response.warning,
					});
				}
				NotificationService.success('Team member updated successfully');
				return onClose(true);
			})
			.finally(() => setLoading(false));
	};

	return (
		<AppSlideOver isOpen={isOpen} closeModal={() => onClose()} slideOverContentClass={'!bg-white'}>
			<div className='p-4'>
				<h1 className='ttg-text-base text-gray-900 font-medium'>Edit Team Member</h1>
				<p className='text-gray-500 ttg-text-sm mt-2 mb-4'>
					Please edit the email address below and click the 'Save' button.
				</p>
				<form onSubmit={handleSubmit(save)}>
					<AppInput
						label={'Email Address'}
						{...register('email_address', {
							required: true,
							validate: (value) =>
								UtilityService.isValidEmail(value) || 'You must enter a valid email address',
							maxLength: 96,
						})}
						data-ttg-max-length={96}
						errors={errors}
					/>

					<div className='my-4 border-t-1 border-gray-200' />

					<div className='flex flex-1 mt-4'>
						<div className='flex flex-1'>
							<AppButton
								id={'ttg-assessment-edit-team-member-confirm-button'}
								text={'Save'}
								className={'btn btn-primary w-full mr-2'}
								loading={editingMembers}
								disabled={!isDirty}
							/>
						</div>
						<div className='flex flex-1'>
							<AppButton
								id={'ttg-assessment-edit-team-member-cancel-button'}
								text={'Cancel'}
								className={'btn btn-secondary w-full ml-2'}
								type={'button'}
								onClick={() => onClose()}
								loading={editingMembers}
							/>
						</div>
					</div>
				</form>
			</div>
		</AppSlideOver>
	);
};

export default EditTeamMemberSidebar;
