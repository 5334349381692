import React from 'react';
import Modal from 'react-modal';
import { AppIcon } from '../App';

Modal.setAppElement('body');

const SlideOver = ({ isOpen, closeModal, slideFrom = 'right', slideOverContentClass, children, type, ...props }) => {
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={closeModal}
			className={`slide-modal ${type} ${slideFrom}`}
			overlayClassName={`slide-overlay ${slideFrom}`}
			closeTimeoutMS={300}
			shouldFocusAfterRender={false}
			{...props}
		>
			{slideFrom === 'right' && (
				<div>
					<button onClick={() => closeModal()} className='slide-over-btn'>
						<AppIcon icon='close-fill' className='slide-over-icon text-2xl text-white' />
					</button>
				</div>
			)}
			<div className={`slide-over-content ${slideOverContentClass}`}>{children}</div>
		</Modal>
	);
};

export default SlideOver;
