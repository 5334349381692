import ApiService from './ApiService';
import UtilityService from './UtilityService';

const verifyLicenseClaimInvite = (token) =>
	ApiService.get(`v1/licenses/verify_token?token=${token}`).then(({ data }) => {
		return data;
	});

const registerClaimLicense = (payload) =>
	ApiService.post(`v1/licenses/register`, payload).then(({ data }) => {
		UtilityService.setAuthCookie(data.token);

		return data;
	});

const transferLicenses = (payload) =>
	new Promise((resolve, reject) => {
		ApiService.post(`v1/licenses/transfer`, payload)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});

const cancelTransfer = (payload) =>
	new Promise((resolve, reject) => {
		ApiService.delete(`v1/licenses/transfer`, { data: payload })
			.then(({ data }) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});

const LicenseService = {
	verifyLicenseClaimInvite,
	registerClaimLicense,
	transferLicenses,
	cancelTransfer,
};

export default LicenseService;
