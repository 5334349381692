export const assessmentsFilterOptions = {
	1: 
	[
		{ name: "status", value: "" },
		{ name: "num_scores", value: "" },
		{ name: "team_type", value: "" },
		{ name: "complete", value: "" },
	],
	2: 
	[
		{ name: "status", value: "In Process" },
		{ name: "num_scores", value: "" },
		{ name: "team_type", value: "" },
		{ name: "complete", value: "" },
	],
	3: 
	[
		{ name: "status", value: "Complete" },
		{ name: "num_scores", value: "" },
		{ name: "team_type", value: "" },
		{ name: "complete", value: "" },
	],
	4: 
	[
		{ name: "status", value: "" },
		{ name: "num_scores", value: 0 },
		{ name: "team_type", value: "" },
		{ name: "complete", value: 0 },
	],
	5: 
	[
		{ name: "status", value: "" },
		{ name: "num_scores", value: "" },
		{ name: "team_type", value: "individual" },
		{ name: "complete", value: "" },
	],
	6: 
	[
		{ name: "status", value: "Complete" },
		{ name: "num_scores", value: "" },
		{ name: "team_type", value: "individual" },
		{ name: "complete", value: "" },
	],
	7: 
	[
		{ name: "status", value: "In Process" },
		{ name: "num_scores", value: "" },
		{ name: "team_type", value: "individual" },
		{ name: "complete", value: 0 },
	],
	8: 
	[
		{ name: "status", value: "Setup Incomplete" },
		{ name: "num_scores", value: "" },
		{ name: "team_type", value: "individual" },
		{ name: "complete", value: "" },
	],
	9: 
	[
		{ name: "status", value: "Scheduled" },
		{ name: "num_scores", value: "" },
		{ name: "team_type", value: "individual" },
		{ name: "complete", value: "" },
	],
	10: 
	[
		{ name: "status", value: "" },
		{ name: "num_scores", value: "" },
		{ name: "team_type", value: "team" },
		{ name: "complete", value: "" },
	],
	11: 
	[
		{ name: "status", value: "Complete" },
		{ name: "num_scores", value: "" },
		{ name: "team_type", value: "team" },
		{ name: "complete", value: "" },
	],
	12: 
	[
		{ name: "status", value: "In Process" },
		{ name: "num_scores", value: "" },
		{ name: "team_type", value: "team" },
		{ name: "complete", value: 0 },
	],
	13: 
	[
		{ name: "status", value: "Setup Incomplete" },
		{ name: "num_scores", value: "" },
		{ name: "team_type", value: "team" },
		{ name: "complete", value: "" },
	],
	14: 
	[
		{ name: "status", value: "Scheduled" },
		{ name: "num_scores", value: "" },
		{ name: "team_type", value: "team" },
		{ name: "complete", value: "" },
	],
};
  