import React from 'react';
export const disciplines = {
	1: {
		header: 'Discipline 1: Build a Cohesive Leadership Team',
		body: (
			<div className={'survey-font'}>
				<h4 className={'text-red-700 ttg-text-lg py-4'}>Tips for Building a Cohesive Leadership Team:</h4>
				<p>There are a few simple tools that serve to build a cohesive team:</p>

				<div className={'list-decimal py-2 '}>
					<li className={'py-2 survey-font'}>
						<b className={'pl-2'}>Use a personality instrument:</b>
						<div className={'pl-5'}>
							A personality instrument (e.g. Myers-Briggs, DISC or Social Styles) will help team members
							understand one another's different preferences, skills and attitudes and identify collective
							strengths and potential blind spots of the team. This will help team members avoid making
							unproductive judgments about one another and instead leverage the diverse approaches and
							perspectives of the team. It will also accelerate trust by speeding up the process of team
							members getting to know one another.
						</div>
					</li>

					<li className={'py-2 survey-font'}>
						<b className={'pl-1'}>Spend time together:</b>
						<div className={'pl-5'}>
							Find opportunities to spend more time together, face-to-face. One of the biggest impediments
							to trust building on a team is the lack of time spent working collectively. This can include
							off-site meetings, strategic planning sessions, and even social activities. Keep in mind
							that face-time will ultimately save you time in the long run.
						</div>
					</li>
					<li className={'py-2 survey-font'}>
						<b className={'pl-1'}>Share personal histories:</b>
						<div className={'pl-5'}>
							Sharing personal histories with one another will help team members understand one another's
							backgrounds. Just by having everyone describe relatively innocuous attributes or
							experiences, team members will begin to relate to one another on a more personal basis, and
							see one another as human beings with life stories and interesting backgrounds. This
							encourages greater empathy and understanding, and discourages unfair and inaccurate
							behavioral attributions.
						</div>
					</li>
					<li className={'py-2 survey-font'}>
						<b className={'pl-1'}>Implement the team effectiveness exercise:</b>
						<div className={'pl-5'}>
							The team effectiveness exercise provides a forum for quick and effective exchange of
							feedback. Ask team members to identify and communicate one another's positive attributes and
							areas of improvement. By doing so, teams can quickly and constructively surface issues that
							might take months to address using a more formal, and potentially divisive 360-degree
							program.
						</div>
					</li>
				</div>

				<p className={'survey-font pl-2'}>
					For additional assistance on building a cohesive leadership team, be sure to read{' '}
					<i>The Five Dysfunctions of a Team</i>:{' '}
					<a className={'text-red-700'} href='https://www.tablegroup.com/product/dysfunctions/'>
						www.tablegroup.com/dysfunctions
					</a>
					.
				</p>
			</div>
		),
		high: 'Your score in this area is high, which indicates that your leadership team is cohesive and has created an environment where vulnerability, ideological conflict, commitment, accountability and results are the norm.Though you scored high in building a cohesive team, it is recommended that youconsider one or more of the suggestions below to maintain current levels.',
		medium: 'Your score in this area is medium, which indicates that your leadership team may need to get more comfortable being vulnerable with one another, engaging in ideological conflict, committing to decisions, holding team members accountable and driving for organizational results. While you scored medium in building a cohesive team, it is recommended that you consider using many of the suggestions below.',
		low: 'Your score in this area is low, which indicates that your leadership team is potentially dysfunctional and needs to get more comfortable being vulnerable with one another, engaging in ideological conflict, committing to decisions, holding team members accountable and driving for organizational results. Because you scored low in building a cohesive team, it is critical that you consider using the suggestions below.',
	},
	2: {
		header: 'Discipline 2: Create Clarity',
		body: (
			<div className={'survey-font'}>
				<h4 className={'text-red-700 ttg-text-lg py-4'}>Tips for Creating Clarity:</h4>
				<p className={'py-2'}>
					Ask the team to discuss and come to resolution around the questions below (which may vary somewhat
					depending on the nature of the team and its place in the organization). Before moving on, make
					certain that each team member has the same understanding of what has been agreed to. Questions
					include:
				</p>

				<div className={'list-decimal py-2 pl-5 survey-font'}>
					<li className={'py-2'}>Why do we exist?</li>
					<li className={'py-2'}>How do we behave?</li>
					<li className={'py-2'}>What do we do?</li>
					<li className={'py-2'}>How will we succeed?</li>
					<li className={'py-2'}>What is most important &mdash; right now?</li>
					<li className={'py-2'}>Who must do what?</li>
				</div>

				<p className={'py-2 survey-font'}>
					If you need more detailed information on establishing a thematic goal, refer to the book{' '}
					<i>Silos, Politics and Turf Wars</i>:{' '}
					<a className={'text-red-700'} href='http://www.tablegroup.com/silos/'>
						www.tablegroup.com/silos
					</a>
					.
				</p>
			</div>
		),
		high: 'Your score in this area is high, which indicates that your leadership team is clear and aligned around what the organization believes, where it is headed, and what it needs to accomplish in order to succeed. Though you scored high in creating clarity, it is recommended that you regularly revisit your organizational clarity by discussing the questions below.',
		medium: 'Your score in this area is medium, which indicates that your leadership team needs to better clarify what the organization believes, where it is headed, and what it needs to accomplish in order to succeed. While you scored medium in creating clarity, it is recommended that you discuss the questions below.',
		low: 'Your score in this area is low, which indicates that your leadership team is not on the same page when it comes to key issues such as what the organization believes, where it is headed, and what it needs to accomplish in order to succeed. Because you scored low in creating clarity, it is critical that you discuss the questions below.',
	},
	3: {
		header: 'Discipline 3: Over-Communicate Clarity',
		body: (
			<div className={'survey-font'}>
				<h4 className={'text-red-700 ttg-text-lg pb-1'}>Tips for Over-Communicating Clarity</h4>
				<p>There are a few simple tools that serve to communicate an organization's sense of clarity:</p>

				<div className={'list-decimal px-2 py-1'}>
					<li className={'pt-2 survey-font'}>
						<b className={'pl-2'}>Repeat, repeat, repeat:</b>
						<div className={'pl-6'}>
							Repetition is one of the most powerful and underutilized characteristics of good
							communication from a leadership team. However, too many leaders shy away from it because
							they get bored saying the same messages again and again, and because they want to avoid
							criticism for appearing redundant and potentially condescending. Ironically, most leaders
							complain that employees do not remember or act upon the messages they deliver, and yet they
							are reluctant to repeat that message.
						</div>
					</li>
					<li className={'pt-2 survey-font'}>
						<b className={'pl-2'}>Keep it simple:</b>
						<div className={'pl-6'}>
							Another key to effective communication is the ability to avoid overcomplicating key
							messages. Years of education and training make most leaders feel compelled to use all of
							their intellectual capabilities when speaking or writing. While this is certainly
							understandable, it only serves to confuse employees. What employees need from leaders is
							clear, uncomplicated messages about where the organization is headed and how they can
							contribute to getting there.
						</div>
					</li>
					<li className={'pt-2 survey-font'}>
						<b className={'pl-2'}>Use multiple mediums:</b>
						<div className={'pl-6'}>
							All too often, executives feel comfortable using just one particular form of communication
							to convey messages to the rest of the organization. Some leaders prefer live communication,
							either to large groups or in more intimate settings. Others feel more comfortable writing
							messages, through e-mail or Intranet postings. Still others prefer to communicate primarily
							to their direct reports, who are then charged with relaying messages to employees deeper in
							the organization.
						</div>
						<div className={'pl-6 pt-4'}>
							Which of these methods is best? All of them. For example, live communication provides
							opportunities for meaningful interaction and emotional context; e-mail allows for more
							extensive information to be received and maintained for later review; and relayed
							communication from an employee's manager creates an opportunity for in depth discussion
							about how the message will impact people's daily jobs.
						</div>
					</li>
					<li className={'pt-1 survey-font'}>
						<b className={'pl-2'}>Cascade key messages:</b>
						<div className={'pl-6'}>
							At the conclusion of leadership team meetings, ask team members to agree on a common set of
							messages that they will communicate to their respective staffs within a set period of time,
							usually between 24 and 48 hours after the end of the meeting. Then, ask members of their
							staffs to communicate those same messages to their staffs, and so on until they have
							cascaded throughout much or all of the organization.
						</div>
					</li>
				</div>
			</div>
		),
		high: "Your score in this area is high, which indicates that communication doesn't seem to be an issue in your organization. Employees at all levels know what the organization stands for, where the organization is heading and the roles they play. Though you scored high in over-communication, it is recommended that you consider one or more of the suggestions below to maintain current levels.",
		medium: 'Your score in this area is medium, which indicates that communication may be an issue in your organization. Leaders need to ensure that employees know what the organization stands for, where the organization is headed and the roles they play. While you scored medium in over-communication, it is recommended that you consider using many of the suggestions below.',
		low: 'Your score in this area is low, which indicates that communication is lacking and your employees are in the dark about many aspects of the organization. Until this is addressed, it will be virtually impossible for employees to fully engage in their work. Because you scored low in over-communication, it is critical that you consider using the suggestions below.',
	},
	4: {
		header: 'Discipline 4: Reinforce Clarity',
		body: (
			<div className={'survey-font'}>
				<h4 className={'text-red-700 ttg-text-lg pb-1'}>Tips for Reinforcing Clarity</h4>
				<p>
					There are a few primary systems that serve to institutionalize an organization's sense of clarity:
				</p>

				<div className={'list-decimal pt-1 px-2'}>
					<li className={'pt-2 survey-font'}>
						<b className={'pl-2'}>Hiring:</b>
						<div className={'pl-6'}>
							Look beyond experience in job candidates to ensure a fit with the organization's values and
							culture. Ask consistent, behavioral questions of interviewees and probe for evidence that
							they will thrive in the organization over time. Debrief collectively with all involved in
							the hiring process to determine if any additional interviewing needs to occur.
						</div>
					</li>
					<li className={'pt-2 survey-font'}>
						<b className={'pl-2'}>Managing performance:</b>
						<div className={'pl-6'}>
							An employee's performance needs to be measured beyond just the "feeling" of their direct
							manager. When evaluating performance, use a systematic, non-bureaucratic method that reviews
							not only progress against goals, but behavioral attributes as well. Avoid the temptation to
							employ a generic, off-the-shelf program. Spend time developing a model that is specific and
							customized to reinforce the organization's clarity.
						</div>
					</li>
					<li className={'pt-2 survey-font'}>
						<b className={'pl-2'}>Meetings:</b>
						<div className={'pl-6'}>
							At first glance, meetings may not seem like a human system. In reality, meetings are one of
							the most important human systems that exist within an organization. Without effective
							meetings, an organization will waste time, make poor decisions and leave employees
							frustrated and resolved to work in silos. Put in place a meeting structure that delineates
							between tactical and strategic issues so employees can properly focus. Avoid the desire to
							fit everything into one big meeting.
						</div>
					</li>
					<li className={'pt-2 survey-font'}>
						<b className={'pl-2'}>Rewarding:</b>
						<div className={'pl-6'}>
							Rewards and recognition should be simple and tied to organizational clarity. Reward
							employees for behaving according to your organization's core values. Hold up those stellar
							employees as examples that others should strive to emulate. Be cautious of rewarding only
							based on financial metrics, as this may send an inconsistent message regarding the expected
							behaviors.
						</div>
					</li>
					<li className={'pt-2 survey-font'}>
						<b className={'pl-2'}>Dismissing:</b>
						<div className={'pl-6'}>
							While firing employees is never enjoyable, sometimes it's necessary to sustain the culture
							of an organization. To avoid doing this capriciously, engage in clear and consistent
							conversations with problem employees. When an organization retains an employee who does not
							fit, it sends a clear message to the rest of the staff that problem behavior is acceptable.
							This ultimately erodes trust and credibility in leaders. Ironically, the clearer leaders are
							about expected behaviors in the hiring process, the more likely the "mis-fits" will opt out
							on their own.
						</div>
					</li>
				</div>
				<p className={'pt-4 survey-font'}>
					For assistance in running more effective meetings, see the book <i>Death by Meeting</i>:{' '}
					<a className={'text-red-700'} href='http://www.tablegroup.com/dbm/'>
						www.tablegroup.com/dbm
					</a>
					.
				</p>
			</div>
		),
		high: 'Your score in this area is high, which indicates that your organization has an appropriate level of structure to reinforce its culture, direction and priorities. Though you scored high in reinforcing clarity, it is recommended that you regularly revisit the systems below to maintain current levels.',
		medium: 'Your score in this area is medium, which indicates that your organization is not doing all it should to reinforce its culture, direction and priorities. Addressing this will increase the likelihood that the organization maintains its health over the long-term. While you scored medium in reinforcing clarity, it is recommended that you consider further developing the systems below.',
		low: 'Your score in this area is low, which indicates that your organization is failing to use tools and systems to reinforce its culture, direction and priorities. Addressing this is critical to ensure the long-term health and success of the organization. Because you scored low in reinforcing clarity, it is critical that you consider developing the five primary systems below.',
	},
};
