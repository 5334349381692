import React from 'react';
import { AppIcon } from '../App';
import UtilityService from '../../services/UtilityService';

const EditableInput = React.forwardRef(({ ...props }, ref) => {
	const randomId = props.id ? props.id : UtilityService.generateRandomString(10);

	const handleKeyDown = () => {
		const el = document.getElementById(randomId);
		el.style.height = `${Math.max(el.scrollHeight, 58)}px`;
	};

	React.useEffect(() => {
		handleKeyDown();
	}, []);

	const { errors, name } = props;

	const hasError = errors && errors[name] && errors[name].message;

	return (
		<div className={'flex flex-1 flex-col'}>
			<div className={'editable-input-container'}>
				<textarea
					id={randomId}
					ref={ref}
					className={`${hasError ? 'error' : ''}`}
					onKeyDown={handleKeyDown}
					{...props}
				/>
				<div
					id={'editable-input-icon'}
					className='flex items-center justify-center absolute w-8 h-8 bg-white rounded-full cursor-pointer'
					style={{ top: '10px', right: '10px' }}
					onClick={() => {
						document.getElementById(randomId).focus();
					}}
				>
					<AppIcon icon={'pencil-fill'} />
				</div>
			</div>
			{hasError && <span className={'ttg-text-sm text-red-500'}>{errors[name].message}</span>}
		</div>
	);
});

export default EditableInput;
