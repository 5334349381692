const CustomerCompanySizes = [
	{ label: '0 - 50 Employees', value: '0-50_EMPLOYEES' },
	{ label: '51 - 500 Employees', value: '51-500_EMPLOYEES' },
	{ label: '501 - 1,000 Employees', value: '501-1000_EMPLOYEES' },
	{ label: '1,001 - 2,500 Employees', value: '1001-2500_EMPLOYEES' },
	{ label: '2,501 - 5,000 Employees', value: '2501-5000_EMPLOYEES' },
	{ label: '5,001 - 10,000 Employees', value: '5001-10000_EMPLOYEES' },
	{ label: '10,001+ Employees', value: '10001_EMPLOYEES' },
];

export default CustomerCompanySizes;
