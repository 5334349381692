import React from 'react';
import { AppCalendar, AppDropdown, AppIcon, AppLabel, AppMonthNavigator, AppWidget } from './index';
import UtilityService from '../../services/UtilityService';
import dayjs from 'dayjs';
import timezones from '../../constants/TimeZones';

const Datepicker = ({ value, label, onChange, min, timezone, onTimezoneChange, timeSensitive = false }) => {
	const [date, setDate] = React.useState(dayjs());

	const _setDate = (v) => {
		setDate(dayjs(v));
	};

	React.useEffect(() => {
		_setDate(value);
	}, [value]);

	const renderCalendar = () => {
		return (
			<div className={'w-64 px-4 pt-4'}>
				<AppMonthNavigator date={date} onChange={setDate} />
				<AppCalendar
					date={date}
					value={value}
					onChange={onChange}
					min={min}
					timeSensitive={timeSensitive}
					timeZone={timezone}
				/>
				<AppDropdown options={timezones} value={timezone} onChange={onTimezoneChange} />
			</div>
		);
	};

	return (
		<div className={'app-datepicker'}>
			<AppLabel label={label} />

			<AppWidget
				id={'date'}
				labelComponent={
					<div className={'app-datepicker-input'}>
						<p className={'date'}>{UtilityService.formatDate(value, 'ddd MMM DD YYYY, hh:mm A', true)}</p>
						<AppIcon icon={'calendar-line'} className={'text-lg mr-1'} />
					</div>
				}
			>
				{renderCalendar()}
			</AppWidget>
		</div>
	);
};

export default Datepicker;
