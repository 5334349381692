export enum ProductTypeEnum {
  Certification = 'certification',
  CertificationForSomeoneElse = 'certification-someone-else',
  AlumniIntensive = 'alumni_intensive',
  Physical = 'physical',
  Assessment = 'assessment',
  Video = 'video',
  WgAssessment = 'wg_assessment',
  WgAssessmentStudent = 'wg_assessment_student',
  AppGuide = 'team_application_guide',
  Workshop = 'workshop',
  WGBook = 'wg_book',
  MeetingAdvantage = 'meeting_advantage',
  SmartHireBundle = 'smart_hire_bundle'
}
