import config from '../constants/config';
import Axios from 'axios';

const PodcastApiService = Axios.create({
	baseURL: `${config.ttg_url}`,
	headers: {
		'Content-Type': 'application/json',
	},
});

export const getPodcasts = (slug) =>
	PodcastApiService.get(`/wp-json/wl/v1/get_podcast_by_slug?slug=${slug}`)
		.then(({ data }) => {
			return data;
		})
		.catch((error) => {
			return error;
		});
