import React from 'react';
import { AppPdfWrapper } from '../../../App';
import CoverPage from './CoverPage';
import Introduction from './Introduction';
import TheModel from './TheModel';
import The5D from './The5D';
import TheRewards from './TheRewards';
import TeamSummary from './TeamSummary';
import TheFiveBehaviors from './TheFiveBehaviors';
import Areas from './Areas';
import Images from '../../../../assets/Images';
import ReportService from '../../../../services/ReportService';
import KeyDifferences from './KeyDifferences';
import TeamCultureQuestionsConflict from './TeamCultureQuestionsConflict';
import TeamCultureQuestionsTrust from './TeamCultureQuestionsTrust';
import TeamCultureQuestionsCommitment from './TeamCultureQuestionsCommitment';
import TeamCultureQuestionsAccountability from './TeamCultureQuestionsAccountability';
import TeamCultureQuestionsResults from './TeamCultureQuestionsResults';
import TeamAverage from './TeamAverage';
import ItemRanking from './ItemRanking';
import TipsExercises from './TipsExercises';
import TipsExercisesIntro from './TipsExercisesIntro';

const ReportV2 = ({ details }) => {
	const strongAreas = React.useMemo(() => {
		if (details.current_team.team_type !== 'team') {
			return { grouped: [] };
		}
		return ReportService.groupAreaQuestions(details, 1);
	}, []);

	const weakAreas = React.useMemo(() => {
		if (details.current_team.team_type !== 'team') {
			return { grouped: [] };
		}
		return ReportService.groupAreaQuestions(details, -1);
	}, []);

	const differentAreas = React.useMemo(() => {
		return ReportService.highestVariation(details);
	}, []);

	const keyDiffPage = React.useMemo(() => {
		if (details.current_team.team_type !== 'team') {
			return 6;
		}
		return strongAreas.grouped.length + weakAreas.grouped.length;
	}, [strongAreas]);

	const TCQTrustPage = React.useMemo(() => {
		if (details.current_team.team_type !== 'team') {
			return 6;
		}
		return 7 + keyDiffPage + (differentAreas.length > 3 ? 2 : 1);
	}, [keyDiffPage]);

	const TCQConflictPage = React.useMemo(() => {
		if (details.current_team.team_type !== 'team') {
			return 6;
		}
		return TCQTrustPage + 1;
	}, [TCQTrustPage]);

	const TCQCommitmentPage = React.useMemo(() => {
		if (details.current_team.team_type !== 'team') {
			return 6;
		}
		return TCQConflictPage + 1;
	}, [TCQConflictPage]);

	const TCQAccountabilityPage = React.useMemo(() => {
		if (details.current_team.team_type !== 'team') {
			return 6;
		}
		return TCQCommitmentPage + 1;
	}, [TCQCommitmentPage]);

	const TCQResultsPage = React.useMemo(() => {
		if (details.current_team.team_type !== 'team') {
			return 6;
		}
		return TCQAccountabilityPage + 1;
	}, [TCQAccountabilityPage]);

	const TeamAverageTrustPage = React.useMemo(() => {
		return TCQResultsPage + 1;
	}, [TCQResultsPage]);

	const TeamAverageConflictPage = React.useMemo(() => {
		return TeamAverageTrustPage + 1;
	}, [TeamAverageTrustPage]);

	const TeamAverageCommitmentPage = React.useMemo(() => {
		return TeamAverageConflictPage + 1;
	}, [TeamAverageConflictPage]);

	const TeamAverageAccountabilityPage = React.useMemo(() => {
		return TeamAverageCommitmentPage + 1;
	}, [TeamAverageCommitmentPage]);

	const TeamAverageResultsPage = React.useMemo(() => {
		return TeamAverageAccountabilityPage + 1;
	}, [TeamAverageAccountabilityPage]);

	const ItemRankingPage = React.useMemo(() => {
		return TeamAverageResultsPage + 1;
	}, [TeamAverageResultsPage]);

	const TipsExercisesIntroPage = React.useMemo(() => {
		return ItemRankingPage + 4;
	}, [ItemRankingPage]);

	const TipsExercisesPage = React.useMemo(() => {
		return TipsExercisesIntroPage + 1;
	}, [TipsExercisesIntroPage]);

	return (
		<AppPdfWrapper className={'AssessmentReport'}>
			<CoverPage details={details} />
			<Introduction details={details} />
			<TheModel details={details} />
			<The5D details={details} />
			<TheRewards details={details} />
			<TeamSummary details={details} />
			<TheFiveBehaviors details={details} />
			{strongAreas.grouped.map((area, index) => (
				<Areas
					details={details}
					questions={area}
					page={index}
					index={index}
					key={index}
					sectionClassName={'StrongestAreas'}
					title={'The Strongest Areas'}
					description={`The questions listed below represent the top ${strongAreas.total} areas where your team scored highest relative to other questions. Understanding and continuing to leverage your team’s strengths is as important as identifying and correcting its weaknesses. Please note that questions with an average score above a 4.50 are areas where your team scored particularly high.`}
					icon={Images.report.checkmark}
				/>
			))}
			{weakAreas.grouped.map((area, index) => (
				<Areas
					details={details}
					questions={area}
					page={strongAreas.grouped.length + index}
					index={index}
					key={index}
					sectionClassName={'WeakestAreas'}
					title={'The Weakest Areas'}
					description={`The questions listed below represent the bottom ${weakAreas.total} areas where your team scored lowest relative to other questions. To improve the cohesiveness of your team, it is critical that you understand and address these areas. Please note that questions with an average score below a 3.00 are areas where your team scored particularly low.`}
					icon={Images.report.close}
				/>
			))}
			{details.current_team.team_type === 'team' && (
				<>
					<KeyDifferences details={details} questions={differentAreas} page={keyDiffPage} />
					<TeamCultureQuestionsTrust details={details} page={TCQTrustPage} />
					<TeamCultureQuestionsConflict details={details} page={TCQConflictPage} />
					<TeamCultureQuestionsCommitment details={details} page={TCQCommitmentPage} />
					<TeamCultureQuestionsAccountability details={details} page={TCQAccountabilityPage} />
					<TeamCultureQuestionsResults details={details} page={TCQResultsPage} />
				</>
			)}
			<TeamAverage details={details} page={TeamAverageTrustPage} type={ReportService.TRUST} />
			<TeamAverage details={details} page={TeamAverageConflictPage} type={ReportService.CONFLICT} />
			<TeamAverage details={details} page={TeamAverageCommitmentPage} type={ReportService.COMMITMENT} />
			<TeamAverage details={details} page={TeamAverageAccountabilityPage} type={ReportService.ACCOUNTABILITY} />
			<TeamAverage details={details} page={TeamAverageResultsPage} type={ReportService.RESULTS} />
			<ItemRanking details={details} page={ItemRankingPage} />
			<TipsExercisesIntro details={details} page={TipsExercisesIntroPage} />
			{ReportService.TipExercisesPages.map((page, index) => (
				<TipsExercises page={TipsExercisesPage + index} {...page} key={index} />
			))}
		</AppPdfWrapper>
	);
};

export default ReportV2;
