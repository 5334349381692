import React, { useState, useEffect, useRef, useCallback } from 'react';
import { AppPdfPage } from '../App';
import Images from '../../assets/Images';
import PodcastPdfHeader from './PodcastPdfHeader';

const PodcastNotes = ({ podcast }) => {
	const containerRef = useRef(null);
	const showNotes = podcast?.content[0];

	const [pages, setPages] = useState([]);

	const pageHeight = 845;

	const measureHeight = (content) => {
		const styledDiv = document.createElement('div');
		styledDiv.innerHTML = content;
		styledDiv.style.visibility = 'hidden';
		styledDiv.style.width = '37rem';
		styledDiv.className = 'ttg-podcast-pdf-page-content';

		document.body.appendChild(styledDiv);

		const height = styledDiv.clientHeight;

		document.body.removeChild(styledDiv);

		return height;
	};

	const splitSectionsIntoPages = useCallback(() => {
		const sections = showNotes.split('\n').filter((section) => section.trim() !== '');

		let currentPage = '';
		const pdfPages = [];

		sections.forEach((section) => {
			const newPageContent = `${currentPage}\n${section}`;
			const sectionHeight = measureHeight(newPageContent);

			if (sectionHeight < pageHeight) {
				currentPage = newPageContent;
			} else {
				pdfPages.push(currentPage.trim());
				currentPage = section;
			}
		});

		pdfPages.push(currentPage.trim());
		setPages(pdfPages);
	}, [showNotes]);

	useEffect(() => {
		if (!showNotes) return;
		splitSectionsIntoPages();
	}, [showNotes, splitSectionsIntoPages]);

	return pages.map((pageContent, index) => (
		<AppPdfPage key={index} page={index + 1} sectionClassName={'ttg-podcast-pdf-page-content'}>
			<div id={'ttg-podcast-pdf'} ref={containerRef}>
				<PodcastPdfHeader title={podcast?.title} className={'PageHeader'} logo={Images.logoWhite} />
				<div className={'PageContent px-16 py-8'}>
					<span>
						{index === 0 && <h1>Episode Summary</h1>}
						<span dangerouslySetInnerHTML={{ __html: pageContent }} />
					</span>
				</div>
			</div>
		</AppPdfPage>
	));
};

export default PodcastNotes;
