import React from 'react';
import { AppButton, AppIcon, AppLabel, AppRadioGroup } from '../../App';
import RouteNames from '../../../router/RouteNames';
import TakeAssessmentErrors from '../../../constants/TakeAssessmentErrors';
import { useForm } from 'react-hook-form';

const Info = ({ nextStep, optIn, setOptIn, details }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm({
		defaultValues: {
			opt_in: optIn,
		},
		reValidateMode: 'onChange',
	});

	const renderInfoOptInLabel = () => (
		<div>
			I’d like to receive helpful content from{' '}
			<a className={'text-rose-500 hover:text-rose-600'} target='_blank' href='https://www.tablegroup.com/'>
				The Table Group
			</a>{' '}
			and{' '}
			<a className={'text-rose-500 hover:text-rose-600'} target='_blank' href='https://www.workinggenius.com/'>
				Working Genius
			</a>
		</div>
	);

	if (details.hasError) {
		return (
			<div
				id={'ttg-take-assessment-error'}
				className={'flex justify-center items-center flex-col mx-auto mt-12'}
				style={{ maxWidth: '550px' }}
			>
				<AppIcon icon={'information-line'} className={'text-7xl text-red-500'} />
				<p
					className={'text-center'}
					dangerouslySetInnerHTML={{
						__html: TakeAssessmentErrors[details.code]({ main_contact: details.main_email }),
					}}
				/>
			</div>
		);
	}

	if (!details.members || details.members.length === 0) {
		return null;
	}

	return (
		<div className={'mx-4'} id={'ttg-take-assessment-info-container'}>
			<h1 className={'ttg-text-2xl font-medium text-gray-900 mt-12 mb-8'}>
				The Five Dysfunctions of a Team Assessment
			</h1>
			<h1 className={'ttg-text-lg font-medium text-gray-900 mb-6'}>
				Welcome to the Team Assessment for the team {details.name}
			</h1>
			<p className={'ttg-text-sm text-gray-800'}>
				Please ensure that you are taking the assessment for the correct team by verifying the team roster
				below:
			</p>
			<ul className='list-disc ml-3'>
				{details.members.map((member, i) => {
					if (!member) {
						return null;
					}
					return (
						<li className={'ttg-text-sm text-gray-800 font-medium'} key={i}>
							{member}
						</li>
					);
				})}
			</ul>
			<h1 className={'ttg-text-lg font-medium text-gray-900 mt-6 mb-4'}>Instructions</h1>
			<p className={'ttg-text-sm text-gray-800'}>
				Use the scale below to indicate how each statement applies to your team. Please use the entire scale to
				represent your most accurate response, and be as honest as possible. Your individual responses will be
				held confidential. They will only be seen by professionals at The Table Group, Inc.
			</p>
			<br />
			<p className={'ttg-text-sm text-gray-800'}>
				While it is important to be thoughtful, don't agonize over each response. The Assessment should not take
				more than 15 minutes.
			</p>

			<h1 className={'text-gray-900 font-medium mt-8 mb-4'}>The rating scale</h1>
			<ul className='list-disc ml-3'>
				<li className={'ttg-text-sm text-gray-800'}>Almost Never</li>
				<li className={'ttg-text-sm text-gray-800'}>Rarely</li>
				<li className={'ttg-text-sm text-gray-800'}>Sometimes</li>
				<li className={'ttg-text-sm text-gray-800'}>Usually</li>
				<li className={'ttg-text-sm text-gray-800'}>Almost Always</li>
			</ul>

			<p className={'ttg-text-sm text-gray-600 pt-4'}>
				In order to participate in this Team Assessment, The Table Group will use your email address and
				submitted responses to generate the team report. This tool guarantees confidentiality. Your responses
				are not shared with the team leader or other members of your team. View our updated{' '}
				<a
					id={'ttg-take-assessment-info-terms'}
					href={RouteNames.TermsService}
					target={'_blank'}
					className={'hover:underline text-rose-500 font-medium'}
				>
					terms of service
				</a>{' '}
				and{' '}
				<a
					id={'ttg-take-assessment-info-privacy'}
					href={RouteNames.Privacy}
					target={'_blank'}
					className={'hover:underline text-rose-500 font-medium'}
				>
					privacy policy
				</a>{' '}
				for more information on processing and deletion of data.
			</p>

			<form
				onSubmit={handleSubmit(nextStep)}
				id={'ttg-take-assessment-info-form'}
				autoComplete='autocomplete_off_hack_xfr4!k'
			>
				<div id={'ttg-assessment-opt-in'} className={'my-4'}>
					<AppLabel label={renderInfoOptInLabel()} hasError={!!errors['opt_in']} />

					<AppRadioGroup
						{...register('opt_in', {
							required: 'is required',
						})}
						name={'opt_in'}
						errors={errors}
						value={optIn}
						options={[
							{
								label: 'Yes',
								value: 1,
							},
							{
								label: 'No',
								value: 0,
							},
						]}
						onChange={(selectedOption) => {
							setOptIn(selectedOption);
							setValue('opt_in', selectedOption);
							clearErrors('opt_in');
						}}
					/>
				</div>

				<AppButton id={'ttg-take-assessment-info-start-button'} text={'Start Assessment'} type={'submit'} />
			</form>
		</div>
	);
};

export default Info;
