import Cookies from 'js-cookie';
import RouteNames, { PublicRouteNames } from '../router/RouteNames';
import timezones from '../constants/TimeZones';
import config from '../constants/config';
import dayjs from 'dayjs';

const emailRegex =
	// eslint-disable-next-line no-control-regex
	/(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

// eslint-disable-next-line no-useless-escape
const phoneNumberRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

// eslint-disable-next-line no-useless-escape
const urlRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;

const zipCodeRegex = /^[a-zA-Z0-9]+(\s?-?){1}[a-zA-Z0-9]+$/;

const isValidEmail = (val) => {
	if (!val) {
		return false;
	}
	return !(!val.match(emailRegex) || val.match(emailRegex)[0] !== val);
};

const formatPrice = (price) => {
	const formattedPrice = price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
	return `$${formattedPrice}`;
};

const formatOrderAddress = (order, type) => {
	if (
		order.order_details[`${type}_city`] &&
		(order.order_details[`${type}_state`] || order.order_details[`${type}_postcode`])
	)
		return `${order.order_details[`${type}_city`]}, ${order.order_details[`${type}_state`]} ${
			order.order_details[`${type}_postcode`]
		}`;

	return `${order.order_details[`${type}_city`]} ${order.order_details[`${type}_state`]} ${
		order.order_details[`${type}_postcode`]
	}`;
};

const scrollToTop = () => {
	window.scrollTo(0, 0);
	window.postMessage({ name: 'onTtgScrollTop', scrollTop: 0 }, window.origin);
};

const getQueryParams = (url, decode = false) => {
	const params = {};
	if (!url) {
		return params;
	}
	const qs = url.split('?')[1];
	if (!qs) {
		return params;
	}
	const pieces = qs.split('&');
	pieces.map((piece) => {
		const tmp = piece.split('=');
		decode ? (params[tmp[0]] = decodeURIComponent(tmp[1])) : (params[tmp[0]] = tmp[1]);
		return params;
	});
	return params;
};

const serializeQueryString = function (obj, prefix) {
	let str = [],
		p;
	for (p in obj) {
		let k = prefix ? prefix + '[' + p + ']' : p,
			v = obj[p];
		if (v !== null && v !== undefined) {
			str.push(
				typeof v === 'object' ? serializeQueryString(v, k) : encodeURIComponent(k) + '=' + encodeURIComponent(v)
			);
		}
	}
	return str.join('&');
};

const getAuthCookie = () => {
	const token = Cookies.get('TTG_USER');
	if (token && token !== 'undefined') {
		return encodeURIComponent(token);
	}
	return null;
};

const getCaptchaToken = () => {
	const token = Cookies.get(config.captchaTokenKey);

	if (token && token !== 'undefined') {
		return encodeURIComponent(token);
	}
	return null;
};

const setAuthCookie = (token) => {
	Cookies.set('TTG_USER', token, { expires: (1 / 24) * 1.5 });
};

const withConverterWrite = (value) => {
	return encodeURIComponent(value);
};

const updateAuthCookieExpiration = () => {
	const token = Cookies.get('TTG_USER');
	if (token && token !== 'undefined') {
		Cookies.withConverter({
			write: UtilityService.withConverterWrite,
		}).set('TTG_USER', token, { expires: (1 / 24) * 1.5 });
	} else {
		Cookies.remove('TTG_USER');
	}
};

const formatNow = (format = 'YYYY') => dayjs().format(format);

const formatDate = (date, format = 'MM/DD/YYYY hh:mm A', utc = false) => {
	const value = dayjs.isDayjs(date) ? date.format('YYYY-MM-DD HH:mm:ss') : date;

	if (utc) {
		return dayjs(value, 'YYYY-MM-DD HH:mm:ss').isValid()
			? dayjs(value, 'YYYY-MM-DD HH:mm:ss').format(format)
			: dayjs(value, 'YYYY-MM-DD HH:mm').format(format);
	}

	return dayjs.utc(value, 'YYYY-MM-DD HH:mm:ss').isValid()
		? dayjs
				.utc(value, 'YYYY-MM-DD HH:mm:ss')
				.tz(window.ttg_timezone || 'America/Los_Angeles')
				.format(format)
		: dayjs
				.utc(value, 'YYYY-MM-DD HH:mm')
				.tz(window.ttg_timezone || 'America/Los_Angeles')
				.format(format);
};

const splitArray = (inputArray, chunk) => {
	return inputArray.reduce((resultArray, item, index) => {
		const chunkIndex = Math.floor(index / chunk);
		if (!resultArray[chunkIndex]) {
			resultArray[chunkIndex] = [];
		}
		resultArray[chunkIndex].push(item);
		return resultArray;
	}, []);
};

const setCookieIfPresent = (location) => {
	const autoLoginPaths = ['client', 'clientlogin', 'clientaccountorders', 'clientvideo'];
	const { search, pathname } = location;
	const { token } = getQueryParams(search);
	const strippedDownPath = pathname?.replace(/\//g, '') ?? '';
	if (autoLoginPaths.indexOf(strippedDownPath) > -1 && token) {
		setAuthCookie(token.split('@')[0]);
		return true;
	}
	return false;
};

const findIntersection = (array1, array2) => {
	return array1.filter(function (n) {
		return array2.indexOf(n) !== -1;
	});
};

const areObjectsIdentical = (object1, object2) => {
	return JSON.stringify(object1) === JSON.stringify(object2);
};

const downloadPdfFromBuffer = (arrayBuffer, fileName) => {
	const buffer = new Uint8Array(arrayBuffer);
	UtilityService.downloadFileFromBuffer(buffer, fileName, 'application/pdf');
};

const downloadFileFromBuffer = (buffer, fileName, type) => {
	const a = document.createElement('a');
	document.body.appendChild(a);
	a.style = 'display: none';
	const url = window.URL.createObjectURL(new Blob([buffer], { type }));
	a.href = url;
	a.download = fileName;
	a.click();
	window.URL.revokeObjectURL(url);
};

const convertBase64ToBuffer = (base64) => {
	const binary_string = window.atob(base64);
	const len = binary_string.length;
	const bytes = new Uint8Array(len);
	for (let i = 0; i < len; i++) {
		bytes[i] = binary_string.charCodeAt(i);
	}
	return bytes.buffer;
};

const getLocalTimezone = () => {
	return dayjs.tz.guess();
};

const isChildOf = (ref, event) => {
	try {
		return ref.current.contains(event.target);
	} catch (e) {
		return false;
	}
};

const onTabVisibilityChange = () => {
	if (document.visibilityState === 'visible') {
		const isPublicRoute =
			PublicRouteNames.findIndex((r) => {
				return r.replace(/\//g, '') === window.location.pathname.replace(/\//g, '');
			}) > -1;
		if (!UtilityService.getAuthCookie() && !isPublicRoute) {
			window.open(RouteNames.Login, '_self');
		} else {
			UtilityService.updateAuthCookieExpiration();
		}
	}
};

const setContentMarginTop = (headerClass, contentClass) => {
	const header = document.getElementsByClassName(headerClass)[0];
	const content = document.getElementsByClassName(contentClass)[0];
	content.style.marginTop = `${header.clientHeight}px`;
};

const getTimezoneByValue = (value) => {
	return timezones.find((t) => t.value === value);
};

const generateRandomString = (length) => {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

const getWeeksInRange = (date) => {
	const value = dayjs.isDayjs(date) ? date.format() : date;
	let startDay = dayjs(value).startOf('month').startOf('week');
	const endDay = dayjs(value).endOf('month').endOf('week');
	const calendar = [];

	while (startDay.isBefore(endDay)) {
		const weekStart = startDay;
		calendar.push({
			week: weekStart.week(),
			days: Array(7)
				.fill(0)
				.map((_, i) => weekStart.add(i, 'day')),
		});
		startDay = startDay.add(1, 'week');
	}

	return calendar;
};

const prepareNumberOptions = (min, max) => {
	const options = [];
	for (let i = min; i <= max; i++) {
		options.push({ value: i, label: i });
	}
	return options;
};

const prepareMinutesOptions = () => {
	return [
		{ value: 0, label: '00' },
		{ value: 10, label: '10' },
		{ value: 20, label: '20' },
		{ value: 30, label: '30' },
		{ value: 40, label: '40' },
		{ value: 50, label: '50' },
	];
};

const getTimeOptions = (date, constants, timeZone) => {
	const value = dayjs.isDayjs(date) ? date.format() : date;
	const currentHour = Number(dayjs().tz(timeZone.value).format('hh'));
	const selectedHour = Number(dayjs(value).format('hh'));
	const currentMinutes = Number(dayjs().tz(timeZone.value).format('mm'));

	const meridiem = dayjs().tz(timeZone.value).format('A');

	let minutes = [],
		hours = UtilityService.prepareNumberOptions(1, 11);

	if (meridiem === 'PM' && dayjs(value).format('A') === 'AM') {
		return {
			hours: [],
			minutes: [],
		};
	}

	if (meridiem === 'AM' && dayjs(value).format('A') === 'PM') return { ...constants };

	if (currentHour === 12) {
		hours = currentMinutes < 50 ? UtilityService.prepareNumberOptions() : hours;
	} else {
		hours =
			currentMinutes >= 50
				? UtilityService.prepareNumberOptions(currentHour + 1, 11)
				: UtilityService.prepareNumberOptions(currentHour, 11);
	}

	if (selectedHour > currentHour) {
		minutes = UtilityService.prepareMinutesOptions();
	}

	if (selectedHour === currentHour && currentMinutes < 50)
		UtilityService.prepareMinutesOptions().forEach((el) => {
			if (el.value > currentMinutes) minutes.push(el);
		});

	return {
		hours: hours,
		minutes: minutes,
	};
};

const checkReminder = (reminders) => {
	let check = true;
	reminders.forEach((el) => {
		const selectedDate = Number(dayjs(el.date).format('YYYYMMDDHHmm'));
		const currentDate = Number(dayjs().tz(el.timezone.value).format('YYYYMMDDHHmm'));
		if (selectedDate <= currentDate) check = false;
	});
	return check;
};

const updateStateMethod = (setState, key, value) => {
	setState((d) => ({
		...d,
		[key]: value,
	}));
};

const sortByProp = (array, prop, dir = 'asc') => {
	return [...array].sort((a, b) => {
		if (typeof a[prop] === 'string') {
			if (dir === 'asc') {
				return a[prop] > b[prop] ? 1 : -1;
			} else {
				return a[prop] > b[prop] ? -1 : 1;
			}
		}
		return a[prop] - b[prop];
	});
};

const groupByProp = (array, prop) => {
	return [...array].reduce((groups, item) => {
		const group = groups[item[prop]] || [];
		group.push(item);
		groups[item[prop]] = group;
		return groups;
	}, {});
};

const prepareRedirectionPath = (path) => {
	if (path[0] === '/') {
		return path;
	}
	return `/${path}`;
};

const getTimezoneAbbv = (timezone) => {
	return dayjs().tz(timezone).format('z');
};

const padNumber = (value, pad) => {
	return value > 9 ? value : `${pad}${value}`;
};

const confirmPageLeave = (e) => {
	e.returnValue = 'Changes that you made may not be saved.';
	return false;
};

const clone = (item) => {
	if (!item) {
		return item;
	} // null, undefined values check

	var types = [Number, String, Boolean],
		result;

	// normalizing primitives if someone did new String('aaa'), or new Number('444');
	types.forEach(function (type) {
		if (item instanceof type) {
			result = type(item);
		}
	});

	if (typeof result == 'undefined') {
		if (Object.prototype.toString.call(item) === '[object Array]') {
			result = [];
			item.forEach(function (child, index) {
				result[index] = clone(child);
			});
		} else if (typeof item == 'object') {
			// testing that this is DOM
			if (!item.prototype) {
				// check that this is a literal
				if (item instanceof Date) {
					result = new Date(item);
				} else {
					// it is an object literal
					result = {};
					for (var i in item) {
						result[i] = clone(item[i]);
					}
				}
			} else {
				result = item;
			}
		} else {
			result = item;
		}
	}

	return result;
};

const UtilityService = {
	emailRegex,
	phoneNumberRegex,
	passwordRegex,
	urlRegex,
	zipCodeRegex,
	formatPrice,
	scrollToTop,
	getQueryParams,
	serializeQueryString,
	getAuthCookie,
	getCaptchaToken,
	setAuthCookie,
	updateAuthCookieExpiration,
	formatDate,
	formatNow,
	splitArray,
	setCookieIfPresent,
	findIntersection,
	areObjectsIdentical,
	downloadPdfFromBuffer,
	downloadFileFromBuffer,
	convertBase64ToBuffer,
	getLocalTimezone,
	isChildOf,
	onTabVisibilityChange,
	withConverterWrite,
	setContentMarginTop,
	getTimezoneByValue,
	generateRandomString,
	getWeeksInRange,
	prepareNumberOptions,
	prepareMinutesOptions,
	getTimeOptions,
	checkReminder,
	updateStateMethod,
	sortByProp,
	groupByProp,
	prepareRedirectionPath,
	getTimezoneAbbv,
	padNumber,
	confirmPageLeave,
	clone,
	isValidEmail,
	formatOrderAddress,
};

export default UtilityService;
