import React from 'react';
import { useLocation } from 'react-router-dom';
import UtilityService from '../../services/UtilityService';
import ProgressReportV1 from '../../components/Assessments/ProgressReport/V1';
import ProgressReportV2 from '../../components/Assessments/ProgressReport/V2';
import AssessmentService from '../../services/AssessmentService';
import { useAssessments } from '../../providers/AssessmentProvider';

const ProgressReport = () => {
	const location = useLocation();
	const { reportData, loading, setLoading, setReportData } = useAssessments();

	const _getDetails = React.useCallback(() => {
		const filters = UtilityService.getQueryParams(location.search);
		setLoading(true);
		AssessmentService.getProgressReportData(filters)
			.then((response) => {
				setReportData(response);
			})
			.finally(() => setLoading(false));
	}, [location.search]);

	React.useEffect(() => {
		_getDetails();
	}, [_getDetails]);

	if (loading || !reportData) {
		return <p>loading</p>;
	}

	if (reportData && reportData.parent.current_team.version === 1) {
		return <ProgressReportV1 details={reportData} />;
	}

	return <ProgressReportV2 details={reportData} />;
};

export default ProgressReport;
