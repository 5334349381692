import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import UtilityService from '../../services/UtilityService';
import { Helmet } from 'react-helmet';
import * as PodcastService from '../../services/PodcastService';
import PodcastNotes from '../../components/Podcast/PodcastNotes';
import { AppPdfWrapper } from '../../components/App';

const PodcastPdf = () => {
	const [podcast, setPodcast] = useState({});
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const location = useLocation();
	const { slug } = UtilityService.getQueryParams(location.search);

	useEffect(() => {
		PodcastService.getPodcasts(slug)
			.then((response) => {
				const selectedPodcast = response.find((item) => item.slug === slug);
				setPodcast(selectedPodcast);
			})
			.catch(({ message }) => {
				console.error('Error getting podcasts:', message);
				setError(message);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [slug]);

	const renderContent = () => {
		if (loading || error) {
			return null;
		}

		return <PodcastNotes podcast={podcast} />;
	};

	return (
		<AppPdfWrapper className={'ttg-podcast-pdf'}>
			<Helmet>
				<meta charSet='UTF-8' />
				<meta name='viewport' content='width=device-width, initial-scale=1.0' />
				<meta httpEquiv='X-UA-Compatible' content='ie=edge' />
				<title>The Table Group Podcast Pdf</title>
			</Helmet>
			{renderContent()}
		</AppPdfWrapper>
	);
};

export default PodcastPdf;
