import React from 'react';
import UtilityService from '../../../services/UtilityService';
import { AppIcon, AppTooltip } from '../../App';
import { Link } from 'react-router-dom';
import RouteNames from '../../../router/RouteNames';

const HeaderTexts = ({ details }) => {
	const texts = React.useMemo(() => {
		const _values = [];
		if (details.assessment_data?.company_name) {
			_values.push({
				id: 'ttg-assessment-details-company-name',
				icon: 'building-line',
				value: details.assessment_data?.company_name,
				tooltip: 'Company name',
			});
		}
		if (!details.isScheduled && details.assessment_data.sent) {
			_values.push({
				id: 'ttg-assessment-details-sent-date',
				icon: 'calendar-line',
				value: `Sent On ${UtilityService.formatDate(details.assessment_data.sent, 'MMMM DD YYYY, hh:mm A')}`,
			});
		} else {
			_values.push({
				id: 'ttg-assessment-details-creation-date',
				icon: 'calendar-line',
				value: `Created On ${UtilityService.formatDate(
					details.assessment_data.created,
					'MMMM DD YYYY, hh:mm A'
				)}`,
			});
		}

		if (details.isTeamAssessment) {
			_values.push({
				id: 'ttg-assessment-details-main-contact',
				icon: 'user-line',
				value: `Main Contact: ${details.assessment_data.main_email}`,
				tooltip: details.assessment_data.do_main_contact_updates
					? `${details.assessment_data.main_email} is the main contact for this team and will receive status updates as each member completes the assessment.`
					: `${details.assessment_data.main_email} is the main contact for this team but will not receive status updates as each member completes the assessment.`,
			});
			if (details.assessment_data.cc_email_text && details.assessment_data.do_main_contact_updates) {
				const emails = details.assessment_data.cc_email.split(',').join(', ');
				const txt = `Additional Contacts: ${emails}`;
				_values.push({
					id: 'ttg-assessment-details-additional-contact',
					icon: 'team-line',
					value: txt.length > 50 ? `${txt.substring(0, 50)}...` : txt,
					tooltip: `${details.assessment_data.cc_email_text}`,
				});
			}
		}

		_values.push({
			id: 'ttg-assessment-details-status',
			icon: 'focus-line',
			value: `Status: ${details.assessment_data.status}`,
		});

		return _values;
	}, [details]);

	const renderCounterItem = (icon, label, counterProp) => {
		if (!details.counts) {
			return null;
		}
		return (
			<div className={'flex flex-row items-center'} id={`ttg-assessment-count-${counterProp}`}>
				<AppIcon icon={icon} className={'text-gray-600'} />
				<div className={'flex flex-1 items-center flex-row ml-1'}>
					<p className={'ttg-text-sm flex flex-1 font-medium text-gray-800'}>{label}</p>
					<p className={'ttg-text-sm font-medium text-gray-800 w-8 text-center'}>
						{details.counts[counterProp]}
					</p>
				</div>
			</div>
		);
	};

	return (
		<>
			{details.isTeamAssessment && (
				<div className='flex gap-4 mx-4 flex-col md:flex-row mt-4'>
					<div className='flex flex-col md:w-3/4'>
						{!!details.retest_of && !!details.retest_of.name && (
							<div
								id={'ttg-assessment-details-retest-of'}
								className='flex flex-1 flex-col md:flex-row mt-2'
							>
								<p className={'ttg-text-sm text-gray-800'}>
									<span>This team is a retest of</span>&nbsp;
									<Link
										className={'font-medium hover:text-rose-500 underline'}
										to={`${RouteNames.AssessmentDetails}?id=${details.retest_of.team_id}`}
									>
										{details.retest_of.name}
									</Link>
									&nbsp;<span>assessment.</span>
								</p>
							</div>
						)}
						{!!details.retest_from && !!details.retest_from.name && (
							<div
								id={'ttg-assessment-details-retest-from'}
								className='flex flex-1 flex-col md:flex-row mt-2'
							>
								<p className={'ttg-text-sm text-gray-800'}>
									<span>This team has been retested in the</span>&nbsp;
									<Link
										className={'font-medium hover:text-rose-500 underline'}
										to={`${RouteNames.AssessmentDetails}?id=${details.retest_from.team_id}`}
									>
										{details.retest_from.name}
									</Link>
									&nbsp;<span>assessment.</span>
								</p>
							</div>
						)}
					</div>
					{!!details.counts?.avgCompletionTime && (
						<div className='flex flex-col md:w-1/4 justify-end'>
							<p className={'ttg-text-sm md:text-center text-gray-800'}>
								Avg Completion Time: {details.counts.avgCompletionText}
							</p>
						</div>
					)}
				</div>
			)}
			<div className='flex gap-4 mx-4 flex-col-reverse md:flex-row mt-1'>
				<div
					className={`flex flex-col shadow-sm bg-white p-4 rounded-sm ${
						details.isTeamAssessment ? 'md:w-3/4' : 'w-full'
					}`}
				>
					<div
						className={`grid ${
							details.isTeamAssessment ? 'md:grid-cols-2' : 'md:grid-cols-3'
						}  gap-x-4 gap-y-1`}
					>
						{texts.map((text, idx) => (
							<div className={'flex items-center'} key={idx}>
								<div
									className={`flex items-center ${text.tooltip ? 'cursor-pointer' : ''}`}
									data-for={`ttg-assessment-header-texts-${idx}`}
									data-tip
								>
									<AppIcon className={'text-gray-800'} icon={text.icon} />
									<p className={'ttg-text-sm text-gray-800 ml-2'} id={text.id}>
										{text.value}
									</p>
								</div>
								{!!text.tooltip && (
									<AppTooltip id={`ttg-assessment-header-texts-${idx}`} placement={'right'}>
										<p dangerouslySetInnerHTML={{ __html: text.tooltip }}></p>
									</AppTooltip>
								)}
							</div>
						))}
					</div>
				</div>
				{details.isTeamAssessment && (
					<div className='flex flex-col shadow-sm bg-white p-4 rounded-sm md:w-1/4 justify-between'>
						{renderCounterItem('checkbox-circle-line', 'Completed', 'complete')}
						{renderCounterItem('loader-4-line', 'In Process', 'inProgress')}
						{renderCounterItem('mail-open-line', 'Email Read', 'emailsRead')}
					</div>
				)}
			</div>
		</>
	);
};

export default HeaderTexts;
