import React from 'react';
import { AppIcon } from './index';

const Loading = ({ height = 'h-screen', size = 'text-4xl', ...props }) => {
	return (
		<div className={`app-loading-spinner ${height}`} {...props}>
			<AppIcon className={`animate-spin text-primary ${size}`} icon={'loader-4-line'} />
		</div>
	);
};

export default Loading;
