import React from 'react';
import { AppNotice } from '../../../App';

const IndividualSetupNotice = () => {
	return (
		<AppNotice
			containerClass={'ttg-assessment-section flex-col'}
			title={'Note'}
			description={
				'The individual assessment is designed for single users to assess his or her perception of the team’s dynamics. This is not a team-based process. '
			}
			actions={[
				{
					id: 'dismiss',
					text: 'Dismiss',
					dismiss: true,
				},
			]}
		/>
	);
};

export default IndividualSetupNotice;
