import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import RouteNames from '../../router/RouteNames';

const AutoDownload = ({}) => {
	const { id } = useParams();

	const navigate = useNavigate();

	React.useEffect(() => {
		if (id) {
			navigate(`${RouteNames.OrderDetails}?id=${id}&download=1`, { replace: true });
		} else {
			navigate(RouteNames.Orders, { replace: true });
		}
	}, []);

	return null;
};

export default AutoDownload;
