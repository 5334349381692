import React from 'react';
import { AppPdfPage } from '../../../App';
import Images from '../../../../assets/Images';

const TipsExercises = ({ page, title, pagination, description, tips, className }) => {
	return (
		<AppPdfPage page={page} sectionClassName={`TipsExercises ${className}`}>
			<div className='TipsExercises__layout'>
				<div className='TipsExercises__heading'>
					<div className='PageHeading'>
						<div className='PageHeading__title' dangerouslySetInnerHTML={{ __html: title }} />
						<div className='PageHeading__pagination'>{pagination}</div>
					</div>
				</div>
				<div className='TipsExercises__content'>
					<div className='TipsExercises__summary' dangerouslySetInnerHTML={{ __html: description }} />

					<div className='TipsExercises__tips'>
						<div className='TipsExercises__tipsTitle'>Tips & Exercises</div>

						<ul className='TipsExercises__tipList'>
							{tips.map((tip, index) => (
								<li className='TipsExercises__tip' key={index}>
									<div className='TipsExercises__tipLayout'>
										<div className='TipsExercises__tipBullet'>
											<img
												className='TipsExercises__tipBulletImage'
												src={Images.report.emblem}
												alt={''}
											/>
										</div>

										<div className='TipsExercises__tipContent'>
											<div className='TipsExercises__tipTitle'>
												{tip.title}
												{!!tip.titleUrl && (
													<a
														className='TipsExercises__tipTitleLink'
														target='_blank'
														href={tip.titleUrl}
														dangerouslySetInnerHTML={{ __html: tip.titleUrlLabel }}
													/>
												)}
											</div>
											<div
												className='TipsExercises__tipText'
												dangerouslySetInnerHTML={{ __html: tip.content }}
											/>
											{!!tip.extraUrl && (
												<div className='TipsExercises__tipLinkContainer'>
													Additional content:{' '}
													<a
														className='TipsExercises__tipLink'
														href={tip.extraUrl}
														dangerouslySetInnerHTML={{ __html: tip.extraUrlLabel }}
													/>
												</div>
											)}
										</div>
									</div>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</AppPdfPage>
	);
};

export default TipsExercises;
