import React from 'react';
import { AppPdfPage } from '../../../App';
import ReportService from '../../../../services/ReportService';
import UtilityService from '../../../../services/UtilityService';

const TeamAverage = ({ details, page, type }) => {
	const childCompletedAt = React.useMemo(() => {
		return UtilityService.formatDate(details.child.current_team.completed, 'MM/DD/YY');
	}, [details]);

	const parentCompletedAt = React.useMemo(() => {
		return UtilityService.formatDate(details.parent.current_team.completed, 'MM/DD/YY');
	}, [details]);

	const calculatePercentage = (value) => {
		value = value - 1;
		if (value === 0) {
			return 0.5;
		}
		return (value / 4) * 100;
	};

	const questions = React.useMemo(() => {
		return details.child.questions
			.filter(({ sort }) => details.child.questions_categories[type].indexOf(sort) > -1)
			.map((q) => {
				return {
					...q,
					score: {
						child: details.child.raw_scores_average[q.sort - 1],
						parent: details.parent.raw_scores_average[q.sort - 1],
					},
					level: {
						child: ReportService.getLevelByValue(details.child.raw_scores_average[q.sort - 1]),
						parent: ReportService.getLevelByValue(details.parent.raw_scores_average[q.sort - 1]),
					},
					percentage: {
						child: `${calculatePercentage(details.child.raw_scores_average[q.sort - 1])}%`,
						parent: `${calculatePercentage(details.parent.raw_scores_average[q.sort - 1])}%`,
					},
				};
			});
	}, [details]);

	return (
		<AppPdfPage page={page} sectionClassName={'Page'}>
			<div className='DysfunctionAverage__layout'>
				<div className='DysfunctionAverage__heading'>
					<div className='PageHeading'>
						<div className='PageHeading__title'>{type}</div>
					</div>
				</div>
				<div className='DysfunctionAverage__content'>
					<div className='DysfunctionAverage__questionReview'>
						{questions.map((question) => (
							<div
								className='AreaQuestion AreaQuestion--condensed AreaQuestion--small'
								key={question.sort}
							>
								<div className='AreaQuestion__number'>
									{UtilityService.padNumber(question.sort, '0')}.
								</div>
								<div className='AreaQuestion__content'>
									<div
										className='AreaQuestion__label'
										dangerouslySetInnerHTML={{ __html: question.question }}
									/>

									<div className='AreaQuestion__progressBar'>
										<div className='ProgressBar'>
											<div className='ProgressBar__label'>
												{childCompletedAt}{' '}
												<span className='ProgressBar__label--score'>
													{question.score.child.toFixed(2)}
												</span>
											</div>
											<svg className='ProgressBar__meter'>
												<rect className='ProgressBar__meterBar'></rect>
												<rect
													className={`ProgressBar__meterFill ProgressBar--${question.level.child}`}
													width={question.percentage.child}
												/>
											</svg>
										</div>

										<div className='ProgressBar'>
											<div className='ProgressBar__label'>
												{parentCompletedAt}{' '}
												<span className='ProgressBar__label--score'>
													{question.score.parent.toFixed(2)}
												</span>
											</div>
											<svg className='ProgressBar__meter ParentMeter'>
												<rect className='ProgressBar__meterBar'></rect>
												<rect
													className={`ProgressBar__meterFill ProgressBar--${question.level.parent}`}
													width={question.percentage.parent}
												/>
											</svg>
										</div>
									</div>
								</div>
							</div>
						))}

						<div className='DysfunctionAverage__summaryRow'>
							<div className='ProgressScale ProgressScale--withRightLabel'>
								<div className='ProgressScale__mark'>
									<div className='ProgressScale__markLabel MarkLabel'>
										<div className='MarkLabel__number'>1</div>
										<div className='MarkLabel__text'>Almost Never</div>
									</div>
								</div>
								<div className='ProgressScale__mark'>
									<div className='ProgressScale__markLabel MarkLabel'>
										<div className='MarkLabel__number'>2</div>
										<div className='MarkLabel__text'>Rarely</div>
									</div>
								</div>
								<div className='ProgressScale__mark'>
									<div className='ProgressScale__markLabel MarkLabel'>
										<div className='MarkLabel__number'>3</div>
										<div className='MarkLabel__text'>Sometimes</div>
									</div>
								</div>
								<div className='ProgressScale__mark'>
									<div className='ProgressScale__markLabel MarkLabel'>
										<div className='MarkLabel__number'>4</div>
										<div className='MarkLabel__text'>Usually</div>
									</div>
								</div>
								<div className='ProgressScale__mark'>
									<div className='ProgressScale__markLabel MarkLabel'>
										<div className='MarkLabel__number'>5</div>
										<div className='MarkLabel__text'>Almost Always</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</AppPdfPage>
	);
};

export default TeamAverage;
