import React from 'react';
import { useLocation } from 'react-router-dom';
import UtilityService from '../../services/UtilityService';
import { AppLoading, AppPdfPage, AppPdfWrapper } from '../../components/App';
import Images from '../../assets/Images';
import {
	OrderReceiptAddressInfo,
	OrderReceiptDetails,
	OrderReceiptHistory,
	OrderReceiptItems,
	OrderReceiptTotals,
} from '../../components/Orders/Receipt';
import OrderService from '../../services/OrderService';

const OrderPrintReceipt = () => {
	const location = useLocation();
	const [loading, setLoading] = React.useState(true);
	const [details, setDetails] = React.useState(null);

	const _getOrders = React.useCallback(() => {
		setLoading(true);
		const { order_id, ...filters } = UtilityService.getQueryParams(location.search);
		OrderService.receiptDetails(order_id, filters)
			.then((response) => setDetails(response))
			.finally(() => setLoading(false));
	}, [location.search]);

	React.useEffect(() => {
		_getOrders();
	}, [_getOrders]);

	if (loading || !details) {
		return <AppLoading />;
	}

	const shouldShowSecondPage = details.items.length > 12;

	const renderHistory = () => {
		return <OrderReceiptHistory order={details} />;
	};

	return (
		<AppPdfWrapper>
			<AppPdfPage>
				<img src={Images.logo} alt='Logo' />

				<div className='flex mt-6'>
					<OrderReceiptAddressInfo
						label={'Ordered From'}
						company={'The Table Group'}
						info={
							'1211 Newell Ave, Suite 150 <br /> Walnut Creek, CA 94596 <br /> ph: 925.299.9700 <br /> fx: 925.299.9708 <br /> www.tablegroup.com'
						}
					/>

					<OrderReceiptAddressInfo
						label={'Billed To'}
						company={details.billingAddress.company}
						info={`${details.billingAddress.name} <br /> ${details.billingAddress.street} <br /> ${details.billingAddress.location} ${details.billingAddress.country} <br /> ${details.billingAddress.phone}`}
					/>
				</div>

				<OrderReceiptDetails order={details} />

				<OrderReceiptItems order={details} />

				<OrderReceiptTotals order={details} />

				{!shouldShowSecondPage && renderHistory()}
			</AppPdfPage>

			{shouldShowSecondPage && <AppPdfPage>{renderHistory()}</AppPdfPage>}
		</AppPdfWrapper>
	);
};

export default OrderPrintReceipt;