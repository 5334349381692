import React from 'react';
import { AppIcon } from './index';

const NoData = ({ icon, message }) => {
	return (
		<div className={'flex flex-col items-center'}>
			<AppIcon className={'ttg-text-5xl text-gray-400'} icon={icon} />
			<span className={'ttg-text-sm text-gray-800 mt-5'}>{message}</span>
		</div>
	);
};

export default NoData;
