import React from 'react';
import { AppButton, AppTable, AppTooltip } from '../../App';
import UtilityService from '../../../services/UtilityService';
import AddTeamMemberSidebar from './AddTeamMemberSidebar';
import EditTeamMemberSidebar from './EditTeamMemberSidebar';
import ConfirmDeleteRosterModal from './ConfirmDeleteRosterModal';
import RosterBulkActions from './RosterBulkActions';
import config from '../../../constants/config';
import NotificationService from '../../../services/NotificationService';
import AssessmentService from '../../../services/AssessmentService';
import { useCustomer } from '../../../providers/CustomerProvider';

const EDIT_ACTION = 'Edit';
const SEND_REMINDER_ACTION = 'Send Reminder';
const DELETE_ACTION = 'Delete';
const SEND_RESPONSE_ACTION = 'Send Responses';
const SEND_INITIAL_EMAIL = 'Send Initial Email';
const COPY_LINK_ACTION = '';
const MORE_ACTIONS = 'More Actions';

const Roster = ({ details, getAssessmentDetails }) => {
	const actions = [
		{
			label: SEND_RESPONSE_ACTION,
			action: 'onSendResponse',
			icon: 'file-transfer-line',
			className: '!text-gray-800',
			iconClassName: '!text-gray-800',
		},
		{
			label: COPY_LINK_ACTION,
			key: 'copy-link-btn',
			callback: (item) => onCopyTextToClipboard(item),
			icon: 'link-m',
			iconClassName: 'text-xl w-5',
			className: 'btn btn-secondary w-8',
			isButton: true,
		},
		{
			label: MORE_ACTIONS,
			icon: 'arrow-down-s-line',
			key: 'more-actions-dropdown',
			subActions: [
				{
					label: EDIT_ACTION,
					action: 'onEdit',
					icon: 'pencil-line',
					className: '!text-gray-800',
					iconClassName: '!text-gray-800',
				},
				{
					label: SEND_REMINDER_ACTION,
					action: 'onSendReminder',
					icon: 'notification-4-line',
					className: '!text-gray-800',
					iconClassName: '!text-gray-800',
				},
				{
					label: SEND_INITIAL_EMAIL,
					action: 'onSendInitialEmail',
					icon: 'mail-send-line',
					className: '!text-gray-800',
					iconClassName: '!text-gray-800',
				},
				{
					label: DELETE_ACTION,
					action: 'onDelete',
					labelClassName: '!text-red-500',
					icon: 'delete-bin-line',
					iconClassName: '!text-red-500',
				},
			],
		},
	];

	const [filters, setFilters] = React.useState({
		sort_key: 'email_address',
		sort_dir: 'asc',
	});

	const [isAddingNewMember, setIsAddingNewMember] = React.useState(false);
	const [editingMember, setEditingMember] = React.useState({});
	const [deletingMember, setDeletingMember] = React.useState({});
	const [loaders, setLoaders] = React.useState({});

	const { licenses: { licenses } } = useCustomer();

	const rosters = React.useMemo(() => {
		return UtilityService.sortByProp(details.rosters, filters.sort_key, filters.sort_dir);
	}, [details.rosters, filters]);

	const columns = React.useMemo(() => {
		return [
			{
				name: 'Member',
				key: 'email_address',
				sortable: details.rosters.length > 1,
				customRender: 'renderTeamMember',
			},
			{
				name: 'Date/Time Completed',
				key: 'dateTimeCompleted',
				sortable: details.rosters.length > 1,
				customRender: 'renderDateTime',
			},
			{ name: 'Status', key: 'status', sortable: details.rosters.length > 1 },
		];
	}, [details.rosters, filters]);

	const renderTeamMember = (item) => {
		return (
			<div>
				<span>{item.email_address}</span>
				<br />
				{details.isTeamAssessment && item.email_address === details.assessment_data.main_email && (
					<>
						<span
							className={'text-gray-500 cursor-pointer'}
							data-for={`team-assessments-tooltip-${item.email_address}`}
							data-tip
						>
							Main Contact
						</span>

						<AppTooltip id={`team-assessments-tooltip-${item.email_address}`} placement={'right'}>
							{details.assessment_data.do_main_contact_updates ? (
								<span>
									{item.email_address} is the main contact for this team and will receive status
									updates as each member completes the assessment.
								</span>
							) : (
								<span>
									{item.email_address} is the main contact for this team but will not receive status
									updates as each member completes the assessment.
								</span>
							)}
						</AppTooltip>
					</>
				)}
				{!!details.assessment_data.do_main_contact_updates && item.isOptionalEmail && (
					<>
						<span
							className={'text-gray-500 cursor-pointer'}
							data-for={`team-assessments-tooltip-${item.email_address}`}
							data-tip
						>
							Additional Contact
						</span>

						<AppTooltip id={`team-assessments-tooltip-${item.email_address}`} placement={'right'}>
							<span>
								{item.email_address} has been specified to receive status updates as each member
								completes the assessment.
							</span>
						</AppTooltip>
					</>
				)}
			</div>
		);
	};

	const renderDateTime = (item) => {
		return (
			<span>
				{item.dateTimeCompleted
					? UtilityService.formatDate(item.dateTimeCompleted, 'MM/DD/YYYY | hh:mm A')
					: 'N/A'}
			</span>
		);
	};

	const onAddTeamMember = () => {
		setIsAddingNewMember(true);
	};

	const onSendReminder = React.useCallback(
		(item) => {
			AssessmentService.remindTeamMember({
				team_id: details.assessment_data.team_id,
				person_ids: [item.person_id],
			}).then(() => {
				NotificationService.success('Reminder sent successfully');
			});
		},
		[details]
	);

	const onSendResponse = (item) => {
		setLoaders((prev) => ({ ...prev, [item.email_address]: true }));
		AssessmentService.sendResponses({
			team_id: details.assessment_data.team_id,
			person_ids: [item.person_id],
			identifier: item.email_address,
		})
			.then(() => NotificationService.success('Responses sent successfully'))
			.finally(() => {
				setLoaders((prev) => ({ ...prev, [item.email_address]: false }));
			});
	};

	const onSendInitialEmail = () => {
		NotificationService.success('Initial email sent successfully');
	};

	const onCopyTextToClipboard = async (item) => {
		const text = `${config.ttg_url}/rd/?team=${details.assessment_data.team_slug}&roster=${item.team_roster_slug}`;
		await navigator.clipboard.writeText(text);
		NotificationService.success('Link copied successfully!', '', { duration: 10000 });
	};

	const onEditSidebarClose = (isSaving = false) => {
		if (isSaving) {
			getAssessmentDetails();
		}
		setEditingMember({});
	};

	const canAddTeamMember = React.useMemo(() => {
		return licenses > 0 && details.assessment_data && details.assessment_data.status !== 'Complete';
	}, [licenses, details]);
	return (
		<div>
			<div className={'flex flex-1 flex-col md:flex-row md:items-center gap-4 mb-4 md:mb-0'}>
				<h1 className={'flex flex-1 ttg-text-lg text-gray-800 font-medium'}>
					{details.isTeamAssessment ? 'Team Roster' : 'Roster'}
				</h1>
				{details.isTeamAssessment && (
					<AppButton
						id={'ttg-assessment-details-add-team-member'}
						text={'Add Team Member'}
						onClick={() => {
							if (canAddTeamMember) {
								onAddTeamMember();
							}
						}}
						icon={'add-line'}
						className={'btn btn-secondary'}
						iconRight={false}
						disabled={!canAddTeamMember}
					/>
				)}
			</div>
			<AppTable
				identifier='email_address'
				columns={columns}
				data={rosters}
				filters={filters}
				onSort={(col, sort_dir) => {
					const newFilters = {
						...filters,
						sort_key: col.key,
						sort_dir: sort_dir,
					};
					setFilters(newFilters);
				}}
				selectable={details.isTeamAssessment}
				renderTeamMember={renderTeamMember}
				renderDateTime={renderDateTime}
				actions={actions}
				onEdit={setEditingMember}
				onSendReminder={onSendReminder}
				onDelete={(item) => {
					const willAssessmentBeDeleted = rosters.length === 1;
					const willAssessmentBeClosed =
						rosters.filter((r) => r.status !== 'Completed' && r.person_id !== item.person_id).length === 0;
					setDeletingMember({
						...item,
						willAssessmentBeDeleted,
						willAssessmentBeClosed,
					});
				}}
				onSendResponse={onSendResponse}
				onSendInitialEmail={onSendInitialEmail}
				isActionAvailable={(label, item, subaction = false) => {
					if (subaction) {
						if (label === DELETE_ACTION) return item.status !== 'Completed';

						if (label === EDIT_ACTION) return item.status !== 'Completed';

						if (label === SEND_REMINDER_ACTION) return !details.isScheduled && item.status !== 'Completed';

						if (label === SEND_INITIAL_EMAIL)
							return !details.isTeamAssessment && item.status !== 'Completed';
					}

					if (label === SEND_RESPONSE_ACTION) return item.status === 'Completed';

					if (label === COPY_LINK_ACTION) return item.status !== 'Completed';

					if (label === MORE_ACTIONS) return item.status !== 'Completed';

					return true;
				}}
				actionLoaders={loaders}
				renderBulkActions={(props) => <RosterBulkActions {...props} assessment={details} />}
				disableSelection={() => {
					return false;
				}}
			/>
			<AddTeamMemberSidebar
				teamId={details.assessment_data.team_id}
				isOpen={isAddingNewMember}
				onClose={() => setIsAddingNewMember(false)}
				limit={licenses}
				isScheduled={details.isScheduled}
			/>
			<EditTeamMemberSidebar
				teamId={details.assessment_data.team_id}
				roster={editingMember}
				isOpen={!!editingMember.email_address}
				onClose={onEditSidebarClose}
			/>
			<ConfirmDeleteRosterModal
				teamId={details.assessment_data.team_id}
				roster={deletingMember}
				isOpen={!!deletingMember.email_address}
				onClose={() => setDeletingMember({})}
				isScheduled={details.isScheduled}
				isTeamAssessment={details.isTeamAssessment}
			/>
		</div>
	);
};

export default Roster;
