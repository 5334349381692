import React from 'react';
import { AppIcon } from './index';

const Notice = ({ icon, containerClass, title, description, actions, loading, isDismissable = true }) => {
	const [dismissed, setDismissed] = React.useState(false);

	const _onClick = (action, dismiss) => {
		if (dismiss) {
			setDismissed(true);
		} else if (!loading) {
			action();
		}
	};

	if (dismissed) {
		return null;
	}

	return (
		<div className={`${containerClass} flex-row`}>
			{!!icon && <AppIcon icon={icon} className={'text-4xl md:text-5xl mr-2 text-gray-600'} />}
			<div className='flex flex-1 flex-col'>
				<div className='flex flex-1 flex-col'>
					<div className={'flex'}>
						<p
							className={'flex flex-1 ttg-text-base text-gray-800 font-medium '}
							dangerouslySetInnerHTML={{ __html: title }}
						/>
						{!!isDismissable && (
							<AppIcon
								id={'ttg-notice-dismiss-button'}
								icon={'close-line'}
								className={'cursor-pointer text-2xl leading-none'}
								onClick={() => setDismissed(true)}
							/>
						)}
					</div>
					{typeof description === 'object' ? (
						<div className={'ttg-text-sm text-cyan-800 my-3'}>{description}</div>
					) : (
						<p
							className={'ttg-text-sm text-cyan-800 my-3'}
							dangerouslySetInnerHTML={{ __html: description }}
						/>
					)}
				</div>
				{actions && actions.length > 0 && (
					<div id={'ttg-notice-action-container'} className={'flex flex-row'}>
						{actions.map(({ id, text, action, dismiss, color = 'text-rose-600' }) => {
							return (
								<div
									id={id}
									key={id}
									className={`mr-6 font-medium ttg-text-sm hover:underline ${color} ${
										loading ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer '
									}`}
									onClick={() => _onClick(action, dismiss)}
								>
									{text}
								</div>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
};

export default Notice;
