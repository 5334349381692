import React from 'react';
import { AppButton } from '../../App';
import { TakeAssessmentNavigation } from '../index';
import UtilityService from '../../../services/UtilityService';
import { useAssessments } from '../../../providers/AssessmentProvider';

const ChangeAddressStep = ({
	currentStep,
	totalSteps,
	previousStep,
	nextStep,
	onComplete,
	onChangeEmail,
	loading,
	saveProgress,
	step,
	onProgress,
}) => {
	React.useEffect(() => {
		window.addEventListener('beforeunload', UtilityService.confirmPageLeave);
		return () => window.removeEventListener('beforeunload', UtilityService.confirmPageLeave);
	}, []);

	const { loading: updatingProgress } = useAssessments();

	const isLastStep = React.useMemo(() => {
		return currentStep === totalSteps - 1;
	}, [currentStep, totalSteps]);

	const _nextStep = () => {
		if (saveProgress) {
			onProgress(step, nextStep);
		} else {
			nextStep();
		}
	};

	const _next = () => {
		if (isLastStep) {
			onComplete(nextStep);
		} else {
			_nextStep();
		}
	};

	const renderNavigation = () => {
		return (
			<TakeAssessmentNavigation
				backText={currentStep === 1 ? 'Cancel' : 'Back'}
				onBack={previousStep}
				onNext={_next}
				loading={loading || updatingProgress}
				nextText={isLastStep ? 'Finish' : 'Next'}
			/>
		);
	};

	return (
		<div className={'ttg-account-section flex-col'} id={'ttg-take-assessment-step-checkbox-question'}>
			<h1 className={'text-2xl font-medium text-gray-900 my-4'}>Almost done...</h1>
			<p className={'text-base font-normal text-gray-900 my-2'}>
				You are about to finish your assessment. By clicking Finish your responses will be sent to your email
				address and you will no longer be able to go back and change any of your answers.
			</p>
			<p className={'text-base font-normal text-gray-900 my-2'}>
				If you want to receive the assessment responses to a different email address from the one that you used
				for taking the assessment, click change email address.
			</p>
			<AppButton
				type={'button'}
				className={'btn btn-secondary w-56 my-6'}
				text={'Change email address'}
				loading={loading}
				iconRight={false}
				onClick={onChangeEmail}
				id={'ttg-take-assessment-change-email-button'}
			/>
			{renderNavigation()}
		</div>
	);
};

export default ChangeAddressStep;
