export enum USER_ROLE {
  'C_SUITE_LEADER' = 'C_SUITE_LEADER',
  'DEPARTMENT_LEADER' = 'DEPARTMENT_LEADER',
  'TEAM_LEADER' = 'TEAM_LEADER',
  'INDIVIDUAL_CONTRIBUTOR' = 'INDIVIDUAL_CONTRIBUTOR',
  'CONSULTANT_COACH' = 'CONSULTANT_COACH',
  'STUDENT' = 'STUDENT',
  'INCOMPLETE' = 'INCOMPLETE',
  'OTHER' = 'OTHER',
}

type UserRoles = {
  [key in USER_ROLE]: string;
};

export const ReadableUserRoles: Omit<UserRoles, "INCOMPLETE" | "OTHER"> = {
  'C_SUITE_LEADER': 'C Suite Leader',
  'DEPARTMENT_LEADER': 'Department Leader',
  'TEAM_LEADER': 'Team Leader',
  'INDIVIDUAL_CONTRIBUTOR': 'Individual Contributor',
  'CONSULTANT_COACH': 'Consultant / Coach',
  'STUDENT': 'Student'
}

export const RolesEnum: string[] = Object.values(USER_ROLE);
