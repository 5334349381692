import React from 'react';
import ReportV1 from '../../components/Assessments/Report/V1';
import ReportV2 from '../../components/Assessments/Report/V2';
import { useLocation } from 'react-router-dom';
import UtilityService from '../../services/UtilityService';
import { useAssessments } from '../../providers/AssessmentProvider';
import AssessmentService from '../../services/AssessmentService';

const Report = () => {
	const location = useLocation();
	const { reportData: details, setReportData, loading } = useAssessments();

	const _getDetails = React.useCallback(() => {
		const filters = UtilityService.getQueryParams(location.search);
		AssessmentService.getReportData(filters).then((response) => {
			setReportData(response);
		});
	}, [location.search]);

	React.useEffect(() => {
		_getDetails();
	}, [_getDetails]);

	if (loading || !details) {
		return <p>loading</p>;
	}

	if (details.current_team.version === 1) {
		return <ReportV1 details={details} />;
	}

	return <ReportV2 details={details} />;
};

export default Report;
