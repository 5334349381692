import React from 'react';
import Images from '../../../../assets/Images';
import { AppPdfPage } from '../../../App';

const Introduction = () => {
	return (
		<AppPdfPage page={2} sectionClassName={'Introduction'}>
			<div className='Introduction__layout'>
				<div className='Introduction__heading'>
					<div className='PageHeading'>
						<div className='PageHeading__title'>Introduction</div>
						<div className='PageHeading__image'>
							<img width='82' src={Images.report.chatBox} />
						</div>
					</div>
				</div>

				<div className='Introduction__mainText'>
					<div className='Blurb'>
						<div className='Blurb__content'>
							<p>
								The Online Team Assessment Progress Report provides a side-by-side comparison of your
								team scores from the current and previous assessments. This report is designed to give
								your team insight into the progress you&rsquo;ve made since your last assessment and to
								further discuss and develop the behaviors of a cohesive team. While the results are
								presented in a quantitative and data-driven way, the primary value of the report comes
								from the qualitative perspective it provides for your team, and the discussion it
								provokes around specific issues.
							</p>

							<p>
								Remember, creating a cohesive team is simple but difficult. Teams who intentionally and
								repeatedly focus on overcoming the five dysfunctions will experience higher degrees of
								cohesion, fulfillment, and success.
							</p>
						</div>
					</div>

					<div className='Introduction__mainTextAside'>
						<div className='BracketPanel'>
							<div className='BracketPanel__content'>
								&ldquo;If you could get all the people in an organization rowing in the same direction,
								you could
								<br />
								dominate any industry, in any market, against any competition, at any time.&rdquo;
								<div className='BracketPanel__content--attribution'>
									&ndash; <i>The Five Dysfunctions of a Team</i>, by Patrick Lencioni
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='Page__contentBg Page__contentBg--pyramid'>
				<img src={Images.report.pyramidGrayedOut} alt='' />
			</div>
		</AppPdfPage>
	);
};

export default Introduction;
