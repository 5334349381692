import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './cookieyes';
import './dayjs';

import App from './App';

import '@ttg/ui-common-lib/index.css';
import '@ttg/ui-itp-lib/index.css';
import './styles/index.scss';
//IMPORT REMIX ICONS
import 'remixicon/fonts/remixicon.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<StrictMode>
		<App />
	</StrictMode>
);
