import React from 'react';
import { AppIcon } from '../../../App';

const Progress = ({ currentStep, goToStep }) => {
	const steps = [
		{ number: 1, label: 'Company details' },
		{ number: 2, label: 'Team details' },
		{ number: 3, label: 'Custom Email' },
		{ number: 4, label: 'Send Reminders (optional)' },
	];

	if (currentStep > 4) {
		return null;
	}

	return (
		<div className={'progress-bar'}>
			<h1 className={'title'}>Team Assessment Setup</h1>

			<div className='items-container'>
				{steps.map((step) => {
					const customStepClass = [];
					const isCompleted = step.number < currentStep;
					const isActiveStep = step.number === currentStep;
					const isFutureStep = step.number > currentStep;
					if (isCompleted) {
						customStepClass.push('completed');
					}
					if (isActiveStep) {
						customStepClass.push('active');
					}
					if (isFutureStep) {
						customStepClass.push('future');
					}
					return (
						<div
							className={`item ${customStepClass.join(' ')}`}
							key={step.number}
							onClick={() => {
								if (isCompleted) {
									goToStep(step.number);
								}
							}}
						>
							{isCompleted ? (
								<p className='circle'>
									<AppIcon className={'text-white'} icon={'check-fill'} />
								</p>
							) : (
								<p className='circle'>{step.number}</p>
							)}
							<p className={`text`}>{step.label}</p>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Progress;
