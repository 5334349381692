import React from 'react';
import { AppAccordion, AppBreadcrumbs } from '../../components/App';
import { AssessmentLayout } from '../../components/Layout';
import RouteNames from '../../router/RouteNames';

const Help = () => {
	return (
		<AssessmentLayout>
			<div className={'mt-8 mx-4'}>
				<AppBreadcrumbs items={[{ name: 'Assessments', to: RouteNames.Assessments }, { name: 'Help' }]} />
			</div>
			<div className='ttg-assessment-section flex-col ttg-help-page'>
				<h1 className='text-2xl font-medium my-1'>Help</h1>
				<p className='text-sm text-gray-500 font-normal'>
					Welcome to the Help section of the client console. To get started, please click on a category below.
				</p>

				<div className='question-block'>
					<AppAccordion
						isOpen={false}
						title={<div className={'help-question-title'}>General Questions About the New Assessment</div>}
						showIcon={false}
					>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									1. The Online Team Assessment was updated in November 2019. What has changed from
									the earlier version?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<ul style={{ listStyle: 'circle' }}>
									<li>
										<b>Updated statements:</b>
										<ul>
											<li>
												8. Team members point out one another's behaviors that hold the team
												back.
											</li>
											<li>
												9. The team has a reputation for consistently achieving its objectives.
											</li>
											<li>
												21. The team ensures that members feel pressure from their peers and the
												expectation to perform.
											</li>
											<li>
												25. Team members are humble regarding their own contributions to the
												team.
											</li>
											<li>26. All members of this team are held to the same high standards.</li>
											<li>
												37. Team members place more importance on team results than on titles
												and status.
											</li>
										</ul>
									</li>
									<li>
										<b>Added team culture questions to the team-based assessment:</b>
										<ul style={{ listStyle: 'square', marginLeft: '1rem' }}>
											<li>There would be more trust on our team if people…</li>
											<li>What is acceptable behavior during conflict?</li>
											<li>I sometimes don’t buy in to the team’s decisions because…</li>
											<li>
												Our ability to hold one another accountable could improve if we
												challenged one another to…
											</li>
											<li>Some distractions that keep us from focusing on results are…</li>
										</ul>
									</li>
									<li>A new scale: Almost always, Usually, Sometimes, Rarely, Almost Never</li>
									<li>
										A new scoring band: Red = less than 3.00, Yellow = 3.00 to 3.99, Green = 4.00
										and above
									</li>
									<li>
										<b>
											Individual assessment includes the updated statements only, no team culture
											questions
										</b>
									</li>
									<li>
										<b>Updated TAR with new branding and updated tip and exercises</b>
									</li>
									<li>
										<b>Updated Progress Report with new branding and one consolidated report</b>
									</li>
								</ul>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									2. I have an old (v1) assessment and I want to conduct a retest?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									Retests are available for like versions—v1 or v2—of the Team Assessment. As we
									transition to the new (v2) assessment, we are encouraging all users to consider
									creating a new assessment rather than a progress Report. You can still manually
									compare the scores side-by-side and this will ensure that all future Progress
									Reports are based on the most up-to-date version of our assessment.
								</p>
								<p>
									If you are still interested in conducting a retest of an old assessment, the option
									still exists. From your Administrator's Console, find the team you wish to retest on
									the assessments page. Select the “Retest” link under the “Action” column and proceed
									through the setup process. During the setup process, you will have the ability to
									add or delete team members.
								</p>
							</div>
						</AppAccordion>
					</AppAccordion>
				</div>
				<div className='question-block'>
					<AppAccordion
						isOpen={false}
						title={<div className={'help-question-title'}>General Questions</div>}
						showIcon={false}
					>
						<AppAccordion
							isOpen={false}
							title={<div className={'help-subquestion'}>1. What is the Online Team Assessment?</div>}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									The Online Team Assessment is a fully automated, web-based tool that helps a team
									better understand its susceptibility to the model found in the book{' '}
									<i>The Five Dysfunctions of a Team</i>. With 37 statements and five team culture
									questions, the assessment identifies a team's strengths and weaknesses, as well as
									those areas where team members have differing perspectives. The detailed report
									offers recommendations for overcoming potential dysfunction and instructions for
									debriefing the results.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									2. How does the Online Team Assessment differ from The Five Behaviors of a Cohesive
									Team™ offered by Wiley?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									The Five Behaviors of a Cohesive Team™ is the result of a partnership between The
									Table Group and Wiley Workplace Learning Solutions. The Online Team Assessment and
									Patrick Lencioni’s five behaviors model was used as the foundation for The Five
									Behaviors™ solutions. The Five Behaviors™ offers research-validated testing that
									gives participants personalized results that deepen their understanding of self and
									others, combined with Patrick Lencioni’s five behaviors model.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									3. How does the Online Team Assessment differ from the one in the back of The Five
									Dysfunctions of a Team book and field guide?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									The Online Team Assessment is a much more rigorous tool than the 15-statement
									assessment provided in the five dysfunctions book and field guide. It provides a
									significantly richer analysis of the team and includes tips and advice for
									overcoming each dysfunction. Please note that both assessments are protected by
									copyright and may not be reproduced or transmitted in any form or by any means.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={<div className={'help-subquestion'}>4. What is a license?</div>}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									A license is our way of accounting for the number of participants on a team. In
									setting up a single team, the number of licenses required is equivalent to the
									number of team members.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={<div className={'help-subquestion'}>5. How much does it cost?</div>}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>The Online Team Assessment costs $56.50 per license (per person on the team).</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									6. What information do I need before I purchase the team assessment licenses?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									Before you purchase the licenses, you will need your contact information and payment
									information. If you want to set-up a team immediately, you will also need the team's
									name, as well as email addresses for every member of the team.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={<div className={'help-subquestion'}>7. Do you accept purchase orders?</div>}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									Yes, we accept purchase orders for orders over $500. Please select the appropriate
									radio button and enter the approved PO number in the field provided on the purchase
									page. Someone from The Table Group will notify you within 24 hours to approve your
									purchase.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={<div className={'help-subquestion'}>8. Do you offer volume discounts?</div>}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									Yes. Our volume discount starts at 100 licenses and is automatically applied during
									the checkout process. The schedule is as follows:
								</p>
								<p>
									0-99: N/A
									<br />
									100-499: 20%
									<br />
									500-999: 25%
									<br />
									1000 and over: 30%
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									9. Why do I need to create an account before I can set-up a team?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									While purchasing your team assessment licenses, you will establish an account using
									your e-mail and a selected password, which allows you to access our system. Our
									system, or Administrator's Console, is where you setup the team assessment by
									entering the team's email addresses and customizing a message to participants.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									10. How will the email addresses I enter be used?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									The email addresses you provide will not be given to any party other than The Table
									Group.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={<div className={'help-subquestion'}>11. How does the process work?</div>}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									The Online Team Assessment is a fully automated, web-based process. The main contact
									sets up the team by entering the email address of every team member. The main
									contact also has the ability to customize a standard email message that will be sent
									to each team member. Once they hit "Send Now," emails are automatically sent to each
									team member with a unique link to the assessment. Each participant then follows the
									link provided and responds to the assessment statements. Once the final person on
									the team completes the assessment, the Team Assessment Report will be emailed as a
									PDF to the main contact.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									12. What should I do if my email and password don't work?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									First try the "forgotten password" link on the Administrator’s Console login page.
									If that does not work, email us at{' '}
									<a href='mailto:assessments@tablegroup.com' target='_blank' rel='noreferrer'>
										assessments@tablegroup.com
									</a>
									.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>13. What should I do if I forgot my password?</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									First try the "forgotten password" link on the Administrator’s Console login page.
									If that does not work, email us at{' '}
									<a href='mailto:assessments@tablegroup.com' target='_blank' rel='noreferrer'>
										assessments@tablegroup.com
									</a>
									.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>14. How do I roll out the results to my team?</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									A recommended process is included in the email that you receive with the Team
									Assessment Report.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									15. Will the Main Contact and/or the Company Administrator be able to identify my
									responses?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>No. Your responses are anonymous and will not be available for others to see.</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={<div className={'help-subquestion'}>16. Who can I contact if I am really lost?</div>}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									Please email us at{' '}
									<a href='mailto:assessments@tablegroup.com' target='_blank' rel='noreferrer'>
										assessments@tablegroup.com
									</a>
									, or call us at 925-299-9700.
								</p>
							</div>
						</AppAccordion>
					</AppAccordion>
				</div>
				<div className='question-block'>
					<AppAccordion
						isOpen={false}
						title={<div className={'help-question-title'}>Assessment Questions</div>}
						showIcon={false}
					>
						<AppAccordion
							isOpen={false}
							title={<div className={'help-subquestion'}>1. What does the report identify?</div>}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									The Team Assessment Report identifies a team's strengths and weaknesses, as well as
									those areas where team members have differing perspectives.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									2. What type of scale is used? Frequency? Other?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									The scale for each statement includes five possible responses ranging from Almost
									Never to Almost Always.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									3. How are the scores tabulated? Average? Are they weighted?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									The team member's scores are averaged to provide an overall snapshot for the team.
									The questions are all equally weighted.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									4. How do we determine a high, average and low range?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									Your Team Assessment Report will rate your team high, average or low against the
									five behaviors. The numerical cut-off for the different rating are as follows:
								</p>
								<p>
									Red/Low - less than 3.00 <br />
									Yellow/Average - 3.00 to 3.99 <br />
									Green/High - 4.00 and above
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									5. How long does it take to answer all the statements on the assessment?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									On average, answering the 37 statements and 5 team culture questions takes about 15
									minutes.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									6. How long does the assessment process take for a team?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									The length of the process is simply the amount of time it takes for all members of
									your team to respond to our assessment. Since our system generates the report
									automatically upon the last team member submitting their responses, you will receive
									the report as quickly as possible.
								</p>
							</div>
						</AppAccordion>
					</AppAccordion>
				</div>
				<div className='question-block'>
					<AppAccordion
						isOpen={false}
						title={<div className={'help-question-title'}>Getting Started (after the purchase)</div>}
						showIcon={false}
					>
						<AppAccordion
							isOpen={false}
							title={<div className={'help-subquestion'}>1. How do I set-up a new team?</div>}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									From the home page within the Administrator's Console, you can select the red icon
									that reads, “Team Assessment Setup.” The Team Assessment Setup page is where you
									create your assessment by entering the team's email addresses and customizing a
									message to participants.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									2. What if I want to have an individual take the assessment for their team?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									From the home page within the Administrator's Console, you can select the red link
									that reads, “Individual Assessment Setup.” The Individual Assessment Setup page is
									where you assign the license by entering the participant name and email address.
									Please note that the individual will be the only person that receives the final
									report, as we maintain the anonymity of all responses.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									3. What is the difference between a team assessment and an individual assessment?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									Our Online Team Assessment was designed and intended for intact teams. Less common,
									but still effective, participants can take the individual assessment to reveal
									his/her own perceptions of the team.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={<div className={'help-subquestion'}>4. How do I purchase more licenses?</div>}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									To purchase more licenses, simply click "Purchase more" in the top red bar of the
									home page within your Administrator's Console. You can also purchase more licenses
									from our website,{' '}
									<a href='http://www.tablegroup.com/store' target='_blank' rel='noreferrer'>
										www.tablegroup.com/store
									</a>
									. After selecting the licenses, you can login using your existing account
									information.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={<div className={'help-subquestion'}>5. Who does the email come from?</div>}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									All email will come from assessments@tablegroup.com. Email blockers, spam filters,
									junk mail filters or other blocking technology must allow email from tablegroup.com
									to be received by the individual.
								</p>
							</div>
						</AppAccordion>
					</AppAccordion>
				</div>
				<div className='question-block'>
					<AppAccordion
						isOpen={false}
						title={<div className={'help-question-title'}>In Process</div>}
						showIcon={false}
					>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									1. I deleted the email message containing my unique assessment URL?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									You can also access the Online Team Assessment from our website, www.tablegroup.com.
									Click on “Login” link at the top of the page. You will be prompted to enter your
									email address under the “Take Your Team Assessment” section. You can also contact
									the person who set-up the team and ask them to send you a reminder email.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									2. Why is my link not working when I click on it directly from the email?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									Your link is unique. If for some reason, another person may have taken the
									assessment using your link, then it will have been disabled. This happens when the
									link is forwarded to another participant. Another reason your link may not work is
									if it was truncated by your email system. Try to cut and paste the entire link (even
									if it wraps lines) in the address bar in your browser. If that does not work, you
									can use the Login function at the bottom of our website, www.tablegroup.com. You
									will be prompted to enter your email address under the “Take Your Team Assessment”
									section.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={<div className={'help-subquestion'}>3. Why am I being denied access?</div>}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>Please contact your main contact or administrator to help resolve that problem.</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									4. How do I add a new team member to an open assessment?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<ol className='list-decimal ml-4'>
									<li>Purchase an additional license if you do not have any remaining licenses.</li>
									<li>
										Choose the team you wish to modify on the assessments page in the
										Administrator’s Console.
									</li>
									<li>
										After selecting the appropriate team, scroll down to the Team Roster section.
									</li>
									<li>
										From the “What Would You Like to Do?” drop-down menu, choose "Add a Team
										Member", and click on the “Go” button.
									</li>
									<li>
										Enter the email address of the new team member in the field provided and click
										on the “Add” button. An additional license will be deducted from your account.
										If you do not have enough licenses, you will be prompted to purchase more.
									</li>
									<li>
										An email containing your original custom email message will be sent to the team
										member you just added.
									</li>
								</ol>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									5. How do I delete a team member from an open assessment?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<ol className='list-decimal ml-4'>
									<li>
										Choose the team you wish to modify on the assessments page in the
										Administrator’s Console.
									</li>
									<li>
										After selecting the appropriate team, scroll down to the Team Roster section.
									</li>
									<li>Check the box next to the team member you wish to delete.</li>
									<li>
										From the “What Would You Like to Do?” drop-down menu, choose "Remove Selected
										Team Members", and click on the “Go” button.
									</li>
									<li>
										You will be prompted to confirm the team member you will be deleting. Select
										“Remove” if you choose to delete him/her. If you wish to delete a team member
										who has already completed the assessment, please contact us by email at
										assessments@tablegroup.com for assistance.
									</li>
								</ol>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>6. How do I change an incorrect email address?</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<ol className='list-decimal ml-4'>
									<li>
										Choose the team you wish to modify on the assessments page in the
										Administrator’s Console.
									</li>
									<li>
										After selecting the appropriate team, scroll down to the Team Roster section.
									</li>
									<li>
										Check the box next to the team member whose email address you wish to change.
									</li>
									<li>
										From the “What Would You Like to Do?” drop-down menu, choose “Edit Selected Team
										Members”, and click on the “Go” button.
									</li>
									<li>
										Enter the correct email address in the field provided and click on the "Save"
										button.
									</li>
								</ol>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									7. How do I send email reminders to team members who have not completed their
									assessments?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									If you are the main contact, and are receiving updates on the team assessment, you
									can simply click the "Send Reminders" button in the most recent email update you
									received. You can also send reminders directly from the Administrator's Console:
								</p>
								<ol className='list-decimal ml-4'>
									<li>
										Choose the team you wish to modify on the assessments page in the
										Administrator’s Console.
									</li>
									<li>
										After selecting the appropriate team, scroll down to the Team Roster section.
									</li>
									<li>Check the box next to the team members you would like to remind.</li>
									<li>
										From the “What Would You Like to Do?” drop-down menu, choose “Send Reminder to
										Selected Team Members”, and click on the “Go” button
									</li>
									<li>
										You will be prompted to confirm the details. Select “Send” if you choose to send
										reminders.
									</li>
								</ol>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									8. How do I schedule email reminders to team members so I don’t have to manually
									prompt the system?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									Choose the team on the assessments page in the Administrator’s Console. Under the
									“Team Assessment Status” section in the upper right-hand corner of the page, choose
									“Schedule Reminders For This Team.” Select a date and time and click on the “Done”
									button. The reminder email will go out on the selected date and time to team members
									who have not completed the assessment.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									9. How do I resend an initial Team Assessment email to selected team members?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<ol className='list-decimal ml-4'>
									<li>
										Choose the team you wish to modify on the assessments page in the
										Administrator’s Console.
									</li>
									<li>
										After selecting the appropriate team, scroll down to the Team Roster section.
									</li>
									<li>
										Check the box next to the team members whom you'd like to receive the initial
										team assessment email.
									</li>
									<li>
										From the “What Would You Like to Do?” drop-down menu, choose “Send Initial Email
										to Selected Team Members”, and click on the “Go” button.
									</li>
									<li>
										You will be prompted to confirm the details. Select “Send” if you choose to send
										the initial email.
									</li>
								</ol>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									10. How do I send responses to selected team members?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<ol className='list-decimal ml-4'>
									<li>
										Choose the team you wish to modify on the assessments page in the
										Administrator’s Console.
									</li>
									<li>
										After selecting the appropriate team, scroll down to the Team Roster section.
									</li>
									<li>Check the box next to the team members to whom you'd like to send responses</li>
									<li>
										From the “What Would You Like to Do?” drop-down menu, choose “Send Responses to
										Selected Team Members”, and click on the “Go” button.
									</li>
									<li>
										You will be prompted to confirm the details. Select “Send” if you choose to send
										responses.
									</li>
								</ol>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									11. Our team has not received the initial emails for the assessment. What should I
									do?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									Double-check the assessments page in the Administrator’s Console to ensure the team
									is in process. If it is, please select the team to verify that the email addresses
									in the Team Roster section are accurate. If they are, the emails are most likely
									being filtered as spam by your email server.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									12. The assessment emails are being flagged as spam. How can team members access the
									assessment?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									Ask your team members to go to the Table Group website at www.tablegroup.com, and
									click on the "Login" link at the top of the page. On the resulting page, instruct
									each team member to enter his/her email address in the "Take Your Team Assessment"
									field. Team members will then be prompted through the assessment process.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									13. Can I run the assessment report without everyone's input?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									Yes. Once 75% of the team members have submitted their responses, you can end the
									assessment and receive the report. Choose the team on the assessments page in the
									Administrator’s Console. Under the “Team Assessment Status” section in the upper
									right-hand corner of the page, click on the “End This Assessment and Send Report”
									link.{' '}
									<b>
										Please keep in mind that this will be your final report and all remaining team
										members will be deleted from the team. You will not have the opportunity to run
										another report.
									</b>
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									14. How quickly will I receive the Team Assessment Report?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									The Team Assessment Report will be generated and emailed to the main contact
									immediately once the last person on the team submits their responses.
								</p>
							</div>
						</AppAccordion>
					</AppAccordion>
				</div>
				<div className='question-block'>
					<AppAccordion
						isOpen={false}
						title={<div className={'help-question-title'}>After the Team Assessment Is Complete</div>}
						showIcon={false}
					>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									1. I have a new team member whom I'd like to add to an old assessment. How can I do
									that?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									Once the assessment has ended and the report has been generated, you are no longer
									able to add additional team members. In fact, adding a new team member changes the
									dynamics of a team and may affect team members' perceptions. To get a complete and
									accurate picture of the new dynamics of the team, it would be necessary to have the
									whole team (with the new member) take the assessment again.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									2. My team has finished the assessment, but our main contact has not received the
									report. How do we generate the report?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									Choose the team on the assessments page in the Administrator’s Console. Under the
									“Team Assessment Status” section in the upper right-hand corner of the page, choose
									“Send Team Assessment Report” to email the PDF. You can also choose “View Team
									Assessment Report” and save the PDF that opens.
								</p>
							</div>
						</AppAccordion>
						<AppAccordion
							isOpen={false}
							title={
								<div className={'help-subquestion'}>
									3. If my team has taken the assessment earlier, how can we compare our current
									performance to the past results?
								</div>
							}
							showIcon={false}
						>
							<div className={'help-answer'}>
								<p>
									For teams that want to gauge their progress over time, a Team Assessment Progress
									Report is available. The Progress Report graphically compares how a team's
									performance changed over time and highlights areas of greatest improvement and areas
									of greatest decline.
								</p>
								<p>
									First, you must have the necessary licenses to conduct the assessment, which can be
									purchased in our store. Next, from your Administrator's Console, find the team you
									wish to retest on the assessments page. Select the “Retest” link under the “Action”
									column and proceed through the setup process. During the setup process, you will
									have the ability to add or delete team members.
								</p>
							</div>
						</AppAccordion>
					</AppAccordion>
				</div>
			</div>
		</AssessmentLayout>
	);
};

export default Help;
