import React from 'react';
import { Link } from 'react-router-dom';
import { TTGForgotPassword } from '@ttg/ui-user-lib';

import { PublicLayout } from '../../components/Layout';
import RouteNames from '../../router/RouteNames';
import config from '../../constants/config';
import AppTabTitle from '../../components/App/AppTabTitle';
import { useCustomer } from '../../providers/CustomerProvider';

const ForgotPassword = () => {
	const { customer } = useCustomer();

	if (customer && customer.customers_id) {
		window.location.href = `${RouteNames.Home}`;
		return null;
	}

	const Footer = (
		<Link to={RouteNames.Login} className='text-sm text-rose-500 font-medium mt-4' id={'ttg-login-signup-link'}>
			Back to Login
		</Link>
	);

	return (
		<PublicLayout headerTitlse='' headerStatus={null} footer={<></>}>
			<AppTabTitle title='Forgot Password | The Table Group' />
			<TTGForgotPassword
				apiEndpoint={config.ttg_url}
				captchaEnabled={config.captcha_enabled}
				captchaKey={config.captcha_site_key}
				footer={Footer}
				theme={'ttg-app'}
			/>
		</PublicLayout>
	);
};

export default ForgotPassword;
