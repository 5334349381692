import React from 'react';
import { AppButton, AppIcon, AppModalComponent } from '../../../App';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const TestEmailsSentModal = ({ emails, isOpen, onClose }) => {
	const validEmails = emails.filter(({ isValidEmail }) => isValidEmail).map(({ value }) => value);

	const sentEmails = validEmails.slice(0, validEmails.length - 1).join(', ');
	const lastEmail = validEmails[validEmails.length - 1];

	const renderText = () => {
		if (validEmails.length > 1) {
			return (
				<p>
					A test email is sent to <span className={'font-medium'}>{sentEmails}</span> and{' '}
					<span className='font-medium'>{lastEmail}</span>
				</p>
			);
		}
		return (
			<p>
				A test email is sent to <span className={'font-medium'}>{lastEmail}</span>
			</p>
		);
	};

	return (
		<AppModalComponent id={'ttg-test-emails-sent-modal'} isOpen={isOpen} style={customStyles}>
			<div className='bg-white p-6' style={{ maxWidth: '612px' }}>
				<div className='text-center'>
					<div className={'flex items-center justify-center rounded-full w-12 h-12 bg-green-100 mx-auto'}>
						<AppIcon icon={'mail-send-line'} className={'text-2xl text-green-500'} />
					</div>
					<h1 className={'ttg-text-lg text-gray-900 mt-6 mb-3'}>Test email successfully sent!</h1>
					<p className={'ttg-text-sm text-gray-500 mb-4'}>{renderText()}</p>
				</div>
				<div className={'flex flex-1 justify-center mt-2'}>
					<AppButton className={'btn btn-primary w-48'} text={'OK'} onClick={onClose} />
				</div>
			</div>
		</AppModalComponent>
	);
};

export default TestEmailsSentModal;
