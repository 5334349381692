import React from 'react';
import { useLocation } from 'react-router';
import { AccountLayout } from '../../components/Layout';
import { AppButton, AppIcon, AppLoading } from '../../components/App';
import RouteNames from '../../router/RouteNames';
import UtilityService from '../../services/UtilityService';
import { verifyChangeEmail } from '../../services/CustomerService';

const EmailVerification = () => {
	const location = useLocation();
	const { token } = UtilityService.getQueryParams(location.search);

	const [loading, setLoading] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState('');
	const [showSuccess, setShowSuccess] = React.useState(false);

	React.useEffect(() => {
		setErrorMessage('');
		setLoading(true);

		verifyChangeEmail(token)
			.then(() => {
				setShowSuccess(true);
			})
			.catch((error) => {
				setErrorMessage(error.message);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	if (!token) {
		window.open(RouteNames.Login, '_self');
		return null;
	}

	const getRequestStatus = () => {
		if (loading) {
			return <AppLoading height={'h-24'} />;
		}
		if (showSuccess) {
			return (
				<div
					id={'ttg-email-verification-success'}
					className={'flex justify-center items-center flex-col mx-auto'}
					style={{ maxWidth: '550px' }}
				>
					<div
						className={
							'flex items-center justify-center rounded-full w-12 h-12 border-green-500 border-3 mx-auto'
						}
					>
						<AppIcon icon={'check-line'} className={'text-2xl text-green-500 font-semibold'} />
					</div>
					<p className='text-center text-xl font-medium my-6'>Email address successfully changed!</p>
					<AppButton
						id={'ttg-email-verification-account-link'}
						text={'Go to your account'}
						className={'btn btn-primary w-48'}
						type={'button'}
						onClick={() => (window.location.href = `${RouteNames.Account}`)}
					/>
				</div>
			);
		}
		if (!!errorMessage) {
			return (
				<div
					id={'ttg-email-verification-error'}
					className={'flex justify-center items-center flex-col mx-auto'}
					style={{ maxWidth: '550px' }}
				>
					<AppIcon icon={'information-line'} className={'text-7xl text-red-500'} />
					<p className={'text-center my-6'}>{errorMessage}</p>
					<AppButton
						id={'ttg-email-verification-account-link'}
						text={'Go to your account'}
						className={'btn btn-primary w-48'}
						type={'button'}
						onClick={() => (window.location.href = `${RouteNames.Account}`)}
					/>
				</div>
			);
		}
		return '';
	};

	return (
		<AccountLayout sidebar={false}>
			<div className='flex flex-col items-center justify-center mt-12'>
				<h1 className='text-2xl text-gray-900 font-medium mb-6'>Email Change Verification</h1>
				{getRequestStatus()}
			</div>
		</AccountLayout>
	);
};

export default EmailVerification;
