import React from 'react';
import { AccountLayout } from '../../components/Layout';
import { TakeAssessmentWizard } from '../../components/Assessments';

const Take = () => {
	return (
		<AccountLayout sidebar={false}>
			<TakeAssessmentWizard />
		</AccountLayout>
	);
};

export default Take;
