import React from 'react';
import { AppButton, AppIcon } from '../../App';
import { AccountLayout } from '../../Layout';

import AssessmentService from '../../../services/AssessmentService';

import { useCaptchaProvider } from '../../../providers/CaptchaProvider';
import NotificationService from '../../../services/NotificationService';

const EmailStep = ({ goToStep, email }) => {
	const { sendAction, loading } = useCaptchaProvider();
	const [sent, setSent] = React.useState('sent you an');

	const sendEmail = async () =>
		sendAction((captcha) => AssessmentService.sendFindAssessmentEmail({ email, captcha })).then(
			() =>
				NotificationService.success(
					'Email Successfully Resent!\n We have resent the link that gives you access to the complete list of teams you are a member of.'
				),
			setSent('resent you the')
		);

	return (
		<AccountLayout sidebar={false}>
			<div className={'ttg-find-assessment-step'}>
				<div className={'ttg-find-assessment-step-content'}>
					<AppIcon icon={'mail-send-line'} className={'ttg-find-assessment-email-icon'} />
					<span className={'ttg-find-assessment-step-title'}>We’ve {sent} email</span>

					<span className={'ttg-find-assessment-step-subtitle'}>
						Please check your email for the link that will give you access to the complete list of teams you
						are a member of.
					</span>

					<div className={'ttg-find-assessment-step-actions'}>
						<AppButton text={'Resend email'} loading={loading} onClick={() => sendEmail()} />
						<AppButton
							onClick={() => goToStep(1)}
							text={`< Back to Find Your Assessments`}
							className={'ttg-find-assessment-step-actions-back'}
						/>
					</div>
				</div>
			</div>
		</AccountLayout>
	);
};

export default EmailStep;
