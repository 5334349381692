import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import RouteNames from '../../../router/RouteNames';
import { AppIcon, AppLoading } from '../../App';
import Images from '../../../assets/Images';
import CustomerService from '../../../services/CustomerService';
import config from '../../../constants/config';
import { useCustomer } from '../../../providers/CustomerProvider';

const Sidebar = () => {
	const [loaders, setLoaders] = React.useState({});
	const location = useLocation();

	const { customer } = useCustomer();

	const items = [
		{
			id: 'ttg-account-details',
			to: RouteNames.Account,
			icon: 'user-fill',
			text: 'Account Details',
			visible: true,
		},
		{
			id: 'ttg-account-change-password',
			to: RouteNames.ChangePassword,
			icon: 'key-2-fill',
			text: 'Change Password',
			visible: customer.customers_login_method === config.AUTH_PROVIDERS.PASSWORD,
		},
		{
			id: 'ttg-account-customer-portal',
			action: () => {
				setLoaders({
					...loaders,
					'ttg-account-customer-portal': true,
				});
				CustomerService.getStripePortalLink().then((link) => {
					window.open(link, '_blank');
					setLoaders({
						...loaders,
						'ttg-account-customer-portal': false,
					});
				});
			},
			icon: 'bank-card-fill',
			text: 'Customer Portal',
			visible: true,
		},
		{
			id: 'ttg-account-address-book',
			to: RouteNames.AddressBook,
			icon: 'contacts-book-2-fill',
			text: 'Address Book',
			visible: true,
		},
		{
			id: 'ttg-account-capa-pro-listing',
			to: RouteNames.CapaProListing,
			image: Images.capaLogo,
			text: '<span>CAPA<sup>PRO</sup> Directory Listing</span>',
			visible: customer.show_capapro,
		},
		{
			id: 'ttg-account-orders',
			to: RouteNames.Orders,
			icon: 'coupon-fill',
			text: 'Order History',
			visible: true,
		},
	];

	const renderIcon = (item) => {
		if (item.icon) {
			return <AppIcon icon={item.icon} className={'ttg-sidebar-icon'} />;
		}
		return <img className={'sidebar-image'} src={item.image} alt='' width='32' height='32' />;
	};

	return (
		<div className={'ttg-account-main-layout-sidebar'}>
			<div className={'ttg-account-main-layout-sidebar-wrapper'}>
				{items.map((item) => {
					if (!item.visible) {
						return null;
					}
					const className = `sidebar-item ${location.pathname.indexOf(item.to) > -1 ? 'active' : ''}`;
					if (item.action) {
						return (
							<div
								className={className}
								key={item.id}
								id={item.id}
								onClick={() => !loaders[item.id] && item.action()}
							>
								<p className={'flex flex-1 items-center'}>
									{renderIcon(item)}
									<span dangerouslySetInnerHTML={{ __html: item.text }} />
								</p>
								<div className={'mr-2'}>
									{!!loaders[item.id] && <AppLoading height={'h-8'} size={'text-sm'} />}
								</div>
							</div>
						);
					}
					return (
						<Link id={item.id} key={item.id} className={className} to={item.to}>
							{renderIcon(item)}
							<span dangerouslySetInnerHTML={{ __html: item.text }} />
						</Link>
					);
				})}
			</div>
		</div>
	);
};

export default Sidebar;
