const Scores = ({ details, humbleColor, hungryColor, smartColor }) => {
	const { questions, disciplines } = details;

	return (
		<div className={'flex-row survey-font'}>
			<div className={'ttg-text-xl text-gray-500 font-medium'}>Ideal Team Player Assessment: Your Scores</div>

			<div className={'inline-flex bg-white mt-10 survey-font'}>
				<div className={`itp-pdf-square-${humbleColor} w-48 rounded-sm shadow-lg mt-3 -ml-5 mx-3`}>
					<div className={'ttg-text-3xl text-white font-bold text-center pt-5 px-2 opacity-60'}>Humble</div>
					<div className={'ttg-text-lg text-white font-bold text-center pt-2 mb-24'}>
						My Teammates would say:
					</div>
				</div>
				<div className={'mt-3 px-3 w-5/6'}>
					{disciplines[0].map.map((i) => (
						<div className={'ttg-text-sm font-medium py-1 survey-font'} key={i}>
							{questions[i].question} <b>Response: {questions[i].response}</b>
						</div>
					))}
				</div>
			</div>

			<div className={'inline-flex bg-white'}>
				<div className={`itp-pdf-square-${hungryColor} w-48 mt-8 rounded-sm shadow-lg -ml-5 mx-3 survey-font`}>
					<div className={'ttg-text-3xl text-white font-bold text-center pt-5 px-2 opacity-60'}>Hungry</div>
					<div className={'ttg-text-lg text-white font-bold text-center pt-2'}>My Teammates would say:</div>
				</div>

				<div className={'mt-8 px-3 w-5/6'}>
					{disciplines[1].map.map((i) => (
						<div className={'ttg-text-sm font-medium py-1 survey-font'} key={i}>
							{questions[i].question} <b>Response: {questions[i].response}</b>
						</div>
					))}
				</div>
			</div>

			<div className={'inline-flex py-5 bg-white'}>
				<div
					className={`itp-pdf-square-${smartColor} w-48 mt-4 rounded-sm shadow-lg mb-3 -ml-5 mx-3 survey-font`}
				>
					<div className={'ttg-text-3xl text-white font-bold text-center pt-5 px-2 opacity-60'}>Smart</div>
					<div className={'ttg-text-lg text-white font-bold text-center pt-2'}>My Teammates would say:</div>
				</div>

				<div className={'mt-5 px-3 w-5/6'}>
					{disciplines[2].map.map((i) => (
						<div className={'ttg-text-sm font-medium py-1 survey-font'} key={i}>
							{questions[i].question} <b>Response: {questions[i].response}</b>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Scores;
