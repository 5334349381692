import React from 'react';
import { AppPdfPage } from '../../../App';
import ReportService from '../../../../services/ReportService';
import UtilityService from '../../../../services/UtilityService';

const TeamSummary = ({ details }) => {
	const { child, parent } = details;

	const childLevels = React.useMemo(() => {
		return {
			[ReportService.TRUST]: ReportService.getLevelByValue(
				child.average_response_per_category[ReportService.TRUST]
			),
			[ReportService.ACCOUNTABILITY]: ReportService.getLevelByValue(
				child.average_response_per_category[ReportService.ACCOUNTABILITY]
			),
			[ReportService.RESULTS]: ReportService.getLevelByValue(
				child.average_response_per_category[ReportService.RESULTS]
			),
			[ReportService.COMMITMENT]: ReportService.getLevelByValue(
				child.average_response_per_category[ReportService.COMMITMENT]
			),
			[ReportService.CONFLICT]: ReportService.getLevelByValue(
				child.average_response_per_category[ReportService.CONFLICT]
			),
		};
	}, []);

	const parentLevels = React.useMemo(() => {
		return {
			[ReportService.TRUST]: ReportService.getLevelByValue(
				parent.average_response_per_category[ReportService.TRUST]
			),
			[ReportService.ACCOUNTABILITY]: ReportService.getLevelByValue(
				parent.average_response_per_category[ReportService.ACCOUNTABILITY]
			),
			[ReportService.RESULTS]: ReportService.getLevelByValue(
				parent.average_response_per_category[ReportService.RESULTS]
			),
			[ReportService.COMMITMENT]: ReportService.getLevelByValue(
				parent.average_response_per_category[ReportService.COMMITMENT]
			),
			[ReportService.CONFLICT]: ReportService.getLevelByValue(
				parent.average_response_per_category[ReportService.CONFLICT]
			),
		};
	}, []);

	const childCompletedAt = React.useMemo(() => {
		return UtilityService.formatDate(child.current_team.completed, 'MMMM DD, YYYY');
	}, [details]);

	const parentCompletedAt = React.useMemo(() => {
		return UtilityService.formatDate(parent.current_team.completed, 'MMMM DD, YYYY');
	}, [details]);

	return (
		<AppPdfPage page={4} sectionClassName={'ProgressTeamSummary'}>
			<div className='TeamSummary__layout'>
				<div className='TeamSummary__heading'>
					<div className='PageHeading'>
						<div className='PageHeading__title'>Team Summary</div>
					</div>
				</div>
				<div className='TeamSummary__content'>
					<div className='ScoresLegend'>
						<div className='ScoresLegend__column'>
							<div className='ScoresLegend__key ScoresLegend__key--green'></div>
							<div className='ScoresLegend__description'>
								<strong>High</strong> (4 & Above)
							</div>
						</div>

						<div className='ScoresLegend__column'>
							<div className='ScoresLegend__key ScoresLegend__key--yellow'></div>
							<div className='ScoresLegend__description'>
								<strong>Medium</strong> (3 — 3.99)
							</div>
						</div>

						<div className='ScoresLegend__column'>
							<div className='ScoresLegend__key ScoresLegend__key--red'></div>
							<div className='ScoresLegend__description'>
								<strong>Low</strong> (Less Than 3)
							</div>
						</div>
					</div>

					<div className='TeamSummary__pyramidLayout'>
						<div className='TeamSummary__pyramidLevels'>
							<div className='level'>
								<span>Results</span>
							</div>
							<div className='level'>
								<span>Accountability</span>
							</div>
							<div className='level'>
								<span>Commitment</span>
							</div>
							<div className='level'>
								<span>Conflict</span>
							</div>
							<div className='level'>
								<span>Trust</span>
							</div>
						</div>

						<div className='TeamSummary__pyramid'>
							<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 442.67 337.17'>
								<title>Asset 1</title>
								<g data-name='Layer 2' className='ScoresPyramid'>
									<g id='Layer_1-2' data-name='Layer 1'>
										<polygon
											fill='#F9FAFB'
											points='220.49 0 440.98 337.17 0 337.17 220.49 0'
											transform='translate(-15 0)'
										/>
										<polygon
											className={parentLevels[ReportService.ACCOUNTABILITY]}
											points='220.18 77.23 169.68 77.23 128.16 140.72 220.18 140.72 220.18 77.23'
										/>
										<polygon
											className={parentLevels[ReportService.COMMITMENT]}
											points='220.18 142.72 127.05 142.72 85.53 206.2 220.18 206.2 220.18 142.72'
										/>
										<polygon
											className={parentLevels[ReportService.CONFLICT]}
											points='220.18 208.2 84.03 208.2 42.52 271.68 220.18 271.68 220.18 208.2'
										/>
										<polygon
											className={parentLevels[ReportService.TRUST]}
											points='220.18 273.68 41.52 273.68 0 337.17 220.18 337.17 220.18 273.68'
										/>
										<polygon
											className={childLevels[ReportService.ACCOUNTABILITY]}
											points='222.45 77.23 272.95 77.23 314.47 140.72 222.45 140.72 222.45 77.23'
										/>
										<polygon
											className={childLevels[ReportService.COMMITMENT]}
											points='222.45 142.72 315.51 142.72 357.02 206.2 222.45 206.2 222.45 142.72'
										/>
										<polygon
											className={childLevels[ReportService.CONFLICT]}
											points='222.45 208.2 358.33 208.2 399.85 271.68 222.45 271.68 222.45 208.2'
										/>
										<polygon
											className={childLevels[ReportService.TRUST]}
											points='222.45 273.68 401.15 273.68 442.67 337.17 222.45 337.17 222.45 273.68'
										/>
										<polygon
											className={parentLevels[ReportService.RESULTS]}
											points='220.18 0 170.99 75.23 220.18 75.23 220.18 0'
										/>
										<polygon
											className={childLevels[ReportService.RESULTS]}
											points='222.45 0 271.64 75.23 222.45 75.23 222.45 0'
										/>
									</g>
								</g>
							</svg>
						</div>
					</div>

					<div className='TeamSummary__Dates'>
						<span>{parentCompletedAt}</span>
						<span>{childCompletedAt}</span>
					</div>
				</div>
			</div>
		</AppPdfPage>
	);
};

export default TeamSummary;
