import ApiService from './ApiService';

const getSurvey = (payload, type) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/itp/${type}/take`, payload)
			.then((data) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
const postSurvey = (payload, type) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/itp/${type}/end`, payload)
			.then((data) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const getSurveyPdfDetails = (payload, type) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/itp/${type}/map`, payload)
			.then((data) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const SurveyService = {
	getSurvey,
	postSurvey,
	getSurveyPdfDetails,
};

export default SurveyService;
