import React from 'react';
import dayjs from 'dayjs';
import MarkAsReadToggle from './MarkAsReadToggle';
import MarkAsReadToggleMobile from './MarkAsReadToggleMobile';
import Images from '../../assets/Images';

const TtgTeamNotificationsItem = ({ notification, onStatusChange }) => {
	const isRead = notification.read;

	return (
		<div className={`flex items-start flex-row relative p-2 mb-4 ${isRead ? '' : 'bg-gray-100'}`}>
			<img src={Images.tacLogo} alt='Logo' width='24' height='24' />
			<div className={'flex flex-1 flex-col ml-2'}>
				<p
					className={'text-lg font-medium mb-2 mr-2 text-gray-800'}
					dangerouslySetInnerHTML={{ __html: notification.title }}
				/>
				<p
					className={'text-normal mb-2 mr-2 text-gray-600'}
					dangerouslySetInnerHTML={{ __html: notification.text }}
				/>
				<p className={'text-tiny text-gray-500'}>{dayjs(notification.notification_date).fromNow()}</p>
			</div>
			<div className={'hidden md:block'}>
				<MarkAsReadToggle notification={notification} onComplete={onStatusChange} />
			</div>
			<div className={'block absolute top-0 right-0 m-1 md:hidden'}>
				<MarkAsReadToggleMobile notification={notification} onComplete={onStatusChange} />
			</div>
		</div>
	);
};

export default TtgTeamNotificationsItem;
