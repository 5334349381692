import React, { createContext, useContext } from 'react';

export const AssessmentContext = createContext(undefined);

export const AssessmentProvider = ({ children }) => {
	const [setupAssessment, setSetupAssessment] = React.useState({});
	const [loading, setLoading] = React.useState(false);
	const [assessmentDetails, setAssessmentDetails] = React.useState(null);
	const [newAssessmentId, setNewAssessmentId] = React.useState(null);
	const [reportData, setReportData] = React.useState(null);
	const [selectedFilter, setSelectedFilter] = React.useState(0);

	return (
		<AssessmentContext.Provider
			value={{
				setupAssessment,
				setSetupAssessment,
				loading,
				setLoading,
				assessmentDetails,
				setAssessmentDetails,
				newAssessmentId,
				setNewAssessmentId,
				reportData,
				setReportData,
				selectedFilter,
				setSelectedFilter,
			}}
		>
			{children}
		</AssessmentContext.Provider>
	);
};

export const useAssessments = () => useContext(AssessmentContext);

export const AssessmentContextLayout = ({ children }) => <AssessmentProvider>{children}</AssessmentProvider>;
