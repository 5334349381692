import React, { createContext, useContext } from 'react';
import NotificationService from '../services/NotificationService';

export const NotificationContext = createContext(undefined);

const initialState = {
	today: [],
	later: [],
	ttg: [],
	loading: false,
	unreadLocal: 0,
	unreadGlobal: 0,
	unreadAll: 0,
};

export const NotificationProvider = ({ children }) => {
	const [loading, setLoading] = React.useState(false);
	const [notifications, setNotifications] = React.useState(initialState);

	const loadNotifications = () => {
		setLoading(true);

		NotificationService.get()
			.then((notifications) => setNotifications(notifications))
			.catch(() => setNotifications(initialState))
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<NotificationContext.Provider
			value={{
				loading,
				setLoading,
				notifications,
				setNotifications,
				loadNotifications
			}}
		>
			{children}
		</NotificationContext.Provider>
	);
};

export const useNotifications = () => useContext(NotificationContext);

export const NotificationContextLayout = ({ children }) => <NotificationProvider>{children}</NotificationProvider>;
