import React from 'react';
import { AppIcon } from '../App';

const Empty = () => {
	return (
		<div className='ttg-no-address-info-section'>
			<AppIcon icon='contacts-book-2-line' className='ttg-text-5xl text-gray-400' />
			<p className='ttg-no-address-p'>
				You don’t have any address <br /> registered in the address book
			</p>
		</div>
	);
};

export default Empty;
