import React from 'react';
import { AppButton, AppInput, AppSlideOver } from '../../App';
import { useForm, useFormState } from 'react-hook-form';
import UtilityService from '../../../services/UtilityService';
import ConfirmTransfer from './ConfirmTransfer';
import CustomerService from '../../../services/CustomerService';
import { useCustomer } from '../../../providers/CustomerProvider';
import NotificationService from '../../../services/NotificationService';

const TransferLicenses = ({ isOpen, onClose, updateFilters }) => {
	const { licenses: { licenses }, loading: loadingVerifyUser, setLoading } = useCustomer();

	const [email, setEmail] = React.useState('');
	const [adjustment, setAdjustment] = React.useState(0);
	const [confirmTransferModal, setConfirmTransferModal] = React.useState(false);
	const [existingAccount, setExistingAccount] = React.useState(true);

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		control,
		reset,
	} = useForm();

	const { isSubmitted } = useFormState({ control });

	const trimInputValue = (name, e) => {
		setValue(name, e.target.value.trim(), {
			shouldValidate: isSubmitted,
			shouldDirty: true,
		});
	};

	const _save = (_payload) => {
		setEmail(_payload.email);
		setLoading(true);
		CustomerService.verifyUserEmail(_payload)
			.then((response) => {
				setExistingAccount(response.exists);
				setConfirmTransferModal(true);
			})
			.catch((error) => NotificationService.error('Error verifying email:', error.message))
			.finally(() => setLoading(false));
	};

	React.useEffect(() => {
		if (!isOpen) {
			reset();
			setAdjustment(0);
			setExistingAccount(true);
			setEmail('');
		}
	}, [isOpen, reset]);

	return (
		<AppSlideOver isOpen={isOpen} closeModal={onClose} slideOverContentClass={'!bg-white'}>
			<form
				className='transfer-licenses-sidebar'
				onSubmit={handleSubmit(_save)}
				id={'ttg-transfer-licenses-form'}
			>
				<div className='p-4'>
					<h1 className={'ttg-text-lg font-medium text-gray-900'}>Transfer licenses</h1>
					<p className={'ttg-text-sm text-gray-400 font-normal'}>Transfer licenses to a user.</p>

					<h1 className={'ttg-text-lg font-medium text-gray-900 mt-8'}>My licenses</h1>

					<hr className='text-gray-200 my-4' />
					<p className={'ttg-text-sm text-gray-800 font-normal'}>
						Total unused licenses <b className='float-right'>{licenses || 0}</b>
					</p>
					<hr className='text-gray-200 my-4' />

					<h1 className={'ttg-text-lg font-medium text-gray-900 mt-8'}>Transfer licenses to</h1>
					<hr className='text-gray-200 my-4' />
					<AppInput
						id={'ttg-transfer-licenses-email-id'}
						label={'Email Address'}
						{...register('email', {
							required: true,
							pattern: {
								value: UtilityService.emailRegex,
								message: 'should be a valid email',
							},
							maxLength: 96,
						})}
						data-ttg-max-length={96}
						errors={errors}
						onBlur={(e) => trimInputValue('email', e)}
						onChange={(e) => {
							if (e.target.value.trim() !== '') {
								setExistingAccount(!(email === e.target.value.trim()));
							}
							setValue('email', e.target.value);
						}}
					/>

					<div className='flex flex-row items-center justify-between'>
						<p className='ttg-text-sm text-gray-800 font-medium'>Number of licenses to transfer</p>
						<AppInput
							id={'ttg-transfer-licenses-number-id'}
							min={1}
							max={licenses}
							disabled={licenses === 0}
							containerClassName={'!mb-0 w-20'}
							type={'number'}
							value={adjustment}
							onChange={(e) => setAdjustment(Math.min(e.target.value, licenses))}
							errors={errors}
						/>
					</div>

					<hr className='text-gray-200 my-4' />
					<div>
						<div className={'flex mb-6'}>
							<AppButton
								type={'submit'}
								id={'ttg-transfer-licenses-submit-button'}
								className={'btn btn-primary w-full !px-0'}
								text={'Transfer'}
								loading={loadingVerifyUser}
								disabled={licenses === 0 || adjustment === 0}
							/>
							<AppButton
								id={'ttg-transfer-licenses-cancel-button'}
								type={'button'}
								className={'btn btn-secondary ml-4 w-full'}
								text={'Cancel'}
								loading={loadingVerifyUser}
								onClick={onClose}
							/>
						</div>
					</div>
				</div>
			</form>
			<ConfirmTransfer
				isOpen={confirmTransferModal}
				onClose={() => {
					setConfirmTransferModal(false);
				}}
				onSuccessClose={() => {
					setConfirmTransferModal(false);
					setTimeout(() => {
						onClose();
						updateFilters();
					}, 500);
				}}
				email={email}
				licenses={adjustment}
				existingAccount={existingAccount}
			/>
		</AppSlideOver>
	);
};

export default TransferLicenses;
