export enum CAPTCHA_ACTIONS {
  VERIFY_USER_EMAIL = 'VERIFY_USER_EMAIL',
  REGISTER_USER = 'REGISTER_USER',
  TTG_FIND_ASSESSMENT = 'TTG_FIND_ASSESSMENT',
  TTG_REQUEST_ASSESSMENT = 'TTG_REQUEST_ASSESSMENT',
  TTG_SEND_ASSESSMENT_REMINDER = 'TTG_SEND_ASSESSMENT_REMINDER',
  TTG_TAKE_SURVEY = 'TTG_TAKE_SURVEY',
  TTG_DELETE_CUSTOMER = 'TTG_DELETE_CUSTOMER',
  TTG_VERIFY_TOKEN = 'TTG_VERIFY_TOKEN',
  TTG_FORGOT_PASSWORD = 'TTG_FORGOT_PASSWORD',
  WG_GENERATE_PODCAST_PDF = 'WG_GENERATE_PODCAST_PDF',
  WG_UPDATE_CUSTOMER_PERMISSION = 'WG_UPDATE_CUSTOMER_PERMISSION',
  WG_FIND_ROSTER = 'WG_FIND_ROSTER',
  WG_WORKSHOP_REGISTER = 'WG_WORKSHOP_REGISTER',
  WG_ACCEPT_ASSESSMENT_REQUEST = 'WG_ACCEPT_ASSESSMENT_REQUEST',
  WG_DECLINE_ASSESSMENT_REQUEST = 'WG_DECLINE_ASSESSMENT_REQUEST',
  WG_SAMPLE_REPORT = 'WG_SAMPLE_REPORT',
  WG_SAMPLE_BOOK = 'WG_SAMPLE_BOOK',
  WG_WEBINAR_REGISTER = 'WG_WEBINAR_REGISTER',
  WG_INVITE_CUSTOMER = 'WG_INVITE_CUSTOMER',
}
