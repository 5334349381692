import React from 'react';
import { TakeAssessmentNavigation } from '../index';
import RadioButton from './RadioButton';
import UtilityService from '../../../services/UtilityService';

const RadioQuestionStep = ({
	questions,
	onAnswer,
	nextStep,
	previousStep,
	currentStep,
	totalSteps,
	onComplete,
	onProgress,
	loading,
	saveProgress,
	step,
	updatingProgress,
}) => {
	React.useEffect(() => {
		window.addEventListener('beforeunload', UtilityService.confirmPageLeave);
		return () => window.removeEventListener('beforeunload', UtilityService.confirmPageLeave);
	}, []);

	const [submitted, setSubmitted] = React.useState(false);

	const isLastStep = React.useMemo(() => {
		return currentStep === totalSteps - 1;
	}, [currentStep, totalSteps]);

	const _next = () => {
		setSubmitted(true);
		const notAnsweredQuestions = questions.filter((q) => q.answer < 1);
		if (notAnsweredQuestions.length > 0) {
			const violation = document.getElementById(
				`ttg-take-assessment-question-${notAnsweredQuestions[0].sort_order}`
			);
			window.scrollTo({
				top: violation.offsetTop,
				behavior: 'smooth',
			});
		} else if (isLastStep) {
			onComplete(nextStep);
		} else if (saveProgress) {
			onProgress(step, nextStep);
		} else {
			nextStep();
		}
	};

	return (
		<div className={'ttg-account-section flex-col'}>
			{questions.map((question, idx) => {
				return (
					<div
						className={`mb-4 ${idx === 0 ? '' : 'border-t-1 border-gray-200 pt-4'}`}
						key={idx}
						id={`ttg-take-assessment-question-${question.sort_order}`}
					>
						<div
							className={`ttg-text-lg font-medium mb-2 ${
								submitted && question.answer < 0 ? 'text-rose-500' : 'text-gray-800'
							}`}
						>
							<p dangerouslySetInnerHTML={{ __html: `${question.sort_order}. ${question.title}` }}></p>
						</div>
						<div className={'flex flex-col md:flex-row'}>
							{question.options.map((option, i) => {
								return (
									<RadioButton
										key={i}
										label={option.label}
										labelClass={'ml-2 md:ml-0'}
										direction={
											'flex-row md:flex-col items-start justify-start md:items-center md:justify-center'
										}
										checked={option.value === question.answer}
										onClick={() => {
											onAnswer(idx, option.value);
										}}
									/>
								);
							})}
						</div>
					</div>
				);
			})}
			<TakeAssessmentNavigation
				backText={'Back'}
				canGoBack={currentStep !== 2}
				onBack={previousStep}
				onNext={_next}
				loading={loading || updatingProgress}
				nextText={isLastStep ? 'Finish' : 'Next'}
			/>
		</div>
	);
};

export default RadioQuestionStep;
