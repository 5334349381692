import React from 'react';
import { useLocation } from 'react-router-dom';
import { useForm, useFormState } from 'react-hook-form';
import PasswordChecklist from 'react-password-checklist';

import { AccountLayout } from '../../components/Layout';
import { AppButton, AppIcon, AppInput, AppNotice } from '../../components/App';
import UtilityService from '../../services/UtilityService';
import config from '../../constants/config';
import CustomerService from '../../services/CustomerService';
import { useCustomer } from '../../providers/CustomerProvider';
import NotificationService from '../../services/NotificationService';

const ChangePassword = () => {
	const location = useLocation();
	const { reset_code, next } = UtilityService.getQueryParams(location.search);
	
	const { customer, setCustomer, loading: updating, setLoading } = useCustomer();
	
	const [showRestrictions, setShowRestrictions] = React.useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		watch,
		reset,
		control,
		setError,
		clearErrors,
		setValue,
	} = useForm({
		defaultValues: {
			token: reset_code,
			old_password: '',
			new_password: '',
			confirm_password: '',
		},
	});

	const { isDirty, isSubmitted } = useFormState({ control });

	React.useEffect(() => {
		if (isSubmitted && getValues('confirm_password') !== getValues('new_password')) {
			setError('confirm_password', { type: 'validate', message: 'Passwords do not match.' });
		}
	}, [isSubmitted, getValues, setError]);

	const _update = (_passwords) => {
		setLoading(true);
		if (_passwords.new_password !== _passwords.confirm_password) {
			return setError('confirm_password', { type: 'validate', message: 'Passwords do not match.' });
		}
		if (reset_code) {
			return CustomerService.updatePassword({
				old_password: reset_code,
				new_password: _passwords.new_password,
			})
				.then(() => {
					setCustomer({ ...customer, needs_password_change: false });
					NotificationService.success('', 'Password Successfully changed!');
					if (next) {
						return window.open(UtilityService.prepareRedirectionPath(next), '_self');
					}
					reset();
				})
				.finally(() => setLoading(false));
		}
		return CustomerService.updatePassword({
			old_password: _passwords.old_password,
			new_password: _passwords.new_password,
		})
			.then(() => {
				NotificationService.success('', 'Password Successfully changed!');
				reset();
			})
			.finally(() => setLoading(false));
	};

	const disabledForms = React.useMemo(
		() => customer.customers_login_method !== config.AUTH_PROVIDERS.PASSWORD,
		[customer]
	);

	return (
		<AccountLayout>
			{!!customer.needs_password_change && (
				<AppNotice
					containerClass={'ttg-account-section'}
					title={'Password change required!'}
					description={`Please change your password first, then you can access your Team Assessment Console.`}
				/>
			)}
			<div className='ttg-account-section'>
				<form id={'ttg-change-password-form'} onSubmit={handleSubmit(_update)}>
					<h1 className='ttg-text-2xl mb-8'>{reset_code ? 'Reset Password' : 'Change Password'}</h1>
					{!reset_code && (
						<AppInput
							disabled={disabledForms}
							id={'ttg-current-password-input-field'}
							label={'Current Password'}
							errors={errors}
							{...register('old_password', {
								required: true,
							})}
							passwordInput={true}
						/>
					)}
					<AppInput
						disabled={disabledForms}
						id={'ttg-new-password-input-field'}
						label={'New Password'}
						errors={errors}
						{...register('new_password', {
							required: true,
							pattern: {
								value: UtilityService.passwordRegex,
								message: "Your password doesn't meet the requirements.",
							},
						})}
						passwordInput={true}
						onFocus={() => setShowRestrictions(true)}
						onBlur={() => setShowRestrictions(false)}
					/>
					{showRestrictions && (
						<div className={'ttg-password-requirements'}>
							<h1 className={'ttg-requirements-title'}>Password requirements</h1>
							<PasswordChecklist
								disabled={disabledForms}
								rules={['minLength', 'capital', 'lowercase', 'number']}
								minLength={8}
								value={watch('new_password')}
								iconComponents={{
									ValidIcon: <AppIcon icon='checkbox-circle-fill' style={{ color: '#10B981' }} />,
									InvalidIcon: <AppIcon icon='error-warning-fill' style={{ color: '#F59E0B' }} />,
								}}
								messages={{
									minLength: 'Must have at least 8 characters',
									capital: 'Must have at least 1 upper case',
									lowercase: 'Must have at least 1 lower case',
									number: 'Must have at least 1 number',
								}}
								className={'ttg-password-checklist'}
							/>
						</div>
					)}
					<AppInput
						disabled={disabledForms}
						id={'ttg-confirm-password-input-field'}
						label={'Confirm Password'}
						errors={errors}
						{...register('confirm_password', {
							required: true,
						})}
						onChange={(e) => {
							const val = e.target.value;
							setValue('confirm_password', val, { shouldDirty: true });
							if (isSubmitted && val !== getValues('new_password')) {
								return setError('confirm_password', { type: 'validate', message: 'Passwords do not match.' });
							} return clearErrors('confirm_password');
						}}
						passwordInput={true}
					/>
					<AppButton
						id={'ttg-change-password-submit-button'}
						type={'submit'}
						text={'Save Changes'}
						loading={updating}
						disabled={!isDirty || disabledForms}
					/>
				</form>
			</div>
		</AccountLayout>
	);
};

export default ChangePassword;
