import React, { createContext, useCallback, useContext, useEffect } from 'react';
import CustomerService from '../services/CustomerService';
import NotificationService from '../services/NotificationService';
import Cookies from 'js-cookie';
import RouteNames from '../router/RouteNames';
import { AppLoading } from '../components/App';
import { useNotifications } from './NotificationProvider';

export const CustomerContext = createContext(undefined);

export const CustomerProvider = ({ children }) => {
	const [loading, setLoading] = React.useState(false);
	const [loaded, setLoaded] = React.useState(false);
	const [customer, setCustomer] = React.useState(null);
	const [licenses, setLicenses] = React.useState({});
	const [signup, setSignup] = React.useState({
		signinDetails: {},
		accountDetails: {},
		addressDetails: {},
		confirmEmail: {},
	});
	const { loadNotifications } = useNotifications();

	const shouldLogOut = React.useMemo(() => {
		const qs = window.location.href.split('?')[1];
		return qs && qs.indexOf('logout') > -1;
	}, []);

	const _loadNotifications = React.useCallback(() => {
		if (!shouldLogOut) return loadNotifications();
	}, [shouldLogOut]);

	const loadCustomer = useCallback(() => {
		setLoading(true);
		if (shouldLogOut) { logout() }
		CustomerService.getCustomerData()
			.then(({ user_data }) => {
				window.ttg_timezone = user_data.default_timezone;
				setCustomer(user_data);
			})
			.finally(() => {
				setLoading(false);
				setLoaded(true);
			});
	}, []);

	useEffect(() => {
		loadCustomer();
		_loadNotifications();
	}, [loadCustomer, _loadNotifications]);

	const logout = () => {
		setCustomer(null);
		setLoading(true);
		setLoaded(false);

		localStorage.removeItem('usersCart-TTG');
		CustomerService.logout()
			.catch((error) => {
				NotificationService.error('Error logging out:', error.message);
			})
			.finally(() => {
				Cookies.remove('TTG_USER');
				Cookies.remove('PHPSESSID');
				window.open(RouteNames.Login, '_self');
				setLoading(false);
			});
	};

	return (
		<CustomerContext.Provider
			value={{
				loading,
				setLoading,
				customer,
				setCustomer,
				licenses,
				setLicenses,
				signup,
				setSignup,
				logout,
				loaded,
			}}
		>
			{!loaded || shouldLogOut ? <AppLoading /> : <>{children}</>}
		</CustomerContext.Provider>
	);
};

export const useCustomer = () => useContext(CustomerContext);

export const CustomerContextLayout = ({ children }) => <CustomerProvider>{children}</CustomerProvider>;
