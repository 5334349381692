import React from 'react';
import { AppButton } from '../../App';

const Navigation = ({
	iconClass,
	backIcon,
	backText,
	nextIcon,
	nextClass = 'btn btn-primary',
	nextText,
	onBack,
	children,
	loading,
}) => {
	return (
		<div className='flex flex-1 flex-col md:flex-row pt-4 border-t-1 border-gray-200'>
			<div className='flex flex-1'>
				<AppButton
					type={'button'}
					className={'btn btn-secondary w-full md:w-auto'}
					icon={backIcon}
					iconClass={iconClass}
					iconRight={false}
					text={backText}
					onClick={onBack}
					id={'ttg-setup-assessment-prev-button'}
				/>
			</div>
			{children}
			<AppButton
				type={'submit'}
				className={`${nextClass} md:ml-4 mt-2 md:mt-0`}
				text={nextText}
				loading={loading}
				icon={nextIcon}
				iconRight={false}
				id={'ttg-setup-assessment-next-button'}
			/>
		</div>
	);
};

export default Navigation;
