import React from 'react';
import ReactTooltip from 'react-tooltip';

const Tooltip = ({ id, placement = 'top', type = 'dark', multiline = true, children, fontSize }) => {
	return (
		<ReactTooltip
			id={id}
			place={placement}
			type={type}
			effect={'solid'}
			multiline={multiline}
			className={'ttg-tooltip'}
		>
			<span className={`ttg-text-${fontSize} font-normal`}>{children}</span>
		</ReactTooltip>
	);
};

export default Tooltip;
