import React from 'react';
import { AppPdfPage } from '../../../App';
import Images from '../../../../assets/Images';

const TeamCultureQuestionsResults = ({ details, page }) => {
	const questions = React.useMemo(() => {
		return details.responses_multiple
			.filter(({ question }) => question === 42)
			.sort((a, b) => {
				return (
					parseInt(b.response_count) - parseInt(a.response_count) ||
					a.response_text.localeCompare(b.response_text)
				);
			});
	}, [details]);

	const renderProgressBar = (value) => {
		value = parseInt(value);

		let percentage = (value / details.responses.length) * 100;

		if (percentage === 0) {
			percentage = 0.5;
		}

		return (
			<div className='ProgressBar'>
				<svg className='ProgressBar__meter'>
					<rect className='ProgressBar__meterBar' />
					<rect className={`ProgressBar__meterFill`} width={`${percentage}%`} />
				</svg>
			</div>
		);
	};

	return (
		<AppPdfPage page={page} sectionClassName={'TeamCultureQuestions'}>
			<div className='TeamCultureQuestions__layout'>
				<div className='TeamCultureQuestions__heading'>
					<div className='PageHeading'>
						<div className='PageHeading__title'>Team Culture Questions</div>
						<div className='PageHeading__image'>
							<img width='82' src={Images.report.puzzlePieces} alt={''} />
						</div>
					</div>
				</div>
				<div className='TeamCultureQuestions__content'>
					<div className='TeamCultureQuestions__continuedText'>(Continued from the previous page.)</div>

					<div className='TeamCultureQuestions__blurb Blurb'>
						<div className='Blurb__title'>Focusing on Results</div>
						<div className='Blurb__subTitle'>What Is Needed To Focus On Results?</div>
						<div className='Blurb__content'>
							As part of the assessment, you and your team members had an opportunity to identify what you
							believe might be a distraction from achieving results. The number of team members who
							selected each option appears next to the corresponding bar. You and your team members had
							the option to select all that apply.
						</div>
					</div>

					<div className='TeamCultureQuestions__questionReview'>
						<div className='TeamCultureQuestions__questionReviewTitle'>
							Some distractions that keep us from focusing on results are:
						</div>

						<div className='TeamCultureQuestions__scoreMeterList'>
							{questions.map((question, index) => (
								<div className='TeamCultureQuestions__scoreMeterItem' key={index}>
									<div
										className='TeamCultureQuestions__scoreMeterItemTitle'
										dangerouslySetInnerHTML={{ __html: question.response_text }}
									/>
									<div className='TeamScoreMeter'>
										<div className='TeamScoreMeter__meter'>
											{renderProgressBar(question.response_count)}
										</div>
										<div className='TeamScoreMeter__label'>
											{question.response_count} out of {details.responses.length}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</AppPdfPage>
	);
};

export default TeamCultureQuestionsResults;
