const Scores = ({ questions, disciplines, page = 1, start = 1 }) => {
	return (
		<div className={'flex-row py-1'}>
			{questions.map((el, i) => (
				<div key={i}>
					<div className={`flex-row ttg-text-sm font-medium pt-${page} survey-font`}>
						<div className={'pb-1 inline-flex font-semibold'}>
							{i + (page - 1) * 10 + start}.{' '}
							<div className={`pl-${3 - page} font-medium`}>{el.question}</div>
						</div>
						<div className={`pl-${4 + page} pt-1 survey-font`}>
							<div>Response: {el.response}</div>
							<div>
								Discipline {el.discipline}: {disciplines[el.discipline - 1].name}
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default Scores;
