import React from 'react';
import { AppMobileSidebar, AssessmentHeader } from '../index';
import { AppLoading } from '../../App';
import UtilityService from '../../../services/UtilityService';
import CustomerService from '../../../services/CustomerService';
import { useCustomer } from '../../../providers/CustomerProvider';

const MainLayout = ({ loading, children }) => {
	const [isOpenSidebar, setIsOpenSidebar] = React.useState(false);

	const { licenses: { licenses }, setLicenses } = useCustomer();

	const handleResize = () => {
		UtilityService.setContentMarginTop('ttg-assessment-main-layout-header', 'ttg-assessment-main-layout-content');
	};

	React.useEffect(() => {
		handleResize();
	}, [handleResize]);

	React.useEffect(() => {
		if (!licenses) {
			CustomerService.getLicensesNumber().then((response) => {
				const { licenses, ordered_licenses, used_licenses } = response;
				setLicenses((prev) => ({ ...prev, licenses, ordered_licenses, used_licenses: Number(used_licenses) }));
			});
		}
	}, []);

	React.useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [loading]);

	const renderContent = () => {
		if (loading) {
			return <AppLoading height={'h-96'} />;
		}
		return children;
	};

	return (
		<div className={'ttg-layout ttg-assessment-main-layout'}>
			<AssessmentHeader onMenuClick={() => setIsOpenSidebar(!isOpenSidebar)} />
			<div className={'ttg-assessment-main-layout-content'}>
				<div className={'block md:hidden'}>
					<AppMobileSidebar
						sidebar={false}
						isOpen={isOpenSidebar}
						closeModal={() => setIsOpenSidebar(!isOpenSidebar)}
						slideFrom='left'
					/>
				</div>
				<div className={'ttg-assessment-main-layout-inner-content'}>{renderContent()}</div>
			</div>
		</div>
	);
};

export default MainLayout;
