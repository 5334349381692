import React from 'react';

import Images from '../../../assets/Images';

const Header = ({ status, title, className = '', logo = Images.logo }) => {
	return (
		<div className={`ttg-public-main-layout-header ${className ?? ''}`}>
			<div className='logo mt-20 mb-8' id='ttg-public-header-logo'>
				<a href='/'>
					<img src={logo} alt='Logo' />
				</a>
			</div>
			{status && (
				<div className='status mb-4' id='ttg-public-header-status'>
					{status}
				</div>
			)}
			{title && (
				<div className='title mb-8 ttg-text-2xl text-gray-900 font-semibold' id='ttg-public-header-title'>
					<span>{title}</span>
				</div>
			)}
		</div>
	);
};

export default Header;
