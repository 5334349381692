import React from 'react';

const Details = ({ order }) => {
	const Piece = (label, value) => {
		if (!value) {
			return null;
		}
		return (
			<div className={'flex border-b-1 border-gray-300 py-1'}>
				<p className={'flex flex-1 text-gray-800 font-medium ttg-text-xs'}>{label}</p>
				<span className={'ttg-text-xs text-gray-500'}>{value}</span>
			</div>
		);
	};

	return (
		<div className='flex flex-col mt-5'>
			<h1 className='ttg-text-base text-gray-800 font-medium mb-2'>Order Details</h1>
			{Piece('Order ID', order.id)}
			{Piece('Date of Order', order.date_purchased)}
			{Piece('Order Status', order.status)}
			{Piece('Payment Method', order.payment_method)}
			{Piece('Purchase Order Number', order.po_number)}
			{Piece('Authorized By', order.po_authorizer)}
		</div>
	);
};

export default Details;
