import React from 'react';
import ReactPlayer from 'react-player';
import { AppIcon, AppLoading } from './index';

const VideoPlayer = React.forwardRef(
	({ url, width = '100%', height = 'auto', onClick, loadingClass, canPlay = false, ...props }, ref) => {
		const [videoHeight, setVideoHeight] = React.useState('0');

		const onReady = () => setVideoHeight(height);

		return (
			<div className={'relative'}>
				{videoHeight === '0' && <AppLoading height={'h-24'} size={`w-4 ${loadingClass}`} />}
				{!canPlay && videoHeight !== '0' && (
					<div
						className='flex absolute top-0 left-0 w-full h-full items-center justify-center cursor-pointer'
						onClick={onClick}
					>
						<AppIcon icon={'play-circle-fill'} className={'text-white ttg-text-6xl'} />
					</div>
				)}
				<ReactPlayer ref={ref} url={url} width={width} height={videoHeight} onReady={onReady} {...props} />
			</div>
		);
	}
);

export default VideoPlayer;
