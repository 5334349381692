import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import config from '../../../constants/config';
import { AppIcon } from '../../App';
import Images from '../../../assets/Images';
import RouteNames from '../../../router/RouteNames';
import UtilityService from '../../../services/UtilityService';
import { useCustomer } from '../../../providers/CustomerProvider';

const Tools = () => {
	const navigate = useNavigate();

	const { customer } = useCustomer();
	const { show_capapro, show_meeting_advantage } = customer;

	return (
		<div className={'ttg-account-tools'}>
			<div className='ttg-account-ttg-tools'>
				<h1 className='ttg-account-label'>Tablegroup</h1>
				<Link to={RouteNames.TeamAssessmentConsole} className={'ttg-account-tool'} id='ttg-account-tac-tool'>
					<div className='flex items-center justify-between'>
						<img src={Images.tacLogo} alt='Logo' width='22' height='22' />
						<span className='ttg-tool-title'>Team Assessment Console</span>
					</div>
					<AppIcon icon={'share-box-fill'} />
				</Link>
				{!!show_meeting_advantage && (
					<div
						className='ttg-account-tool'
						onClick={() => window.open(`${RouteNames.MeetingAdvantage}`, '_self')}
						id='ttg-account-ma-tool'
					>
						<div className='flex items-center justify-between'>
							<img src={Images.maLogo} alt='Logo' width='22' height='22' />
							<span className='ttg-tool-title'>The Meeting Advantage</span>
						</div>
						<AppIcon icon={'share-box-fill'} />
					</div>
				)}
				{!!show_capapro && (
					<div
						className='ttg-account-tool'
						onClick={() => window.open(`${RouteNames.CapaPro}`, '_self')}
						id='ttg-account-capa-tool'
					>
						<div className='flex items-center justify-between'>
							<img src={Images.capaLogo} alt='Logo' width='22' height='22' />
							<span className='ttg-tool-title'>
								CAPA<sup>PRO</sup>{' '}
							</span>
						</div>
						<AppIcon icon={'share-box-fill'} />
					</div>
				)}
				<div
					className='ttg-account-tool'
					onClick={() => navigate(`${RouteNames.VideoList}`)}
					id='ttg-account-videos-tool'
				>
					<div className='flex items-center justify-between'>
						<img src={Images.videosLogo} alt='Logo' width='22' height='22' />
						<span className='ttg-tool-title'>Streaming Videos</span>
					</div>
					<AppIcon icon={'share-box-fill'} />
				</div>
			</div>
			<div className='ttg-account-wg-tools'>
				<h1 className='ttg-account-label'>Workinggenius</h1>
				<div
					className='ttg-account-tool'
					onClick={() => {
						const token = UtilityService.getAuthCookie();
						window.open(`${config.wg_url}/client/?token=${token}`);
					}}
					id='ttg-account-wgac-tool'
				>
					<div className='flex items-center justify-between'>
						<img src={Images.wgacLogo} alt='Logo' width='22' height='22' />
						<span className='ttg-tool-title'>WG Assessment Console</span>
					</div>
					<AppIcon icon={'share-box-fill'} />
				</div>
				<div
					className='ttg-account-tool'
					onClick={() => {
						const token = UtilityService.getAuthCookie();
						window.open(`${config.wg_url}/client/certification?token=${token}`);
					}}
					id='ttg-account-wgcr-tool'
				>
					<div className='flex items-center justify-between'>
						<img src={Images.wgcrLogo} alt='Logo' width='22' height='22' />
						<span className='ttg-tool-title'>WG Certification Resources</span>
					</div>
					<AppIcon icon={'share-box-fill'} />
				</div>

				<div
					className='ttg-account-tool'
					onClick={() => {
						const token = UtilityService.getAuthCookie();
						if (customer.show_team_application_guide) {
							return window.open(`${config.wg_url}/application-guide-product?token=${token}`);
						}
						return window.open(`${config.wg_url}/teamapplicationguide?token=${token}`);
					}}
					id='ttg-account-wgtag-tool'
				>
					<div className='flex items-center justify-between'>
						<img src={Images.wgtagLogo} alt='Logo' width='22' height='22' />
						<span className='ttg-tool-title'>Team Application Guide</span>
					</div>
					<AppIcon icon={'share-box-fill'} />
				</div>
			</div>
		</div>
	);
};

export default Tools;
