export enum PERMISSION_ROLE {
  LIMITED = "LIMITED",
  ADMIN = "ADMIN",
}

export enum PERMISSION_ACTION {
  "USER.GET"= "USER.GET",
  "USER.PUT"= "USER.PUT",
  "USER.REFRESH"= "USER.REFRESH",
  "USER.LOGOUT"= "USER.LOGOUT",
  "USER.PASSWORD.PUT"= "USER.PASSWORD.PUT",
  "USER.EMAIL.POST"= "USER.EMAIL.POST",
  "USER.BILLING_PORTAL"= "USER.BILLING_PORTAL",
  "USER.PERMISSION"= "USER.PERMISSION",
  "5DOT.ASSESSMENT"= "5DOT.ASSESSMENT",
  "USER.ADDRESS.GET"= "USER.ADDRESS.GET",
  "USER.ADDRESS.POST"= "USER.ADDRESS.POST",
  "USER.ADDRESS.PUT"= "USER.ADDRESS.PUT",
  "USER.ADDRESS.DELETE"= "USER.ADDRESS.DELETE",
  "USER.ORDER.GET"= "USER.ORDER.GET",
  VIDEO= "VIDEO",
  CAPA_PRO= "CAPA_PRO",
  "NOTIFICATION.GET"= "NOTIFICATION.GET",
  "NOTIFICATION.PUT"= "NOTIFICATION.PUT",
  CHECKOUT= "CHECKOUT",
  "USER.GET.RESOURCE"= "USER.GET.RESOURCE",
  "WG_ASSESSMENT.PUT.LOGO"= "WG_ASSESSMENT.PUT.LOGO",
  "WG_ASSESSMENT.GET"= "WG_ASSESSMENT.GET",
  "WG_ASSESSMENT.PUT"= "WG_ASSESSMENT.PUT",
  "WG_ASSESSMENT.GET.LOGO"= "WG_ASSESSMENT.GET.LOGO",
  "WG_ASSESSMENT.POST.CLOSE"= "WG_ASSESSMENT.POST.CLOSE",
  "WG_ASSESSMENT.POST"= "WG_ASSESSMENT.POST",
  "WG_ASSESSMENT.POST.SHARE"= "WG_ASSESSMENT.POST.SHARE",
  "WG_ASSESSMENT.POST.MOVE"= "WG_ASSESSMENT.POST.MOVE",
  "WG_ASSESSMENT.POST.DUPLICATE"= "WG_ASSESSMENT.POST.DUPLICATE",
  "WG_ASSESSMENT.GET.REQUEST"= "WG_ASSESSMENT.GET.REQUEST",
  "WG_ASSESSMENT.GET.REQUESTS"= "WG_ASSESSMENT.GET.REQUESTS",
  "WG_ASSESSMENT.POST.REQUEST"= "WG_ASSESSMENT.POST.REQUEST",
  "WG_ASSESSMENT.DELETE.REQUEST"= "WG_ASSESSMENT.DELETE.REQUEST",
  "WG_ASSESSMENT.POST.REQUEST.SEND"= "WG_ASSESSMENT.POST.REQUEST.SEND",
  "WG_TEAMMAP.GET"= "WG_TEAMMAP.GET",
  "WG_TEAMMAP.POST"= "WG_TEAMMAP.POST",
  "WG_LICENSE.POST.ADD"= "WG_LICENSE.POST.ADD",
  "WG_LICENSE.POST.TRANSFER"= "WG_LICENSE.POST.TRANSFER",
  "WG_LICENSE.DELETE.TRANSFER"= "WG_LICENSE.DELETE.TRANSFER",
  "WG_LICENSE.GET.LIST"= "WG_LICENSE.GET.LIST",
  "WG_LICENSE.GET.USAGE"= "WG_LICENSE.GET.USAGE",
  "WG_ROSTER.GET.DUPLICATED"= "WG_ROSTER.GET.DUPLICATED",
  "WG_ROSTER.GET"= "WG_ROSTER.GET",
  "WG_ROSTER.DELETE"= "WG_ROSTER.DELETE",
  "WG_ROSTER.PUT.WIDGET"= "WG_ROSTER.PUT.WIDGET",
  "WG_ROSTER.GET.REPORT"= "WG_ROSTER.GET.REPORT",
  "WG_ROSTER.POST.REPORT"= "WG_ROSTER.POST.REPORT",
  "WG_ROSTER.GET.ORIGINAL"= "WG_ROSTER.GET.ORIGINAL",
  "WG_ROSTER.GET.SHARE"= "WG_ROSTER.GET.SHARE",
  "WG_ROSTER.POST.SHARE"= "WG_ROSTER.POST.SHARE",
  "WG_ROSTER.DUPLICATE.DELETE"= "WG_ROSTER.DUPLICATE.DELETE",
  "WG_CERTIFICATION.GET"= "WG_CERTIFICATION.GET",
  "WG_CERTIFICATION.PUT"= "WG_CERTIFICATION.PUT",
  "WG_TRAINEE.GET"= "WG_TRAINEE.GET",
  "WG_TOOLKIT"= "WG_TOOLKIT",
  "WG_ASSESSMENT.TAKE.POST"= "WG_ASSESSMENT.TAKE.POST",
  "TTG_TOOLS"= "TTG_TOOLS",
  "TTG_ORDERS"= "TTG_ORDERS",
}

type AllPermissionActions = {
  [key in PERMISSION_ACTION]: boolean;
};

type UserPermissions = {
  [key in PERMISSION_ROLE]: AllPermissionActions;
};


export const USER_PERMISSIONS: UserPermissions = {
  [PERMISSION_ROLE.ADMIN]: {
    [PERMISSION_ACTION["USER.GET"]]: true,
    [PERMISSION_ACTION["USER.PUT"]]: false,
    [PERMISSION_ACTION["USER.REFRESH"]]: true,
    [PERMISSION_ACTION["USER.LOGOUT"]]: true,
    [PERMISSION_ACTION["USER.PASSWORD.PUT"]]: false,
    [PERMISSION_ACTION["USER.EMAIL.POST"]]: false,
    [PERMISSION_ACTION["USER.BILLING_PORTAL"]]: false,
    [PERMISSION_ACTION["USER.PERMISSION"]]: false,
    [PERMISSION_ACTION["5DOT.ASSESSMENT"]]: false,
    [PERMISSION_ACTION["USER.ADDRESS.GET"]]: true,
    [PERMISSION_ACTION["USER.ADDRESS.POST"]]: true,
    [PERMISSION_ACTION["USER.ADDRESS.PUT"]]: true,
    [PERMISSION_ACTION["USER.ADDRESS.DELETE"]]: true,
    [PERMISSION_ACTION["USER.ORDER.GET"]]: true,
    [PERMISSION_ACTION.VIDEO]: false,
    [PERMISSION_ACTION.CAPA_PRO]: false,
    [PERMISSION_ACTION["NOTIFICATION.GET"]]: true,
    [PERMISSION_ACTION["NOTIFICATION.PUT"]]: false,
    [PERMISSION_ACTION.CHECKOUT]: true,
    [PERMISSION_ACTION["USER.GET.RESOURCE"]]: false,
    [PERMISSION_ACTION["WG_ASSESSMENT.PUT.LOGO"]]: true,
    [PERMISSION_ACTION["WG_ASSESSMENT.GET"]]: true,
    [PERMISSION_ACTION["WG_ASSESSMENT.PUT"]]: true,
    [PERMISSION_ACTION["WG_ASSESSMENT.GET.LOGO"]]: true,
    [PERMISSION_ACTION["WG_ASSESSMENT.POST.CLOSE"]]: true,
    [PERMISSION_ACTION["WG_ASSESSMENT.POST"]]: true,
    [PERMISSION_ACTION["WG_ASSESSMENT.POST.SHARE"]]: true,
    [PERMISSION_ACTION["WG_ASSESSMENT.POST.MOVE"]]: true,
    [PERMISSION_ACTION["WG_ASSESSMENT.POST.DUPLICATE"]]: true,
    [PERMISSION_ACTION["WG_ASSESSMENT.GET.REQUEST"]]: true,
    [PERMISSION_ACTION["WG_ASSESSMENT.GET.REQUESTS"]]: true,
    [PERMISSION_ACTION["WG_ASSESSMENT.POST.REQUEST"]]: true,
    [PERMISSION_ACTION["WG_ASSESSMENT.DELETE.REQUEST"]]: true,
    [PERMISSION_ACTION["WG_ASSESSMENT.POST.REQUEST.SEND"]]: true,
    [PERMISSION_ACTION["WG_ASSESSMENT.TAKE.POST"]]: false,
    [PERMISSION_ACTION["WG_TEAMMAP.GET"]]: true,
    [PERMISSION_ACTION["WG_TEAMMAP.POST"]]: true,
    [PERMISSION_ACTION["WG_LICENSE.POST.ADD"]]: true,
    [PERMISSION_ACTION["WG_LICENSE.DELETE.TRANSFER"]]: true,
    [PERMISSION_ACTION["WG_LICENSE.POST.TRANSFER"]]: true,
    [PERMISSION_ACTION["WG_LICENSE.GET.LIST"]]: true,
    [PERMISSION_ACTION["WG_LICENSE.GET.USAGE"]]: true,
    [PERMISSION_ACTION["WG_ROSTER.GET.DUPLICATED"]]: true,
    [PERMISSION_ACTION["WG_ROSTER.GET"]]: true,
    [PERMISSION_ACTION["WG_ROSTER.DELETE"]]: true,
    [PERMISSION_ACTION["WG_ROSTER.PUT.WIDGET"]]: true,
    [PERMISSION_ACTION["WG_ROSTER.GET.REPORT"]]: true,
    [PERMISSION_ACTION["WG_ROSTER.POST.REPORT"]]: true,
    [PERMISSION_ACTION["WG_ROSTER.GET.ORIGINAL"]]: true,
    [PERMISSION_ACTION["WG_ROSTER.GET.SHARE"]]: true,
    [PERMISSION_ACTION["WG_ROSTER.POST.SHARE"]]: true,
    [PERMISSION_ACTION["WG_ROSTER.DUPLICATE.DELETE"]]: true,
    [PERMISSION_ACTION["WG_CERTIFICATION.GET"]]: false,
    [PERMISSION_ACTION["WG_CERTIFICATION.PUT"]]: false,
    [PERMISSION_ACTION["WG_TRAINEE.GET"]]: false,
    [PERMISSION_ACTION["WG_TOOLKIT"]]: true,
    [PERMISSION_ACTION["TTG_TOOLS"]]: false,
    [PERMISSION_ACTION["TTG_ORDERS"]]: false,
  },
  [PERMISSION_ROLE.LIMITED]: {
    [PERMISSION_ACTION["USER.GET"]]: true,
    [PERMISSION_ACTION["USER.PUT"]]: false,
    [PERMISSION_ACTION["USER.REFRESH"]]: true,
    [PERMISSION_ACTION["USER.LOGOUT"]]: true,
    [PERMISSION_ACTION["USER.PASSWORD.PUT"]]: false,
    [PERMISSION_ACTION["USER.EMAIL.POST"]]: false,
    [PERMISSION_ACTION["USER.BILLING_PORTAL"]]: false,
    [PERMISSION_ACTION["USER.PERMISSION"]]: false,
    [PERMISSION_ACTION["5DOT.ASSESSMENT"]]: false,
    [PERMISSION_ACTION["USER.ADDRESS.GET"]]: true,
    [PERMISSION_ACTION["USER.ADDRESS.POST"]]: true,
    [PERMISSION_ACTION["USER.ADDRESS.PUT"]]: true,
    [PERMISSION_ACTION["USER.ADDRESS.DELETE"]]: true,
    [PERMISSION_ACTION["USER.ORDER.GET"]]: false,
    [PERMISSION_ACTION.VIDEO]: false,
    [PERMISSION_ACTION.CAPA_PRO]: false,
    [PERMISSION_ACTION["NOTIFICATION.GET"]]: true,
    [PERMISSION_ACTION["NOTIFICATION.PUT"]]: false,
    [PERMISSION_ACTION.CHECKOUT]: false,
    [PERMISSION_ACTION["USER.GET.RESOURCE"]]: false,
    [PERMISSION_ACTION["WG_ASSESSMENT.PUT.LOGO"]]: true,
    [PERMISSION_ACTION["WG_ASSESSMENT.GET"]]: true,
    [PERMISSION_ACTION["WG_ASSESSMENT.PUT"]]: false,
    [PERMISSION_ACTION["WG_ASSESSMENT.GET.LOGO"]]: true,
    [PERMISSION_ACTION["WG_ASSESSMENT.POST.CLOSE"]]: false,
    [PERMISSION_ACTION["WG_ASSESSMENT.POST"]]: true,
    [PERMISSION_ACTION["WG_ASSESSMENT.POST.SHARE"]]: false,
    [PERMISSION_ACTION["WG_ASSESSMENT.POST.MOVE"]]: true,
    [PERMISSION_ACTION["WG_ASSESSMENT.POST.DUPLICATE"]]: true,
    [PERMISSION_ACTION["WG_ASSESSMENT.GET.REQUEST"]]: false,
    [PERMISSION_ACTION["WG_ASSESSMENT.GET.REQUESTS"]]: false,
    [PERMISSION_ACTION["WG_ASSESSMENT.POST.REQUEST"]]: true,
    [PERMISSION_ACTION["WG_ASSESSMENT.DELETE.REQUEST"]]: true,
    [PERMISSION_ACTION["WG_ASSESSMENT.POST.REQUEST.SEND"]]: true,
    [PERMISSION_ACTION["WG_ASSESSMENT.TAKE.POST"]]: false,
    [PERMISSION_ACTION["WG_TEAMMAP.GET"]]: true,
    [PERMISSION_ACTION["WG_TEAMMAP.POST"]]: true,
    [PERMISSION_ACTION["WG_LICENSE.POST.ADD"]]: false,
    [PERMISSION_ACTION["WG_LICENSE.DELETE.TRANSFER"]]: true,
    [PERMISSION_ACTION["WG_LICENSE.POST.TRANSFER"]]: true,
    [PERMISSION_ACTION["WG_LICENSE.GET.LIST"]]: false,
    [PERMISSION_ACTION["WG_LICENSE.GET.USAGE"]]: true,
    [PERMISSION_ACTION["WG_ROSTER.GET.DUPLICATED"]]: true,
    [PERMISSION_ACTION["WG_ROSTER.GET"]]: true,
    [PERMISSION_ACTION["WG_ROSTER.DELETE"]]: false,
    [PERMISSION_ACTION["WG_ROSTER.PUT.WIDGET"]]: false,
    [PERMISSION_ACTION["WG_ROSTER.GET.REPORT"]]: false,
    [PERMISSION_ACTION["WG_ROSTER.POST.REPORT"]]: true,
    [PERMISSION_ACTION["WG_ROSTER.GET.ORIGINAL"]]: true,
    [PERMISSION_ACTION["WG_ROSTER.GET.SHARE"]]: true,
    [PERMISSION_ACTION["WG_ROSTER.POST.SHARE"]]: false,
    [PERMISSION_ACTION["WG_ROSTER.DUPLICATE.DELETE"]]: false,
    [PERMISSION_ACTION["WG_CERTIFICATION.GET"]]: false,
    [PERMISSION_ACTION["WG_CERTIFICATION.PUT"]]: false,
    [PERMISSION_ACTION["WG_TRAINEE.GET"]]: false,
    [PERMISSION_ACTION["WG_TOOLKIT"]]: false,
    [PERMISSION_ACTION["TTG_TOOLS"]]: false,
    [PERMISSION_ACTION["TTG_ORDERS"]]: false,
  },
};
