import React from 'react';
import RadioButton from '../Assessments/Take/RadioButton';
import { AppButton } from '../App';

const SurveyStep = ({
	questions,
	stepIndex,
	answers,
	onChange,
	currentStep,
	className,
	answered,
	nextStep,
	previousStep,
	loading,
	submitting = false,
	onNext,
	onPrevious,
	totalSteps,
	res_values,
}) => {
	const scrollToQuestion = () => {
		const indx = answers.findIndex((el) => el === -1);
		const violation = document.getElementById(`survey-question-${indx}`);
		if (violation) {
			window.scrollTo({
				top: violation.offsetTop,
				behavior: 'smooth',
			});
			window.postMessage({ name: 'surveyScroll', scrollTop: violation.offsetTop }, window.origin);
		}
	};

	React.useEffect(() => {
		if (answered) {
			scrollToQuestion();
		}
	}, [answers]);

	return (
		<div className={className}>
			{questions.map((question, qIndex) => (
				<div
					className={`mb-4 ${qIndex === 0 ? '' : 'border-t-1 border-gray-200 pt-4'}`}
					id={`survey-question-${stepIndex * 5 + qIndex}`}
				>
					<div
						className={`ttg-text-lg font-medium mb-2 ${
							answered && answers[stepIndex * 5 + qIndex] < 0 ? 'text-rose-500' : 'text-gray-800'
						}`}
					>
						<p
							dangerouslySetInnerHTML={{ __html: `${stepIndex * 5 + qIndex + 1}. ${question.question}` }}
						></p>
					</div>

					<div className={'flex flex-col md:flex-row pt-6'}>
						{res_values.map((option, i) => (
							<RadioButton
								label={option.label}
								key={i}
								checked={option.value === answers[stepIndex * 5 + qIndex]}
								onClick={() => {
									onChange(stepIndex, qIndex, option.value);
								}}
								direction={'flex-row md:flex-col items-start justify-start md:items-center'}
								labelClass={'flex items-start text-center'}
							/>
						))}
					</div>
				</div>
			))}
			<AppButton
				text={currentStep < totalSteps - 1 ? 'Next' : 'Submit'}
				className={'btn btn-primary uppercase md:w-auto h-10 box-border text-sm min-w-0 float-right'}
				onClick={(e) => {
					onNext(e, nextStep, stepIndex);
					scrollToQuestion();
				}}
				loading={loading || submitting}
			/>
			{currentStep > 1 && !submitting && (
				<AppButton
					text={'Back'}
					className={'btn btn-secondary border-3 uppercase md:w-auto h-10 box-border text-sm float-left'}
					onClick={() => {
						onPrevious(previousStep);
					}}
					loading={loading}
				/>
			)}
		</div>
	);
};

export default SurveyStep;
