import React from 'react';
import AppTabTitle from '../../components/App/AppTabTitle';
import List from '../../components/Assessments/Find/List';

const Find = () => {
	return (
		<>
			<AppTabTitle title={'Find Your Assessments List | The Table Group'} />
			<List />
		</>
	);
};

export default Find;
