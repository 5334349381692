import { AssessmentConsole } from '@ttg/ui-itp-lib';
import { AccountLayout } from '../../components/Layout';

export const ItpView = () => {
	return (
		<AccountLayout sidebar={false}>
			<AssessmentConsole />
		</AccountLayout>
	);
};
