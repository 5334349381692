import React from 'react';
import { AppPdfWrapper } from '../../../App';
import FirstPage from './FirstPage';

const Report = () => {
	return (
		<AppPdfWrapper>
			<FirstPage />
		</AppPdfWrapper>
	);
};

export default Report;
