import React from 'react';
import { AssessmentLayout } from '../../components/Layout';
import { AssessmentSetupWizard } from '../../components/Assessments';
import { useLocation, useNavigate } from 'react-router-dom';
import UtilityService from '../../services/UtilityService';
import NotificationService from '../../services/NotificationService';
import RouteNames from '../../router/RouteNames';
import AssessmentService from '../../services/AssessmentService';
import { useAssessments } from '../../providers/AssessmentProvider';
import { useCustomer } from '../../providers/CustomerProvider';

const Team = () => {
	const [alreadyChecked, setAlreadyChecked] = React.useState(false);
	const location = useLocation();
	const navigate = useNavigate();

	const { licenses: { licenses }, customer } = useCustomer();
	const { setupAssessment, setSetupAssessment, loading, setLoading } = useAssessments();

	const { id, retest } = UtilityService.getQueryParams(location.search);

	const _getTemplates = () => async () =>
		await AssessmentService.getAssessmentTemplate().then((response) =>
				setSetupAssessment((prev) => ({ ...prev, template: response.data }))
		);

	const _getDetails = React.useCallback(async () => {
		if (id || retest) {
			setLoading(true);
			return AssessmentService.details({ id: id ? id : retest, setup: true, retest: retest })
				.then((response) => {
					setSetupAssessment(AssessmentService.prepareAssessmentAsDraft(response, retest));
				})
				.catch(() => {
					navigate(RouteNames.Assessments, { replace: true });
				})
				.finally(() => setLoading(false));
		}
		setSetupAssessment(AssessmentService.resetTeamSetup(customer));
	}, [id, location]);

	React.useEffect(() => {
		_getDetails().then(_getTemplates());
	}, [_getDetails]);

	React.useEffect(() => {
		if (!alreadyChecked) {
			if (licenses) {
				setAlreadyChecked(true);
			}
			if (licenses && licenses < 2) {
				NotificationService.custom('You do not have enough licenses to setup a new team assessment');
				navigate(RouteNames.Assessments, { replace: true });
			}
		}
	}, [licenses]);

	React.useEffect(() => {
		if (setupAssessment && setupAssessment.id) {
			if (!setupAssessment.is_draft && !setupAssessment.retest) {
				navigate(RouteNames.Assessments, { replace: true });
			} else if (!setupAssessment.is_team) {
				navigate(`${RouteNames.IndividualAssessment}?id=${setupAssessment.id}`, { replace: true });
			}
		}
	}, [setupAssessment]);

	return (
		<AssessmentLayout loading={loading}>
			<AssessmentSetupWizard />
		</AssessmentLayout>
	);
};

export default Team;