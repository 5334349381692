import React from 'react';
import { AppPdfPage, AppPdfWrapper } from '../../components/App';
import Intro from './Intro';
import UtilityService from '../../services/UtilityService';
import Images from '../../assets/Images';
import ResultsSummary from './ResultsSummary';
import { isNull } from 'lodash/lang';
import Scores from './Scores';
import Tips from './Tips';
import LastPage from './LastPage';
import SurveyService from '../../services/SurveyService';

const getScoreDetails = (score, lowThreshold, highThreshold) => {
	if (score < lowThreshold) {
		return {
			icon: 'low',
			color: 'red',
		};
	}

	if (score > highThreshold) {
		return {
			icon: 'high',
			color: 'green',
		};
	}

	return {
		icon: 'medium',
		color: 'yellow',
	};
};

const SurveyPdf = () => {
	const [details, setDetails] = React.useState(null);
	const [filters, setFilters] = React.useState(null);
	const [humbleDetails, setHumbleDetails] = React.useState({});
	const [hungryDetails, setHungryDetails] = React.useState({});
	const [smartDetails, setSmartDetails] = React.useState({});

	const highThreshold = 16,
		lowThreshold = 14;

	const _getDetails = React.useCallback(() => {
		const params = UtilityService.getQueryParams(window.location.search, true);

		Object.keys(params)
			.filter((k) => k.startsWith('question'))
			.forEach((k) => {
				params[k] = Number(params[k]);
			});

		setFilters(params);

		SurveyService.getSurveyPdfDetails(params, 'assessment').then((response) => {
			const details = response.data;
			setDetails(details);

			setHumbleDetails({
				...getScoreDetails(details.disciplines[0].score, lowThreshold, highThreshold),
				score: details.disciplines[0].score,
			});

			setHungryDetails({
				...getScoreDetails(details.disciplines[1].score, lowThreshold, highThreshold),
				score: details.disciplines[1].score,
			});

			setSmartDetails({
				...getScoreDetails(details.disciplines[2].score, lowThreshold, highThreshold),
				score: details.disciplines[2].score,
			});
		});
	}, [window.location.search]);

	React.useEffect(() => {
		_getDetails();
	}, [_getDetails, window.location.search]);

	return (
		!isNull(details) && (
			<AppPdfWrapper className={'AssessmentReport'}>
				<AppPdfPage page={1} sectionClassName={''}>
					<Intro first_name={filters.first_name} last_name={filters.last_name} />
				</AppPdfPage>
				<AppPdfPage page={2} sectionClassName={''}>
					<div className={'py-5 px-5 flex-row survey-font'}>
						<div className={'ttg-text-xl text-gray-500 font-medium  uppercase py-5'}>Introduction</div>
						<div className={'ttg-text-lg survey-font'}>
							The primary purpose of this report is to help you explore how you embody the three virtues
							of an ideal team player. If you have not yet read the book{' '}
							<b>
								<i>The Ideal Team Player</i>
							</b>
							, the following pages are included to give you a summary of the model that this assessment
							is based upon. This will help you interpret your results.
						</div>
						<div className={'ttg-text-lg py-5'}>
							If you are familiar with the concepts in the book, you can skip ahead to page 5, and
							investigate the interpretations of your scores.
						</div>
					</div>
				</AppPdfPage>
				<AppPdfPage page={3} sectionClassName={''}>
					<div className={'px-5 flex-row'}>
						<div className={'inline-flex -mt-3 survey-font'}>
							<img src={Images.itp_assessment.book} className={`w-1/5`} alt='itp-book' />
							<div className={'ttg-text-2xl text-red-900 font-bold uppercase mt-15 ml-20'}>
								The Ideal Team Player
							</div>
						</div>
						<div className={'flex flex-col justify-center items-center -mt-8'}>
							<img src={Images.itp_assessment.venSmall} alt={'ven'} className={`w-56 ml-15`}></img>
						</div>

						<div className={'py-2 flex-row -mt-3'}>
							<div className={'py-2 survey-font'}>
								<div className={'ttg-text-base text-red-900'}>Humble</div>
								<div className={'ttg-text-base border-t-1 border-gray-200 pt-3 '}>
									Ideal team players are humble. They lack excessive ego or concerns about status.
									Humble people are quick to point out the contributions of others and slow to seek
									attention for their own. They share credit, emphasize team over self and define
									success collectively rather than individually.
								</div>
							</div>

							<div className={'survey-font'}>
								<div className={'ttg-text-base text-red-900'}>Hungry</div>
								<div className={'ttg-text-base border-t-1 border-gray-200 pt-3 '}>
									Ideal team players are hungry. They are always looking for more. More things to do.
									More to learn. More responsibility to take on. Hungry people almost never have to be
									pushed by a manager to work harder because they are self-motivated and diligent.
									They are constantly thinking about the next step and the next opportunity.
								</div>
							</div>

							<div className={'py-3 survey-font'}>
								<div className={'ttg-text-base text-red-900'}>Smart</div>
								<div className={'ttg-text-base border-t-1 border-gray-200 pt-3 '}>
									Ideal team players are smart. They have common sense about people. Smart people tend
									to know what is happening in a group situation and how to deal with others in the
									most effective way. They have good judgment and intuition around the subtleties of
									group dynamics and the impact of their words and actions.
								</div>
							</div>

							<div className={'text-center mt-3 survey-font'}>
								<div className={'ttg-text-base text-red-900 font-bold inline-flex'}>
									Humble, Hungry and Smart <div className={'border-t-3 w-5 mx-2 ttg-itp-pdf'}></div>{' '}
									The Three Virtues Combined
								</div>
								<div className={'ttg-text-sm text-red-900 font-medium'}>
									What makes humble, hungry and smart powerful and unique is not the individual
									attributes themselves, but rather the required combination of all three. If even one
									is missing in a team member, teamwork becomes significantly more difficult, and
									sometimes not possible.
								</div>
							</div>
						</div>
					</div>
				</AppPdfPage>
				<AppPdfPage page={4} sectionClassName={''}>
					<ResultsSummary
						humbleDetails={humbleDetails}
						hungryDetails={hungryDetails}
						smartDetails={smartDetails}
					/>
				</AppPdfPage>
				<AppPdfPage page={5} sectionClassName={''}>
					<Scores
						details={details}
						humbleColor={humbleDetails.color}
						hungryColor={hungryDetails.color}
						smartColor={smartDetails.color}
					/>
				</AppPdfPage>
				<AppPdfPage page={6} sectionClassName={''}>
					<Tips humbleDetails={humbleDetails} hungryDetails={hungryDetails} smartDetails={smartDetails} />
				</AppPdfPage>
				<AppPdfPage page={7} sectionClassName={''}>
					<LastPage humbleDetails={humbleDetails} hungryDetails={hungryDetails} smartDetails={smartDetails} />
				</AppPdfPage>
			</AppPdfWrapper>
		)
	);
};

export default SurveyPdf;
