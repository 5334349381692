import UtilityService from './UtilityService';

const RESULTS = 'Results';
const ACCOUNTABILITY = 'Accountability';
const COMMITMENT = 'Commitment';
const CONFLICT = 'Conflict';
const TRUST = 'Trust';

const AS_INITIAL = 'Your assessment scores indicate that';
const AS_SINGULAR_HIGH_VALUE = 'is a likely area of strength for your team';
const AS_MULTIPLE_HIGH_VALUE = 'are likely areas of strength for your team';
const AS_CONJUNCTION_AND = 'and';
const AS_CONJUNCTION_WHILE = 'while';
const AS_FOR_YOUR_TEAM = 'for your team';
const AS_SINGULAR_MEDIUM_VALUE = 'is potentially an area for improvement';
const AS_MULTIPLE_MEDIUM_VALUE = 'are potentially areas for improvement';
const AS_SINGULAR_LOW_VALUE = 'is an area of likely concern';
const AS_MULTIPLE_LOW_VALUE = 'are areas of likely concern';

const getLevelByValue = (value) => {
	return value >= ReportService.HIGH_SCORE ? 'high' : value < ReportService.LOW_SCORE ? 'low' : 'medium';
};

const buildInitialString = (getLevelByProp) => {
	let str = AS_INITIAL;

	const values = { high: [], medium: [], low: [] };

	ReportService.Ordered5DF.forEach((type) => {
		values[getLevelByProp(type)].push(type.toLowerCase());
	});

	const high_size = values.high.length;
	const med_size = values.medium.length;
	const low_size = values.low.length;

	// build the portion relating to areas of strength
	if (high_size > 0) {
		if (high_size === 1) {
			str = str + ' ' + values.high[0] + ' ' + AS_SINGULAR_HIGH_VALUE;
		} else {
			for (let x = 0; x < high_size; x++) {
				if (x === high_size - 1) {
					// we're at the end of the array
					str = str + ' ' + AS_CONJUNCTION_AND + ' ' + values.high[x] + ' ' + AS_MULTIPLE_HIGH_VALUE;
				} else if (x === high_size - 2) {
					// we don't want a comma between the penultimate value
					// and the word 'and' and the last value
					str = str + ' ' + values.high[x];
				} else {
					// it's one of many, throw a comma in after it
					str = str + ' ' + values.high[x] + ',';
				}
			}
		}
	}

	if (med_size > 0) {
		if (high_size > 0) {
			// we printed some high values, we need a conjoining phrase
			str = str + ', ' + AS_CONJUNCTION_WHILE;
		}

		if (med_size === 1) {
			str = str + ' ' + values.medium[0] + ' ' + AS_SINGULAR_MEDIUM_VALUE;
		} else {
			for (let x = 0; x < med_size; x++) {
				if (x === med_size - 1) {
					// we're at the end of the array
					str = str + ' ' + AS_CONJUNCTION_AND + ' ' + values.medium[x] + ' ' + AS_MULTIPLE_MEDIUM_VALUE;
				} else if (x === med_size - 2) {
					// we don't want a comma between the penultimate value
					// and the word 'and' and the last value
					str = str + ' ' + values.medium[x];
				} else {
					// it's one of many, throw a comma in after it
					str = str + ' ' + values.medium[x] + ',';
				}
			}
		}
		if (high_size === 0) {
			// tack on some stuff, so it doesn't sound so dry
			str = str + ' ' + AS_FOR_YOUR_TEAM;
		}
	}

	if (low_size > 0) {
		if (high_size > 0 || med_size > 0) {
			// we printed some high values, we need a conjoining phrase
			str = str + ', ' + AS_CONJUNCTION_AND;
		}

		if (low_size === 1) {
			str = str + ' ' + values.low[0] + ' ' + AS_SINGULAR_LOW_VALUE;
		} else {
			for (let x = 0; x < low_size; x++) {
				if (x === low_size - 1) {
					// we're at the end of the array
					str = str + ' ' + AS_CONJUNCTION_AND + ' ' + values.low[x] + ' ' + AS_MULTIPLE_LOW_VALUE;
				} else if (x === low_size - 2) {
					// we don't want a comma between the penultimate value
					// and the word 'and' and the last value
					str = str + ' ' + values.low[x];
				} else {
					// it's one of many, throw a comma in after it
					str = str + ' ' + values.low[x] + ',';
				}
			}
		}
		if (high_size === 0 && med_size === 0) {
			// tack on some stuff, so it doesn't sound so dry
			str = str + ' ' + AS_FOR_YOUR_TEAM;
		}
		str = str + '.';
	}

	return str;
};

const groupAreaQuestions = (details, direction, firstBatchSize = 6, secondBatchSize = 7) => {
	let numberOfCitations = 5;

	const allHighScores = Object.keys(details.raw_scores_average)
		.map((key) => details.raw_scores_average[key])
		.sort((a, b) => {
			return direction * (b - a);
		});

	while (
		allHighScores[numberOfCitations] &&
		allHighScores[numberOfCitations] === allHighScores[numberOfCitations - 1]
	) {
		numberOfCitations++;
	}

	const highScores = allHighScores.splice(0, numberOfCitations);

	const _ret = Object.keys(details.raw_scores_average)
		.filter((key) => highScores.indexOf(details.raw_scores_average[key]) > -1)
		.map((key) => parseInt(key))
		.sort((a, b) => {
			return direction * (details.raw_scores_average[b] - details.raw_scores_average[a]);
		});

	const firstBatch = [..._ret].splice(0, firstBatchSize);
	const secondBatch = UtilityService.splitArray([..._ret].splice(firstBatchSize, _ret.length), secondBatchSize);

	return {
		grouped: [firstBatch, ...secondBatch],
		total: numberOfCitations,
	};
};

const groupProgressAreaQuestions = (details, direction, firstBatchSize = 6, secondBatchSize = 7) => {
	let numberOfCitations = 5;

	const allHighScores = Object.keys(details.child.raw_scores_average)
		.map((key) => details.child.raw_scores_average[key])
		.sort((a, b) => {
			return direction * (b - a);
		});

	while (
		allHighScores[numberOfCitations] &&
		allHighScores[numberOfCitations] === allHighScores[numberOfCitations - 1]
	) {
		numberOfCitations++;
	}

	const highScores = allHighScores.splice(0, numberOfCitations);

	const _ret = Object.keys(details.child.raw_scores_average)
		.filter((key) => highScores.indexOf(details.child.raw_scores_average[key]) > -1)
		.map((key) => {
			const question = details.child.questions.find(({ sort }) => sort - 1 === parseInt(key));
			return {
				question,
				parentScore: details.parent.raw_scores_average[key],
				childScore: details.child.raw_scores_average[key],
			};
		})
		.sort((a, b) => {
			return direction * (b.childScore - a.childScore);
		});

	const firstBatch = [..._ret].splice(0, firstBatchSize);
	const secondBatch = UtilityService.splitArray([..._ret].splice(firstBatchSize, _ret.length), secondBatchSize);

	return {
		grouped: [firstBatch, ...secondBatch],
		total: numberOfCitations,
	};
};

const calculateProgressDifferences = ({ child, parent }) => {
	const childHighVariations = ReportService.highestVariation(child);
	const parentHighVariations = ReportService.highestVariation(parent);

	const childQuestionIdxs = childHighVariations.map((i) => i.question.sort);

	const common = parentHighVariations
		.filter((i) => childQuestionIdxs.indexOf(i.question.sort) > -1)
		.map((i) => i.question.sort);

	return {
		common,
		child: childHighVariations,
		parent: parentHighVariations,
	};
};

const average = (array) => {
	return array.reduce((a, b) => a + b) / array.length;
};

const variance = (array) => {
	const mean = array.reduce((acc, curr) => acc + curr, 0) / array.length;
	array = array.map((k) => {
		return (k - mean) ** 2;
	});
	const sum = array.reduce((acc, curr) => acc + curr, 0);
	return sum / (array.length - 1);
};

const highestVariation = (details) => {
	if (details.current_team.team_type !== 'team') {
		return [];
	}

	const q = {};

	details.responses.forEach((res) => {
		Object.keys(res).forEach((key) => {
			if (!q[key]) {
				q[key] = [];
			}
			q[key].push(res[key]);
		});
	});

	return Object.keys(q)
		.map((key) => {
			const answers = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
			q[key].forEach((v) => {
				answers[v]++;
			});
			return {
				question: details.questions.find(({ sort }) => {
					return sort === parseInt(key.replace('q', ''));
				}),
				answers,
				average: ReportService.average(q[key]),
				variance: ReportService.variance(q[key]),
				range: Math.max(...q[key]) - Math.min(...q[key]),
			};
		})
		.filter((item) => {
			return item.range >= 3;
		})
		.sort((a, b) => b.variance - a.variance)
		.splice(0, 5);
};

const highestDiff = (child, parent) => {
	let improvements = [];
	let declines = [];
	Object.values(child.raw_scores_average).forEach((score, index) => {
		const diff = score - parent.raw_scores_average[index];
		const question = child.questions.find(({ sort }) => sort === index + 1);
		if (diff > 0) {
			improvements.push({
				question,
				parentScore: parent.raw_scores_average[index],
				childScore: score,
				diff,
			});
		} else if (diff < 0) {
			declines.push({
				question,
				parentScore: parent.raw_scores_average[index],
				childScore: score,
				diff,
			});
		}
	});

	let improvementsCount = 5;
	improvements.sort((a, b) => {
		return b.diff - a.diff;
	});

	while (
		improvements[improvementsCount] &&
		improvements[improvementsCount].diff === improvements[improvementsCount - 1].diff
	) {
		improvementsCount++;
	}

	improvements = improvements.splice(0, improvementsCount);

	let declinesCount = 5;
	declines.sort((a, b) => {
		return a.diff - b.diff;
	});

	while (declines[declinesCount] && declines[declinesCount].diff === declines[declinesCount - 1].diff) {
		declinesCount++;
	}

	declines = declines.splice(0, declinesCount);

	return {
		improvements: improvements.length > 0 ? UtilityService.splitArray(improvements, 5) : [[]],
		declines: declines.length > 0 ? UtilityService.splitArray(declines, 5) : [[]],
		improvementsCount,
		declinesCount,
	};
};

const ReportService = {
	getLevelByValue,
	buildInitialString,
	groupAreaQuestions,
	groupProgressAreaQuestions,
	calculateProgressDifferences,
	average,
	variance,
	highestVariation,
	highestDiff,
	RESULTS: 'Results',
	ACCOUNTABILITY: 'Accountability',
	COMMITMENT: 'Commitment',
	CONFLICT: 'Conflict',
	TRUST: 'Trust',
	HIGH_SCORE: 4,
	LOW_SCORE: 3,
	Ordered5DF: [RESULTS, ACCOUNTABILITY, COMMITMENT, CONFLICT, TRUST],
	PyramidItems: [
		{
			key: RESULTS,
			points: '220.49 0 171.29 75.23 269.68 75.23 220.49 0',
			transform: 'translate(192.76 65.08)',
		},
		{
			key: ACCOUNTABILITY,
			points: '270.99 77.23 169.98 77.23 128.47 140.71 312.51 140.71 270.99 77.23',
			transform: 'translate(164.77 129.72)',
		},
		{
			key: COMMITMENT,
			points: '313.82 142.72 127.16 142.72 85.65 206.2 355.33 206.2 313.82 142.72',
			transform: 'translate(175.68 198.21)',
		},
		{
			key: CONFLICT,
			points: '356.64 208.2 84.34 208.2 42.82 271.68 398.15 271.68 356.64 208.2',
			transform: 'translate(188.78 260.45)',
		},
		{
			key: TRUST,
			points: '41.52 273.68 0 337.17 440.98 337.17 399.46 273.68 41.52 273.68',
			transform: 'translate(200.17 324.79)',
		},
	],
	Behavior: {
		Trust: {
			high: 'Your score in this area was high, which indicates that your team has created an environment where vulnerability and openness are the norm.',
			medium: 'Your score in this area was medium, which indicates that your team may need to get more comfortable being vulnerable and open with one another about individual strengths, weaknesses, mistakes and needs for help.',
			low: 'Your score in this area was low, which indicates that your team lacks necessary levels of openness and vulnerability about individual strengths, weaknesses, mistakes and needs for help.',
		},
		Conflict: {
			high: 'Your score in this area was high, which indicates that your team is comfortable engaging in unfiltered discussion around important topics.',
			medium: 'Your score in this area was medium, which indicates that your team may need to learn to engage in more unfiltered discussion around important topics.',
			low: 'Your score in this area was low, which indicates that your team is not comfortable engaging in unfiltered discussion around important topics.',
		},
		Commitment: {
			high: 'Your score in this area was high, which indicates that your team is able to buy-in to clear decisions leaving little room for ambiguity and second-guessing.',
			medium: 'Your score in this area was medium, which indicates that your team may struggle at times to buy-in to clear decisions.  This could be creating ambiguity within the organization.',
			low: 'Your score in this area was low, which indicates that your team is not able to buy-in to clear decisions, leaving room for ambiguity and second-guessing.',
		},
		Accountability: {
			high: 'Your score in this area was high, which indicates that your team does not hesitate to confront one another about performance and behavioral concerns.',
			medium: 'Your score in this area was medium, which indicates that your team may be hesitating to confront one another about performance and behavioral concerns.',
			low: 'Your score in this area was low, which indicates that your team hesitates to confront one another about performance and behavioral concerns.',
		},
		Results: {
			high: 'Your score in this area was high, which indicates that your team values collective outcomes more than individual recognition and attainment of status.',
			medium: 'Your score in this area was medium, which indicates that members of your team may be placing too much importance on individual or departmental recognition and ego, rather than focusing on the collective goals of the team.',
			low: 'Your score in this area was low, which indicates that your team needs to place greater value on the collective achievement of outcomes, rather than individual or departmental recognition and ego.',
		},
	},
	TeamAverage: {
		Trust: {
			high: "Though your team scored high in trust, it is recommended that you consider one or more of the exercises in the 'Tips & Exercises' section of this report to maintain current levels.",
			low: "Because your team scored low in trust, it is recommended that you consider using many of the suggestions in the 'Tips & Exercises' section of this report. Please note that by increasing your trust score, your team will have more success in overcoming the other dysfunctions.",
			medium: "While your team scored medium in trust, it is recommended that you consider using many of the suggestions in the 'Tips & Exercises' section of this report. Please note that by increasing your trust score, your team will have more success in overcoming the other dysfunctions.",
		},
		Conflict: {
			high: "Though your team scored high in conflict, it is recommended that you consider one or more of the exercises in the 'Tips & Exercises' section of this report to maintain current levels.",
			low: "Because your team scored low in conflict, it is recommended that you consider using many of the suggestions in the 'Tips & Exercises' section of this report.",
			medium: "While your team scored medium in conflict, it is recommended that you consider using many of the suggestions in the 'Tips & Exercises' section of this report.",
		},
		Commitment: {
			high: "Though your team scored high in commitment, it is recommended that you consider one or more of the exercises in the 'Tips & Exercises' section of this report to maintain current levels.",
			low: "Because your team scored low in commitment, it is recommended that you consider using many of the suggestions in the 'Tips & Exercises' section of this report.",
			medium: "While your team scored medium in commitment, it is recommended that you consider using many of the suggestions in the 'Tips & Exercises' section of this report.",
		},
		Accountability: {
			high: "Though your team scored high in accountability, it is recommended that you consider one or more of the exercises in the 'Tips & Exercises' section of this report to maintain current levels.",
			low: "Because your team scored low in accountability, it is recommended that you consider using many of the suggestions in the 'Tips & Exercises' section of this report.",
			medium: "While your team scored medium in accountability, it is recommended that you consider using many of the suggestions in the 'Tips & Exercises' section of this report.",
		},
		Results: {
			high: "Though your team scored high in results, it is recommended that you consider one or more of the exercises in the 'Tips & Exercises' section of this report to maintain current levels.",
			low: "Because your team scored low in results, it is recommended that you consider using many of the suggestions in the 'Tips & Exercises' section of this report.",
			medium: "While your team scored medium in results, it is recommended that you consider using many of the suggestions in the 'Tips & Exercises' section of this report.",
		},
	},
	TipExercisesPages: [
		{
			title: 'Overcoming the Absence of Trust',
			pagination: '(1 of 2)',
			description:
				'Trust lies at the heart of a functioning, cohesive team. It is the foundation, and without it, real teamwork cannot occur. Team trust comes from the vulnerability of members’ sharing their weaknesses, skill deficiencies, interpersonal shortcomings, mistakes, requests for help, etc. Such trust enables team members to focus on the job at hand rather than on protecting themselves, their turf, or their individual jobs.',
			tips: [
				{
					title: 'Personal Histories Exercise',
					titleUrl: 'https://www.tablegroup.com/download/personal-histories-exercise',
					titleUrlLabel: 'Download a PDF',
					content:
						'One of the simplest and most effective ways to build vulnerability on a team is to use the Personal Histories Exercise. This exercise consists of three simple questions, takes no more than 25 minutes and without fail, team members walk away with a deeper knowledge of one another’s stories. This is a great place to start building vulnerability and trust on the team.',
				},
				{
					title: 'The Six Types of Working Genius Assessment',
					content:
						'The Table Group developed their very own personality assessment designed specifically to help team members identify their natural gifts/frustrations and apply them to getting work done. When teammates better understand their own and their coworkers’ relative areas of genius <i>and</i> frustration, they are immediately more capable of avoiding making unfair and inaccurate judgments about one another’s motivation.  The Working Genius Assessment and custom report provides detailed insights about your areas of working genius, working competency and working frustration, along with a robust application section that will help you leverage this information on your team and in your life.',
					extraUrl: 'https://www.workinggenius.com/',
					extraUrlLabel: 'Learn more: WorkingGenius.com',
				},
				{
					title: 'Other Personality Instruments',
					content:
						'Personality instruments (i.e., Myers-Briggs, DiSC or Social Styles) help team members understand one another’s different preferences, skills and attitudes, and identify collective strengths and potential blind spots of the team. This will help team members avoid making unproductive judgments about one another and instead leverage the diverse approaches and perspectives of the team.',
				},
			],
		},
		{
			title: 'Overcoming the Absence of Trust',
			pagination: '(2 of 2)',
			tips: [
				{
					title: 'Fundamental Attribution Error',
					titleUrl: 'https://www.tablegroup.com/the-fundamental-attribution-error',
					titleUrlLabel: 'Watch the Video',
					content:
						'The fundamental attribution error occurs when human beings falsely attribute the negative behaviors of others to their character (an internal attribution), while they attribute their own negative behaviors to their environment (an external attribution). View the video on the Fundamental Attribution Error and discuss how using a personality instrument can help team members avoid making bad judgments about one another’s character and intentions.',
				},
				{
					title: 'Self-ranking Exercise',
					titleUrl: 'https://www.tablegroup.com/download/itp-self-ranking-exercise',
					titleUrlLabel: 'Download a PDF',
					content:
						'In Pat&rsquo;s related book <i>The Ideal Team Player</i>, he looks at the individual team member and identifies three essential virtues of real team players. When individuals on a team are humble, hungry, and smart, it makes overcoming the five dysfunctions of a team much more attainable. This self-ranking exercise is a great way for team members to assess themselves against the three virtues and build trust by sharing with the team.',
					extraUrl: 'https://www.ted.com/talks/patrick_lencioni_are_you_an_ideal_team_player',
					extraUrlLabel: 'Watch Pat&rsquo;s TED talk, <i>Are you an ideal team player?</i>',
				},
				{
					title: 'An Important Note for Virtual Teams...',
					content:
						'Virtual teams need to commit to spending face-to-face time together, as much and as often as possible, and to use that time wisely. That means working hard to build vulnerability-based trust with one another. It&rsquo;s hard enough for people who work in the same office every day and who look at each other in the face during meetings to do this well. Teams who don’t have that luxury are going to have to be much more intentional about getting to know one another during their virtual meetings and when they are together.',
				},
			],
		},
		{
			title: 'Overcoming the Fear of Conflict',
			pagination: '(1 of 2)',
			description:
				'Once trust is established, conflict on a team becomes nothing but the pursuit of the best idea. True conflict is about the productive exchange of diverse ideas and opinions in a focused and unfiltered way. Without conflict, decision-making suffers and relationships among team members stagnate. Additionally, if healthy conflict around ideas is not a regular part of team meetings and discussion it generally degenerates to mean-spirited, back-channel comments behind closed doors.',
			tips: [
				{
					title: 'Real-time Permission',
					titleUrl: 'https://www.tablegroup.com/real-time-permission-video',
					titleUrlLabel: 'Watch the Video',
					content:
						'For most teams, conflict can feel foreign and uncomfortable. Real-time permission is when the leader interrupts team members who are in the midst of an uncustomary debate, to remind them that what they are doing is okay. It is the role of the leader to provide real-time permission when healthy conflict is occurring, encouraging the team to continue to passionately debate in pursuit of the best answer.',
				},
				{
					title: 'Mine for Conflict',
					content:
						'If team members remain hesitant to engage in conflict or avoid sharing dissenting opinions, it is the leader’s job to provoke team conflict.  We call this “mining for conflict.”  It is important that a team member, most often the leader, is responsible for drawing out any potential unresolved issues and forcing the team to confront them.',
				},
				{
					title: 'Personality Instrument',
					content:
						'Many personality instruments include an analysis of how each style or type deals with conflict.  Explore and discuss how different team members naturally engage in conflict. ',
				},
			],
		},
		{
			title: 'Overcoming the Fear of Conflict',
			pagination: '(2 of 2)',
			tips: [
				{
					title: 'Conflict Norms',
					content:
						'Establish rules of engagement for dealing with conflict within the team (e.g., behaviors, displays of emotion, language, process). Having clear standards of behavior allows a team to focus on the discussion of issues without having to slow down to think about what is and is not appropriate. Capture the team conflict norms and refer to them in meetings.',
				},
				{
					title: 'Conflict Continuum',
					titleUrl: 'https://www.tablegroup.com/conflict-continuum',
					titleUrlLabel: 'Watch the Video',
					content:
						'The conflict continuum is a spectrum depicting the full range of conflict, from artificial harmony (zero conflict) to aggressive and destructive politics (extreme conflict). At the middle of the continuum is the point where conflict changes from constructive and ideological to destructive and personal.  View the video on the conflict continuum and discuss where the team might fall on the scale.  Discuss ways artificial harmony shows up and identify how to introduce more healthy conflict into team meetings and discussions.',
				},
			],
		},
		{
			title: 'Overcoming the Lack of Commitment',
			pagination: '(1 of 2)',
			description:
				'Teams that have a strong level of commitment understand that members do not need to get their way to support a decision but only need to know that their opinions have been heard and seriously considered. Teams with high levels of commitment can unite behind a decision even though there is no certainty that the decision is correct. They know that a decision is better than no decision and that it is better to make a choice, act with boldness, be wrong and change direction than it is to waffle or wait for 100% certainty.',
			tips: [
				{
					title: 'Weigh-in to Buy-in',
					content:
						'A team cannot achieve commitment without conflict. Team members will not actively commit to a decision if they have not had the opportunity to provide input, ask questions, and understand the rationale behind it.  If people don’t weigh-in, they can’t buy-in. It is the job of the leader to solicit the input of each team member during meetings and discussions.',
				},
				{
					title: 'Thematic Goal',
					titleUrl: 'https://www.tablegroup.com/download/silos-model',
					titleUrlLabel: 'Download a PDF',
					content:
						'With a foundation of trust and a good dose of healthy conflict, a team needs to agree upon their most important near-term priority, a thematic goal, and how they are going to go about achieving it.  Agreeing on a top priority and reviewing progress towards it during weekly meetings reinforces commitment.',
				},
				{
					title: 'Meetings',
					content:
						'At the end of every meeting, a team should explicitly review the key decisions made and agree on what needs to be communicated to employees and other constituencies. ' +
						'The use of this simple exercise, called cascading communication, demonstrates public commitment to agreements and aligns employees throughout the organization around common objectives. ' +
						'For more information and tools on improving the effectiveness of meetings, please visit <a class="TipsExercises__tipLink __no_margin" target="_blank" href="https://www.tablegroup.com/dbm/">Death by Meeting</a>.',
				},
			],
		},
		{
			title: 'Overcoming the Lack of Commitment',
			pagination: '(2 of 2)',
			tips: [
				{
					title: 'Cascading Communication',
					content:
						'At the end of every meeting, a team should explicitly review the key decisions made and agree on what needs to be communicated to employees and other constituents. The use of this simple exercise, called cascading communication, demonstrates a public commitment to agreements and aligns employees throughout the organization around common objectives. Even naturally hesitant team members commit to decisions when they have communicated them to their direct reports.',
				},
				{
					title: 'Clarity and Closure',
					content:
						'Force the team to achieve clarity and closure. Leaders of teams who commit to decisions demand that their people eliminate ambiguity and leave meetings and discussions clear about what they are agreeing to do.  Do the hard work of wrestling issues to the ground.  Be sure not to shy away from lively, often lengthy discussion around big strategic topics or to move on to new agenda items too early for the sake of time.',
				},
			],
		},
		{
			className: 'overcoming-avoidance-acc',
			title: 'Overcoming the Avoidance<br>of Accountability',
			description:
				'For teams, accountability means the willingness of team members to call their peers on behaviors that might hurt the team’s performance. Team members avoid accountability because of the personal discomfort that comes from calling a peer on his/her behaviors and a more general tendency to avoid difficult conversations. Holding peers accountable means that team members must “enter the danger” with one another. Of course, they can do this only if levels of trust, healthy conflict and commitment are sufficiently high.',
			tips: [
				{
					title: 'Team Effectiveness Exercise',
					titleUrl: 'https://www.tablegroup.com/download/team-effectiveness-exercise',
					titleUrlLabel: 'Download a PDF',
					content:
						'The Team Effectiveness Exercise provides a forum for quick and effective exchange of feedback. Ask team members to identify and communicate one another’s positive and negative actions/behaviors. By doing so, teams can quickly and constructively surface issues that might take months to address using a more formal, politically divisive 360-degree program.',
				},
				{
					title: 'The Six Types of Working Genius Assessment',
					content:
						'The Table Group developed their very own personality assessment designed specifically to help team members identify their natural gifts/frustrations and apply them to getting work done. When teammates better understand their own and their coworkers’ relative areas of genius <i>and</i> frustration, they are immediately more capable of avoiding making unfair and inaccurate judgments about one another’s motivation.  The Working Genius Assessment and custom report provides detailed insights about your areas of working genius, working competency and working frustration, along with a robust application section that will help you leverage this information on your team and in your life.',
					extraUrl: 'https://www.workinggenius.com/',
					extraUrlLabel: 'Learn more: WorkingGenius.com',
				},
				{
					title: 'The Thematic Goal—Meetings',
					content:
						'During every weekly meeting, review progress on the team’s agreed upon thematic goal. When a team ensures deviations from plans are identified quickly, they make it more likely that performance issues of team members will be highlighted and addressed.' +
						'<br/>' +
						'For more information and tools on improving the effectiveness of meetings, please visit <a class="TipsExercises__tipLink __no_margin" target="_blank" href="https://www.tablegroup.com/dbm/">Death by Meeting</a>.',
				},
			],
		},
		{
			title: 'Overcoming the Inattention to Results',
			description:
				'One of the main reasons to work in teams is that they can achieve results that would be impossible for an individual working alone. An unrelenting focus on collective goals is a requirement for any team that judges itself on results. Teams that have this dysfunction suffer because individuals work to satisfy their individual status, ego or departmental needs, rather than focusing on the collective goals of the group.',
			tips: [
				{
					title: 'Team #1',
					titleUrl: 'https://www.tablegroup.com/team-1',
					titleUrlLabel: 'Watch the Video',
					content:
						'Getting all members of the team to value and emphasize the collective success of the group over their own personal needs or departmental goals is essential for effective teams.  Results suffer when team members put a higher priority on the activities of their own departments or divisions.  Review the video and ensure each individual commits to the team goals/results as his/her top priority.',
				},
				{
					title: 'The Thematic Goal—Meetings ',
					content:
						'During every weekly meeting, a team should focus on its thematic goal. This is a way of reinforcing collective results in a public way, and team members are much more likely to follow through and less likely to let personal needs take precedence.' +
						'<br/>' +
						'For more information and tools on improving the effectiveness of meetings, please visit <a class="TipsExercises__tipLink __no_margin" target="_blank" href="https://www.tablegroup.com/dbm/">Death by Meeting</a>.',
				},
				{
					title: 'Team-based Rewards',
					content:
						'Ensure that team-based rewards form the basis for most compensation and recognition programs. When team members have incentives to focus on their individual performance objectives and not those of the team, it becomes easy for results to take a back seat to personal financial goals and career development.',
				},
			],
		},
	],
};

export default ReportService;
