import React from 'react';
import { AppButton, AppModalComponent } from '../../App';
import NotificationService from '../../../services/NotificationService';
import RouteNames from '../../../router/RouteNames';
import { useNavigate } from 'react-router-dom';
import AssessmentService from '../../../services/AssessmentService';
import { useAssessments } from '../../../providers/AssessmentProvider';
import { useCustomer } from '../../../providers/CustomerProvider';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const BulkConfirmDeleteActionModal = ({ isOpen, onClose, data, assessment, deletingMembers, setDeletingMembers }) => {
	const navigate = useNavigate();

	const { setLicenses } = useCustomer();
	const { setAssessmentDetails } = useAssessments();

	const confirm = async () => {
		setDeletingMembers(true);
		AssessmentService.removeTeamMember(assessment.assessment_data.team_id, {
			person_ids: data.person_ids,
		})
			.then((response) => {
				setAssessmentDetails((prev) =>
					AssessmentService.prepareDeleteMemberData({ person_ids: data.person_ids }, prev)
				);
				if (!assessment.isScheduled) {
					setLicenses((prev) => ({ ...prev, licenses: prev.licenses + data.person_ids.length }));
				}
				if (response.deleted_assessment) {
					NotificationService.success('Assessment deleted successfully');
					onClose();
					return navigate(RouteNames.Assessments, { replace: true });
				}
				NotificationService.success(
					`${data.person_ids.length > 1 ? 'Team members' : 'Team member'} removed successfully`
				);
				setAssessmentDetails((prev) => ({
					...prev,
					assessment_data: {
						...prev.assessment_data,
						complete: 1,
						status: 'Complete',
					},
				}));
				onClose();
			})
			.finally(() => setDeletingMembers(false));
	};

	return (
		<AppModalComponent isOpen={isOpen} closeModal={onClose} style={customStyles} onRequestClose={onClose}>
			<div className='bg-white p-6' style={{ maxWidth: '512px' }}>
				<div className='flex'>
					<div className='flex flex-1 flex-col ml-4'>
						<h1 className={'ttg-text-lg text-gray-900 font-medium mb-1'}>
							{assessment.isTeamAssessment ? 'Delete team members' : 'Delete individual assessment'}
						</h1>
						{data.emails.length > 0 && (
							<>
								{assessment.isTeamAssessment ? (
									<p className={'ttg-text-sm text-gray-700 mb-2'}>
										Are you sure you wish to remove the following members from the{' '}
										<span className='font-medium'>{data.team_name}</span> team?
									</p>
								) : (
									<p className={'ttg-text-sm text-gray-700 mb-2'}>
										Are you sure you wish to remove the following individual?
									</p>
								)}

								<ul className={'list-disc ml-3'}>
									{data.emails.map((e, i) => {
										return (
											<li key={i} className={'ttg-text-sm text-gray-700 font-medium'}>
												{e}
											</li>
										);
									})}
								</ul>
							</>
						)}
						{data.noticeEmails.length > 0 && (
							<>
								<p className={'ttg-text-sm text-gray-700 mt-4 mb-2'}>
									The following members from the <span className='font-medium'>{data.team_name}</span>{' '}
									have already completed their assessment, and will not be removed:
								</p>
								<ul className='list-disc ml-3'>
									{data.noticeEmails.map((e, i) => {
										return (
											<li key={i} className={'ttg-text-sm text-gray-700 font-medium'}>
												{e}
											</li>
										);
									})}
								</ul>
							</>
						)}
						{!!data.willAssessmentBeDeleted && (
							<p
								className={'ttg-text-sm text-gray-700 my-4'}
								id={'ttg-delete-roster-delete-assessment-notice'}
							>
								<span className='font-medium'>WARNING:</span> Removing{' '}
								{data.emails.length > 1
									? 'these team members'
									: assessment.isTeamAssessment
									? 'this team member'
									: 'the individual'}{' '}
								will also delete the assessment. Are you sure you wish to continue?
							</p>
						)}
						{!data.willAssessmentBeDeleted && !!data.willAssessmentBeClosed && (
							<p
								className={'ttg-text-sm text-gray-700 my-4'}
								id={'ttg-delete-roster-close-assessment-notice'}
							>
								<span className='font-medium'>PLEASE NOTE:</span> Removing{' '}
								{data.emails.length > 1 ? 'these team members' : 'this team member'} will end the
								assessment and generate the report.
							</p>
						)}
						<div className={'flex justify-end'}>
							<AppButton
								id={'ttg-delete-roster-cancel-button'}
								text={'No'}
								className={'btn btn-secondary mr-4'}
								onClick={onClose}
								loading={deletingMembers}
							/>
							<AppButton
								id={'ttg-delete-roster-confirm-button'}
								text={'Yes, Confirm'}
								onClick={confirm}
								loading={deletingMembers}
							/>
						</div>
					</div>
				</div>
			</div>
		</AppModalComponent>
	);
};

export default BulkConfirmDeleteActionModal;
