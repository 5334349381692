import React from 'react';
import Images from '../../../../assets/Images';
import UtilityService from '../../../../services/UtilityService';

const Footer = ({ page }) => {
	const year = React.useMemo(() => {
		return UtilityService.formatNow('YYYY');
	}, []);

	return (
		<div className='Page__footer'>
			<div className='PageFooter'>
				<div className='PageFooter__layout'>
					<div className='PageFooter__logo'>
						<img src={Images.report.fullMarkColorLogo} alt='tablegroup-logo' />
					</div>

					<div className='PageFooter__textContainer'>
						<p className='PageFooter__paragraph'>www.tablegroup.com</p>
						<p className='PageFooter__paragraph'> &copy; Copyright {year} The Table Group, Inc. </p>
					</div>

					<div className='PageFooter__pageContainer'>
						<p className='PageFooter__page'>
							{page}
							<span className='PageFooter__pageTriangle'>
								<img src={Images.report.pageTriangle} alt='tablegroup-logo' />
							</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
