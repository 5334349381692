import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { PurchasedVideoDescription, VideoSliderNavigation } from './index';
import { AppVideoPlayer } from '../App';

import 'swiper/css';
import VideoService from '../../services/VideoService';

const Slider = ({ videos, onClick }) => {
	const [slidesPerView, setSlidesPerView] = React.useState(VideoService.getSlidesNumber());
	const [active, setActive] = React.useState(0);

	const hasNavigation = videos.length > slidesPerView;

	const handleResize = () => {
		setSlidesPerView(VideoService.getSlidesNumber());
	};

	React.useEffect(() => {
		handleResize();
	}, [handleResize]);

	React.useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<div className={'ttg-video-slider'}>
			<Swiper
				observer
				modules={[Navigation]}
				spaceBetween={20}
				slidesPerView={slidesPerView}
				onSlideChange={(i) => setActive(i.activeIndex)}
			>
				{videos.map((video, index) => {
					return (
						<SwiperSlide key={index}>
							<div id={`ttg-video-slider-${index}`} className={'video-slide'}>
								<div className={'bg-gray-900'}>
									<AppVideoPlayer
										url={`https://player.vimeo.com/video/${video.vimeo_id}`}
										controls={false}
										loadingClass={'text-white'}
										onClick={() => onClick(video)}
									/>
								</div>
								<div className={'py-4 px-6'}>
									<p onClick={() => onClick(video)} className={'video-title'}>
										{video.title}
									</p>
									<PurchasedVideoDescription video={video} />
								</div>
							</div>
						</SwiperSlide>
					);
				})}

				<VideoSliderNavigation
					canGoLeft={active > 0}
					canGoRight={active < videos.length - slidesPerView}
					hasNavigation={hasNavigation}
				/>
			</Swiper>
		</div>
	);
};
export default Slider;
