import React from 'react';
import { AppButton, AppModalComponent } from '../../App';
import RouteNames from '../../../router/RouteNames';
import { useNavigate } from 'react-router-dom';
import { useCustomer } from '../../../providers/CustomerProvider';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const ConfirmIndividualAssessmentModal = ({ isOpen, onClose }) => {
	const navigate = useNavigate();

	const { licenses: { licenses } } = useCustomer();

	const canCreateGroup = React.useMemo(() => {
		return licenses > 1;
	}, [licenses]);

	return (
		<AppModalComponent
			id={'ttg-confirm-individual-assessment-modal'}
			isOpen={isOpen}
			style={customStyles}
			onRequestClose={onClose}
		>
			<div className='bg-white p-6' style={{ maxWidth: '738px' }}>
				<div className='flex'>
					<div className='flex flex-1 flex-col ml-4'>
						<h1 className={'ttg-text-lg text-gray-900 font-medium mb-2'}>Please, read this message</h1>
						<p className={'ttg-text-sm text-gray-500 mb-4'}>
							The individual assessment is designed for single users to assess his or her perception of
							the team’s dynamics.{' '}
							<span className={'font-medium'}>
								This is not a team-based process, and you will not have the ability to combine the
								responses of team members.
							</span>
							{!canCreateGroup && (
								<>
									<br />
									<br />
									<span className={'pt-4'}>
										You do not have enough licenses to setup a team assessment. Use the below button
										to purchase more licenses
									</span>
								</>
							)}
						</p>
						<div className={'flex flex-1'}>
							<div className='flex flex-1'>
								<AppButton
									id={'confirm-individual-assessment-modal-setup-team'}
									className={'btn btn-primary mr-2 w-full'}
									text={canCreateGroup ? 'Setup a team assessment' : 'Purchase Licenses'}
									onClick={() => {
										if (canCreateGroup) {
											onClose();
											return navigate(RouteNames.TeamAssessmentSetup);
										}
										window.open(RouteNames.OnlineTeamAssessment, '_self');
									}}
								/>
							</div>
							<div className='flex flex-1'>
								<AppButton
									id={'confirm-individual-assessment-modal-setup-individual'}
									className={'btn btn-secondary ml-2 w-full'}
									text={'Continue with individual assessment'}
									onClick={() => {
										onClose();
										navigate(RouteNames.IndividualAssessment);
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</AppModalComponent>
	);
};

export default ConfirmIndividualAssessmentModal;
