import React from 'react';
import { AppButton, AppInput, AppModalComponent } from '../../App';
import { useForm, useFormState } from 'react-hook-form';
import UtilityService from '../../../services/UtilityService';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const ChangeEmailModal = ({ isOpen, onClose, onConfirm, email }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
		setValue,
	} = useForm();

	const { isDirty, isSubmitted } = useFormState({ control });

	const confirm = async (payload) => {
		onConfirm(payload);
	};

	React.useEffect(() => {
		reset({ email: email });
	}, [isOpen, reset, email]);

	const trimInputValue = (name, e) => {
		setValue(name, e.target.value.trim(), {
			shouldValidate: isSubmitted,
			shouldDirty: true,
		});
	};

	return (
		<AppModalComponent isOpen={isOpen} closeModal={onClose} style={customStyles} onRequestClose={onClose}>
			<div className='bg-white p-6' style={{ maxWidth: '512px' }}>
				<div className='flex'>
					<div className='flex flex-1 flex-col ml-4'>
						<h1 className={'ttg-text-lg text-gray-900 font-medium mb-4'}>Change email address</h1>
						<p className={'ttg-text-sm text-gray-700 mb-4'}>
							If you want to receive the assessment responses to a different email address from the one
							that you used for taking the assessment enter your email in the input field below and click
							the Done button.
						</p>
						<form onSubmit={handleSubmit(confirm)}>
							<AppInput
								label={'Email Address'}
								{...register('email', {
									validate: (value) =>
										value
											? UtilityService.isValidEmail(value.trim()) ||
											  'You must enter a valid email address'
											: true,
									maxLength: 96,
								})}
								data-ttg-max-length={96}
								errors={errors}
								defaultValue={email}
								onBlur={(e) => trimInputValue('email', e)}
							/>

							<div className='my-4 border-t-1 border-gray-200' />

							<div className='flex justify-end mt-4'>
								<AppButton
									id={'ttg-assessment-change-email-cancel-button'}
									text={'Cancel'}
									className={'btn btn-secondary mr-4'}
									type={'button'}
									onClick={onClose}
								/>
								<AppButton
									id={'ttg-assessment-change-email-done-button'}
									text={'Done'}
									className={'btn btn-primary'}
									disabled={!isDirty}
									type='submit'
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</AppModalComponent>
	);
};

export default ChangeEmailModal;
