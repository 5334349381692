import React from 'react';
import { AppButton, AppIcon, AppModalComponent } from '../../App';
import NotificationService from '../../../services/NotificationService';
import { useNavigate } from 'react-router-dom';
import RouteNames from '../../../router/RouteNames';
import { useAssessments } from '../../../providers/AssessmentProvider';
import AssessmentService from '../../../services/AssessmentService';
import { useCustomer } from '../../../providers/CustomerProvider';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const ConfirmDeleteRosterModal = ({ teamId, roster, isOpen, onClose, isScheduled, isTeamAssessment }) => {
	const navigate = useNavigate();

	const { setLicenses } = useCustomer();
	const { setAssessmentDetails, loading: deletingMembers, setLoading } = useAssessments();

	const _delete = async () => {
		setLoading(true);
		AssessmentService.removeTeamMember(teamId, {
			person_ids: [roster.person_id],
		}).then((response) => {
			setAssessmentDetails((prev) =>
				AssessmentService.prepareDeleteMemberData({ person_ids: [roster.person_id] }, prev)
			);
			if (!isScheduled) {
				setLicenses((prev) => ({ ...prev, licenses: prev.licenses + 1 }));
			}
			onClose();
			if (response.deleted_assessment) {
				NotificationService.success('Assessment deleted successfully');
				return navigate(RouteNames.Assessments, { replace: true });
			}
			return NotificationService.success('Team member removed successfully');
		}).finally(() => (setLoading(false)));
	};

	return (
		<AppModalComponent isOpen={isOpen} closeModal={onClose} style={customStyles} onRequestClose={onClose}>
			<div className='bg-white p-6' style={{ maxWidth: '512px' }}>
				<div className='flex'>
					<div className='flex flex-1 flex-col ml-4'>
						{isTeamAssessment ? (
							<>
								<div className={'flex items-center justify-center w-10 h-10 rounded-full bg-red-100'}>
									<AppIcon icon={'delete-bin-line'} className={'text-lg text-red-500'} />
								</div>
								<h1 className={'ttg-text-lg text-gray-900 font-medium mb-2'}>
									Remove Member From Assessment
								</h1>
								<p className={'ttg-text-sm text-gray-500 mb-4'}>
									Are you sure you wish to remove{' '}
									<span className='font-medium'>{roster.email_address}</span> from this assessment?
								</p>
							</>
						) : (
							<>
								<h1 className={'ttg-text-lg text-gray-900 font-medium mb-1'}>
									Delete individual assessment
								</h1>

								<p className={'ttg-text-sm text-gray-700 mb-2'}>
									Are you sure you wish to remove the following individual?
								</p>

								{roster.email_address && (
									<ul className={'list-disc ml-3 mb-4'}>
										<li className={'ttg-text-sm text-gray-700 font-medium'}>
											{roster.email_address}
										</li>
									</ul>
								)}
							</>
						)}

						{!!roster.willAssessmentBeDeleted && (
							<p
								className={'ttg-text-sm text-gray-500 mb-4'}
								id={'ttg-delete-roster-delete-assessment-notice'}
							>
								<span className='font-medium'>WARNING:</span> Removing{' '}
								{isTeamAssessment ? 'this team member' : 'the individual'} will also delete the
								assessment. Are you sure you wish to continue?
							</p>
						)}
						{!roster.willAssessmentBeDeleted && !!roster.willAssessmentBeClosed && (
							<p
								className={'ttg-text-sm text-gray-500 mb-4'}
								id={'ttg-delete-roster-close-assessment-notice'}
							>
								<span className='font-medium'>PLEASE NOTE:</span> Removing this team member will end the
								assessment and generate the report.
							</p>
						)}
						<div className={'flex justify-end'}>
							<AppButton
								id={'ttg-delete-roster-cancel-button'}
								text={'No'}
								className={'btn btn-secondary mr-4'}
								onClick={onClose}
								loading={deletingMembers}
							/>
							<AppButton
								id={'ttg-delete-roster-confirm-button'}
								text={isTeamAssessment ? 'Yes, Delete' : 'Yes, Confirm'}
								onClick={_delete}
								loading={deletingMembers}
							/>
						</div>
					</div>
				</div>
			</div>
		</AppModalComponent>
	);
};

export default ConfirmDeleteRosterModal;
