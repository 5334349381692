import React from 'react';
import { AppButton, AppIcon, AppLabel } from '../App';
import renderErrorMessage from '../../constants/ErrorMessages';
import CreatableSelect from 'react-select/creatable';
import NotificationService from '../../services/NotificationService';
import UtilityService from '../../services/UtilityService';

const MultipleEmailInput = React.forwardRef(
	(
		{
			label,
			actionIcon,
			tooltip,
			onAction,
			actionId,
			placeholder = '',
			showErrorMessage = true,
			isSubmitted,
			errors = {},
			defaultErrorMessage = '',
			values = [],
			limit,
			min,
			setError,
			clearErrors,
			inputContainerClassName,
			loadingAction,
			...props
		},
		ref
	) => {
		const triggerKeys = [9, 13, 32, 188];
		const { name } = props;
		const shouldShowErrorMessage = !!(showErrorMessage && errors && errors[name]);

		const prepareValuesFromInput = () => {
			const inputVal = ref.current.inputRef.value;
			const piecesByComa = inputVal.split(',');
			let pieces = [];
			piecesByComa.map((p) => {
				pieces = [...pieces, ...p.split(' ')];
			});
			const currentValues = ref.current.getValue();
			const newValues = [];
			pieces.map((p) => {
				const val = p.trim().toLowerCase();
				if (val) {
					const exists = [...currentValues, ...newValues].find(({ value }) => value === val);
					if (exists) {
						NotificationService.error('Oops', `Email ${val} already inserted`);
					} else {
						newValues.push({
							label: val,
							value: val,
							isValidEmail: UtilityService.isValidEmail(val),
							fakeId: UtilityService.generateRandomString(30),
						});
					}
				}
			});
			if (newValues.length > 0) {
				ref.current.setValue([...currentValues, ...newValues]);
			}
		};

		const onKeyDown = (e) => {
			if (triggerKeys.indexOf(e.keyCode) > -1) {
				e.preventDefault();
				prepareValuesFromInput();
			} else if (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) {
				setTimeout(prepareValuesFromInput, 50);
			}
		};

		const checkForValidation = () => {
			if (!setError || !clearErrors) {
				return;
			}
			if (values.length < min) {
				Object.keys(errors).map((key) => {
					setError(key, errors[key]);
				});
				setError(name, {
					type: 'validate',
					message: isSubmitted
						? "You've more team members to add, please enter an email address for each team member"
						: '',
				});
			} else if (values.length > limit) {
				Object.keys(errors).map((key) => {
					setError(key, errors[key]);
				});
				setError(name, {
					type: 'validate',
					message: "You've added more team members, please enter an email address for each team member",
				});
			} else {
				const notValidEmails = values.filter(({ isValidEmail }) => !isValidEmail);
				if (notValidEmails.length > 0) {
					Object.keys(errors).map((key) => {
						setError(key, errors[key]);
					});
					setError(name, {
						type: 'validate',
						message:
							notValidEmails.length > 1
								? 'The highlighted values are not valid email addresses'
								: 'The highlighted value is not a valid email address',
					});
				} else {
					clearErrors(name);
					Object.keys(errors).map((key) => {
						setError(key, errors[key]);
					});
				}
			}
		};

		React.useEffect(() => {
			checkForValidation();
		}, [values.length, isSubmitted, limit]);

		React.useEffect(() => {
			const toAddValues = values.filter(({ value }) => !!value);
			ref.current.setValue(toAddValues);
		}, [values.length]);

		return (
			<div className={'w-full mb-4'}>
				<AppLabel label={label} />
				<div className={`flex flex-row ${inputContainerClassName}`}>
					<CreatableSelect
						isClearable={false}
						isMulti={true}
						placeholder={placeholder}
						classNamePrefix='no-options app-dropdown-prefix'
						className={`app-dropdown flex flex-1 ${props.className} ${
							errors && errors[name] ? ' error' : ''
						}`}
						components={{
							IndicatorSeparator: null,
							DropdownIndicator: null,
							MenuList: () => null,
							MultiValue: ({ data, ...props }) => {
								return (
									<div
										className={`flex flex-row items-center !rounded-sm m-1 px-1 ${
											data.isValidEmail && props.index < limit ? 'bg-gray-100' : 'bg-red-100'
										}`}
									>
										<p className={'ttg-text-xs'}>{data.label}</p>
										<AppIcon
											icon={'close-line'}
											className={'text-sm cursor-pointer hover:text-rose-500'}
											{...props.removeProps}
										/>
									</div>
								);
							},
						}}
						onKeyDown={onKeyDown}
						{...props}
						ref={ref}
					/>
					{!!actionIcon && (
						<AppButton
							id={actionId}
							type={'button'}
							icon={actionIcon}
							className={'btn btn-action ml-4'}
							onClick={onAction}
							tooltip={tooltip}
						/>
					)}
				</div>
				<span className={`app-input-error-message ${shouldShowErrorMessage ? 'visible' : 'invisible'}`}>
					{errors &&
						errors[name] &&
						renderErrorMessage(errors[name].type, props, defaultErrorMessage, errors[name].message)}
				</span>
			</div>
		);
	}
);

export default MultipleEmailInput;
