import React from 'react';

const Items = ({ order }) => {
	return (
		<div className='flex flex-col mt-6'>
			<h1 className='ttg-text-base text-gray-800 font-medium mb-2'>Product Summary and Billing</h1>

			{order.items.map((item) => {
				return (
					<div className={'flex border-b-1 border-gray-300 py-1'} key={item.id}>
						<p className={'flex flex-1 text-gray-800 ttg-text-xs mr-4'}>{item.name}</p>
						<div className={'flex w-64'}>
							<p className={'flex flex-1 ttg-text-xs text-gray-600'}>
								{item.item_price} x {item.quantity}
							</p>
							<p className={'ttg-text-xs text-gray-800'}>{item.total_price}</p>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default Items;
