import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UtilityService from '../../services/UtilityService';
import RouteNames from '../../router/RouteNames';
import { AssessmentLayout } from '../../components/Layout';
import { AssessmentDetailsHeader, AssessmentDetailsRoster } from '../../components/Assessments';
import AssessmentService from '../../services/AssessmentService';
import { useAssessments } from '../../providers/AssessmentProvider';

const Details = () => {
	const location = useLocation();

	const navigate = useNavigate();

	const { id } = UtilityService.getQueryParams(location.search);

	const { assessmentDetails: details, setAssessmentDetails, loading, setLoading } = useAssessments();

	const _getAssessmentDetails = React.useCallback(() => {
		setLoading(true);
		AssessmentService.details({ id })
			.then((response) => {
				setAssessmentDetails(AssessmentService.prepareAssessmentDetails(response));
				if (response.assessment_data.status === 'Setup Incomplete') {
					if (response.assessment_data.team_type === 'team') {
						navigate(`${RouteNames.TeamAssessmentSetup}?id=${id}`, { replace: true });
					} else {
						navigate(`${RouteNames.IndividualAssessment}?id=${id}`, { replace: true });
					}
				}
			})
			.catch(() => navigate(RouteNames.Assessments, { replace: true }))
			.finally(() => setLoading(false));
	}, [id, location, navigate]);

	React.useEffect(() => {
		_getAssessmentDetails();
	}, [_getAssessmentDetails]);

	if (!details) {
		return null;
	}

	return (
		<AssessmentLayout loading={loading}>
			 <AssessmentDetailsHeader details={details} />
			<div className={'ttg-assessment-section flex-col'}>
				<AssessmentDetailsRoster details={details} getAssessmentDetails={_getAssessmentDetails} />
			</div>
		</AssessmentLayout>
	);
};

export default Details;
