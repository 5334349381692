import React from 'react';
import { AppIcon } from './index';

const Accordion = ({ isOpen, showIcon = true, title, children }) => {
	const [active, setActive] = React.useState(isOpen);

	React.useEffect(() => {
		setActive(isOpen);
	}, [isOpen]);

	return (
		<div className={`ttg-accordion ${active ? 'open' : 'closed'}`} id={'ttg-accordion'}>
			<div className={'ttg-accordion-title-container'} onClick={() => setActive(!active)}>
				<div className={'ttg-accordion-title'}>{title}</div>
				{showIcon && <AppIcon icon={'arrow-down-s-line'} className={'text-3xl'} />}
			</div>
			<div className={`ttg-accordion-content`}>{children}</div>
		</div>
	);
};

export default Accordion;
