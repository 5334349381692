import React from 'react';
import { AppPdfPage } from '../../../App';
import ReportService from '../../../../services/ReportService';
import UtilityService from '../../../../services/UtilityService';

const scoreLevels = {
	low: 0,
	medium: 1,
	high: 2,
};

const answers = [
	{ value: 1, label: 'Almost Never' },
	{ value: 2, label: 'Rarely' },
	{ value: 3, label: 'Sometimes' },
	{ value: 4, label: 'Usually' },
	{ value: 5, label: 'Almost Always' },
];

const TheFiveBehaviors = ({ details }) => {
	const { child, parent } = details;

	const reversePyramidItems = React.useMemo(() => {
		return [...ReportService.PyramidItems].reverse();
	}, []);

	const ordered5D = React.useMemo(() => {
		const childCompletedAt = UtilityService.formatDate(child.current_team.completed, 'MM/DD/YYYY');
		const parentCompletedAt = UtilityService.formatDate(parent.current_team.completed, 'MM/DD/YYYY');
		return Object.values(ReportService.Ordered5DF).map((behavior) => {
			const childScore = child.average_response_per_category[behavior] ?? 1;
			const childLevel = ReportService.getLevelByValue(childScore);
			const parentScore = parent.average_response_per_category[behavior] ?? 1;
			const parentLevel = ReportService.getLevelByValue(parent.average_response_per_category[behavior]);

			return {
				behavior,
				child: {
					average: childScore?.toFixed(2),
					completedAt: childCompletedAt,
					level: childLevel,
					percentage: `${((childScore - 1) / 4) * 100}%`,
					helperText:
						scoreLevels[childLevel] === scoreLevels[parentLevel]
							? 'the same as'
							: scoreLevels[childLevel] > scoreLevels[parentLevel]
							? 'an improvement from'
							: 'a decline from',
				},
				parent: {
					average: parentScore?.toFixed(2),
					completedAt: parentCompletedAt,
					level: parentLevel,
					percentage: `${((parentScore - 1) / 4) * 100}%`,
				},
			};
		});
	}, [child, parent]);

	const renderPyramid = (type) => {
		return (
			<svg className='teamPyramid' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 440.98 337.17'>
				<title>Pyramid</title>
				<g className={`ScoresPyramid ScoresPyramid--hideLabels`} data-name='Layer 2'>
					<g id='Layer_1-2' data-name='Layer 1'>
						<polygon
							fill='#F9FAFB'
							points='220.49 0 440.98 337.17 0 337.17 220.49 0'
							transform='translate(-15 0)'
						/>
						{reversePyramidItems.map((i) => (
							<g id={i.key} key={i.key}>
								<polygon
									className={`${i.key} ${
										i.key === type
											? ReportService.getLevelByValue(child.average_response_per_category[type])
											: ''
									}`}
									points={i.points}
								/>
								<text className='PyramidText' transform={i.transform} />
							</g>
						))}
					</g>
				</g>
			</svg>
		);
	};

	return (
		<AppPdfPage page={5} sectionClassName={'FiveBehaviors'}>
			<div className='FiveBehaviors__layout'>
				<div className='FiveBehaviors__heading'>
					<div className='PageHeading'>
						<div className='PageHeading__title'>The Five Behaviors</div>
					</div>
				</div>
				<div className='FiveBehaviors__content'>
					<div className='FiveBehaviors__summary'>
						{ordered5D.map((behavior) => (
							<div className='FiveBehaviors__summaryRow' key={behavior.behavior}>
								<div className='FiveBehaviors__summaryBehaviorColumn'>{behavior.behavior}</div>

								<div className='FiveBehaviors__summaryMeterColumn'>
									<div className='ProgressBar'>
										<svg className='ProgressBar__meter'>
											<rect className='ProgressBar__meterBar'></rect>
											<rect
												className={`ProgressBar__meterFill ProgressBar--${behavior.child.level}`}
												width={behavior.child.percentage}
											></rect>
										</svg>
										<div className='ProgressBar__label pl-1'>
											{behavior.child.completedAt}{' '}
											<span className='ProgressBar__label--score'>{behavior.child.average}</span>
										</div>
									</div>

									<div className='ProgressBar'>
										<svg className='ProgressBar__meter'>
											<rect className='ProgressBar__meterBar'></rect>
											<rect
												className={`ProgressBar__meterFill ProgressBar--${behavior.parent.level}`}
												width={behavior.parent.percentage}
											></rect>
										</svg>
										<div className='ProgressBar__label pl-1'>
											{behavior.parent.completedAt}{' '}
											<span className='ProgressBar__label--score'>{behavior.parent.average}</span>
										</div>
									</div>
								</div>
							</div>
						))}

						<div className='FiveBehaviors__summaryRow'>
							<div className='FiveBehaviors__summaryBehaviorColumn'></div>

							<div className='FiveBehaviors__summaryMeterColumn'>
								<div className='ProgressScale ProgressScale'>
									{answers.map((answer) => (
										<div className='ProgressScale__mark' key={answer.value}>
											<div className='ProgressScale__markLabel MarkLabel'>
												<div className='MarkLabel__number'>{answer.value}</div>
												<div className='MarkLabel__text'>{answer.label}</div>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>

					<div className='FiveBehaviors__descriptions'>
						{ordered5D.map((behavior) => (
							<div className='FiveBehaviors__descriptionRow' key={behavior.behavior}>
								<div className='FiveBehaviors__descriptionPyramidColumn'>
									{renderPyramid(behavior.behavior)}
								</div>

								<div className='FiveBehaviors__descriptionTextColumn'>
									<div className='FiveBehaviors__descriptionTitle'>{behavior.behavior}</div>
									<div className='FiveBehaviors__descriptionBody'>
										Your team scores {behavior.child.level} on{' '}
										<span className='lowercase'>{behavior.behavior}</span>. This is{' '}
										{behavior.child.helperText} your previous score.
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</AppPdfPage>
	);
};

export default TheFiveBehaviors;
