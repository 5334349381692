import React from 'react';
import { AppPdfPage } from '../../../App';
import UtilityService from '../../../../services/UtilityService';

const Areas = ({ details, sectionClassName, title, questions, page, index, description, icon }) => {
	const allQuestions = React.useMemo(() => {
		const q = {};
		if (!details.questions) {
			return q;
		}
		details.questions
			.filter((q) => questions.indexOf(q.sort - 1) > -1)
			.forEach((question) => {
				q[question.sort - 1] = question;
			});
		return q;
	}, [details]);

	return (
		<AppPdfPage page={7 + page} sectionClassName={sectionClassName}>
			<div className='AreaPage'>
				<div className='AreaPage__layout'>
					<div className='AreaPage__heading'>
						<div className='AreaHeading'>
							<div className='AreaHeading__title'>{title}</div>
							<div className='AreaHeading__iconContainer'>
								<img src={icon} alt={''} />
							</div>
						</div>
					</div>
					<div className='AreaPage__content'>
						<div className='AreaPage__summary'>
							<div className='Blurb'>
								<div className='Blurb__content'>
									{index === 0 ? description : '(Continued from the previous page.)'}
								</div>
							</div>
						</div>

						<div className='AreaPage__questionReview'>
							{questions.map((q, idx) => (
								<div className='AreaQuestion' key={idx}>
									<div className='AreaQuestion__number'>
										{UtilityService.padNumber(allQuestions[q]?.sort, '0')}.
									</div>
									<div className='AreaQuestion__content'>
										<div
											className='AreaQuestion__label'
											dangerouslySetInnerHTML={{ __html: allQuestions[q]?.question }}
										/>
										<div className='AreaQuestion__meta'>
											<div className='AreaQuestion__metaCol'>
												Behavior: <strong>{allQuestions[q]?.category}</strong>
											</div>
											<div className='AreaQuestion__metaCol'>
												Avg. Score:{' '}
												<strong>{Number(details.raw_scores_average[q]).toFixed(2)}</strong>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</AppPdfPage>
	);
};

export default Areas;
