import React from 'react';
import Select from 'react-select';
import { AppButton, AppIcon, AppLabel, AppLoading } from './index';
import renderErrorMessage from '../../constants/ErrorMessages';

const Dropdown = React.forwardRef(
	(
		{
			label,
			actionIcon,
			tooltip,
			onAction,
			actionId,
			loading = false,
			placeholder = '',
			showErrorMessage = true,
			showDropdownIndicator = true,
			errors = {},
			defaultErrorMessage = '',
			inputContainerClassName = '',
			containerClassName = '',
			...props
		},
		ref
	) => {
		const { name } = props;
		const shouldShowErrorMessage = !!(showErrorMessage && errors && errors[name]);

		return (
			<div ref={ref} className={`w-full mb-4 ${containerClassName}`}>
				<AppLabel label={label} />
				<div className={`flex flex-row ${inputContainerClassName}`}>
					<Select
						{...props}
						ref={ref}
						placeholder={placeholder}
						classNamePrefix='app-dropdown-prefix'
						isLoading={loading}
						className={`app-dropdown flex flex-1 ${props.className} ${
							errors && errors[name] ? ' error' : ''
						}`}
						components={{
							IndicatorSeparator: null,
							DropdownIndicator: () =>
								showDropdownIndicator ? (
									<AppIcon icon={'arrow-down-s-line'} className={'text-xl mx-2 text-gray-500'} />
								) : null,
							LoadingIndicator: () => <AppLoading height={'h-4'} size={'text-sm'} />,
						}}
						styles={{
							option: (provided) => ({
								...provided,
								fontSize: '14px',
							}),
						}}
					/>
					{!!actionIcon && (
						<AppButton
							id={actionId}
							type={'button'}
							icon={actionIcon}
							className={'btn btn-action ml-4'}
							onClick={onAction}
							tooltip={tooltip}
						/>
					)}
				</div>
				<span className={`app-input-error-message ${shouldShowErrorMessage ? 'visible' : 'invisible'}`}>
					{errors &&
						errors[name] &&
						renderErrorMessage(errors[name].type, props, defaultErrorMessage, errors[name].message)}
				</span>
			</div>
		);
	}
);

export default Dropdown;
