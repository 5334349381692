import React from 'react';
import { AppButton, AppIcon, AppModalComponent } from '../../App';
import RouteNames from '../../../router/RouteNames';
import { useNavigate } from 'react-router-dom';
import AssessmentService from '../../../services/AssessmentService';
import NotificationService from '../../../services/NotificationService';
import { useAssessments } from '../../../providers/AssessmentProvider';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const ConfirmDeleteAssessmentModal = ({ isOpen, name, onClose }) => {
	const navigate = useNavigate();
	const [deleting, setDeleting] = React.useState(false);

	const { setupAssessment } = useAssessments();

	const _delete = async () => {
		setDeleting(true);
		await AssessmentService.deleteDraft(setupAssessment.id)
			.then(() => {
				NotificationService.success('Assessment was deleted successfully');
				onClose();
				navigate(RouteNames.Assessments, { replace: true });
			})
			.finally(() => setDeleting(false));
	};

	return (
		<AppModalComponent id={'ttg-setup-delete-assessment-confirm'} isOpen={isOpen} style={customStyles}>
			<div className='bg-white p-6' style={{ maxWidth: '512px' }}>
				<div className='flex'>
					<div className={'flex items-center justify-center w-10 h-10 rounded-full bg-red-100'}>
						<AppIcon icon={'delete-bin-line'} className={'text-lg text-red-500'} />
					</div>
					<div className='flex flex-1 flex-col ml-4'>
						<h1 className={'ttg-text-lg text-gray-900 font-medium mb-2'}>Delete Assessment</h1>
						<p className={'ttg-text-sm text-gray-500 mb-4'}>
							Are you sure you wish to delete <span className='font-medium'>{name}</span> assessment? Your
							current setup progress will be lost.
						</p>
						<div>
							<AppButton
								id={'ttg-setup-delete-assessment-cancel-button'}
								text={'No, Continue setup process'}
								className={'btn btn-secondary mr-4'}
								onClick={onClose}
								loading={deleting}
							/>
							<AppButton
								id={'ttg-setup-delete-assessment-confirm-button'}
								text={'Yes, Delete'}
								onClick={_delete}
								loading={deleting}
							/>
						</div>
					</div>
				</div>
			</div>
		</AppModalComponent>
	);
};

export default ConfirmDeleteAssessmentModal;
