import React from 'react';
import { AppButton, AppDatepicker, AppSlideOver } from '../../App';
import dayjs from 'dayjs';
import UtilityService from '../../../services/UtilityService';
import Alert from '../../App/Alert';
import AssessmentService from '../../../services/AssessmentService';
import { useAssessments } from '../../../providers/AssessmentProvider';

const EditScheduleDateSidebar = ({ isOpen, onClose, assessment }) => {
	const [date, setDate] = React.useState();
	const [timezone, setTimezone] = React.useState(
		UtilityService.getTimezoneByValue(assessment.assessment_data.scheduled_process_timezone)
	);
	const { loading: updatingSchedule, setLoading } = useAssessments();

	React.useEffect(() => {
		if (!isOpen && assessment.assessment_data.scheduled_process_at) {
			setDate(assessment.assessment_data.scheduled_process_at);
			setTimezone(UtilityService.getTimezoneByValue(assessment.assessment_data.scheduled_process_timezone));
		}
	}, [isOpen]);

	const hasRemindersBeforeDate = React.useMemo(() => {
		if (!assessment.reminders || !timezone || !timezone.value || assessment.reminders.length === 0) {
			return false;
		}
		const i = assessment.reminders.findIndex((r) => {
			const d = dayjs.tz(date, 'YYYY-MM-DD HH:mm', timezone.value);
			const rd = dayjs.tz(r.raw_process_at, 'YYYY-MM-DD HH:mm', r.raw_timezone);
			return d.isAfter(rd);
		});
		return i > -1;
	}, [date, timezone]);

	const save = async () => {
		const payload = {
			date: dayjs(date).format('YYYY-MM-DD HH:mm'),
			timezone: timezone.value,
		};

		setLoading(true);
		AssessmentService.updateIndividual(assessment.assessment_data.team_id, payload)
			.then(onClose)
			.finally(() => setLoading(false));
	};

	return (
		<AppSlideOver isOpen={isOpen} closeModal={onClose} slideOverContentClass={'!bg-white'}>
			<div className={'flex flex-1 flex-col p-4'}>
				<p className={'font-medium text-gray-900 mb-5'}>Edit Scheduled Date/Time</p>

				{!!hasRemindersBeforeDate && (
					<div className={'mb-5'}>
						<Alert title={"Schedule Date can't be updated"}>
							You can't reschedule this assessment for this date because there are reminders set to be
							sent before that time.
						</Alert>
					</div>
				)}

				<div className={'w-full'}>
					<AppDatepicker
						label={'Scheduled Date/Time'}
						value={date}
						onChange={(d) => setDate(dayjs(d).format())}
						min={dayjs().endOf('day').add(-1, 'day')}
						timezone={timezone}
						onTimezoneChange={setTimezone}
					/>
				</div>

				<div className='my-5 border-t-1 border-gray-200' />

				<div className='flex gap-2'>
					<div className='flex flex-1'>
						<AppButton
							id={'ttg-edit-schedule-date-save-button'}
							className={'btn btn-primary w-full'}
							text={'Save'}
							onClick={save}
							loading={updatingSchedule}
							disabled={hasRemindersBeforeDate}
						/>
					</div>
					<div className='flex flex-1'>
						<AppButton
							id={'ttg-edit-schedule-date-cancel-button'}
							className={'btn btn-secondary w-full'}
							text={'Cancel'}
							onClick={onClose}
							loading={updatingSchedule}
						/>
					</div>
				</div>
			</div>
		</AppSlideOver>
	);
};

export default EditScheduleDateSidebar;
