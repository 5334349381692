import React from 'react';
import { AppToolsWidget, AppWidget } from '..';
import { AppIcon } from '../../App';
import Images from '../../../assets/Images';
import RouteNames from '../../../router/RouteNames';
import Widget from '../../Notifications/Widget';

const Header = ({ onMenuClick }) => {
	return (
		<div className={'ttg-account-main-layout-header'}>
			<div className='content'>
				<div className={'flex items-center md:hidden'} onClick={onMenuClick}>
					<AppIcon icon='menu-fill' className={'mx-2 ttg-text-2xl text-rose-500 font-bold'} />
				</div>
				<div className={'logo'} id={'ttg-account-header-logo'}>
					<a href='/'>
						<img src={Images.logo} alt='Logo' />
					</a>
				</div>
				<div className={'flex items-center md:hidden'}>
					<Widget />
				</div>
				<div className={'nav'}>
					<Widget />
					<a id={'ttg-account-header-store'} className={'nav-item'} href={RouteNames.Store}>
						Store
					</a>
					<AppToolsWidget />
					<AppWidget />
				</div>
			</div>
		</div>
	);
};

export default Header;
