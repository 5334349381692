const errors = {
	ASSESSMENT_COMPLETED: ({ main_contact }) =>
		`<p class="ttg-text-sm text-gray-800">The online Team Assessment you are a member of is complete.</p><br><p class="ttg-text-sm text-gray-800"> Please contact <span class="text-rose-500 font-medium">${main_contact}</span> with any further questions.</p>`,
	MEMBER_COMPLETED: () =>
		'<p class="ttg-text-sm text-gray-800">The Assessment scores for this team and this person have already been submitted to our system.</p><br><p class="ttg-text-sm text-gray-800"> If you are having trouble, please contact <a class="text-rose-500 font-medium" href="mailto:support@tablegroup.com">support@tablegroup.com</a>.</p>',
	ASSESSMENT_NOT_FOUND: () =>
		'<p class="ttg-text-sm text-gray-800">This assessment cannot be found.</p><br><p class="ttg-text-sm text-gray-800"> If you are having trouble, please contact <a class="text-rose-500 font-medium" href="mailto:support@tablegroup.com">support@tablegroup.com</a>.</p>',
	MEMBER_NOT_FOUND: () =>
		'<p class="ttg-text-sm text-gray-800">There is a problem with your assessment. Please make sure you copied the full link into the location bar in your browser.</p><br><p class="ttg-text-sm text-gray-800"> If you are having trouble, please contact <a class="text-rose-500 font-medium" href="mailto:support@tablegroup.com">support@tablegroup.com</a>.</p>',
	ASSESSMENT_WRONG_LINK: () =>
		'<p class="ttg-text-sm text-gray-800">This online team assessment link has expired. A new link has been sent for this assessment.</p><br><p class="ttg-text-sm text-gray-800"> If you are having trouble, please contact <a class="text-rose-500 font-medium" href="mailto:support@tablegroup.com">support@tablegroup.com</a>.</p>',
	ASSESSMENT_CLOSED: () =>
		'<p class="ttg-text-sm text-gray-800">This online team assessment has been closed by the administrator and is no longer in process.</p><br><p class="ttg-text-sm text-gray-800"> If you are having trouble, please contact <a class="text-rose-500 font-medium" href="mailto:support@tablegroup.com">support@tablegroup.com</a>.</p>',
	ASSESSMENT_NOT_SETUP: () =>
		'<p class="ttg-text-sm text-gray-800">This assessment cannot be updated as it is not setup yet.</p><br><p class="ttg-text-sm text-gray-800"> If you are having trouble, please contact <a class="text-rose-500 font-medium" href="mailto:support@tablegroup.com">support@tablegroup.com</a>.</p>',
	ASSESSMENT_RECOVERED_ERROR: () =>
		'<p class="ttg-text-sm text-gray-800">We are experiencing problems with our Team Assessment. We apologize for the inconvenience. We are actively working to resolve the issue and restore your team&rsquo;s information.</p><br><p class="ttg-text-sm text-gray-800"> If you are having trouble, please contact <a class="text-rose-500 font-medium" href="mailto:support@tablegroup.com">support@tablegroup.com</a>.</p>',
};

export default errors;
