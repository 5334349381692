import React from 'react';
import { AppToolsWidget, AppWidget } from '..';
import { AppIcon } from '../../App';
import Images from '../../../assets/Images';
import RouteNames from '../../../router/RouteNames';
import Widget from '../../Notifications/Widget';
import { useCustomer } from '../../../providers/CustomerProvider';

const Header = ({ onMenuClick }) => {
	const { customer, licenses: { licenses } } = useCustomer();

	return (
		<div className={'ttg-assessment-main-layout-header'}>
			<div className='flex flex-row bg-rose-500'>
				<div className='container mx-auto flex flex-col md:flex-row p-4'>
					<div className={'flex flex-1 text-white'}>
						<p id={'ttg-assessment-header-info'} className='font-medium px'>
							Welcome, {customer.customers_firstname} {customer.customers_lastname}. You have{' '}
							<span className={'font-semibold'}>{licenses}</span> unused licenses in your account.{' '}
							<a
								id={'ttg-assessment-purchase-more'}
								href={RouteNames.OnlineTeamAssessment}
								className='font-semibold cursor-pointer hover:underline'
							>
								Purchase more
							</a>
						</p>
					</div>
					<a
						href='https://help.tablegroup.com/'
						target='_blank'
						rel='noopener noreferrer'
						className='text-white leading-none'
					>
						<AppIcon icon='question-line' className='text-2xl' />
					</a>
				</div>
			</div>
			<div className='content'>
				<div className={'flex items-center md:hidden'} onClick={onMenuClick}>
					<AppIcon icon='menu-fill' className={'mx-2 ttg-text-2xl text-rose-500 font-bold'} />
				</div>
				<div className={'logo'} id={'ttg-assessment-header-logo'}>
					<a href='/'>
						<img src={Images.logo} alt='Logo' />
					</a>
				</div>
				<div className={'flex items-center md:hidden'}>
					<Widget />
				</div>
				<div className={'nav'}>
					<Widget />
					<a id={'ttg-assessment-header-store'} className={'nav-item'} href={RouteNames.Store}>
						Store
					</a>
					<AppToolsWidget />
					<AppWidget />
				</div>
			</div>
		</div>
	);
};

export default Header;
