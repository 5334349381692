import React from 'react';

import { AppLoading } from '../../App';
import { PublicHeader, PublicFooter } from '../index';

const MainLayout = ({ loading, headerTitle, headerStatus, children, footer }) => {
	const renderContent = () => {
		if (loading) {
			return <AppLoading height='h-96' />;
		}
		return children;
	};

	return (
		<div className='ttg-layout ttg-public-main-layout'>
			<PublicHeader status={headerStatus} title={headerTitle} />
			<div className='ttg-public-main-layout-content'>{renderContent()}</div>
			<PublicFooter children={footer} />
		</div>
	);
};

export default MainLayout;
