import React from 'react';
import { AppPdfPage } from '../../../App';

const FirstPage = () => {
	return (
		<AppPdfPage>
			<p>This is the first page of the Assessment Report V1</p>
		</AppPdfPage>
	);
};

export default FirstPage;
