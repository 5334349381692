import React from 'react';
import { useCustomer } from '../../../providers/CustomerProvider';

const LicensesInfo = () => {
	const { licenses: { licenses, ordered_licenses, used_licenses } } = useCustomer();

	return (
		<div className='licenses-stats'>
			<div className='container'>
				<div className='licenses-status-results'>
					<div className='licenses-result'>
						<div id='ttg-used-licenses-tile-id'>{used_licenses || 0}</div>
						Number of used licenses
					</div>
					<div className='licenses-result'>
						<div id='ttg-unused-licenses-tile-id'>{licenses || 0}</div>
						Number of unused licenses
					</div>
					<div className='licenses-result'>
						<div id='ttg-ordered-licenses-tile-id'>{ordered_licenses || 0}</div>
						Total licenses purchased
					</div>
				</div>
			</div>
		</div>
	);
};

export default LicensesInfo;
