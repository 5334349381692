import React from 'react';

const Tabs = ({ active, tabs, onClick }) => {
	return (
		<div className={'flex flex-col'}>
			<div className={'flex flex-row'}>
				{tabs.map((tab, index) => {
					return (
						<div
							id={`ttg-tab-${index}`}
							className={`flex flex-1 justify-center lg:justify-start lg:flex-none border-b-2 px-2 ${
								active === tab.key ? 'border-rose-300' : 'border-gray-100'
							}`}
							key={index}
							onClick={() => onClick(tab.key)}
						>
							<p
								className={`ttg-text-base text-center cursor-pointer py-2 ${
									active === tab.key ? 'text-gray-900 font-medium' : 'text-gray-500'
								}`}
							>
								{tab.title}
							</p>
						</div>
					);
				})}
			</div>
			<div className='flex flex-1 border-b-2 border-gray-100' />
		</div>
	);
};

export default Tabs;
