import React from 'react';
import { useLocation } from 'react-router';
import { TTGRegister, withSSOAuthProvider } from '@ttg/ui-user-lib';

import { PublicLayout } from '../../components/Layout/';
import AppTabTitle from '../../components/App/AppTabTitle';
import RouteNames from '../../router/RouteNames';
import UtilityService from '../../services/UtilityService';
import { useSSOParams } from '../../providers/useSSOParams';
import config from '../../constants/config';
import { useCustomer } from '../../providers/CustomerProvider';

const TTGRegisterWithProvider = withSSOAuthProvider(TTGRegister, useSSOParams);

const SignUp = () => {
	const { customer } = useCustomer();
	const location = useLocation();
	const { redirect, token, license_token, source } = UtilityService.getQueryParams(location.search);
	const decodedRedirect = redirect ? decodeURIComponent(redirect) : undefined;

	const handleUserLoggedIn = React.useCallback(
		(_, onLoadFinish, redirectUrl) => {
			window.open((redirectUrl || decodedRedirect || RouteNames.Tools).replace('//', '/'), '_self');
		},
		[decodedRedirect]
	);

	const handleSignupSuccess = (u, onLoadFinish, redirectUrl) => {
		if (source && source === 'wg') {
			return window.open(`${config.wg_url}/client/login`, '_self');
		}

		handleUserLoggedIn(u, onLoadFinish, redirectUrl);
	};

	const tokenType = React.useMemo(() => {
		if (token) return config.AUTH_TOKENS.WG_CERTIFICATION;
		if (license_token) return config.AUTH_TOKENS['5DOT_LICENSE'];

		return undefined;
	}, [token, license_token]);
	const tokenValue = React.useMemo(() => token || license_token, [token, license_token]);

	const isLoggedIn = customer && customer.customers_id;

	if (isLoggedIn) {
		return handleUserLoggedIn();
	}

	return (
		<PublicLayout headerTitle='' headerStatus={() => {}} footer={<></>}>
			<AppTabTitle title={'Sign Up | The Table Group'} />
			<TTGRegisterWithProvider
				className='mx-auto'
				captchaEnabled={config.captcha_enabled}
				captchaKey={config.captcha_site_key}
				apiEndpoint={config.ttg_url}
				onFinish={handleSignupSuccess}
				theme='ttg-app'
				tokenType={tokenType}
				token={tokenValue}
				resource={
					decodedRedirect
						? {
								redirectPath: decodedRedirect,
						  }
						: undefined
				}
			/>
		</PublicLayout>
	);
};

export default SignUp;
