import React from 'react';
import { Link } from 'react-router-dom';

import { AppIcon, AppWidget } from '../../App';
import RouteNames from '../../../router/RouteNames';
import { useProfileMenu } from '../../../providers/ProfileMenuProvider';
import { useCustomer } from '../../../providers/CustomerProvider';

const Widget = () => {
	const {  customer } = useCustomer();
	const { onLogout } = useProfileMenu();
	const email = customer && customer.customers_email_address;

	if (!email) {
		return null;
	}

	return (
		<AppWidget
			id={'account'}
			labelComponent={<AppIcon className={'nav-item'} icon={'user-line'} />}
			className={'ttg-account-widget'}
		>
			<div className='ttg-account-info'>
				<p className='ttg-text-sm font-normal'>Logged in as</p>
				<p className='ttg-text-sm font-medium'>{email}</p>
			</div>
			<div id={'ttg-my-account-id'} className={'ttg-my-account-action'}>
				<Link to={RouteNames.Account}>
					<div className={'flex items-center'}>
						<AppIcon icon={'user-line'} />
						<span className='ttg-text-sm'>My Account</span>
					</div>
					<AppIcon icon={'share-box-fill'} className={'!text-xl !text-gray-400'} />
				</Link>
			</div>
			<div id={'ttg-my-licenses-id'} className={'ttg-my-account-action'}>
				<Link to={RouteNames.Licenses}>
					<div className={'flex items-center'}>
						<AppIcon icon={'file-line'} />
						<span className='ttg-text-sm'>Licenses</span>
					</div>
					<AppIcon icon={'share-box-fill'} className={'!text-xl !text-gray-400'} />
				</Link>
			</div>
			<div id={'ttg-account-logout-id'} onClick={onLogout} className={'ttg-account-logout-action'}>
				<AppIcon icon={'logout-box-r-line'} />
				<span className='ttg-text-sm'>Logout</span>
			</div>
		</AppWidget>
	);
};

export default Widget;
