import React from 'react';
import NotificationService from '../services/NotificationService';

export const CaptchaContext = React.createContext();

export const CaptchaProvider = ({ children, captchaRef }) => {
	const [showCaptcha, setShowCaptcha] = React.useState(false);
	const [loading, setLoading] = React.useState(false);

	const sendActionCallback = async (callback, showCaptcha) => {
		let recaptchaValue = null;

		if (showCaptcha) {
			recaptchaValue = await captchaRef.current.getCaptchaValue();
		}

		setLoading(true);
		return callback(recaptchaValue)
			.then((response) => {
				setLoading(false);
				if (response?.validateCaptcha) {
					setShowCaptcha(true);
					return sendActionCallback(callback, true);
				}
				setShowCaptcha(false);
				return response;
			})
			.catch((error) => {
				NotificationService.error(error.message);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const sendAction = React.useCallback((callback) => sendActionCallback(callback, showCaptcha), [showCaptcha]);

	return (
		<CaptchaContext.Provider value={{ sendAction, loading, captchaRef, setShowCaptcha }}>
			{children}
		</CaptchaContext.Provider>
	);
};

export const useCaptchaProvider = () => {
	return React.useContext(CaptchaContext);
};
