import React from 'react';

const DetailsSection = ({ title, children }) => {
	return (
		<div className='flex flex-col lg:flex-row border-t-1 border-gray-200 p-5'>
			<div className='flex w-80 ttg-text-lg font-medium text-gray-900 mb-3 lg:mb-0'>{title}</div>
			<div className='flex flex-1 flex-col'>{children}</div>
		</div>
	);
};

export default DetailsSection;
