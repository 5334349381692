import React from 'react';
import { AppButton } from '../../App';

const Navigation = ({
	iconClass,
	backIcon,
	backText = 'Back',
	nextIcon,
	nextClass = 'btn btn-primary',
	nextText = 'Next',
	canGoBack = true,
	onBack,
	onNext,
	loading,
	children,
}) => {
	return (
		<div className='flex flex-1 flex-row pt-4 border-t-1 border-gray-200'>
			<div className='flex flex-1'>
				{!!canGoBack && (
					<AppButton
						type={'button'}
						className={'btn btn-secondary'}
						icon={backIcon}
						iconClass={iconClass}
						iconRight={false}
						text={backText}
						loading={loading}
						onClick={onBack}
						id={'ttg-take-assessment-prev-button'}
					/>
				)}
			</div>
			{children}
			<AppButton
				type={'button'}
				className={nextClass}
				text={nextText}
				loading={loading}
				icon={nextIcon}
				iconRight={false}
				onClick={onNext}
				id={'ttg-take-assessment-next-button'}
			/>
		</div>
	);
};

export default Navigation;
