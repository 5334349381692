import React from 'react';
import { AccountHeader, AccountSidebar, AppMobileSidebar } from '../index';
import { AppLoading } from '../../App';

const MainLayout = ({ sidebar = true, loading, children }) => {
	const [isOpenSidebar, setIsOpenSidebar] = React.useState(false);

	const renderContent = () => {
		if (loading) {
			return <AppLoading height={'h-96'} />;
		}
		return children;
	};

	return (
		<div className={'ttg-layout ttg-account-main-layout'}>
			<AccountHeader onMenuClick={() => setIsOpenSidebar(!isOpenSidebar)} />
			<div className={'ttg-account-main-layout-content'}>
				<div className={'hidden md:flex'}>{sidebar && <AccountSidebar />}</div>
				<div className={'block md:hidden'}>
					<AppMobileSidebar
						sidebar={sidebar}
						sidebarContent={<AccountSidebar />}
						isOpen={isOpenSidebar}
						closeModal={() => setIsOpenSidebar(!isOpenSidebar)}
						slideFrom='left'
					/>
				</div>
				<div className={'ttg-account-main-layout-inner-content'}>{renderContent()}</div>
			</div>
		</div>
	);
};

export default MainLayout;
