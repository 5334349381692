import React from 'react';
import { AppPdfPage } from '../../../App';
import Images from '../../../../assets/Images';
import UtilityService from '../../../../services/UtilityService';

const KeyDifferences = ({ questions, common, page }) => {
	const { first, second } = React.useMemo(() => {
		if (!questions || questions.length === 0) {
			return { first: [], second: [] };
		}
		const [first, second] = UtilityService.splitArray(questions, 3);

		return {
			first,
			second,
		};
	}, []);

	const commonStatement = React.useMemo(() => {
		if (!common || !common.length) {
			return '';
		}
		if (common.length === 1) {
			return `Statement ${common[0]} was previously in your Areas of Key Differences section.`;
		}
		const indexes = common.slice(0, common.length - 1).join(', ');
		const lastIndex = common[common.length - 1];
		return `Statements ${indexes} and ${lastIndex} were previously in your Areas of Key Differences section.`;
	}, [common]);

	const renderRedAsterix = <span style={{ color: '#da1a32' }}>*</span>;

	const _KeyDiff = (pageNr, items, isFirstDiffPage) => (
		<AppPdfPage page={pageNr} sectionClassName={''}>
			<div className='AreaPage'>
				<div className='AreaPage__layout'>
					<div className='AreaPage__heading'>
						<div className='AreaHeading'>
							<div className='AreaHeading__title'>Areas of Key Differences</div>
							<div className='AreaHeading__iconContainer'>
								<img src={Images.report.exclamation} alt={''} />
							</div>
						</div>
					</div>
					<div className='AreaPage__content'>
						<div className='AreaPage__summary'>
							<div className='Blurb'>
								<div className='Blurb__content'>
									<span
										dangerouslySetInnerHTML={{
											__html: isFirstDiffPage
												? 'The questions listed below represent the areas where your team members&rsquo; scores differed from each other in a significant manner. Each question indicates an area that needs to be better understood across the team. The numbers below each question identify the distribution of responses within the team. If there are no questions listed below, each member of your team answered consistently. <br /><br />'
												: '(Continued from the previous page.)',
										}}
									/>

									{isFirstDiffPage && common.length === 0 && (
										<span>
											There were no questions that overlap from your previous report in this
											section.
										</span>
									)}

									{isFirstDiffPage && common.length > 0 && (
										<span>
											{renderRedAsterix} {commonStatement}
										</span>
									)}

									{items.length === 0 && (
										<p className={'mt-6'}>
											<strong>NOTE:</strong> there were no statistically significant areas of key
											differences for this team.
										</p>
									)}
								</div>
							</div>
						</div>

						{items.map((area) => (
							<div className='AreaPage__questionReview mb-4' key={area.question.sort}>
								<div className='AreaQuestion'>
									<div className='AreaQuestion__number'>
										{common.indexOf(area.question.sort) > -1 && <span>{renderRedAsterix} </span>}
										{UtilityService.padNumber(area.question.sort, '0')}.
									</div>
									<div className='AreaQuestion__content'>
										<div
											className='AreaQuestion__label'
											dangerouslySetInnerHTML={{ __html: area.question.question }}
										/>

										<div className='AreaQuestion__categories'>
											<div className='AreaQuestion__categoriesDescription'>
												Number of team members in each category:
											</div>
											<div className='AreaQuestion__scale'>
												<div className='AreaQuestion__scaleCol'>
													<div className='MarkLabel'>
														<div className='MarkLabel__number'>{area.answers[1]}</div>
														<div className='MarkLabel__text'>Almost Never</div>
													</div>
												</div>
												<div className='AreaQuestion__scaleCol'>
													<div className='MarkLabel'>
														<div className='MarkLabel__number'>{area.answers[2]}</div>
														<div className='MarkLabel__text'>Rarely</div>
													</div>
												</div>
												<div className='AreaQuestion__scaleCol'>
													<div className='MarkLabel'>
														<div className='MarkLabel__number'>{area.answers[3]}</div>
														<div className='MarkLabel__text'>Sometimes</div>
													</div>
												</div>
												<div className='AreaQuestion__scaleCol'>
													<div className='MarkLabel'>
														<div className='MarkLabel__number'>{area.answers[4]}</div>
														<div className='MarkLabel__text'>Usually</div>
													</div>
												</div>
												<div className='AreaQuestion__scaleCol'>
													<div className='MarkLabel'>
														<div className='MarkLabel__number'>{area.answers[5]}</div>
														<div className='MarkLabel__text'>Almost Always</div>
													</div>
												</div>
											</div>
										</div>

										<div className='AreaQuestion__meta'>
											<div className='AreaQuestion__metaCol'>
												Behavior: <strong>{area.question.category}</strong>
											</div>
											<div className='AreaQuestion__metaCol'>
												Avg. Score: <strong>{area.average.toFixed(2)}</strong>
											</div>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</AppPdfPage>
	);

	return (
		<>
			{_KeyDiff(page, first, true)}
			{questions.length > 3 && _KeyDiff(page + 1, second, false)}
		</>
	);
};

export default KeyDifferences;
