export enum AUTH_TOKEN_ENUM {
  PERMISSION = 'PERMISSION',
  WG_CERTIFICATION = 'WG_CERTIFICATION',
  WG_LICENSE = 'WG_LICENSE',
  WG_ASSESSMENT = 'WG_ASSESSMENT',
  '5DOT_LICENSE' = '5DOT_LICENSE',
  'RESOURCE' = 'RESOURCE'
}

export const AuthTokensEnum: string[] = Object.values(AUTH_TOKEN_ENUM);

type AuthTokenType = {
  [key in AUTH_TOKEN_ENUM]: string;
}

export const AUTH_TOKENS: AuthTokenType = {
  [AUTH_TOKEN_ENUM.PERMISSION]: 'PERMISSION',
  [AUTH_TOKEN_ENUM.WG_CERTIFICATION]: 'WG_CERTIFICATION',
  [AUTH_TOKEN_ENUM.WG_LICENSE]: 'WG_LICENSE',
  [AUTH_TOKEN_ENUM['5DOT_LICENSE']]: '5DOT_LICENSE',
  [AUTH_TOKEN_ENUM.RESOURCE]: 'RESOURCE',
  [AUTH_TOKEN_ENUM.WG_ASSESSMENT]: 'WG_ASSESSMENT'
}
