import React from 'react';
import { AppLoading, AppNoData, AppIcon, AppCheckBox, AppTooltip, AppButton, AppDropdownButton } from './index';
import UtilityService from '../../services/UtilityService';
import FullPagination from '../App/FullPagination';

const Table = ({
	title,
	info,
	columns,
	data,
	canLoadMore,
	onLoadMore,
	identifier = 'id',
	actions = [],
	noDataIcon,
	noDataMessage,
	loading,
	onItemClick,
	onSearch,
	onReset,
	onSort,
	pagination,
	actionLoaders,
	renderFilters,
	filters,
	setFilters,
	selectable = false,
	renderBulkActions,
	disableSelection,
	actionTooltip,
	isActionAvailable = () => {
		return true;
	},
	setStatusFilterLicenses,
	searchFilterLicenses,
	setSearchFilterLicenses,
	assessmentCounts,
	...props
}) => {
	const [selected, onSelect] = React.useState([]);

	React.useEffect(() => {
		const mappedData = data.map((i) => i[identifier]);
		const _selected = selected.filter((s) => mappedData.indexOf(s) > -1);
		onSelect(_selected);
	}, [data]);

	const _onItemClick = (item, col) => {
		if (col.identifier) {
			onItemClick(item, col);
		}
	};

	const onItemSelect = (id, deselect, item) => {
		if (item && disableSelection) {
			if (disableSelection(item)) {
				return;
			}
		}
		let newSelection = [...selected];
		if (id === 'all') {
			if (deselect) {
				newSelection = [];
			} else {
				newSelection = data.filter((r) => !disableSelection(r)).map((r) => r[identifier]);
			}
		} else {
			const idx = newSelection.indexOf(id);
			if (idx > -1) {
				newSelection.splice(idx, 1);
			} else {
				newSelection.push(id);
			}
		}
		onSelect(newSelection);
	};

	const _onSearch = (e) => {
		e.preventDefault();
		onSearch();
	};

	const renderFiltersContent = () => {
		return (
			<form id={'account-filters-table-form'} onSubmit={_onSearch}>
				<div className='flex flex-col lg:flex-row'>
					<div className='flex flex-1 w-full'>
						{renderFilters({
							filters,
							setFilters: (key, value) => {
								UtilityService.updateStateMethod(setFilters, key, value);
							},
							assessmentCounts,
							setStatusFilterLicenses,
							searchFilterLicenses,
							setSearchFilterLicenses,
						})}
					</div>
				</div>
			</form>
		);
	};

	const renderPagination = () => {
		return <FullPagination {...pagination} elements={data ? data.length : 0} />;
	};

	const renderAction = (actionItem, item) => {
		if (actionItem?.isButton) {
			return (
				<AppButton
					className={actionItem.className}
					icon={actionItem.icon}
					iconClass={actionItem.iconClassName}
					text={actionItem.text ?? null}
					onClick={() => actionItem.callback(item)}
					loading={loading}
					tooltip={'Copy assessment link'}
					tooltipPlacement={'top'}
					tooltipFontSize={'xs'}
				/>
			);
		}
		if (actionItem?.subActions) {
			return (
				<AppDropdownButton
					label={actionItem.label}
					actions={actionItem.subActions
						.filter((subAction) => isActionAvailable(subAction.label, item, true))
						.map((subAction) => ({
							...subAction,
							action: () => props[subAction.action](item),
						}))}
					isOpenClassName={'!bg-gray-50'}
					closeOnAction={true}
					actionContainerClass={'min-w-64 max-w-full md:max-w-none'}
					side={'right'}
					buttonClassName={'btn btn-secondary'}
					id={`app-actions-dropdown-button-${item.team_roster_slug}`}
					loading={loading}
					usePortal
				/>
			);
		}

		return (
			<>
				{!!actionItem.icon && <AppIcon icon={actionItem.icon} className={`${actionItem.iconClassName} mr-1`} />}
				<span
					id={`ttg-table-action-${actionItem.action}-${item[identifier]}`}
					className={`action ${actionItem.className}`}
					onClick={() => props[actionItem.action](item)}
				>
					{actionItem.label}
				</span>
			</>
		);
	};

	const renderContent = () => {
		if (!data || data.length === 0) {
			if (loading) {
				return (
					<div id={'ttg-table-main-loading'} className={'flex flex-1 justify-center items-center'}>
						<AppLoading height={'h-full'} />
					</div>
				);
			}
			return (
				<div className={'flex flex-1 justify-center items-center mt-8 h-2/3'}>
					<AppNoData icon={noDataIcon} message={noDataMessage} />
				</div>
			);
		}

		return (
			<div className={'max-w-full overflow-x-auto border-1 rounded-sm border-gray-200 mt-6'}>
				<div className='max-w-full overflow-x-auto mx-auto'>
					<div className='flex flex-col'>
						<div className='overflow-x-auto shadow-md'>
							<div className='inline-block min-w-full align-middle'>
								<div className='overflow-hidden'>
									<table>
										<thead>
											<tr>
												{selectable && data.length > 0 && (
													<th id='account-table-select-all'>
														<AppCheckBox
															id={'account-table-checkbox-all'}
															checked={selected.length === data.length}
															onChange={() =>
																onItemSelect('all', selected.length === data.length)
															}
														/>
													</th>
												)}
												{columns.map((col) => {
													return (
														<th
															key={col.key}
															onClick={() =>
																col.sortable &&
																col.sortable !== false &&
																onSort(
																	col,
																	filters.sort_dir === 'asc' &&
																		col.key === filters.sort_key
																		? 'desc'
																		: 'asc'
																)
															}
															className={`${
																col.sortable && col.sortable !== false
																	? 'cursor-pointer'
																	: 'cursor-default'
															}`}
														>
															{col.name}
															{!!col.headerTooltip && (
																<AppIcon
																	className={
																		'header-tooltip-icon text-xs text-rose-500 cursor-pointer ml-1'
																	}
																	icon={'question-line'}
																	data-for={`header-tooltip-${col.key}`}
																	data-tip
																/>
															)}
															{!!col.headerTooltip && (
																<AppTooltip
																	id={`header-tooltip-${col.key}`}
																	placement={'top'}
																>
																	{col.headerTooltip}
																</AppTooltip>
															)}
															{filters && filters.sort_key === col.key && (
																<AppIcon
																	icon={
																		filters.sort_dir === 'asc'
																			? 'arrow-up-s-line'
																			: 'arrow-down-s-line'
																	}
																	className={'ml-4'}
																/>
															)}
														</th>
													);
												})}
												{actions && actions.length > 0 && (
													<th id={'ttg-table-action-header'}>
														Actions
														{!!actionTooltip && (
															<AppIcon
																className={
																	'header-tooltip-icon text-xs text-rose-500 cursor-pointer ml-1'
																}
																icon={'question-line'}
																data-for={`action-tooltip`}
																data-tip
															/>
														)}
														{!!actionTooltip && (
															<AppTooltip id={`action-tooltip`} placement={'top'}>
																{actionTooltip}
															</AppTooltip>
														)}
													</th>
												)}
											</tr>
										</thead>
										<tbody>
											{data.map((item, key) => {
												return (
													<tr key={item[identifier]}>
														{selectable && (
															<td key={key} id='account-table-select-row'>
																<AppCheckBox
																	id={'account-table-checkbox'}
																	checked={selected.indexOf(item[identifier]) > -1}
																	onChange={() =>
																		onItemSelect(item[identifier], false, item)
																	}
																	disabled={
																		!!disableSelection && disableSelection(item)
																	}
																/>
															</td>
														)}
														{columns.map((col) => {
															const classes = [];
															if (
																col.identifier &&
																item.status !== 'Received' &&
																item.status !== 'Transferred'
															) {
																classes.push('identifier');
															}
															return (
																<td key={`${item[identifier]}-${col.key}`}>
																	<span
																		id={`ttg-table-data-${item[identifier]}-${col.key}`}
																		className={classes.join(' ')}
																		onClick={() => {
																			if (
																				item.status !== 'Received' &&
																				item.status !== 'Transferred'
																			)
																				return _onItemClick(item, col);
																		}}
																	>
																		{col.customRender &&
																		typeof props[col.customRender] == 'function'
																			? props[col.customRender](item)
																			: item[col.key]}
																	</span>
																</td>
															);
														})}
														{actions && actions.length > 0 && (
															<td key={`${item[identifier]}`}>
																<div className={'flex flex-row'}>
																	{actions.map((action) => {
																		if (!isActionAvailable(action.label, item))
																			return (
																				<span
																					key={action.action ?? action.key}
																				></span>
																			);
																		if (
																			actionLoaders &&
																			actionLoaders[item[identifier]]
																		) {
																			return (
																				<AppLoading
																					id={`ttg-table-action-${action?.action}-${item[identifier]}-loader`}
																					key={action.action ?? action.key}
																					height={'h-2'}
																					size={'text-sm'}
																				/>
																			);
																		}

																		return (
																			<div
																				className={
																					'inline-flex items-center mr-4 whitespace-nowrap'
																				}
																				key={action.action ?? action.key}
																			>
																				{renderAction(action, item)}
																			</div>
																		);
																	})}
																</div>
															</td>
														)}
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				{canLoadMore && (
					<div id={'ttg-table-load-more-container'} className={'load-more-container'}>
						{loading ? (
							<AppLoading height={'h-auto'} />
						) : (
							<p className={'load-more'}>
								<span onClick={onLoadMore}>Load more</span>
							</p>
						)}
					</div>
				)}
			</div>
		);
	};

	return (
		<div className={'ttg-table-container'}>
			{!!title && <h1 className='title'>{title}</h1>}
			{!!info && <h1 className='info'>{info}</h1>}
			{!!renderFilters && renderFiltersContent()}
			{!!pagination && renderPagination()}
			{selectable && (
				<div className={`flex flex-col sm:flex-row ${data.length === 0 ? 'mt-4' : ''}`}>
					<div className={'flex items-center border-2 border-gray-300 px-4 py-1'}>
						<div
							id={'account-table-checkbox-unselect-all'}
							className={`flex flex-row items-center cursor-pointer ${
								selected.length > 0 ? 'cursor-pointer' : 'cursor-not-allowed'
							}`}
							onClick={() => {
								if (selected.length > 0) {
									onItemSelect('all', true);
								}
							}}
						>
							<p
								className={`flex justify-center items-center w-4 h-4 rounded-sm text-white mr-2 ${
									selected.length > 0 ? 'bg-rose-500' : 'bg-gray-300'
								}`}
							>
								{selected.length > 0 ? '-' : ''}
							</p>
							<span>{selected.length} Selected</span>
						</div>
					</div>
					<div className={'mt-4 ml-0 sm:mt-0 sm:ml-4'}>
						{renderBulkActions({ selected, data, identifier })}
					</div>
				</div>
			)}
			{renderContent()}
		</div>
	);
};

export default Table;
