import React from 'react';
import Images from '../../assets/Images';

const PodcastPdfHeader = ({ status, title, className = '', logo = Images.logoWhite }) => {
	return (
		<div className={`ttg-public-main-layout-header ${className ?? ''}`}>
			{title && (
				<div className='title ttg-text-2xl text-gray-900 font-semibold' id='ttg-public-header-title'>
					<span>{title}</span>
				</div>
			)}
			{status && (
				<div className='status mb-4' id='ttg-public-header-status'>
					{status}
				</div>
			)}
			<div className='logo' id='ttg-public-header-logo'>
				<a href='/'>
					<img src={logo} alt='Logo' />
				</a>
			</div>
		</div>
	);
};

export default PodcastPdfHeader;
