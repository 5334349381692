import UtilityService from '../../services/UtilityService';
import React from 'react';
import { AppWizard } from '../App';
import Progress from '../../components/Assessments/Take/Progress';
import SurveyStep from './SurveyStep';
import * as surveyUtility from './SurveyUtility';
import SurveyCompleted from './SurveyCompleted';
import { generateQuestions, validateStep } from './SurveyUtility';
import RouteNames from '../../router/RouteNames';
import { useNavigate } from 'react-router-dom';
import SurveyService from '../../services/SurveyService';
import { useSurveys } from '../../providers/SurveyProvider';

const SurveyTake = ({ surveyType }) => {
	const { surveyDetails, setSurveyDetails } = useSurveys();
	const navigate = useNavigate();

	const {
		email,
		first_name,
		last_name,
		title,
		questions,
		response_values,
		newsletter,
		company,
		company_size,
		industry,
		role,
	} = surveyDetails;

	const [stepsData, setStepsData] = React.useState(questions?.length > 0 ? generateQuestions(questions) : []);
	const [answers, setAnswers] = React.useState(
		questions?.length > 0 ? Array(questions.length).fill(-1) : []
	);
	const [loading, setLoading] = React.useState(false);
	const [submitting, setSubmitting] = React.useState(false);
	const [onReload, setOnReload] = React.useState(false);
	const onChange = (stepIndex, qIndex, responseValue) => {
		let tmp = answers;
		answers[stepIndex * 5 + qIndex] = responseValue;
		setAnswers([...tmp]);
	};

	React.useEffect(() => {
		if (!questions?.length) {
			setOnReload(true);
			navigate(surveyType === 'survey' ? RouteNames.Survey : RouteNames.ItpAssessment);
		}
	}, [questions, navigate, surveyType]);

	const onNext = async (e, nextStep, stepIndex) => {
		const a = e.target.innerText;
		setLoading(true);

		if (a.toUpperCase() === 'SUBMIT' && validateStep(answers.slice(stepIndex * 5, stepIndex * 5 + 5)).length < 1) {
			setCurrentStep(currentStep + 1);
			setLoading(false);
			setSubmitting(true);
			const surveyData = {
				email,
				first_name,
				last_name,
				newsletter,
				company,
				company_size,
				industry,
				role,
			};
			let payload = {};
			answers.map((el, i) => {
				payload[`question_${i}`] = el;
			});
			payload = { ...payload, ...surveyData };

			SurveyService.postSurvey(payload, surveyType)
				.then(() =>
					setSurveyDetails((prev) => ({
						...prev,
						first_name: '',
						last_name: '',
						newsletter: false,
						industry: {},
						role: {},
						company_size: {},
						company: '',
					}))
				)
				.finally(() => {
					setSubmitting(false);
					nextStep();
				});
		}

		if (
			stepIndex < stepsData.length - 1 &&
			validateStep(answers.slice(stepIndex * 5, stepIndex * 5 + 5)).length < 1
		) {
			setCurrentStep(currentStep + 1);
			setAnswered(false);
			setLoading(false);
			return nextStep();
		}
		setLoading(false);
		setAnswered(true);
	};
	const onPrevious = (previousStep) => {
		setCurrentStep(currentStep - 1);
		previousStep();
	};

	const [answered, setAnswered] = React.useState(false);

	const [currentStep, setCurrentStep] = React.useState(1);

	React.useEffect(() => {
		if (currentStep < stepsData.length + 1) {
			window.addEventListener('beforeunload', UtilityService.confirmPageLeave);
			return () => window.removeEventListener('beforeunload', UtilityService.confirmPageLeave);
		}
	}, [currentStep]);

	return (
		<div className={'flex items-center flow-root container mx-auto p-5 md:w-auto max-w-6xl min-w-0 max-h-full'}>
			{!onReload && (
				<AppWizard
					className={'team-assessment-setup'}
					isLazyMount={true}
					nav={
						<Progress
							questions={questions.length}
							onProgress={surveyUtility.handleProgress}
							title={title}
						/>
					}
					onStepChange={() => {
						window.scrollTo(0, 0);
						window.postMessage({ name: 'surveyScroll', scrollTop: 0 }, window.origin);
					}}
				>
					{stepsData.map((q, i) => (
						<SurveyStep
							className={'flex-row shadow-sm bg-white p-4 mt-4 rounded-sm pb-15'}
							key={i}
							stepIndex={i}
							questions={q}
							answers={answers}
							onChange={onChange}
							answered={answered}
							loading={loading}
							submitting={submitting}
							onNext={onNext}
							onPrevious={onPrevious}
							res_values={response_values}
						/>
					))}
					<div className={'bg-white'}>
						<SurveyCompleted type={surveyType} email={email} title={surveyDetails.title}/>
					</div>
				</AppWizard>
			)}
		</div>
	);
};

export default SurveyTake;
