import React from 'react';
import Footer from '../Assessments/Report/V2/Footer';

const PdfPage = ({ children, sectionClassName, page }) => {
	return (
		<section className={`Page ${sectionClassName}`}>
			<div className='Page__content'>
				<div className='Page__callout'>{children}</div>
			</div>
			{!!page && <Footer page={page} />}
		</section>
	);
};

export default PdfPage;
