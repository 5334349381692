import React from 'react';
import SwitchComponent from 'react-switch';

const Switch = ({ onChange, value }) => {
	return (
		<SwitchComponent
			onChange={onChange}
			checked={!!value}
			checkedIcon={false}
			uncheckedIcon={false}
			onColor={'#C71F37'}
			offColor={'#E5E7EB'}
		/>
	);
};

export default Switch;
