import React from 'react';

const DefaultNotification = ({ notification, changeNotificationReadStatus }) => {
	return (
		<div onClick={() => changeNotificationReadStatus()}>
			<p
				className={'text-lg font-medium mb-2 mr-2 text-gray-800'}
				dangerouslySetInnerHTML={{ __html: notification.title }}
			/>
			<p
				className={'text-normal mb-2 mr-2 text-gray-600'}
				style={{ wordBreak: 'break-word' }}
				dangerouslySetInnerHTML={{ __html: notification.text }}
			/>
		</div>
	);
};

export default DefaultNotification;
