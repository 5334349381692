import React from 'react';
import { AppIcon } from '../App';
import MarkAsReadToggle from './MarkAsReadToggle';
import MarkAsReadToggleMobile from './MarkAsReadToggleMobile';
import { useNavigate } from 'react-router';
import NotificationService from '../../services/NotificationService';
import DefaultNotification from './Templates/DefaultNotification';
import dayjs from 'dayjs';

const NotificationItem = ({ notification, onStatusChange, toggleWidgetVisibility }) => {
	const isRead = notification.read;

	const navigate = useNavigate();

	const changeNotificationReadStatus = (to) => {
		toggleWidgetVisibility(false);
		if (to) {
			navigate(to);
		}
		NotificationService.toggleMarkAsRead({ id: notification.id, read: true }).then(() => {
			onStatusChange(true);
		});
	};

	const renderContent = () => {
		switch (notification.code) {
			default:
				return (
					<DefaultNotification
						notification={notification}
						changeNotificationReadStatus={changeNotificationReadStatus}
					/>
				);
		}
	};

	return (
		<div className={`flex flex-row relative p-2 mb-4 ${isRead ? '' : 'bg-gray-100'}`}>
			<AppIcon icon={'file-list-2-line'} className={'text-3xl text-primary'} />
			<div className={'flex flex-1 flex-col ml-2'}>
				{renderContent()}
				<p className={'text-tiny text-gray-500'}>{dayjs(notification.notification_date).fromNow()}</p>
			</div>
			<div className={'hidden md:block'}>
				<MarkAsReadToggle notification={notification} onComplete={onStatusChange} />
			</div>
			<div className={'block absolute top-0 right-0 m-1 md:hidden'}>
				<MarkAsReadToggleMobile notification={notification} onComplete={onStatusChange} />
			</div>
		</div>
	);
};

export default NotificationItem;
