import React from 'react';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { ToasterIcon, ToasterMessage } from '../components/Toasters';
import ApiService from './ApiService';
import UtilityService from './UtilityService';
import dayjs from 'dayjs';

const show = (type, title, message, options, actions) => {
	const toaster = { id: uuidv4() };
	toast[type](<ToasterMessage toaster={toaster} title={title} message={message} actions={actions} />, {
		id: toaster.id,
		icon: <ToasterIcon type={type} />,
		...options,
	});
};

const success = (title, message, options = {}, actions = []) => {
	NotificationService.show('success', title, message, options, actions);
};

const error = (title, message, options = {}, actions = []) => {
	NotificationService.show('error', title, message, options, actions);
};

const custom = (title, message, icon = 'information-line', iconColor = 'gray-500', options = {}, actions = []) => {
	const toaster = { id: uuidv4() };
	toast.success(<ToasterMessage toaster={toaster} title={title} message={message} actions={actions} />, {
		id: toaster.id,
		icon: <ToasterIcon icon={icon} iconColor={iconColor} type={'custom'} />,
		...options,
	});
};

const get = (params) => {
	return new Promise((resolve, reject) => {
		const authCookie = UtilityService.getAuthCookie();
		if (!authCookie) {
			reject({ message: 'Not logged in' });
		} else {
			const queryString = UtilityService.serializeQueryString(params);
			ApiService.get(`v1/notifications?${queryString}`)
				.then(({ data }) => {
					resolve(groupNotifications(data.notifications));
				})
				.catch((error) => {
					reject(error);
				});
		}
	});
};

const toggleMarkAsRead = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/notification/mark_as_read`, {
			notification_id: payload.id,
			read: payload.read,
		})
			.then(resolve)
			.catch(reject);
	});
};

const markAllAsRead = () => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/notification/mark_all_as_read`, {
			read: true,
		}).then(resolve, reject);
	});
};

const groupNotifications = (notifications) => {
	const today = notifications.filter(
		(notification) => notification.type === 'local' && dayjs(notification.notification_date).isSame(dayjs(), 'day')
	);

	const later = notifications.filter(
		(notification) =>
			notification.type === 'local' && dayjs(notification.notification_date).isBefore(dayjs(), 'day')
	);

	const ttg = notifications.filter((notification) => notification.type === 'global');

	const unreadLocal = notifications.filter((n) => n.type === 'local' && !n.read).length;
	const unreadGlobal = notifications.filter((n) => n.type === 'global' && !n.read).length;
	const unreadAll = notifications.filter((n) => !n.read).length;

	return { today, later, ttg, unreadLocal, unreadGlobal, unreadAll };
};

const setNotificationsAsRead = (notifications) => {
	return notifications.map((notification) => {
		return { ...notification, read: true };
	});
};

const NotificationService = {
	show,
	success,
	error,
	custom,
	get,
	toggleMarkAsRead,
	markAllAsRead,
	setNotificationsAsRead,
	groupNotifications,
};

export default NotificationService;
