import React from 'react';
export const cta = {
	survey: (
		<div>
			Take this quick organizational health survey based on Patrick Lencioni's best-selling book, The Advantage:{' '}
			<i>Why Organizational Health Trumps Everything Else in Business</i> , and get a snapshot of your
			organization's overall health, as well as advice on how to start addressing this critical question.
		</div>
	),
	assessment: (
		<div>
			Take this quick self-assessment based on Patrick Lencioni’s new book,<i>The Ideal Team Player</i>, and see
			how well you embody the three essential virtues that make some people better team players than others.
		</div>
	),
};
