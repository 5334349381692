import React from 'react';
import { AppCheckBox } from '../../App';
import { TakeAssessmentNavigation } from '../index';
import RadioButton from './RadioButton';
import UtilityService from '../../../services/UtilityService';

const CheckboxQuestionStep = ({
	currentStep,
	totalSteps,
	previousStep,
	nextStep,
	question,
	onAnswer,
	onComplete,
	loading,
	onSubAnswer,
	saveProgress,
	step,
	onProgress,
	scales,
	updatingProgress,
}) => {
	React.useEffect(() => {
		window.addEventListener('beforeunload', UtilityService.confirmPageLeave);
		return () => window.removeEventListener('beforeunload', UtilityService.confirmPageLeave);
	}, []);

	const [submitted, setSubmitted] = React.useState(false);

	const isLastStep = React.useMemo(() => {
		return currentStep === totalSteps - 1;
	}, [currentStep, totalSteps]);

	const shouldShowValidationMessage = React.useMemo(() => {
		return submitted && question.answer.length === 0;
	}, [submitted, question.answer]);

	const hasUnAnsweredScale = React.useMemo(() => {
		if (!question.hasSubQuestions || !submitted) {
			return {};
		}
		const ret = {};
		question.subQuestions.forEach((q) => {
			scales[question.scale].forEach((scale) => {
				ret[scale.label] = !!ret[scale.label] || isNaN(q.answer[scale.type]);
			});
		});
		return ret;
	}, [submitted, question.subQuestions]);

	const hasAnsweredAllSubQuestions = () => {
		const unAnswered = question.subQuestions.findIndex(
			(subQuestion) =>
				Object.keys(subQuestion.answer).filter(
					(key) => subQuestion.answer[key] !== undefined && subQuestion.answer[key] !== null
				).length !== subQuestion.scales.length
		);

		if (unAnswered > -1) {
			const violation = document.getElementById(`ttg-take-assessment-sub-question-${unAnswered}`);
			window.scrollTo({
				top: violation.offsetTop,
				behavior: 'smooth',
			});
			return false;
		}
		return true;
	};

	const _nextStep = () => {
		if (saveProgress) {
			onProgress(step, nextStep);
		} else {
			nextStep();
		}
	};

	const _next = () => {
		setSubmitted(true);
		if (question.subtype === 'options-scaled') {
			if (hasAnsweredAllSubQuestions()) {
				_nextStep();
			}
		} else {
			if (question.answer.length > 0) {
				if (isLastStep) {
					onComplete(nextStep);
				} else {
					_nextStep();
				}
			} else {
				const violation = document.getElementById(`ttg-take-assessment-question-title`);
				window.scrollTo({
					top: violation.offsetTop,
					behavior: 'smooth',
				});
			}
		}
	};

	const renderNavigation = () => {
		return (
			<TakeAssessmentNavigation
				backText={currentStep === 1 ? 'Cancel' : 'Back'}
				onBack={previousStep}
				onNext={_next}
				loading={loading || updatingProgress}
				nextText={isLastStep ? 'Finish' : 'Next'}
			/>
		);
	};

	if (question.hasSubQuestions) {
		return (
			<div className={'ttg-account-section flex-col'} id={'ttg-take-assessment-step-sub-questions'}>
				<h1 className={'ttg-text-lg text-gray-900 font-medium'}>Team Culture Questions</h1>
				<hr className={'w-32 text-gray-200 my-4'} />
				<p
					id={'ttg-take-assessment-question-title'}
					className={'ttg-text-lg text-gray-900 font-medium mb-4'}
					dangerouslySetInnerHTML={{
						__html: `${question.title} <span class="ttg-text-base font-normal text-gray-500">(you must respond to each statement)</span>`,
					}}
				/>

				<div className={`hidden md:grid grid-cols-6 lg:grid-cols-7 gap-4`}>
					<p className={`col-span-2 lg:col-span-3`} />
					{scales[question.scale].map((scale, i) => {
						return (
							<p
								key={i}
								className={`flex items-center justify-center text-center font-medium ${
									hasUnAnsweredScale[scale.label] ? 'text-red-500' : 'text-gray-700'
								}`}
								dangerouslySetInnerHTML={{
									__html: scale.label,
								}}
							/>
						);
					})}
				</div>

				<div className={'flex flex-col gap-x-4 mb-4'}>
					{question.subQuestions.map((subQuestion, index) => {
						return (
							<div
								id={`ttg-take-assessment-sub-question-${index}`}
								className={`grid md:grid-cols-6 lg:grid-cols-7 md:items-center md:justify-center`}
								key={index}
							>
								<p
									className={`md:col-span-2 lg:col-span-3 ttg-text-lg md:ttg-text-sm font-medium ${
										Object.keys(subQuestion.answer).length !== subQuestion.scales.length &&
										submitted
											? 'text-red-500'
											: 'text-gray-700'
									}`}
									dangerouslySetInnerHTML={{ __html: subQuestion.label }}
								/>
								{scales[question.scale].map((option, i) => {
									if (option.type === 'checkbox') {
										return (
											<div
												key={i}
												className={
													'flex flex-col md:flex-row md:items-center md:justify-center md:text-center text-gray-700 font-medium lg:border-l-1 border-gray-200 py-2'
												}
											>
												<div className={'md:hidden border-t-1 border-gray-200 pt-4'}>
													<p
														className={`flex font-medium ${
															hasUnAnsweredScale[option.label]
																? 'text-red-500'
																: 'text-gray-700'
														}`}
														dangerouslySetInnerHTML={{
															__html: option.label.replace('<br />', ' '),
														}}
													/>
												</div>
												<div className={'flex flex-row'}>
													<RadioButton
														id={`ttg-take-assessment-sub-question-${index}-option-yes`}
														label={'Yes'}
														labelClass={'ttg-text-sm text-gray-700 ml-1'}
														direction={'flex-row md:items-center md:justify-center'}
														checked={subQuestion.answer[option.type] === option.value}
														onClick={() => {
															onSubAnswer(index, option.type, option.value);
														}}
													/>
													<RadioButton
														id={`ttg-take-assessment-sub-question-${index}-option-no`}
														label={'No'}
														labelClass={'ttg-text-sm text-gray-700 ml-1'}
														direction={'flex-row md:items-center md:justify-center'}
														checked={subQuestion.answer[option.type] === 0}
														onClick={() => {
															onSubAnswer(index, option.type, 0);
														}}
													/>
												</div>
											</div>
										);
									}

									return (
										<div
											key={i}
											className={
												'flex items-center justify-center text-center text-gray-700 font-medium'
											}
										>
											<RadioButton
												id={`ttg-take-assessment-sub-question-${index}-option-${i}`}
												direction={'flex-row md:flex-col md:items-center md:justify-center'}
												labelClass={`flex ttg-text-sm md:hidden font-medium ml-2 ${
													hasUnAnsweredScale[option.label] ? 'text-red-500' : 'text-gray-700'
												}`}
												label={option.label.replace('<br />', ' ')}
												checked={subQuestion.answer[option.type] === option.value}
												onClick={() => {
													onSubAnswer(index, option.type, option.value);
												}}
											/>
										</div>
									);
								})}

								<hr className='md:hidden text-gray-800 h-1 bg-gray-800 mb-1' />
							</div>
						);
					})}
				</div>

				{renderNavigation()}
			</div>
		);
	}

	return (
		<div className={'ttg-account-section flex-col'} id={'ttg-take-assessment-step-checkbox-question'}>
			<h1 className={'ttg-text-lg text-gray-900 font-medium'}>Team Culture Questions</h1>
			<hr className={'w-32 text-gray-200 my-4'} />
			<p
				id={'ttg-take-assessment-question-title'}
				className={'ttg-text-lg text-gray-900 font-medium mb-4'}
				dangerouslySetInnerHTML={{ __html: question.title }}
			/>
			<p
				id={'ttg-take-assessment-question-subtext'}
				className={`${shouldShowValidationMessage ? 'text-red-500' : 'text-gray-600'} font-medium`}
				dangerouslySetInnerHTML={{
					__html: `${question.subtext} <span class="ttg-text-base font-normal text-gray-500">(select all that apply)</span>`,
				}}
			/>
			{!!shouldShowValidationMessage && (
				<p className={'ttg-text-sm text-red-500'}>You must select at least one response.</p>
			)}
			<div className={'my-4'}>
				{question.options.map((option, index) => {
					return (
						<div className={'flex flex-col py-2'} key={index}>
							<AppCheckBox
								id={`ttg-take-assessment-checkbox-question-${index}`}
								label={() => {
									return <span dangerouslySetInnerHTML={{ __html: option.label }} />;
								}}
								checked={question.answer.indexOf(option.value) > -1}
								onChange={(e) => {
									onAnswer(e.target.checked, option.value);
								}}
							/>
						</div>
					);
				})}
			</div>
			{renderNavigation()}
		</div>
	);
};

export default CheckboxQuestionStep;
