export enum ITP_ASSESSMENT_TEAM_STATUS_ENUM {
  DRAFT = "DRAFT",
  SCHEDULED = "SCHEDULED",
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  STOPPED = "STOPPED",
}

export enum ITP_ASSESSMENT_TEAM_SETUP_TYPE_ENUM {
  AUTOMATIC = "AUTOMATIC",
  MANUAL = "MANUAL",
}

export enum ITP_ASSESSMENT_TEAM_TYPE_ENUM {
  INDIVIDUAL = "INDIVIDUAL",
  TEAM = "TEAM",
  SHARED = "SHARED",
}
