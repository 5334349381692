import React from 'react';
import { AssessmentSetupNavigation, DeleteDraftAssessment } from '../../index';
import { AppButton, AppDatepicker, AppIcon, AppSwitch } from '../../../App';
import dayjs from 'dayjs';
import UtilityService from '../../../../services/UtilityService';
import AssessmentService from '../../../../services/AssessmentService';
import RouteNames from '../../../../router/RouteNames';
import { useNavigate } from 'react-router-dom';
import NotificationService from '../../../../services/NotificationService';
import { useAssessments } from '../../../../providers/AssessmentProvider';
import { useCustomer } from '../../../../providers/CustomerProvider';

const SendReminderStep = ({ previousStep, nextStep }) => {
	const [settingUp, setSettingUp] = React.useState(false);
	const { customer: { default_timezone: timezone } } = useCustomer();
	const { setupAssessment, setSetupAssessment } = useAssessments();

	const [sendReminder, setSendReminder] = React.useState(
		setupAssessment.reminder.send_comparison_reminder ? setupAssessment.reminder.send_comparison_reminder : false
	);

	const [schedule, setSchedule] = React.useState(
		setupAssessment.reminder.schedule ? !!setupAssessment.reminder.schedule : false
	);

	const [scheduleDate, setScheduleDate] = React.useState(
		setupAssessment.reminder.setup_date
			? dayjs(setupAssessment.reminder.setup_date).format()
			: dayjs().add(1, 'day').startOf('hour').format()
	);

	const [selectedTimezone, setSelectedTimezone] = React.useState(
		UtilityService.getTimezoneByValue(
			setupAssessment.reminder && setupAssessment.reminder.setup_timezone
				? setupAssessment.reminder.setup_timezone
				: timezone
		)
	);

	const [willSendReminders, setWillSendReminders] = React.useState(
		setupAssessment.reminder.reminders && setupAssessment.reminder.reminders.length > 0
	);

	const [reminders, setReminders] = React.useState(
		setupAssessment.reminder.reminders
			? setupAssessment.reminder.reminders.map((r) => ({
					...r,
					timezone: UtilityService.getTimezoneByValue(r.timezone),
			  }))
			: [
					{
						timezone: UtilityService.getTimezoneByValue(
							setupAssessment.reminder.timezone ? setupAssessment.reminder.timezone : timezone
						),
						date: dayjs().add(1, 'day').startOf('hour').format(),
					},
			  ]
	);

	React.useEffect(() => {
		if (willSendReminders && reminders.length === 0) {
			setReminders([
				{
					timezone: UtilityService.getTimezoneByValue(
						setupAssessment.reminder.timezone ? setupAssessment.reminder.timezone : timezone
					),
					date: dayjs().add(1, 'day').startOf('hour').format(),
				},
			]);
		}
	}, [willSendReminders]);

	const navigate = useNavigate();

	const prepareReminderPayload = () => ({
		send_comparison_reminder: sendReminder,
		schedule,
		reminders: willSendReminders
			? reminders.map((r) => {
					return {
						date: dayjs(r.date).format('YYYY-MM-DD HH:mm'),
						timezone: r.timezone.value,
					};
			  })
			: [],
		setup_date: schedule ? dayjs(scheduleDate).format('YYYY-MM-DD HH:mm') : '',
		setup_timezone: schedule ? selectedTimezone.value : '',
	});

	React.useEffect(() => {
		if (setupAssessment.reminder && setupAssessment.reminder.setup_timezone) {
			setSelectedTimezone(UtilityService.getTimezoneByValue(setupAssessment.reminder.setup_timezone));
		}
	}, [setupAssessment.setup_timezone]);

	const areRemindersValid = () => {
		if (!willSendReminders) {
			return true;
		}

		let reminderCheck;

		if (schedule) {
			const i = reminders.findIndex((r) => {
				const d = Number(dayjs(scheduleDate).tz(selectedTimezone.value).format('YYYYMMDDHHmm'));
				const rd = Number(dayjs(r.date).tz(r.timezone.value).format('YYYYMMDDHHmm'));
				return d >= rd;
			});

			reminderCheck = i === -1;
			if (!reminderCheck) {
				NotificationService.error(
					'Oops',
					'The Assessment Reminders dates you selected are before your Scheduled Assessment date.'
				);
			}
		} else {
			reminderCheck = UtilityService.checkReminder(reminders);
			if (!reminderCheck) {
				NotificationService.error('Assessment Reminders', 'You cannot select a time in the past.');
			}
		}

		return reminderCheck;
	};

	const isScheduleValid = () => {
		if (!schedule) return true;
		const sd = Number(dayjs(scheduleDate).format('YYYYMMDDHHmm'));
		const cd = Number(dayjs().tz(selectedTimezone.value).format('YYYYMMDDHHmm'));
		if (sd <= cd) NotificationService.show('error', 'Schedule Time', 'You cannot select a time in the past.');

		return !(sd <= cd);
	};

	const _next = async (e) => {
		e.preventDefault();
		if (areRemindersValid() && isScheduleValid()) {
			const reminder = prepareReminderPayload();
			setSetupAssessment({ ...setupAssessment, reminder });
			nextStep();
		}
	};

	const _schedule = async () => {
		if (areRemindersValid() && isScheduleValid()) {
			const reminder = prepareReminderPayload();
			const payload = AssessmentService.prepareTeamSetupPayload({
				...setupAssessment,
				reminder,
			});
			setSettingUp(true);
			AssessmentService.draftTeam({
				id: setupAssessment.retest ? null : setupAssessment.id,
				retest: setupAssessment.retest ? setupAssessment.retest : null,
				...payload,
			})
				.then(() => {
					navigate(RouteNames.Assessments, {
						state: { savedForLater: true },
					});
				})
				.finally(() => setSettingUp(false));
		}
	};

	return (
		<div className={'ttg-assessment-section flex-col'}>
			<div>
				<h1 className={'ttg-text-lg text-gray-800'}>Send Reminders (Optional)</h1>
				<p className={'ttg-text-sm text-gray-500'}>
					This information is optional. Please check the box below if you would like to be sent a reminder in
					six months to run a comparison report with your team.
					<br />
					<br />A comparison report will allow you to see how your team has progressed towards eliminating the
					Five Dysfunctions, and give you a chance to focus in on any areas that are not improving as rapidly.
				</p>
			</div>
			<form onSubmit={_next} id={'ttg-team-assessment-setup-reminders-form'}>
				<div className={'flex flex-row items-center my-2'}>
					<AppSwitch value={schedule} onChange={setSchedule} />
					<div className={'py-2'}>
						<p className={'ttg-text-sm text-gray-800 font-medium ml-2 mb-2'}>Schedule Option</p>
						<p className={'ttg-text-sm text-gray-500 ml-2'}>
							If you wish, we can send the e-mail for this assessment at a future time and/or date.
						</p>
					</div>
				</div>

				{!!schedule && (
					<div className={'flex flex-row ml-2 mb-4'}>
						<div className={'w-full md:w-1/2'}>
							<AppDatepicker
								label={'Select date and time'}
								value={scheduleDate}
								onChange={(d) => setScheduleDate(dayjs(d).format())}
								min={dayjs().endOf('day').add(-1, 'day')}
								timezone={selectedTimezone}
								onTimezoneChange={setSelectedTimezone}
								timeSensitive
							/>
						</div>
					</div>
				)}

				<div className='my-4 border-t-1 border-gray-200' />

				<div className={'flex flex-row items-center my-2'}>
					<AppSwitch value={willSendReminders} onChange={setWillSendReminders} />
					<div className={'py-2'}>
						<p className={'ttg-text-sm text-gray-800 font-medium ml-2 mb-2'}>Assessment Reminders</p>
						<p className={'ttg-text-sm text-gray-500 ml-2'}>
							The system can automatically notify team members who have not completed their assessment by
							a certain date. Enter up to two dates and times when reminders should be sent to team
							members who have not yet completed their assessment.
						</p>
					</div>
				</div>

				{!!willSendReminders && (
					<div className={'flex flex-row ml-2 '}>
						<div className={'w-full md:w-1/2'}>
							{reminders.map((reminder, i) => (
								<div key={i} className={'flex flex-row mb-4'}>
									<div className='w-full'>
										<AppDatepicker
											label={'Select date and time'}
											value={dayjs(reminder.date)}
											onChange={(d) => {
												const _reminders = [...reminders];
												const _reminder = { ..._reminders[i] };
												_reminder.date = dayjs(d).format();
												_reminder.raw_process_at = dayjs(d).format();
												_reminders[i] = _reminder;
												setReminders(_reminders);
											}}
											min={dayjs().endOf('day').add(-1, 'day')}
											timezone={reminder.timezone}
											onTimezoneChange={(tz) => {
												const _reminders = [...reminders];
												const _reminder = { ..._reminders[i] };
												_reminder.timezone = tz;
												_reminders[i] = _reminder;
												setReminders(_reminders);
											}}
											timeSensitive={!schedule}
										/>
									</div>
									{reminders.length > 1 && (
										<div className={'flex justify-end items-end'}>
											<AppButton
												id={`ttg-assessment-schedule-delete-button-${i}`}
												type={'button'}
												className={'btn btn-action ml-4'}
												icon={'delete-bin-line'}
												iconClass={'text-red-500'}
												onClick={() => {
													const _reminders = [...reminders];
													_reminders.splice(i, 1);
													setReminders(_reminders);
												}}
											/>
										</div>
									)}
								</div>
							))}
						</div>
					</div>
				)}

				{willSendReminders && reminders.length < 2 && (
					<div
						id={'ttg-assessment-setup-new-reminder-button'}
						className={'inline-flex items-center cursor-pointer ttg-text-sm'}
						onClick={() => {
							const _reminders = [...reminders];
							const { ...newReminder } = _reminders[0];
							_reminders.push(newReminder);
							setReminders(_reminders);
						}}
					>
						<AppIcon icon={'add-circle-line'} className={'text-red-500'} />
						<span className={'text-red-500'}> Add new reminder date and time</span>
					</div>
				)}

				<div className='my-4 border-t-1 border-gray-200' />

				<div className={'flex flex-row items-center my-6'}>
					<AppSwitch value={sendReminder} onChange={setSendReminder} />
					<p className={'ttg-text-sm text-gray-800 font-medium ml-2'}>Send progress Report reminder</p>
				</div>

				<AssessmentSetupNavigation
					backIcon={'arrow-left-s-line'}
					backText={'Back'}
					iconClass={'text-xl'}
					onBack={previousStep}
					nextText={'Finish Setup and Review'}
					nextIcon={schedule ? 'calendar-event-line' : ''}
					loading={settingUp}
				>
					<AppButton
						id={'ttg-team-setup-save-for-later'}
						type={'button'}
						className={'btn btn-secondary mt-2 md:mt-0'}
						text={'Save for later'}
						icon={'save-line'}
						iconRight={false}
						onClick={_schedule}
						loading={settingUp}
					/>
					<DeleteDraftAssessment />
				</AssessmentSetupNavigation>
			</form>
		</div>
	);
};

export default SendReminderStep;
