import React from 'react';
import UtilityService from '../../services/UtilityService';

const OutsideAlerter = ({ handleClickOutsideFunction, children, ...props }) => {
	const wrapperRef = React.useRef(null);

	React.useEffect(() => {
		function handleClickOutside(event) {
			if (!UtilityService.isChildOf(wrapperRef, event)) {
				handleClickOutsideFunction();
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [wrapperRef, handleClickOutsideFunction]);

	return (
		<div ref={wrapperRef} {...props}>
			{children}
		</div>
	);
};

export default OutsideAlerter;
