import React from 'react';
import { AppIcon } from './index';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ items }) => {
	return (
		<div className={'flex flex-row mb-4'}>
			{items.map((item, index) => {
				return (
					<div key={index} className={'flex flex-row items-center'}>
						{item.to ? (
							<>
								<Link
									to={item.to}
									className={'ttg-text-sm text-gray-500 underline hover:text-rose-500'}
								>
									{item.name}
								</Link>
								<AppIcon className={'mx-3 text-gray-400'} icon={'arrow-right-s-line'} />
							</>
						) : (
							<p className={'ttg-text-sm text-gray-800 font-medium'}>{item.name}</p>
						)}
					</div>
				);
			})}
		</div>
	);
};

export default Breadcrumbs;
