import React from 'react';

const SurveyCompleted = ({ type, email, title }) => {
	const header = type === 'survey' ? 'Survey' : 'Assessment';
	return (
		email && (
			<div
				className={
					'flex-row items-center flow-root container mx-auto p-4 md:w-auto max-w-6xl min-w-0 bg-white max-h-full'
				}
			>
				<div className={'ttg-text-4xl mt-3 mb-5 font-semibold'}>{header} Complete</div>
				<div className={'ttg-text-xl flex-col md:flex-row container'}>
					<span>Thank you for completing the {title}. A copy of your report will be e-mailed to you at</span>
					<span className={'px-1'}>
						<b>{email}</b> shortly.
					</span>
				</div>
				{type === 'survey' && (
					<div className={'ttg-text-lg pt-5'}>
						To start building a healthy organization,
						<a href={'/organizational-health/'} className='text-rose-500 ml-1'>
							click here to explore our free tools and resources »
						</a>
					</div>
				)}
			</div>
		)
	);
};

export default SurveyCompleted;
