export enum MATCH_LEVEL_ENUM {
  LOW = 'LOW',
  FIRST_COMPETENCY_LOW = 'FIRST_COMPETENCY_LOW',
  SECOND_COMPETENCY_LOW = 'SECOND_COMPETENCY_LOW',
  MEDIUM_LOW = 'MEDIUM_LOW',
  MEDIUM = 'MEDIUM',
  MEDIUM_HIGH = 'MEDIUM_HIGH',
  COMPETENCY_HIGH = 'COMPETENCY_HIGH',
  HIGH = 'HIGH'
}

export enum MATCH_LEVEL_DISPLAY_ENUM {
  FIRST_COMPETENCY_LOW = 'Low',
  SECOND_COMPETENCY_LOW = 'Low',
  LOW = 'Low',
  MEDIUM_LOW = 'Medium-Low',
  MEDIUM = 'Medium',
  MEDIUM_HIGH = 'Medium-High',
  COMPETENCY_HIGH = 'High',
  HIGH = 'High'
}

export const MatchLevelEnum: string[] = Object.values(MATCH_LEVEL_ENUM);

export const MatchLevelOrder = {
  [MATCH_LEVEL_ENUM.FIRST_COMPETENCY_LOW]: 10,
  [MATCH_LEVEL_ENUM.LOW]: 20,
  [MATCH_LEVEL_ENUM.SECOND_COMPETENCY_LOW]: 30,
  [MATCH_LEVEL_ENUM.MEDIUM_LOW]: 40,
  [MATCH_LEVEL_ENUM.MEDIUM]: 50,
  [MATCH_LEVEL_ENUM.MEDIUM_HIGH]: 60,
  [MATCH_LEVEL_ENUM.COMPETENCY_HIGH]: 70,
  [MATCH_LEVEL_ENUM.HIGH]: 80
} as const;

export const MatchLevelToEnum: { [key: number]: MATCH_LEVEL_ENUM } = {
  [10]: MATCH_LEVEL_ENUM.FIRST_COMPETENCY_LOW,
  [20]: MATCH_LEVEL_ENUM.LOW,
  [30]: MATCH_LEVEL_ENUM.SECOND_COMPETENCY_LOW,
  [40]: MATCH_LEVEL_ENUM.MEDIUM_LOW,
  [50]: MATCH_LEVEL_ENUM.MEDIUM,
  [60]: MATCH_LEVEL_ENUM.MEDIUM_HIGH,
  [70]: MATCH_LEVEL_ENUM.COMPETENCY_HIGH,
  [80]: MATCH_LEVEL_ENUM.HIGH
};

export const MatchLevelToDisplayEnum: { [key: number]: MATCH_LEVEL_DISPLAY_ENUM } = {
  [10]: MATCH_LEVEL_DISPLAY_ENUM.FIRST_COMPETENCY_LOW,
  [20]: MATCH_LEVEL_DISPLAY_ENUM.LOW,
  [30]: MATCH_LEVEL_DISPLAY_ENUM.SECOND_COMPETENCY_LOW,
  [40]: MATCH_LEVEL_DISPLAY_ENUM.MEDIUM_LOW,
  [50]: MATCH_LEVEL_DISPLAY_ENUM.MEDIUM,
  [60]: MATCH_LEVEL_DISPLAY_ENUM.MEDIUM_HIGH,
  [70]: MATCH_LEVEL_DISPLAY_ENUM.COMPETENCY_HIGH,
  [80]: MATCH_LEVEL_DISPLAY_ENUM.HIGH,
};


export const MatchLevelShortDescriptions: { [key: number]: string } = {
  [10]: 'This candidate’s strengths and natural gifts may not align well with the core requirements of this role, which could lead to reduced engagement, job dissatisfaction, and the candidate’s strongest competency and frustration. Please consider these insights as you continue evaluating this candidate.',
  [20]: 'This candidate’s strengths and natural gifts may not align well with the core requirements of this role, which could lead to reduced engagement, job dissatisfaction, and the candidate’s frustrations. Please consider these insights as you continue evaluating this candidate.',
  [30]: 'This candidate’s strengths and natural gifts may not align well with the core requirements of this role, which could lead to reduced engagement, job dissatisfaction, and the candidate’s least-strong competency and frustration. Please consider these insights as you continue evaluating this candidate.',
  [40]: 'This candidate may perform some tasks effectively for a time; however, there\'s a strong chance these could lead to long-term frustration. Please consider these insights as you continue analyzing this candidate for the role.',
  [50]: 'This candidate has strengths that align with some key parts of the role, offering good potential for success in certain tasks. However, there are areas that may lead to frustration and disengagement. Consider whether their strengths can balance out any potential mismatches with the role’s demands.',
  [60]: 'This candidate is well-suited for several key aspects of the role and is likely to find fulfillment in those tasks. However, some responsibilities may require additional support to maintain energy and performance over time. Be sure to assess their fit with the job’s demands and the team’s culture to ensure a good lasting match.',
  [70]: 'This candidate is likely to excel and find fulfillment in this role due to strong alignment with its top requirements. Confirm their fit through an interview for both role-specific and cultural alignment.',
  [80]: 'This candidate is likely to excel and find fulfillment in this role due to strong alignment with its top requirements. Confirm their fit through an interview for both role-specific and cultural alignment.',
}

export const MatchLevelDescriptions: { [key: number]: string } = {
  [10]: 'Although this candidate has a high chance of excelling at tasks that fall under their Geniuses of $wg1 and $wg2, we found a significant misalignment between the top tasks and Geniuses required for this role ($rg1 and $rg2) as the candidate’s profile reflects $rg3 as a competency and $rg4 as their frustration. Please keep in mind that this misalignment could indicate that the primary tasks needed for this role could quickly lead to job dissatisfaction, disengagement and frustration. As a result, this candidate is identified as a <span class="font-semibold">Low Match</span> for this role.',
  [20]: 'Although this candidate has a high chance of excelling at tasks that fall under their Geniuses of $wg1 and $wg2, we found a significant misalignment between the top tasks and Geniuses required for this role ($rg1 and $rg2) and the candidate’s frustrations. Please keep in mind that this misalignment could indicate that the primary tasks needed for this role could lead to job dissatisfaction, disengagement and frustration. As a result, this candidate is identified as a <span class="font-semibold">Low Match</span> for this role.',
  [30]: 'Although this candidate has a high chance of excelling at tasks that fall under their Geniuses of $wg1 and $wg2, we found a significant misalignment between the top tasks and Geniuses required for this role in $rg1 and $rg2 and the candidate’s least-strong competency and frustrations. Please keep in mind that this misalignment could indicate that the primary tasks needed for this role could quickly lead to job dissatisfaction, disengagement and frustration. As a result, this candidate is identified as a <span class="font-semibold">Low Match</span> for this role.',
  [40]: 'Although this candidate has a high chance of excelling at tasks that fall under the Geniuses of $wg1 and $wg2, we have identified that the top tasks and Geniuses required for this role in $rg1 and $rg2, align with the candidate’s competency area. This could indicate that while the candidate may be able to perform these tasks effectively for a period, there is also a high likelihood that these activities could lead to frustration, reduced job satisfaction, and lower engagement in the long term. As a result, this candidate is considered a <span class="font-semibold">Medium-Low Match</span> for this role.',
  [50]: 'This candidate possesses partial alignment with the needs of the role. One of the top Geniuses required for the role aligns with this candidate’s strengths in $wg1, which suggests that the candidate has a significant chance of excelling at critical tasks for this role. However, there is also a strong mismatch area. The tasks aligned with the Genius of $rg2 are linked to this candidate’s frustrations, which could indicate that this critical part of the role may lead to disengagement, lack of energy, and reduced job satisfaction. As a result, this candidate is considered a <span class="font-semibold">Medium Match</span> for this role.',
  [60]: 'This candidate’s strength in $wg1 closely aligns with some of the key tasks of this role, as outlined in the job analysis report. The candidate is likely to consistently derive energy and engagement from these tasks. However, there are also critical responsibilities, particularly those under the genius of $rg2, where the candidate may perform well and feel energized, but potentially only for a limited time. With this in mind, the candidate may benefit from support from management and team members while handling these tasks. As a result, this candidate is considered a <span class="font-semibold">Medium-High Match</span> for this role.',
  [70]: 'The top two Geniuses required for the role strongly align with this candidate’s Geniuses and strongest competency in $rg1 and $rg2. This suggests a strong likelihood of long-term engagement, job satisfaction, and high performance in the top tasks outlined in the job analysis report. As a result, this candidate is considered a <span class="font-semibold">High Match</span> for this role.',
  [80]: 'The top two Geniuses required for the role strongly align with this candidate’s Geniuses in $rg1 and $rg2. This suggests a strong likelihood of long-term engagement, job satisfaction, and high performance in the top tasks outlined in the job analysis report. As a result, this candidate is considered a <span class="font-semibold">High Match</span> for this role.',
}
