import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router';
import { TTGResetPassword } from '@ttg/ui-user-lib';
import { PublicLayout } from '../../components/Layout';
import RouteNames from '../../router/RouteNames';
import UtilityService from '../../services/UtilityService';
import config from '../../constants/config';

const ResetPassword = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const { token } = UtilityService.getQueryParams(location.search);

	if (!token) {
		window.open(RouteNames.Login, '_self');
		return null;
	}

	const Footer = (
		<Link to={RouteNames.Login} className='text-sm text-rose-500 font-medium mt-4' id={'ttg-reset-login-link'}>
			Back to Login
		</Link>
	);

	const handleResetFinish = (_) => {
		return window.open(RouteNames.Tools, '_self');
	};

	return (
		<PublicLayout headerTitle='' headerStatus={() => {}} footer={<></>}>
			<TTGResetPassword
				apiEndpoint={config.ttg_url}
				captchaEnabled={config.captcha_enabled}
				captchaKey={config.captcha_site_key}
				footer={Footer}
				theme={'ttg-app'}
				token={token}
				onResetFinish={handleResetFinish}
			/>
		</PublicLayout>
	);
};

export default ResetPassword;
