import React from 'react';
import { AppButton, AppIcon, AppModalComponent, AppRadioGroup } from '../../App';
import RouteNames from '../../../router/RouteNames';
import { useNavigate } from 'react-router-dom';
import NotificationService from '../../../services/NotificationService';
import AssessmentService from '../../../services/AssessmentService';
import { useAssessments } from '../../../providers/AssessmentProvider';
import dayjs from 'dayjs';
import { useCustomer } from '../../../providers/CustomerProvider';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const CancelScheduleAssessmentModal = ({ isOpen, onClose, assessment }) => {
	const [cancelScheduleOption, setCancelScheduleOption] = React.useState(0);
	const [step, setStep] = React.useState(0);

	const navigate = useNavigate();
	
	const { licenses: { licenses }, setLicenses } = useCustomer();
	const { loading: cancelingSchedule, setLoading, setAssessmentDetails } = useAssessments();

	React.useEffect(() => {
		if (!isOpen) {
			setCancelScheduleOption(0);
			setStep(0);
		}
	}, [isOpen]);

	const renderInitialStep = () => {
		const canStartAssessment = assessment.rosters.length <= licenses;
		return (
			<>
				<AppRadioGroup
					containerClass={'flex flex-1 flex-col'}
					itemClass={'mb-4'}
					value={cancelScheduleOption}
					options={[
						{
							label: 'Cancel the scheduling and start the assessment now',
							value: 1,
							disabled: !canStartAssessment,
						},
						{ label: 'Cancel the scheduling and Delete the assessment', value: 2 },
					]}
					onChange={setCancelScheduleOption}
				/>
				{!canStartAssessment && (
					<p className={'ttg-text-sm text-gray-900'}>
						You can not start the assessment now due to not having enough licenses on your account.{' '}
						<a href={RouteNames.OnlineTeamAssessment} className={'hover:underline text-rose-500'}>
							Click here
						</a>{' '}
						to purchase more licenses.
					</p>
				)}
			</>
		);
	};

	const renderConfirmationStep = () => {
		return (
			<div className={'mb-4'}>
				<p className={'ttg-text-sm text-gray-500 mb-2'}>
					<span className={'font-medium'}>WARNING:</span> You will not be able to undo this action.
				</p>
				<p className={'ttg-text-sm text-gray-500'}>
					{cancelScheduleOption === 1
						? 'Are you sure you wish to cancel the scheduling for this assessment and send the team assessment email to all recipients?'
						: 'Are you sure you wish to cancel the scheduling and delete this assessment?'}
				</p>
			</div>
		);
	};

	const renderContent = () => {
		if (step === 0) {
			return renderInitialStep();
		}
		return renderConfirmationStep();
	};

	const _cancelSchedule = async () => {
		setLoading(true);
		AssessmentService.cancelSchedule(
			assessment.assessment_data.team_id,
			cancelScheduleOption === 1
				? {
						start_assessment: true,
				  }
				: {}
		)
			.then(() => {
				setAssessmentDetails((prev) => ({
					...prev,
					isScheduled: false,
					assessment_data: {
						...prev.assessment_data,
						sent: dayjs().toDate(),
						status: 'In Process',
					},
				}));
				onClose();
				if (cancelScheduleOption === 2) {
					navigate(RouteNames.Assessments, { replace: true });
					return NotificationService.success('Assessment deleted successfully');
				}
				setLicenses((prev) => ({ ...prev, licenses: prev.licenses - assessment.rosters.length }));
				NotificationService.success('Schedule was cancelled and the assessment was successfully started');
			})
			.finally(() => setLoading(false));
	};

	return (
		<AppModalComponent isOpen={isOpen} closeModal={onClose} style={customStyles} onRequestClose={onClose}>
			<div className='bg-white p-6' style={{ maxWidth: '460px' }}>
				<div className={'flex flex-1 items-center mb-4'}>
					<p className={'flex flex-1 ttg-text-lg text-gray-900'}>Cancel Scheduling</p>
					<AppIcon icon={'close-line'} className={'text-xl text-gray-500 cursor-pointer'} onClick={onClose} />
				</div>
				{renderContent()}
				<div className={'flex justify-end'}>
					<AppButton
						id={'ttg-cancel-schedule-cancel-button'}
						text={step === 0 ? 'No' : 'Back'}
						className={'btn btn-secondary mr-4'}
						onClick={() => {
							if (step === 0) {
								return onClose();
							}
							setStep(0);
						}}
						loading={cancelingSchedule}
					/>
					<AppButton
						id={'ttg-cancel-schedule-next-button'}
						disabled={!cancelScheduleOption}
						text={step === 0 ? 'Next' : 'Yes, Confirm'}
						loading={cancelingSchedule}
						onClick={() => {
							if (step === 0) {
								return setStep(1);
							}
							_cancelSchedule();
						}}
					/>
				</div>
			</div>
		</AppModalComponent>
	);
};

export default CancelScheduleAssessmentModal;
