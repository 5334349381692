import React from 'react';
import { AppPdfPage } from '../../../App';
import Images from '../../../../assets/Images';
import UtilityService from '../../../../services/UtilityService';

const TeamCultureQuestionsConflict = ({ details, page }) => {
	const questions = React.useMemo(() => {
		return Object.values(UtilityService.groupByProp(details.responses_sub_questions, 'response_text')).sort(
			(a, b) => {
				return (
					parseInt(b[0].response_count) - parseInt(a[0].response_count) ||
					parseInt(b[1].response_count) - parseInt(a[1].response_count) ||
					parseInt(b[2].response_count) - parseInt(a[2].response_count) ||
					parseInt(b[3].response_count) - parseInt(a[3].response_count) ||
					parseInt(b[0].response_text) - parseInt(a[0].response_text)
				);
			}
		);
	}, [details]);

	return (
		<AppPdfPage page={page} sectionClassName={'TeamCultureQuestions'}>
			<div className='TeamCultureQuestions__layout'>
				<div className='TeamCultureQuestions__heading'>
					<div className='PageHeading'>
						<div className='PageHeading__title'>Team Culture Questions</div>
						<div className='PageHeading__image'>
							<img width='82' src={Images.report.puzzlePieces} alt={''} />
						</div>
					</div>
				</div>
				<div className='TeamCultureQuestions__content'>
					<div className='TeamCultureQuestions__continuedText'>(Continued from the previous page.)</div>

					<div className='TeamCultureQuestions__blurb Blurb'>
						<div className='Blurb__title'>Mastering Conflict</div>
						<div className='Blurb__subTitle'>What Is Acceptable Behavior During Conflict?</div>
						<div className='Blurb__content'>
							As part of the assessment, you and your team members were asked whether certain behaviors or
							actions are acceptable while engaging in conflict and how many of you display them at work.
						</div>
					</div>

					<div className='TeamCultureQuestions__questionReview'>
						<div className='ConflictTable'>
							<table>
								<thead>
									<tr>
										<th className='ConflictTable__headerCell ConflictTable__headerCell--wideCell'>
											Response to Conflict:
										</th>
										<th className='ConflictTable__headerCell '>Unacceptable</th>
										<th className='ConflictTable__headerCell '>Tolerable</th>
										<th className='ConflictTable__headerCell '>Perfectly Acceptable</th>
										<th className='ConflictTable__headerCell ConflictTable__headerCell--noBG'>
											# Of Team Members Who Admit They Do This At Work
										</th>
									</tr>
								</thead>
								<tbody>
									{questions.map((question, idx) => (
										<tr key={idx}>
											<td dangerouslySetInnerHTML={{ __html: question[0].response_text }} />
											<td>{question[0].response_count}</td>
											<td>{question[1].response_count}</td>
											<td>{question[2].response_count}</td>
											<td>{question[3].response_count}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</AppPdfPage>
	);
};

export default TeamCultureQuestionsConflict;
