const { AUTH_PROVIDERS, APPLICATION, AUTH_TOKENS, CAPTCHA_TOKEN_KEY } = require('@ttg/app-interfaces-lib');

const env = {
	unit_test: {
		name: 'unit_test',
		captcha_enabled: false,
		base_url: 'http://localhost:3002/client',
		ttg_url: 'http://localhost:3002',
		api_url: 'http://host.docker.internal:8002/api',
		wg_url: 'http://host.docker.internal:3001',
		cookieyes_key: '',
		allowedAuthProviders: [AUTH_PROVIDERS.GOOGLE, AUTH_PROVIDERS.MICROSOFT, AUTH_PROVIDERS.LINKEDIN],
		googleClientId: '737531438169-u0eqtofqvh7ufijrqeambsscjtts40e2.apps.googleusercontent.com',
		microsoftClientId: '1fe6075f-6a2e-42e0-8ffe-76cb6bf0094f',
		linkedinClientId: '77orzyhfljjl3x',
		linkedinRedirectUri: `http://localhost:3002/login/${AUTH_PROVIDERS.LINKEDIN}`,
		microsoftRedirectUri: `http://localhost:3002/login/${AUTH_PROVIDERS.MICROSOFT}`,
	},
	dev: {
		name: 'dev',
		captcha_enabled: true,
		captcha_site_key: '6Lec7_oiAAAAAAD_W9rnuHpvuIzSQZNcAkJZN60L',
		cookieyes_key: '4d67f8ae4d9d2ee4bb74d456',
		ttg_url: 'https://app.dev.thetablegroup.link',
		base_url: 'https://app.dev.thetablegroup.link/client',
		wg_url: 'https://dev.theworkinggenius.link',
		allowedAuthProviders: [AUTH_PROVIDERS.GOOGLE, AUTH_PROVIDERS.MICROSOFT, AUTH_PROVIDERS.LINKEDIN],
		googleClientId: '737531438169-u0eqtofqvh7ufijrqeambsscjtts40e2.apps.googleusercontent.com',
		microsoftClientId: '1fe6075f-6a2e-42e0-8ffe-76cb6bf0094f',
		linkedinClientId: '77orzyhfljjl3x',
		linkedinRedirectUri: `https://app.dev.thetablegroup.link/login/${AUTH_PROVIDERS.LINKEDIN}`,
		microsoftRedirectUri: `https://app.dev.thetablegroup.link/login/${AUTH_PROVIDERS.MICROSOFT}`,
	},
	stage: {
		name: 'stage',
		captcha_enabled: true,
		captcha_site_key: '6Lec7_oiAAAAAAD_W9rnuHpvuIzSQZNcAkJZN60L',
		cookieyes_key: '4d67f8ae4d9d2ee4bb74d456',
		ttg_url: 'https://app.stage.thetablegroup.link',
		base_url: 'https://app.stage.thetablegroup.link/client',
		wg_url: 'https://stage.theworkinggenius.link',
		allowedAuthProviders: [AUTH_PROVIDERS.GOOGLE, AUTH_PROVIDERS.MICROSOFT, AUTH_PROVIDERS.LINKEDIN],
		googleClientId: '737531438169-u0eqtofqvh7ufijrqeambsscjtts40e2.apps.googleusercontent.com',
		microsoftClientId: '624eee06-ff8f-45d9-8eec-490b4bc89554',
		linkedinClientId: '86cukfsxlehx55',
		linkedinRedirectUri: `https://app.stage.thetablegroup.link/login/${AUTH_PROVIDERS.LINKEDIN}`,
		microsoftRedirectUri: `https://app.stage.thetablegroup.link/login/${AUTH_PROVIDERS.MICROSOFT}`,
	},
	prod: {
		name: 'prod',
		captcha_enabled: true,
		ttg_url: 'https://www.tablegroup.com',
		captcha_site_key: '6LeBOGAjAAAAADAW9muwc7Dlja3vtsO8Ut7pUiwS',
		cookieyes_key: '4f819033f5af8ab22b6f7cd3',
		base_url: 'https://www.tablegroup.com/client',
		wg_url: 'https://www.workinggenius.com',
		allowedAuthProviders: [AUTH_PROVIDERS.GOOGLE, AUTH_PROVIDERS.MICROSOFT, AUTH_PROVIDERS.LINKEDIN],
		googleClientId: '737531438169-u0eqtofqvh7ufijrqeambsscjtts40e2.apps.googleusercontent.com',
		microsoftClientId: '624eee06-ff8f-45d9-8eec-490b4bc89554',
		linkedinClientId: '86cukfsxlehx55',
		linkedinRedirectUri: `https://www.tablegroup.com/login/${AUTH_PROVIDERS.LINKEDIN}`,
		microsoftRedirectUri: `https://www.tablegroup.com/login/${AUTH_PROVIDERS.MICROSOFT}`,
	},
};

const config = env[process.env.REACT_APP_ENV || 'unit_test'] || env.unit_test;
export default { ...config, AUTH_TOKENS, AUTH_PROVIDERS, appId: APPLICATION.TTG, captchaTokenKey: CAPTCHA_TOKEN_KEY };
