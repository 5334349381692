import React from 'react';
import { AppButton } from '../../../App';

const SummarySection = ({ children, onClick, className }) => {
	return (
		<div className={`flex flex-1 ${className}`}>
			<div className='flex flex-1 flex-col mr-4'>{children}</div>
			<div>
				<AppButton type={'button'} className={'btn btn-secondary w-40'} text={'Edit'} onClick={onClick} />
			</div>
		</div>
	);
};

export default SummarySection;
