import React, { createContext, useContext } from 'react';

export const SurveyContext = createContext(undefined);

const surveyDetailsInitialState = {
	first_name: '',
	last_name: '',
	email: '',
	title: '',
	cta: '',
	instructions: '',
	response_values: [],
	questions: [],
	error: '',
	industry: {},
	company_size: {},
	company: '',
	role: {},
	loading: false,
};

export const SurveyProvider = ({ children }) => {
	const [loading, setLoading] = React.useState(false);
	const [surveyDetails, setSurveyDetails] = React.useState(surveyDetailsInitialState);

	return (
		<SurveyContext.Provider
			value={{
				loading,
				setLoading,
				surveyDetails,
				setSurveyDetails,
			}}
		>
			{children}
		</SurveyContext.Provider>
	);
};

export const useSurveys = () => useContext(SurveyContext);

export const SurveyContextLayout = ({ children }) => <SurveyProvider>{children}</SurveyProvider>;
