import React from 'react';

const ProductInfo = ({ product }) => {
	return (
		<div className={'flex flex-1 flex-row md:items-center py-4 border-b-1 border-gray-200'}>
			<div className='flex justify-center items-center mr-2 bg-gray-50 w-16 h-16'>
				<img src={product.image} className={'w-12 h-12'} alt={''} />
			</div>

			<div className={'flex flex-1 flex-col md:flex-row'}>
				<div className={'flex flex-1 flex-col mt-5 md:mt-0'}>
					<p className={'ttg-text-base font-medium text-gray-800'}>{product.name}</p>
					{!!product.description && <p className={'ttg-text-sm text-gray-800'}>{product.description}</p>}
				</div>

				<div className={'flex w-48 lg:w-64 my-5 md:my-0'}>
					<p className={'ttg-text-sm text-gray-800'}>
						{product.item_price} x {product.quantity}
					</p>
					<p className={'flex flex-1 ttg-text-sm text-gray-800 font-medium text-right justify-end'}>
						{product.total_price}
					</p>
				</div>
			</div>
		</div>
	);
};

export default ProductInfo;
