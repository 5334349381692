import React from 'react';

const History = ({ order }) => {
	return (
		<div className='flex flex-col mt-4'>
			<h1 className='ttg-text-base text-gray-800 font-medium py-3'>Order History</h1>
			{order.history.map((h) => {
				return (
					<div className={'flex flex-col md:flex-row border-b-1 border-gray-300 py-1'} key={h.id}>
						<p className={'flex flex-1 ttg-text-xs font-medium text-gray-800'}>{h.changedAt}</p>
						<p className={'ttg-text-xs text-gray-500'}>{h.notes}</p>
					</div>
				);
			})}
		</div>
	);
};

export default History;
