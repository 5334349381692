import { RUN_STATUS_ENUM } from "./run-status.enum";

export const RunStatusTransformer = {
  toEnum: (status:
                  'queued'
                  | 'in_progress'
                  | 'requires_action'
                  | 'cancelling'
                  | 'cancelled'
                  | 'failed'
                  | 'completed'
                  | 'incomplete'
                  | 'expired'): RUN_STATUS_ENUM => {
    return RUN_STATUS_ENUM[status.toUpperCase()];
  }
}
