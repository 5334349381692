import React, { useEffect } from 'react';
import { AccountLayout } from '../../components/Layout';
import { AppButton, AppCheckBox, AppDropdown, AppInput } from '../../components/App';
import UtilityService from '../../services/UtilityService';
import { useForm, useFormState, Controller } from 'react-hook-form';
import CountryService from '../../services/CountryService';
import { useCountries } from '../../hooks/useCountries';
import { useZones } from '../../hooks/useZones';
import RouteNames from '../../router/RouteNames';
import NotificationService from '../../services/NotificationService';
import CustomerService from '../../services/CustomerService';
import { useCustomer } from '../../providers/CustomerProvider';

const CapaProListing = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		control,
		reset,
	} = useForm();

	const { isDirty, isSubmitted } = useFormState({ control });
	
	const [details, setDetails] = React.useState({});

	const { loading, setLoading, customer: { how_capapro: hasPurchasedCapaPro } } = useCustomer();

	const { loadingCountries, countries } = useCountries();

	const _getCapaDetails = React.useCallback(() => {
		setLoading(true);
		CustomerService.getCapaProInfo()
			.then((response) => setDetails(response))
			.finally(() => setLoading(false));
	}, []);

	const { loadingZones, zones, getZones } = useZones();

	const setFormValues = () => {
		reset({
			...details,
			country_code: CountryService.getByCode(countries, details.country_code),
			state: {
				label: details.state,
			},
		});
	};

	useEffect(() => {
		if (hasPurchasedCapaPro) {
			return _getCapaDetails();
		}
		window.open(RouteNames.CapaPro, '_self');
	}, [_getCapaDetails, hasPurchasedCapaPro]);

	useEffect(() => {
		if (loading) {
			return () => {};
		}
		getZones(details.country_code);
		setFormValues();
	}, [loading, details]);

	const trimInputValue = (name, e) => {
		setValue(name, e.target.value.trim(), {
			shouldValidate: isSubmitted,
			shouldDirty: true,
		});
	};

	const renderStateInput = () => {
		if (zones && zones.length > 0) {
			return (
				<Controller
					control={control}
					name={'state'}
					render={({ field }) => (
						<AppDropdown
							{...field}
							id={'ttg-capa-state-input'}
							label={'State or province'}
							data-ttg-max-length={32}
							options={zones}
							loading={loadingZones}
						/>
					)}
				/>
			);
		}
	};

	const _update = (payload) => {
		if (zones && zones.length > 0) {
			return payload.state = payload.state ? payload.state.label : payload.state;
		}
		payload.state = '';

		payload.country_code = payload.country_code ? payload.country_code.value : payload.country_code;
		setLoading(true);
		CustomerService.updateCapaData(payload)
			.then(() => {
				setDetails((prev) => ({ ...prev, payload }));
				NotificationService.success('', 'Information saved successfully');
			})
			.finally(() => setLoading(false));
	};

	return (
		hasPurchasedCapaPro && (
			<AccountLayout loading={loading}>
				<div className={'ttg-account-section flex-col'}>
					<h1 className={'ttg-text-2xl text-gray-900'}>
						CAPA<sup>PRO</sup> Directory Listing
					</h1>
					<p className={'ttg-text-sm text-gray-500 mb-4'}>
						The information below will be shared with other members of the CAPA<sup>PRO</sup> community. You
						can opt-out of this feature by deselecting the checkbox to "Share my information with the CAPA
						<sup>PRO</sup> community".
					</p>
					<p className={'ttg-text-sm text-gray-500 mb-4'}>
						Access your CAPA<sup>PRO</sup> resources by{' '}
						<a href='/capapro' className={'underline hover:text-rose-500'}>
							clicking here
						</a>
						.
					</p>
					<form id={'ttg-capa-capa-form'} onSubmit={handleSubmit(_update)}>
						<AppInput
							id={'ttg-capa-first-name-input'}
							label={'First name'}
							errors={errors}
							data-ttg-max-length={80}
							{...register('first_name', { required: 'You must enter a first name.', maxLength: 80 })}
							onBlur={(e) => trimInputValue('first_name', e)}
						/>
						<AppInput
							id={'ttg-capa-last-name-input'}
							label={'Last name'}
							errors={errors}
							data-ttg-max-length={80}
							{...register('last_name', { required: 'You must enter a last name.', maxLength: 80 })}
							onBlur={(e) => trimInputValue('last_name', e)}
						/>
						<AppInput
							id={'ttg-capa-email-input'}
							label={'Email address'}
							data-ttg-max-length={32}
							errors={errors}
							{...register('email', {
								maxLength: 32,
								pattern: {
									value: UtilityService.emailRegex,
									message: 'You must enter a valid email address',
								},
							})}
							onBlur={(e) => trimInputValue('email', e)}
						/>
						<AppInput
							id={'ttg-capa-linkedin-input'}
							type={'url'}
							label={'Linkedin'}
							data-ttg-max-length={1000}
							errors={errors}
							{...register('linkedin', {
								maxLength: 1000,
								pattern: {
									value: UtilityService.urlRegex,
									message: 'You must enter a valid url address',
								},
							})}
							onBlur={(e) => trimInputValue('linkedin', e)}
						/>
						<AppInput
							id={'ttg-capa-title-input'}
							label={'Title'}
							data-ttg-max-length={80}
							errors={errors}
							{...register('title', { maxLength: 80 })}
							onBlur={(e) => trimInputValue('title', e)}
						/>
						<AppInput
							id={'ttg-capa-company-input'}
							label={'Company'}
							data-ttg-max-length={80}
							errors={errors}
							{...register('company', { maxLength: 80 })}
							onBlur={(e) => trimInputValue('company', e)}
						/>
						<AppInput
							id={'ttg-capa-city-input'}
							label={'City'}
							{...register('city', { maxLength: 80 })}
							minLength={2}
							data-ttg-max-length={80}
							errors={errors}
							onBlur={(e) => trimInputValue('city', e)}
						/>
						{renderStateInput()}

						<Controller
							control={control}
							name={'country_code'}
							render={({ field }) => (
								<AppDropdown
									{...field}
									id={'ttg-capa-country-input'}
									label={'Country'}
									data-ttg-max-length={4}
									options={countries}
									onChange={(e) => {
										getZones(e.value);
										setValue('state', { label: '', value: '' });
										field.onChange(e);
									}}
									loading={loadingCountries}
								/>
							)}
						/>
						<AppCheckBox
							id={'ttg-account-set-as-primary-id'}
							label={() => {
								return (
									<span>
										Share my information with CAPA<sup>PRO</sup> Member Directory
									</span>
								);
							}}
							{...register('share')}
						/>
						<div className='flex mt-4'>
							<AppButton
								className={'btn btn-primary'}
								type='submit'
								text={'Save Changes'}
								loading={loading}
								disabled={!isDirty}
								id={'ttg-capa-submit-btn'}
							/>
							{isDirty && (
								<AppButton
									id={'ttg-capa-details-cancel-button'}
									type={'button'}
									text={'Cancel'}
									className={'btn btn-secondary ml-4'}
									loading={loading}
									onClick={setFormValues}
								/>
							)}
						</div>
					</form>
				</div>
			</AccountLayout>
		)
	);
};

export default CapaProListing;
