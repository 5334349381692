import React from 'react';
import UtilityService from '../../services/UtilityService';
import { Link } from 'react-router-dom';
import RouteNames from '../../router/RouteNames';

const PurchasedDescription = ({ video }) => {
	const pClassName = 'text-gray-500 ttg-text-sm';
	const purchasedAt = UtilityService.formatDate(video.created, 'MMMM DD, YYYY');

	const FormatDate = (date) => {
		return [UtilityService.formatDate(date, 'MMMM DD, YYYY'), UtilityService.formatDate(date, 'hh:mm A')].join(
			' at '
		);
	};

	if (!video.started) {
		return (
			<p className={pClassName}>
				This video was purchased on{' '}
				<Link
					className={'font-medium text-gray-500 hover:underline hover:text-rose-500'}
					to={`${RouteNames.OrderDetails}?id=${video.order_id}`}
					target='_blank'
				>
					{purchasedAt}
				</Link>
				, and has not yet been played.
			</p>
		);
	}
	return (
		<p className={pClassName}>
			This video was purchased on{' '}
			<Link
				className={'font-medium text-gray-500 hover:underline hover:text-rose-500'}
				to={`${RouteNames.OrderDetails}?id=${video.order_id}`}
				target='_blank'
			>
				{purchasedAt}
			</Link>
			, was initially played on <span className={'font-medium'}>{FormatDate(video.started)}</span>, and will
			expire on <span className={'font-medium'}>{FormatDate(video.expires)}</span>.
		</p>
	);
};

export default PurchasedDescription;
