import React from 'react';
import { AppButton, AppInput, AppSlideOver } from '../../App';
import EditSection from './EditSection';
import { useForm, useFormState } from 'react-hook-form';
import AssessmentService from '../../../services/AssessmentService';
import NotificationService from '../../../services/NotificationService';
import { useAssessments } from '../../../providers/AssessmentProvider';

const EditIndividualAssessmentSidebar = ({ isOpen, onClose, assessment }) => {
	const { loading: updating, setLoading, setAssessmentDetails } = useAssessments();

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		control,
		reset,
	} = useForm();

	const { isDirty, isSubmitted } = useFormState({ control });

	React.useEffect(() => {
		if (assessment.assessment_data && assessment.assessment_data.team_id) {
			reset(AssessmentService.prepareEditAssessmentData(assessment));
		}
	}, [assessment]);

	const trimInputValue = (name, e) => {
		setValue(name, e.target.value.trim(), {
			shouldValidate: isSubmitted,
			shouldDirty: true,
		});
	};

	const _save = async ({ company_name, name, participant }) => {
		setLoading(true);
		AssessmentService.updateIndividual(assessment.assessment_data.team_id, {
			company_name,
			name,
			participant,
		}).then(() => {
			setAssessmentDetails((prev) => ({
				...prev,
				assessment_data: { ...prev.assessment_data, company_name, name },
			}));
			NotificationService.success('Individual assessment details updated successfully');
			onClose();
		}).finally(() => setLoading(false));
	};

	return (
		<AppSlideOver isOpen={isOpen} closeModal={onClose} type={'large'} slideOverContentClass={'!bg-white'}>
			<form
				className='edit-assessment-sidebar'
				onSubmit={handleSubmit(_save)}
				id={'ttg-edit-individual-assessment-form'}
			>
				<div>
					<div className={'p-4'}>
						<h1 className={'ttg-text-lg font-medium text-gray-900'}>
							Edit Individual Assessment Information
						</h1>
						<p className={'ttg-text-sm text-gray-400'}>
							To edit the details for this assessment, make any changes to the fields below and click the
							"Save" button.
						</p>
					</div>

					<hr className='text-gray-200 my-4' />
				</div>

				<div className={'flex flex-1 flex-col overflow-auto min-h-0'}>
					<EditSection label={'Company Details'} containerClassName={'p-4'}>
						<AppInput
							id={'ttg-edit-individual-company-name'}
							label={'Company Name'}
							errors={errors}
							{...register('company_name', {
								maxLength: 255,
							})}
							data-ttg-max-length={255}
							onBlur={(e) => trimInputValue('company_name', e)}
						/>
					</EditSection>

					<hr className='text-gray-200 my-4' />

					<EditSection label={'Participant Name'} containerClassName={'p-4'}>
						<AppInput
							id={'ttg-edit-individual-name'}
							label={'Full Name'}
							errors={errors}
							{...register('name', {
								required: 'You must enter a participant name.',
								maxLength: 255,
							})}
							data-ttg-max-length={255}
							onBlur={(e) => trimInputValue('name', e)}
						/>
					</EditSection>
				</div>

				<div>
					<hr className='text-gray-200 my-4' />

					<div className={'flex justify-end mb-6 px-4'}>
						<AppButton
							id={'ttg-edit-individual-submit-button'}
							text={'Save Changes'}
							loading={updating}
							disabled={!isDirty}
						/>
						<AppButton
							id={'ttg-edit-individual-cancel-button'}
							type={'button'}
							className={'btn btn-secondary ml-4'}
							text={'Cancel'}
							loading={updating}
							onClick={onClose}
						/>
					</div>
				</div>
			</form>
		</AppSlideOver>
	);
};

export default EditIndividualAssessmentSidebar;
