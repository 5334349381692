import React from 'react';

const AddressInfo = ({ id, label, address }) => {
	if (!address || !address.name) {
		return null;
	}

	return (
		<div className={'flex flex-1 flex-col'} id={id}>
			<p id={`${id}-label`} className={'ttg-text-base font-medium text-gray-800 mb-1'}>
				{label}
			</p>
			<p id={`${id}-name`} className={'ttg-text-sm font-medium text-gray-800'}>
				{address.name}
			</p>
			<p id={`${id}-company`} className={'ttg-text-sm text-gray-800'}>
				{address.company}
			</p>
			<p id={`${id}-street`} className={'ttg-text-sm text-gray-800'}>
				{address.street}
			</p>
			<p id={`${id}-location`} className={'ttg-text-sm text-gray-800'}>
				{address.location}
			</p>
			<p id={`${id}-country`} className={'ttg-text-sm text-gray-800'}>
				{address.country}
			</p>
		</div>
	);
};

export default AddressInfo;
