import React from 'react';

const Items = ({ order }) => {
	return (
		<>
			<div className='flex flex-col mt-2'>
				<div className={'flex'}>
					<p className={'flex flex-1 text-gray-800 ttg-text-xs'}>&nbsp;</p>
					<div className={'flex w-64 border-b-1 border-gray-300 py-1'}>
						<p className={'flex flex-1 ttg-text-xs text-gray-800 font-medium'}>Subtotal</p>
						<p className={'ttg-text-xs text-gray-500'}>{order.totals.subtotal}</p>
					</div>
				</div>
			</div>
			<div className='flex flex-col'>
				<div className={'flex'}>
					<p className={'flex flex-1 text-gray-800 ttg-text-xs'}>&nbsp;</p>
					<div className={'flex items-center w-64 border-b-1 border-gray-300 py-1'}>
						<p className={'text-gray-800 font-medium'}>
							<span className={'ttg-text-xs'}> Discount coupon</span>
							{order.totals.coupon_code && (
								<span className={'text-xs leading-none font-normal'}>
									<br />({order.totals.coupon_code})
								</span>
							)}
						</p>
						<p className={'flex flex-1 ttg-text-xs text-gray-500 text-right justify-end'}>
							{order.totals.discountValue > 0 ? '-' : ''}
							{order.totals.discount}
						</p>
					</div>
				</div>
			</div>
			<div className='flex flex-col'>
				<div className={'flex'}>
					<p className={'flex flex-1 text-gray-800 ttg-text-xs'}>&nbsp;</p>
					<div className={'flex flex-row items-center w-64 border-b-1 border-gray-300 py-1'}>
						<p className={'ttg-text-xs text-gray-800 font-medium'}>
							<span className={'ttg-text-xs'}>Shipping Fee</span>
							{order.shipping_method && (
								<span className={'text-xs leading-none font-normal'}>
									<br />
									{order.shipping_method}
								</span>
							)}
						</p>
						<p className={'flex flex-1 ttg-text-xs text-gray-500 text-right justify-end'}>
							{order.totals.shipping}
						</p>
					</div>
				</div>
			</div>
			<div className='flex flex-col'>
				<div className={'flex'}>
					<p className={'flex flex-1 text-gray-800 ttg-text-xs'}>&nbsp;</p>
					<div className={'flex w-64 border-b-1 border-gray-300 py-1'}>
						<p className={'flex flex-1 ttg-text-xs text-gray-800 font-medium'}>Tax</p>
						<p className={'ttg-text-xs text-gray-500'}>{order.totals.tax}</p>
					</div>
				</div>
			</div>
			<div className='flex flex-col border-b-1 border-gray-300 py-1'>
				<div className={'flex'}>
					<p className={'flex flex-1 text-gray-800 ttg-text-xs'}>&nbsp;</p>
					<div className={'flex w-64 '}>
						<p className={'flex flex-1 ttg-text-sm text-gray-800 font-semibold'}>Total</p>
						<p className={'ttg-text-sm text-gray-800 font-semibold'}>{order.totals.total}</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default Items;
