import IconSmall from './IconSmall';

const Tips = ({ humbleDetails, hungryDetails, smartDetails }) => {
	return (
		<div className={'flex-row survey-font'}>
			<div className={'ttg-text-xl text-gray-500 font-medium py-2'}>TIPS FOR DEVELOPING THE THREE VIRTUES</div>
			<div className={'inline-flex'}>
				<div className={'ttg-text-sm  py-2 survey-font'}>
					<b>Use a personality instrument: (humble, hungry, smart)</b>
					<br />A personality instrument (e.g. Myers-Briggs, DiSC&reg; or Social Styles)
					<br />
					will help you understand and identify your strengths and potential
					<br />
					blind spots. With some personality profiles it is possible to predict
					<br />
					which type or style might have a higher likelihood of having issues
					<br />
					with humility, hunger or people smarts. Simply being aware of
					<br />
					these natural tendencies can be the first step in developing and
					<br />
					cultivating the virtue you are trying to foster.
				</div>
				<div className={'-mt-8 -mx-4'}>
					<IconSmall
						humbleDetails={humbleDetails}
						hungryDetails={hungryDetails}
						smartDetails={smartDetails}
					/>
				</div>
			</div>

			<div className={'ttg-text-sm py-5 mt-8 survey-font'}>
				<b>Be vulnerable: (humble, hungry, smart)</b>
				<br />
				Share the results of this assessment with your manager and/or colleagues. Sitting down with your manager
				or teammates to reveal and discuss your own relative weaknesses related to humble, hungry and smart is a
				powerful way to ensure behavioral change. Encourage your team to take the self-assessment and have each
				member report his or her development area to the rest of the team.
			</div>

			<div className={'ttg-text-sm py-3 survey-font'}>
				<b>Set small personal goals: (humble, hungry, smart)</b>
				<br />
				Take this opportunity to choose one or two things to do differently. Do you need to talk about yourself
				less? Do you need to show more interest in your work? Do you need to be more curious about others&rsquo;
				opinions? For example, if hunger is your area of development, set small personal goals that motivate you
				and also support the effectiveness of the team. Think about the areas in your life where you have
				motivation and find a creative way to translate that to your everyday tasks. Make an internal commitment
				to be the first member on the team to submit a project or volunteer to take on a task that is outside of
				your area of responsibility. Sometimes small changes can make a big difference.
			</div>

			<div className={'ttg-text-sm py-3 survey-font'}>
				<b>Seek out feedback: (humble, hungry, smart)</b>
				<br />
				Ask your colleagues, your boss or an executive coach for assistance. Tell them what you are trying to
				improve on and ask for regular feedback. Be open to feedback and notice when you start to feel
				defensive. Try to recognize when members of your team seem frustrated or offended by your words or
				actions, don&rsquo;t avoid but engage. Make it a habit to debrief tough conversations or meetings.
			</div>

			<div className={'ttg-text-sm py-3 survey-font'}>
				<b>Practice the virtue: (humble)</b>
				<br />
				You can make progress and exercise humility by emulating the behaviors of people you know to be humble.
				By sincerely and intentionally making an effort to compliment others, admit your mistakes and
				weaknesses, and take an interest in colleagues, you can begin to apply the discipline of humility and
				develop this virtue. Practice thinking of others more than yourself. Make a concerted effort to
				recognize the unique contributions of each of your team members and verbalize it to them. Do something
				generous but remain anonymous. Purposefully remaining anonymous and not seeking credit for a
				contribution (time, resources, effort or otherwise) can help cultivate humility.
			</div>
		</div>
	);
};

export default Tips;
