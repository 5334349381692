import React from 'react';
import { AppWizard } from '../../../App';
import { AssessmentSetupIndividualSetupCompleted, AssessmentSetupIndividualSetupForm } from '../../index';

const Wizard = () => {
	return (
		<AppWizard className={'individual-assessment-setup'} isLazyMount={true}>
			<AssessmentSetupIndividualSetupForm />
			<AssessmentSetupIndividualSetupCompleted />
		</AppWizard>
	);
};

export default Wizard;
