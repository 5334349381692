import React from 'react';
import TtgTeamNotificationsItem from './TtgTeamNotificationsItem';

const TtgTeamNotificationsList = ({ notifications, onStatusChange }) => {
	return (
		<div id={'ttg-team-notification-list'}>
			{notifications.map((notification, index) => {
				return (
					<TtgTeamNotificationsItem
						notification={notification}
						onStatusChange={() => onStatusChange(notification)}
						key={index}
					/>
				);
			})}
		</div>
	);
};

export default TtgTeamNotificationsList;
