import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../constants/config';

const ReCaptcha = React.forwardRef(({}, ref) => {
	const captchaRef = React.useRef('captcha');

	// eslint-disable-next-line react-hooks/rules-of-hooks
	React.useImperativeHandle(ref, () => ({
		async getCaptchaValue() {
			if (!config.captcha_enabled) return null;
			const captchaValue = await captchaRef.current?.executeAsync().then((token) => {
				return token;
			});
			captchaRef.current.reset();
			return captchaValue;
		},
	}));

	if (!config.captcha_enabled) return <div ref={ref}></div>;

	return (
		<div ref={ref}>
			<ReCAPTCHA ref={captchaRef} sitekey={config.captcha_site_key} size='invisible' hidden={true} />
		</div>
	);
});

export default ReCaptcha;
