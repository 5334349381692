import React from 'react';
import { AppPdfPage } from '../../../App';
import ReportService from '../../../../services/ReportService';
import UtilityService from '../../../../services/UtilityService';

const TeamAverage = ({ details, page, type }) => {
	const level = React.useMemo(() => {
		return ReportService.getLevelByValue(details.average_response_per_category[type]);
	}, [type]);

	const reversePyramidItems = React.useMemo(() => {
		return [...ReportService.PyramidItems].reverse();
	}, []);

	const questions = React.useMemo(() => {
		return details.questions.filter(({ sort }) => details.questions_categories[type].indexOf(sort) > -1);
	}, [details]);

	const renderProgressBar = (value) => {
		const level = ReportService.getLevelByValue(value);
		let percentage = ((value - 1) / 4) * 100;
		if (percentage === 0) {
			percentage = 0.5;
		}
		value = value ? value.toFixed(2) : '';

		return (
			<div className='ProgressBar'>
				<svg className='ProgressBar__meter'>
					<rect className='ProgressBar__meterBar' />
					<rect className={`ProgressBar__meterFill ProgressBar--${level}`} width={`${percentage}%`} />
				</svg>
				<div className='ProgressBar__label'>{value}</div>
			</div>
		);
	};

	const renderPyramid = () => {
		return (
			<svg className='teamPyramid' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 440.98 337.17'>
				<title>Pyramid</title>
				<g className={`ScoresPyramid ScoresPyramid--hideLabels`} data-name='Layer 2'>
					<g id='Layer_1-2' data-name='Layer 1'>
						<polygon
							fill='#F9FAFB'
							points='220.49 0 440.98 337.17 0 337.17 220.49 0'
							transform='translate(-15 0)'
						/>
						{reversePyramidItems.map((i) => (
							<g id={i.key} key={i.key}>
								<polygon className={`${i.key} ${i.key === type ? level : ''}`} points={i.points} />
								<text className='PyramidText' transform={i.transform} />
							</g>
						))}
					</g>
				</g>
			</svg>
		);
	};

	return (
		<AppPdfPage page={page} sectionClassName={'TeamAverage'}>
			<div className='TeamAverage__layout'>
				<div className='TeamAverage__heading'>
					<div className='PageHeading'>
						<div className='PageHeading__title'>{type}</div>
					</div>
				</div>
				<div className='TeamAverage__content'>
					<div className='TeamAverage__introLayout'>
						<div className='TeamAverage__introPyramid'>{renderPyramid()}</div>
						<div className='TeamAverage__introBlurb Blurb'>
							<div className='Blurb__title'>
								Your Team's Average Score: {details.average_response_per_category[type]}
							</div>

							<div className='Blurb__content'>{ReportService.TeamAverage[type][level]}</div>
						</div>
					</div>

					<div className='TeamAverage__questionReview'>
						{questions.map((question) => (
							<div
								className='AreaQuestion AreaQuestion--condensed AreaQuestion--small'
								key={question.sort}
							>
								<div className='AreaQuestion__number'>
									{UtilityService.padNumber(question.sort, '0')}.
								</div>
								<div className='AreaQuestion__content'>
									<div
										className='AreaQuestion__label'
										dangerouslySetInnerHTML={{ __html: question.question }}
									/>
									<div className='AreaQuestion__progressBar'>
										{renderProgressBar(details.raw_scores_average[question.sort - 1])}
									</div>
								</div>
							</div>
						))}

						<div className='TeamAverage__summaryRow'>
							<div className='ProgressScale ProgressScale--withRightLabel'>
								<div className='ProgressScale__mark'>
									<div className='ProgressScale__markLabel MarkLabel'>
										<div className='MarkLabel__number'>1</div>
										<div className='MarkLabel__text'>Almost Never</div>
									</div>
								</div>
								<div className='ProgressScale__mark'>
									<div className='ProgressScale__markLabel MarkLabel'>
										<div className='MarkLabel__number'>2</div>
										<div className='MarkLabel__text'>Rarely</div>
									</div>
								</div>
								<div className='ProgressScale__mark'>
									<div className='ProgressScale__markLabel MarkLabel'>
										<div className='MarkLabel__number'>3</div>
										<div className='MarkLabel__text'>Sometimes</div>
									</div>
								</div>
								<div className='ProgressScale__mark'>
									<div className='ProgressScale__markLabel MarkLabel'>
										<div className='MarkLabel__number'>4</div>
										<div className='MarkLabel__text'>Usually</div>
									</div>
								</div>
								<div className='ProgressScale__mark'>
									<div className='ProgressScale__markLabel MarkLabel'>
										<div className='MarkLabel__number'>5</div>
										<div className='MarkLabel__text'>Almost Always</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</AppPdfPage>
	);
};

export default TeamAverage;
