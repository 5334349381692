import React from 'react';
import { AppButton, AppIcon, AppSlideOver } from '../../App';
import { AssessmentSingleEmailInput } from '../index';
import { useForm, useFormState } from 'react-hook-form';
import UtilityService from '../../../services/UtilityService';
import NotificationService from '../../../services/NotificationService';
import RouteNames from '../../../router/RouteNames';
import AssessmentService from '../../../services/AssessmentService';
import { useAssessments } from '../../../providers/AssessmentProvider';
import { useCustomer } from '../../../providers/CustomerProvider';

const AddTeamMemberSidebar = ({ teamId, isOpen, onClose, limit, isScheduled }) => {
	const [size, setSize] = React.useState(1);
	const [addingMembers, setAddingMembers] = React.useState(false);
	const { setAssessmentDetails } = useAssessments();

	const {
		register,
		unregister,
		handleSubmit,
		formState: { errors },
		setValue,
		getValues,
		watch,
		setError,
		reset,
		clearErrors,
		control,
	} = useForm();

	const { isSubmitted } = useFormState({ control });

	const { setLicenses } = useCustomer();

	React.useEffect(() => {
		if (!isOpen) {
			reset({
				team_members: [{ fakeId: UtilityService.generateRandomString(10) }],
			});
			setSize(1);
		}
	}, [isOpen]);

	const unRegisterSingleEmailInput = (i) => {
		clearErrors(`tmp_email_${i}`);
		unregister(`tmp_email_${i}`);
	};

	const save = async ({ team_members, ...formData }) => {
		const emails = team_members.map(({ value }) => value);
		setAddingMembers(true);
		AssessmentService.addTeamMembers(teamId, emails)
			.then((response) => {
				if (response.warning) {
					NotificationService.custom('Please note', response.warning, 'information-line', 'yellow-500');
					Object.keys(formData).map((e) => {
						if (response.not_inserted.indexOf(formData[e]) > -1) {
							return setError(e, {
								type: 'validate',
								message: 'Team member already exists',
							});
						}
						const i = team_members.findIndex(({ value }) => value === formData[e]);
						if (i > -1) {
							team_members.splice(i, 1);
							unRegisterSingleEmailInput(e.replace('tmp_email_', ''));
						}
					});
					setValue('team_members', team_members);
				}
				if (response.added_licenses > 0) {
					if (!isScheduled) {
						setLicenses((prev) => ({ ...prev, licenses: prev.licenses - response.added_licenses }));
					}
					if (!response.warning) {
						NotificationService.success(
							emails.length > 1
								? 'Team members were added successfully'
								: 'Team member was added successfully'
						);
						onClose();
					}
				}
				setAssessmentDetails((prev) => AssessmentService.addAssessmentTeamMembers(response, prev));
			})
			.finally(() => setAddingMembers(false));
	};

	const canAddTeamMembers = React.useMemo(() => {
		if (!limit) {
			return true;
		}
		return size < limit;
	}, [size, limit]);

	const addNewEmail = () => {
		const members = [...getValues('team_members'), { fakeId: UtilityService.generateRandomString(10) }];
		setValue('team_members', members);
		setSize(members.length);
	};

	return (
		<AppSlideOver isOpen={isOpen} closeModal={onClose} slideOverContentClass={'!bg-white'}>
			<div className='p-4'>
				<h1 className='ttg-text-base text-gray-900 font-medium'>Add member to team roster</h1>
				<p className='text-gray-500 ttg-text-sm mt-2 mb-4'>
					Please enter the email address of the members you'd like to add to the team roster in the field
					provided below, and click the 'Add Member' button.
				</p>
				<form onSubmit={handleSubmit(save)}>
					<AssessmentSingleEmailInput
						name={'team_members'}
						register={register}
						errors={errors}
						emails={watch('team_members')}
						onChange={(members) => {
							setValue('team_members', members);
						}}
						min={1}
						setError={setError}
						clearErrors={clearErrors}
						onRemove={(i) => {
							unRegisterSingleEmailInput(i);
							setSize(size - 1);
						}}
						isSubmitted={isSubmitted}
						setValue={setValue}
					/>
					{canAddTeamMembers ? (
						<div
							id={'ttg-assessment-add-new-team-member-button'}
							className={'flex flex-1 items-center cursor-pointer ttg-text-sm'}
							onClick={addNewEmail}
						>
							<AppIcon icon={'add-circle-line'} className={'text-red-500'} />
							<span className={'text-red-500'}> Add new email address</span>
						</div>
					) : (
						<div>
							<p className={'ttg-text-sm text-gray-800'}>
								You do not have any more licenses available,{' '}
								<a href={RouteNames.OnlineTeamAssessment} className={'hover:underline text-rose-500'}>
									click here
								</a>{' '}
								to purchase more licenses in order to be able to add more team members to this
								assessment.
							</p>
						</div>
					)}
					<div className='my-4 border-t-1 border-gray-200' />

					<div className='flex flex-1 mt-4'>
						<div className='flex flex-1'>
							<AppButton
								id={'ttg-assessment-add-new-team-member-confirm-button'}
								text={'Add Member'}
								className={'btn btn-primary w-full mr-2'}
								loading={addingMembers}
							/>
						</div>
						<div className='flex flex-1'>
							<AppButton
								id={'ttg-assessment-add-new-team-member-cancel-button'}
								text={'Cancel'}
								className={'btn btn-secondary w-full ml-2'}
								type={'button'}
								onClick={onClose}
								loading={addingMembers}
							/>
						</div>
					</div>
				</form>
			</div>
		</AppSlideOver>
	);
};

export default AddTeamMemberSidebar;
