import React from 'react';
import { useLocation } from 'react-router-dom';
import UtilityService from '../../services/UtilityService';
import { AccountLayout } from '../../components/Layout';
import { AppButton } from '../../components/App';
import AssessmentService from '../../services/AssessmentService';

const GenerateReport = () => {
	const [generated, setGenerated] = React.useState('');
	const [generatingReport, setGeneratingReport] = React.useState(false);

	const location = useLocation();

	const generate = async () => {
		const t = UtilityService.getQueryParams(location.search);
		setGeneratingReport(true);
		const { payload } = await AssessmentService.generateReport(t)
			.then(() => {
				setGeneratingReport(false);
				setGenerated(payload.message);
			})
			.catch((error) => {
				setGeneratingReport(false);
				setGenerated(error.message);
			});
	};

	return (
		<AccountLayout sidebar={false}>
			<div className={'flex flex-col items-center justify-center mx-auto mt-6'} style={{ maxWidth: '550px' }}>
				<h1
					className={'ttg-text-lg text-gray-900 font-medium text-center'}
					id={'ttg-generate-report-page-title'}
				>
					Generate Team Assessment Report
				</h1>
				{!!generated ? (
					<p
						className='ttg-text-sm text-gray-500 text-center mb-4 mt-2'
						id={'ttg-generate-report-page-message'}
						dangerouslySetInnerHTML={{ __html: generated }}
					/>
				) : (
					<p
						className='ttg-text-sm text-gray-500 text-center mb-4 mt-2'
						id={'ttg-generate-report-page-message'}
					>
						At this point in the process you have the option to run the Team Assessment Report. However, we
						highly recommend that all team members complete the Assessment before running the report. <br />
						<br />
						<span className='font-bold'>PLEASE KEEP IN MIND THAT THIS WILL BE THE FINAL REPORT!</span>
					</p>
				)}

				{!generated && (
					<AppButton
						id={'ttg-generate-report-page-confirm-button'}
						text={'Generate Report'}
						onClick={generate}
						loading={generatingReport}
					/>
				)}
			</div>
		</AccountLayout>
	);
};

export default GenerateReport;
