import { Dayjs } from "dayjs";

import { APPLICATION_ENUM } from "../application";

export enum APPLICATION_INTERFACE {
  APP = 'APP',
  API = 'API',
  THIRD_PARTY = 'THIRD_PARTY',
}

export interface HealthStatusDto {
  description: string;
  status: 'UP' | 'DOWN';
  httpStatus: number;
  responseTime?: number | null;
  error?: string;
}

export interface DailyHealthStatusDto {
  description: string;
  upTime: number;
  date: Dayjs;
}

export interface ApplicationInterfaceStatus {
  name: string;
  history: DailyHealthStatusDto[];
}

export interface ApplicationHealthStatus {
  application: APPLICATION_ENUM | string;
  interfaces: {
    [APPLICATION_INTERFACE.APP]: ApplicationInterfaceStatus;
    [APPLICATION_INTERFACE.API]?: ApplicationInterfaceStatus;
    [APPLICATION_INTERFACE.THIRD_PARTY]?: ApplicationInterfaceStatus;
  }
}
