import React from 'react';
import { AssessmentLayout } from '../../components/Layout';
import { AssessmentSetupIndividualSetupWizard } from '../../components/Assessments';
import { useLocation, useNavigate } from 'react-router-dom';
import UtilityService from '../../services/UtilityService';
import NotificationService from '../../services/NotificationService';
import RouteNames from '../../router/RouteNames';
import AssessmentService from '../../services/AssessmentService';
import { useAssessments } from '../../providers/AssessmentProvider';
import { useCustomer } from '../../providers/CustomerProvider';

const Individual = () => {
	const [alreadyChecked, setAlreadyChecked] = React.useState(false);
	const location = useLocation();
	const navigate = useNavigate();

	const { licenses: { licenses }, customer } = useCustomer();
	const { setupAssessment, setSetupAssessment, loading, setLoading } = useAssessments();

	const { id } = UtilityService.getQueryParams(location.search);

	const _getDetails = React.useCallback(() => {
		if (id) {
			setLoading(true);
			AssessmentService.details({ id, setup: true })
				.then((response) => {
					setSetupAssessment(AssessmentService.prepareAssessmentAsDraft(response));
				})
				.catch(() => {
					setSetupAssessment(AssessmentService.resetTeamSetup(customer));
					navigate(RouteNames.Assessments, { replace: true });
				})
				.finally(() => setLoading(false));
		}
	}, [id, location]);

	React.useEffect(() => {
		_getDetails();
	}, [_getDetails]);

	React.useEffect(() => {
		if (!alreadyChecked) {
			if (licenses) {
				setAlreadyChecked(true);
			}
			if (licenses === 0) {
				NotificationService.custom('You do not have enough licenses to setup a new individual assessment');
				navigate(RouteNames.Assessments, { replace: true });
			}
		}
	}, [licenses]);

	React.useEffect(() => {
		if (setupAssessment.id) {
			if (!setupAssessment.is_draft) {
				navigate(RouteNames.Assessments, { replace: true });
			} else if (setupAssessment.is_team) {
				navigate(`${RouteNames.TeamAssessmentSetup}?id=${setupAssessment.id}`, { replace: true });
			}
		}
	}, [setupAssessment]);

	return (
		<AssessmentLayout loading={loading}>
			<AssessmentSetupIndividualSetupWizard />
		</AssessmentLayout>
	);
};

export default Individual;
