import ApiService from './ApiService';
import UtilityService from './UtilityService';
import NotificationService from './NotificationService';

const mapSingleOrder = (order) => {
	return {
		id: order.orders_id,
		date: UtilityService.formatDate(order.date_purchased, 'MM/DD/YYYY | hh:mm A'),
		status: order.orders_status_name,
		total: UtilityService.formatPrice(parseFloat(order.order_total)),
	};
};

const mapAllOrders = (orders) => {
	return orders.map((order) => OrderService.mapSingleOrder(order));
};

const mapOrderDetails = (order) => {
	const items = order.product_details.map((i) => ({
		id: i.products_id,
		name: i.products_name,
		image: i.image,
		quantity: i.products_quantity,
		item_price: UtilityService.formatPrice(parseFloat(i.final_price)),
		total_price: UtilityService.formatPrice(parseFloat(i.final_price) * i.products_quantity),
	}));
	const shippingAddress = order.order_details.delivery_country
		? {
				name: order.order_details.delivery_name,
				company: order.order_details.delivery_company,
				street: order.order_details.delivery_street_address,
				location: UtilityService.formatOrderAddress(order, 'delivery'),
				country: order.order_details.delivery_country,
		  }
		: null;

	const billingAddress = {
		name: order.order_details.billing_name ? order.order_details.billing_name.trim() : '',
		company: order.order_details.billing_company,
		street: order.order_details.billing_street_address,
		location: UtilityService.formatOrderAddress(order, 'billing'),
		country: order.order_details.billing_country,
		phone: order.order_details.billing_phone,
	};
	const history = order.history.map((h, i) => {
		const notes = [];
		if (h.status) {
			notes.push(h.status);
		}
		if (h.comments) {
			notes.push(h.comments);
		}
		return {
			id: i,
			changedAt: UtilityService.formatDate(h.date_added, 'MMMM DD YYYY, hh:mm A'),
			notes: notes.join(' - '),
		};
	});

	let coupon_code = order.order_details.coupon_code;

	if (!coupon_code && order.ot_coupon && order.ot_coupon.title) {
		const coupon = order.ot_coupon.title.split(':')[1];
		if (coupon) {
			coupon_code = coupon.replace(' ', '');
		} else {
			coupon_code = order.ot_coupon.title.replace(':', '');
		}
	}

	return {
		id: order.order_details.orders_id,
		status: order.order_details.status,
		payment_method: order.order_details.payment_method,
		po_authorizer: order.order_details.po_authorizer,
		po_number: order.order_details.po_number,
		shipping_method: order.ot_shipping ? order.order_details.shipping_method : '',
		date_purchased: UtilityService.formatDate(order.order_details.date_purchased, 'MMMM DD YYYY, hh:mm A'),
		items,
		shippingAddress,
		billingAddress,
		totals: {
			subtotal: UtilityService.formatPrice(
				order.ot_subtotal && parseFloat(order.ot_subtotal.value) > 0 ? parseFloat(order.ot_subtotal.value) : 0
			),
			discount: UtilityService.formatPrice(
				order.ot_coupon && parseFloat(order.ot_coupon.value) > 0 ? parseFloat(order.ot_coupon.value) : 0
			),
			discountValue: order.ot_coupon && order.ot_coupon.value ? parseFloat(order.ot_coupon.value) : 0,
			tax: UtilityService.formatPrice(
				order.ot_tax && parseFloat(order.ot_tax.value) > 0 ? parseFloat(order.ot_tax.value) : 0
			),
			shipping: UtilityService.formatPrice(
				order.ot_shipping && parseFloat(order.ot_shipping.value) > 0 ? parseFloat(order.ot_shipping.value) : 0
			),
			total: UtilityService.formatPrice(
				order.ot_total && parseFloat(order.ot_total.value) > 0 ? parseFloat(order.ot_total.value) : 0
			),
			coupon_code,
		},
		history,
		order_tracking_details: order.order_tracking_details,
	};
};

const get = (filters) => {
	return new Promise((resolve, reject) => {
		const query = UtilityService.serializeQueryString(filters);
		ApiService.get(`/v1/orders?${query}`).then(({ data }) => {
			resolve({
				orders: OrderService.mapAllOrders(data.data),
				meta: data.meta,
			});
		}, reject);
	});
};

const details = (id) => {
	return new Promise((resolve, reject) => {
		ApiService.get(`/v1/order/${id}/details`).then(({ data }) => {
			resolve(OrderService.mapOrderDetails(data));
		}, reject);
	});
};

const receiptDetails = (id, filters) => {
	return new Promise((resolve, reject) => {
		const query = UtilityService.serializeQueryString(filters);
		ApiService.get(`/v1/order/${id}/details/pdf?${query}`).then(({ data }) => {
			resolve(OrderService.mapOrderDetails(data));
		}, reject);
	});
};

const printReceipt = (id) => {
	return new Promise((resolve, reject) => {
		ApiService.get(`/v1/order/${id}/pdf`).then(({ data }) => {
			const buffer = UtilityService.convertBase64ToBuffer(data.base64);
			UtilityService.downloadPdfFromBuffer(buffer, `The Table Group - Receipt ${id}.pdf`);
			resolve();
		}, reject);
	});
};

const OrderService = {
	mapSingleOrder,
	mapAllOrders,
	mapOrderDetails,
	get,
	details,
	receiptDetails,
	printReceipt,
};

export default OrderService;
