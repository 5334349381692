export enum WIDGET_READABLE_ENUM {
  Wonder = "Wonder",
  Invention = "Invention",
  Discernment = "Discernment",
  Galvanizing = "Galvanizing",
  Enablement = "Enablement",
  Tenacity = "Tenacity",
}

export enum WIDGET_SHORT_ENUM {
  W = "W",
  I = "I",
  D = "D",
  G = "G",
  E = "E",
  T = "T",
}

export const widgetToReadable: Record<WIDGET_SHORT_ENUM, WIDGET_READABLE_ENUM> = {
  W: WIDGET_READABLE_ENUM.Wonder,
  I: WIDGET_READABLE_ENUM.Invention,
  D: WIDGET_READABLE_ENUM.Discernment,
  G: WIDGET_READABLE_ENUM.Galvanizing,
  E: WIDGET_READABLE_ENUM.Enablement,
  T: WIDGET_READABLE_ENUM.Tenacity,
};
