import React from 'react';
import UtilityService from '../../services/UtilityService';
import { AppDropdown } from './index';
import dayjs from 'dayjs';

const days = ['Mon', 'Tu', 'We', 'Thu', 'Fr', 'Sa', 'Su'];

const Calendar = ({ date, value, onChange, min, timeSensitive = false, timeZone }) => {
	const [weeks, setWeeks] = React.useState([]);

	const getHour = Number(UtilityService.formatDate(value, 'hh', true));
	const getMinute = Number(UtilityService.formatDate(value, 'mm', true));
	const getAmPm = UtilityService.formatDate(value, 'A', true);

	const selectedDate = dayjs(date).utc(true);
	const currentDate = dayjs().tz(timeZone.value).utc(true);

	const change = Number(dayjs(date).format('YYYYMMDD')) <= Number(dayjs().tz(timeZone.value).format('YYYYMMDD'));

	React.useEffect(() => {
		if (timeSensitive) {
			if (change && Number(selectedDate.format('DD')) < Number(currentDate.format('DD'))) {
				onChange(currentDate);
			}
		}
	}, [date, timeZone]);

	const constantTime = {
		hours: UtilityService.prepareNumberOptions(1, 12),
		minutes: UtilityService.prepareMinutesOptions(),
		sunRise: [
			{ value: 'AM', label: 'AM' },
			{ value: 'PM', label: 'PM' },
		],
	};

	const [timeOptions, setTimeOptions] = React.useState({ ...constantTime });

	React.useEffect(() => {
		setWeeks(UtilityService.getWeeksInRange(date));
		setTimeOptions({ ...constantTime });
		if (timeSensitive) {
			if (change) {
				setTimeOptions({ ...timeOptions, ...UtilityService.getTimeOptions(date, constantTime, timeZone) });
			} else {
				setTimeOptions({ ...constantTime });
			}
		}
	}, [date, timeZone, timeSensitive]);

	const Week = (week) => {
		return (
			<div className='weeks-container' key={week.week}>
				{week.days.map((day) => {
					const d = UtilityService.formatDate(day, 'DD', true);

					let canClick = true;
					if (min) {
						canClick = dayjs(day).isAfter(dayjs(min));
					}

					const isSelected =
						UtilityService.formatDate(value, 'MM/DD/YYYY', true) ===
						UtilityService.formatDate(day, 'MM/DD/YYYY', true);

					const extraClasses = ['week-container'];
					if (isSelected) {
						extraClasses.push('selected');
					}
					if (!canClick) {
						extraClasses.push('disabled');
					}

					return (
						<div key={`${week.week}-${d}`} id={`date_${week.week}_${d}`} className={extraClasses.join(' ')}>
							<span
								onClick={() => {
									if (canClick && !isSelected) {
										const h = parseInt(UtilityService.formatDate(value, 'HH', true));
										const newDate = dayjs(day)
											.startOf('day')
											.add(h, 'hours')
											.add(getMinute, 'minutes');
										onChange(newDate);
									}
								}}
							>
								{d}
							</span>
						</div>
					);
				})}
			</div>
		);
	};

	return (
		<div className={'app-calendar'}>
			<div className='days-container'>
				{days.map((day) => (
					<div key={day} className={'day-container'}>
						<span>{day}</span>
					</div>
				))}
			</div>
			{weeks.map(Week)}

			<div className='my-3 border-t-1 border-gray-200' />

			<div className='flex flex-1 items-center gap-x-1'>
				<AppDropdown
					showDropdownIndicator={false}
					inputContainerClassName={'flex flex-1 !max-w-67px'}
					value={{
						value: getHour,
						label: getHour,
					}}
					options={timeOptions.hours}
					onChange={({ value }) => {
						const h = getAmPm === 'PM' ? value + 12 : value % 12;
						const newDate = dayjs(date).startOf('day').add(h, 'hours').add(getMinute, 'minutes');
						onChange(newDate);
					}}
					maxMenuHeight={120}
				/>
				<AppDropdown
					showDropdownIndicator={false}
					inputContainerClassName={'flex flex-1 !max-w-67px'}
					value={{
						value: getMinute,
						label: getMinute,
					}}
					options={timeOptions.minutes}
					onChange={({ value: minutes }) => {
						const h = parseInt(UtilityService.formatDate(value, 'HH', true));
						const newDate = dayjs(date).startOf('day').add(h, 'hours').add(minutes, 'minutes');
						onChange(newDate);
					}}
					maxMenuHeight={120}
				/>
				<AppDropdown
					showDropdownIndicator={false}
					inputContainerClassName={'flex flex-1 !max-w-67px'}
					value={{
						value: getAmPm,
						label: getAmPm,
					}}
					options={timeOptions.sunRise}
					onChange={({ value }) => {
						if (value !== getAmPm) {
							onChange(dayjs(date).add(value === 'PM' ? 12 : -12, 'hours'));
						}
					}}
				/>
			</div>
		</div>
	);
};

export default Calendar;
