import React from 'react';
import { AppDropdownButton, AppIcon, AppTooltip } from '../../App';
import BulkConfirmActionModal from './BulkConfirmActionModal';
import BulkConfirmDeleteActionModal from './BulkConfirmDeleteActionModal';
import AssessmentService from '../../../services/AssessmentService';
import NotificationService from '../../../services/NotificationService';
import { useAssessments } from '../../../providers/AssessmentProvider';

const defaultNoticeData = {
	emails: [],
	noticeEmails: [],
};

const defaultDeleteData = {
	emails: [],
	noticeEmails: [],
};

const RosterBulkActions = ({ selected, data, identifier, assessment }) => {
	const [isShowingNoticeModal, setIsShowingNoticeModal] = React.useState(false);
	const [noticeDataModal, setNoticeDataModal] = React.useState(defaultNoticeData);
	const [isBulkDeleting, setIsBulkDeleting] = React.useState(false);
	const [deleteModalData, setDeleteModalData] = React.useState(defaultDeleteData);
	const [deletingMembers, setDeletingMembers] = React.useState(false);

	const { loading: bulkLoading, setLoading } = useAssessments()

	const selectedItems = React.useMemo(() => {
		return data.filter((item) => selected.indexOf(item[identifier]) > -1);
	}, [data, selected]);

	const completedCount = React.useMemo(() => {
		return selectedItems.filter(({ status }) => status === 'Completed').length;
	}, [selectedItems]);

	const notCompletedCount = React.useMemo(() => {
		return selectedItems.filter(({ status }) => status !== 'Completed').length;
	}, [selectedItems]);

	const completedItems = React.useMemo(() => {
		return selectedItems.filter(({ status }) => status === 'Completed');
	}, [selectedItems]);

	const notCompletedItems = React.useMemo(() => {
		return selectedItems.filter(({ status }) => status !== 'Completed');
	}, [selectedItems]);

	const notCompletedIds = React.useMemo(() => {
		return notCompletedItems.map(({ person_id }) => person_id);
	}, [notCompletedItems]);

	const onSendInitialEmail = (force) => {
		if (completedItems.length === 0 || force) {
			return NotificationService.success('Initial emails sent successfully');
		}
		setNoticeDataModal({
			type: 'initial',
			emails: notCompletedItems.map(({ email_address }) => email_address),
			noticeEmails: completedItems.map(({ email_address }) => email_address),
			team_name: assessment.assessment_data.name,
			callback: onSendInitialEmail,
		});
		setIsShowingNoticeModal(true);
	};

	const onSendReminderReport = (force) => {
		if (completedItems.length === 0 || force) {
			return AssessmentService.remindTeamMember({
				team_id: assessment.assessment_data.team_id,
				person_ids: notCompletedIds,
			}).then(() => {
				NotificationService.success('Reminders sent successfully');
			});
		}
		setNoticeDataModal({
			type: 'reminder',
			emails: notCompletedItems.map(({ email_address }) => email_address),
			noticeEmails: completedItems.map(({ email_address }) => email_address),
			team_name: assessment.assessment_data.name,
			callback: onSendReminderReport,
		});
		setIsShowingNoticeModal(true);
	};

	const onDelete = () => {
		const willAssessmentBeDeleted = data.length === notCompletedItems.length;
		const willAssessmentBeClosed = data.filter((r) => r.status !== 'Completed').length === notCompletedIds.length;
		setIsBulkDeleting(true);
		setDeleteModalData({
			emails: notCompletedItems.map(({ email_address }) => email_address),
			noticeEmails: completedItems.map(({ email_address }) => email_address),
			willAssessmentBeDeleted,
			willAssessmentBeClosed,
			team_name: assessment.assessment_data.name,
			person_ids: notCompletedIds,
		});
	};

	const onSendResponses = (force) => {
		if (notCompletedItems.length === 0 || force) {
			const person_ids = completedItems.map(({ person_id }) => person_id);
			setLoading(true);
			return AssessmentService.sendResponses({
				team_id: assessment.assessment_data.team_id,
				person_ids: person_ids,
				bulk: true,
			})
				.then(() => NotificationService.success('Responses sent successfully'))
				.finally(() => {
					setLoading(false);
				});
		}
		setNoticeDataModal({
			type: 'responses',
			emails: completedItems.map(({ email_address }) => email_address),
			noticeEmails: notCompletedItems.map(({ email_address }) => email_address),
			team_name: assessment.assessment_data.name,
			callback: onSendResponses,
		});
		setIsShowingNoticeModal(true);
	};

	const actions = [
		{
			id: 'send_initial_email',
			icon: 'mail-send-line',
			label: 'Send initial email to selected team members',
			disabled: assessment.isScheduled || notCompletedCount === 0,
			disabledText: assessment.isScheduled
				? 'This assessment is Scheduled to start later'
				: 'The selected team members have already completed the assessment',
			action: onSendInitialEmail,
		},
		{
			id: 'send_reminder_email',
			icon: 'notification-4-line',
			label: 'Send reminder email to selected team members',
			disabled: assessment.isScheduled || notCompletedCount === 0,
			disabledText: assessment.isScheduled
				? 'This assessment is Scheduled to start later'
				: 'The selected team members have already completed the assessment',
			action: onSendReminderReport,
		},
		{
			id: 'delete_member',
			icon: 'delete-bin-line',
			label: 'Remove selected team members',
			disabled: notCompletedCount === 0,
			disabledText: 'The selected team members have already completed the assessment',
			action: onDelete,
		},
		{
			id: 'send_responses',
			icon: 'file-transfer-line',
			label: 'Send responses to selected team members',
			disabled: completedCount === 0,
			disabledText: 'The selected team members have not yet completed the assessment',
			action: onSendResponses,
		},
	];

	return (
		<div className={'flex flex-row items-center'}>
			<AppDropdownButton
				id={'ttg-assessment-roster-bulk-actions'}
				label={'Bulk Actions'}
				actions={actions}
				closeOnAction={true}
				actionContainerClass={'max-w-full md:max-w-none'}
				side={'right'}
				disabled={!selected || selected.length === 0}
				loading={bulkLoading || deletingMembers}
			/>
			{(!selected || selected.length === 0) && (
				<>
					<AppIcon
						icon={'information-line'}
						className={'ml-2 cursor-pointer'}
						data-for={`assessments-roster-tooltip`}
						data-tip
					/>
					<AppTooltip id={`assessments-roster-tooltip`} placement={'right'}>
						Select one or more members to activate bulk actions
					</AppTooltip>
				</>
			)}
			<BulkConfirmActionModal
				isOpen={isShowingNoticeModal}
				onClose={() => setIsShowingNoticeModal(false)}
				onConfirm={() => {
					noticeDataModal.callback(true);
					setIsShowingNoticeModal(false);
				}}
				data={noticeDataModal}
			/>
			<BulkConfirmDeleteActionModal
				isOpen={isBulkDeleting}
				onClose={() => setIsBulkDeleting(false)}
				data={deleteModalData}
				assessment={assessment}
				deletingMembers={deletingMembers}
				setDeletingMembers={setDeletingMembers}
			/>
		</div>
	);
};

export default RosterBulkActions;
