import IconSmall from './IconSmall';

const LastPage = ({ humbleDetails, hungryDetails, smartDetails }) => {
	return (
		<div>
			<div className={'ttg-text-xl text-gray-500 font-medium survey-font flex'}>
				TIPS FOR DEVELOPING THE THREE VIRTUES
				<div className={'ttg-text-sm pl-2 pt-2'}>
					<i>cont.</i>
				</div>
			</div>
			<div className={'inline-flex'}>
				<div className={'ttg-text-sm py-2 survey-font'}>
					<b>Connect with motivated colleagues: (hungry)</b>
					<br />
					The primary driver for hunger comes from being passionate and
					<br />
					inspired by the purpose or mission of the team. Seek out motivated
					<br />
					colleagues and have them describe their connection to the mission.
					<br />
					You may get "infected" by your teammates&rsquo; passion, and even if this
					<br />
					doesn&rsquo;t happen, you may find a way to connect what you do and the
					<br />
					impact it has on others. Ultimately, this connection will lead to a<br />
					greater desire to perform for both yourself and the team.
				</div>

				<div className={'-mt-9 -mx-4'}>
					<IconSmall
						humbleDetails={humbleDetails}
						hungryDetails={hungryDetails}
						smartDetails={smartDetails}
					/>
				</div>
			</div>

			<div className={'ttg-text-sm py-5 mt-8 survey-font'}>
				<b>Practice empathy: (smart)</b>
				<br />
				Make a habit of taking an interest in your colleagues. Ask questions about them. Be curious about their
				opinions. Listen to others and try to see from their point of view. Additionally, seek out a trusted
				colleague to process through how you might approach a conversation. Give your teammates permission to
				give you real-time feedback about your communication and actions and how they affect the team.
			</div>

			<div className={'ttg-text-lg  text-gray-500 py-5'}>
				<div className={'inline-flex survey-font'}>
					For more information, read{' '}
					<p className={'text-black px-1'}>
						<i>
							<b>The Ideal Team Player: How to Recognize and </b>
						</i>
					</p>
				</div>
				<div className={'inline-flex survey-font'}>
					<p className={'text-black pr-2 survey-font'}>
						<b>
							<i> Cultivate the Three Essential Virtues</i>
						</b>
					</p>
					and visit
					<div className={'survey-font'}>
						<a
							className={'text-blue-700 font-medium pl-2'}
							href={'https://www.tablegroup.com/product/ideal-team-player'}
						>
							www.tablegroup.com/
						</a>
					</div>
				</div>
				<a
					className={'text-blue-700 font-medium pr-1'}
					href={'https://www.tablegroup.com/product/ideal-team-player'}
				>
					product/ideal-team-player
				</a>
				.
			</div>
		</div>
	);
};

export default LastPage;
