import React from 'react';

const Forbidden = () => {
	return (
		<div className='container mx-auto p-6'>
			<h2 className='text-2xl md:text-5xl text-primary font-bold my-6'>Forbidden</h2>
			<p className='text-lg text-primary font-normal mb-4'>
				We're sorry. The page you requested cannot be found. The address may have been typed incorrectly or the
				page may have been moved.
			</p>
			<p className='text-lg text-primary font-normal mb-4'>
				You will be automatically redirected to the 6 Geniuses homepage after ten seconds. If the problem
				persists, please contact our office at 925.299.9700 or via email to{' '}
				<a href='mailto:support@tablegroup.com'>support@tablegroup.com</a>.
			</p>
			<p className='text-lg text-primary font-normal mb-4'>Thank you.</p>
		</div>
	);
};

export default Forbidden;
