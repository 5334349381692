import React from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router';
import { TTGLogin, withSSOAuthProvider } from '@ttg/ui-user-lib';

import { AppButton } from '../../components/App';
import { PublicLayout } from '../../components/Layout';
import AppTabTitle from '../../components/App/AppTabTitle';
import RouteNames from '../../router/RouteNames';
import UtilityService from '../../services/UtilityService';
import config from '../../constants/config';
import { useSSOParams } from '../../providers/useSSOParams';
import { useCustomer } from '../../providers/CustomerProvider';

const TTGLoginWithProvider = withSSOAuthProvider(TTGLogin, useSSOParams);

const Login = () => {
	const { customer } = useCustomer();
	const location = useLocation();
	const navigate = useNavigate();

	const { redirect, logout } = UtilityService.getQueryParams(location.search);
	const redirectDecoded = redirect ? decodeURIComponent(redirect) : undefined;

	React.useEffect(() => {
		if (customer && customer.customers_id && !logout) {
			onSuccess();
		}
	}, []);

	const onSuccess = (_, onLoadFinish, redirectUrl) => {
		return window.open((redirectUrl || redirectDecoded || RouteNames.Tools).replace('//', '/'), '_self');
	};

	const Footer = (
		<>
			{redirect === '/new-checkout/' || redirect === '/checkout-view/' ? (
				<div className='flex flex-col items-center'>
					<span
						className={'text-rose-500 font-medium my-4 cursor-pointer'}
						onClick={() => window.open(RouteNames.Cart, '_self')}
						id={'ttg-login-cart-link'}
					>
						Back to Shopping Cart
					</span>
				</div>
			) : (
				<div className='ttg-login-find-assessments-box'>
					<AppButton
						text='Find your Assessments'
						icon='survey-line'
						iconRight={false}
						iconClass='text-2xl text-white font-medium mx-2'
						className='btn btn-primary w-full !py-4'
						onClick={() => navigate(RouteNames.FindAssessment, { replace: true })}
					/>
				</div>
			)}
		</>
	);

	return (
		<PublicLayout headerTitle='' headerStatus={null} footer={<></>}>
			<AppTabTitle title='Login | The Table Group' />
			<TTGLoginWithProvider
				className='mx-auto'
				captchaEnabled={config.captcha_enabled}
				captchaKey={config.captcha_site_key}
				apiEndpoint={config.ttg_url}
				title='LOGIN'
				onFinish={onSuccess}
				onForgotPassword={() => navigate(RouteNames.ForgotPassword)}
				theme='ttg-app'
				footer={Footer}
			/>
		</PublicLayout>
	);
};

export default Login;
