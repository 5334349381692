import React from 'react';
import { AppBreadcrumbs, AppButton } from '../App';
import RouteNames from '../../router/RouteNames';

const DetailsHeader = ({ showBreadcrumbs = true, order, printReceipt, loading }) => {
	return (
		<div className={'mx-4 mt-4 -mb-2'}>
			{!!showBreadcrumbs && (
				<AppBreadcrumbs items={[{ name: 'Order History', to: RouteNames.Orders }, { name: 'Order Details' }]} />
			)}
			<div className={'flex flex-1 flex-col md:flex-row mb-6'}>
				<p className={'flex flex-1 ttg-text-2xl font-medium text-gray-900 mb-3'}>Order Details</p>
				<div className='flex gap-4'>
					{!!order.order_tracking_details && order.order_tracking_details.tracking_url && (
						<AppButton
							id={'order-details-track-button'}
							className={'btn btn-secondary'}
							text={'Track Order'}
							icon={'treasure-map-line'}
							iconRight={false}
							onClick={() => window.open(order.order_tracking_details.tracking_url)}
						/>
					)}
					<AppButton
						id={'order-details-print-button'}
						className={'btn btn-secondary'}
						text={'Print Receipt'}
						icon={'printer-line'}
						iconRight={false}
						onClick={printReceipt}
						loading={loading}
					/>
				</div>
			</div>

			<div className={'flex flex-1 flex-col md:flex-row'}>
				<p className={'flex ttg-text-sm text-gray-800'}>
					<span>Ordered on</span>&nbsp;
					<span>{order.date_purchased}</span>&nbsp;
					<span>|</span>&nbsp;
					<span>{`Order #${order.id}`}</span>
				</p>
				<p className='flex flex-1 ttg-text-sm md:justify-end'>
					<span>{order.status}</span>&nbsp;
					<span>|</span>&nbsp;
					<span>{`Payment Method: ${order.payment_method}`}</span>
				</p>
			</div>

			{!!order.order_tracking_details && order.order_tracking_details.status && (
				<div className={'flex flex-1 flex-col md:flex-row mt-1'}>
					<p className={'flex ttg-text-sm text-gray-800'}>
						<span>{`Shipping Status: ${order.order_tracking_details.status}`}</span>
					</p>
				</div>
			)}

			{order.po_number && (
				<div className={'flex flex-1 flex-col md:flex-row mt-1'}>
					<p className={'flex ttg-text-sm text-gray-800'}> {`Purchase Order Number: ${order.po_number}`}</p>
					<p className='flex flex-1 ttg-text-sm md:justify-end'>{`Authorized by: ${order.po_authorizer}`}</p>
				</div>
			)}
		</div>
	);
};

export default DetailsHeader;
