import React, { useEffect } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { AppSlideOver, AppButton, AppInput, AppDropdown, AppPhoneNumber } from '../App';
import CountryService from '../../services/CountryService';
import UtilityService from '../../services/UtilityService';
import { useCountries } from '../../hooks/useCountries';
import { useZones } from '../../hooks/useZones';
import NotificationService from '../../services/NotificationService';
import AddressService from '../../services/AddressService';

const AddressForm = ({ closeModal, isOpen, noAddresses, address = {}, setAddresses, addressesState }) => {
	const [loading, setLoading] = React.useState(false);
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		control,
		reset,
	} = useForm();

	const { isDirty, isSubmitted } = useFormState({ control });

	const { loadingCountries, countries } = useCountries();

	const { loadingZones, getZones, zones } = useZones();

	const validateSelect = ({ value }) => {
		if (value) return true;
		return 'is required';
	};

	const onSave = (payload) => {
		if (payload?.zone_code) {
			payload.zone_code = payload.zone_code?.value ?? '';
			payload.zone_name = payload.zone_code?.label ?? '';
		}
		payload.country_code = payload.country_code?.value;
		const { addresses, alternateAddresses, primaryAddress } = addressesState;
		if (!!address.address_book_id) {
			setLoading(true);
			return AddressService.updateAddress({ ...address, ...payload })
				.then((response) => {
					setAddresses({
						...addressesState,
						addresses: addresses.map((x) =>
							x.address_book_id === address.address_book_id ? { ...x, ...response.data } : x
						),
						alternateAddresses: alternateAddresses.map((x) =>
							x.address_book_id === address.address_book_id ? { ...x, ...response.data } : x
						),
						primaryAddress: address.is_default ? { ...primaryAddress, ...response.data } : primaryAddress,
					});
					reset(payload);
					closeModal();
					NotificationService.success('', 'Address successfully updated!');
				})
				.catch((error) => NotificationService.error('', error))
				.finally(() => setLoading(false));
		}
		AddressService.addAddress(payload)
			.then((response) => {
				setAddresses({
					...addressesState,
					primaryAddress: noAddresses === 0 ? { ...primaryAddress, ...response.data } : primaryAddress,
					alternateAddresses:
						noAddresses === 0
							? alternateAddresses
							: [
									...alternateAddresses,
									{
										...response.data,
										address_book_id: response.data.address_id,
										is_default: 0,
									},
							  ],
					addresses: [
						...addresses,
						{
							...response.data,
							address_book_id: response.data.address_id,
							is_default: response.data.is_default,
						},
					],
				});
				reset(payload);
				closeModal();
				NotificationService.success('', 'Address successfully added!', { duration: 5000 });
			})
			.catch((error) => NotificationService.error('', error))
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		getZones(!address.address_book_id ? 'US' : address.country_code);

		reset({
			...address,
			country_code: CountryService.getByCode(countries, address.country_code),
			zone_code: {
				value: address.zone_code,
				label: address.zone_name || address.entry_state,
			},
		});
	}, [address, countries]);

	const renderStateInput = () => {
		if (zones && zones.length > 0) {
			return (
				<Controller
					control={control}
					name={'zone_code'}
					rules={{ validate: validateSelect }}
					render={({ field, formState }) => (
						<AppDropdown
							{...field}
							id={'ttg-address-state-input'}
							label={'State or province'}
							data-ttg-max-length={32}
							options={zones}
							loading={loadingZones}
							errors={formState.errors}
						/>
					)}
				/>
			);
		}
	};

	const trimInputValue = (name, e) => {
		setValue(name, e.target.value.trim(), {
			shouldValidate: isSubmitted,
			shouldDirty: true,
		});
	};

	return (
		<AppSlideOver isOpen={isOpen} closeModal={closeModal} onUnmount>
			<form
				className={'ttg-address-form'}
				onSubmit={handleSubmit(onSave)}
				autoComplete='autocomplete_off_hack_xfr4!k'
			>
				<div className='flex flex-col'>
					<h1 className='text-base text-gray-900 font-medium mb-4'>
						{!!address.address_book_id ? 'Edit address' : 'Add addresses'}
					</h1>
					{!address.address_book_id && (
						<p className='text-sm text-gray-500 font-normal mb-4'>
							You currently have <b>{noAddresses}</b> out of a total possible of <b>20</b> addresses in
							your Address Book.
						</p>
					)}
					<AppInput
						id={'ttg-address-first-name-input'}
						label={'First name'}
						{...register('entry_firstname', { required: true, minLength: 2, maxLength: 32 })}
						minLength={2}
						data-ttg-max-length={32}
						errors={errors}
						onBlur={(e) => trimInputValue('entry_firstname', e)}
					/>
					<AppInput
						id={'ttg-address-last-name-input'}
						label={'Last Name'}
						{...register('entry_lastname', { required: true, minLength: 2, maxLength: 32 })}
						minLength={2}
						data-ttg-max-length={32}
						errors={errors}
						onBlur={(e) => trimInputValue('entry_lastname', e)}
					/>
					<AppInput
						id={'ttg-address-company-input'}
						label={'Company'}
						{...register('entry_company', { maxLength: 64 })}
						data-ttg-max-length={64}
						errors={errors}
						onBlur={(e) => trimInputValue('entry_company', e)}
					/>
					<AppInput
						id={'ttg-address-street-input'}
						label={'Street'}
						{...register('entry_street_address', { required: true, minLength: 5, maxLength: 64 })}
						minLength={5}
						data-ttg-max-length={64}
						errors={errors}
						onBlur={(e) => trimInputValue('entry_street_address', e)}
					/>
					<AppInput
						id={'ttg-address-line-2-input'}
						label={'Address line 2'}
						{...register('entry_suburb', { maxLength: 64 })}
						data-ttg-max-length={64}
						errors={errors}
						onBlur={(e) => trimInputValue('entry_suburb', e)}
					/>

					<Controller
						control={control}
						name={'country_code'}
						rules={{ validate: validateSelect }}
						render={({ field, formState }) => (
							<AppDropdown
								{...field}
								id={'ttg-address-country-input'}
								label={'Country'}
								data-ttg-max-length={32}
								options={countries}
								loading={loadingCountries}
								errors={formState.errors}
								onChange={(e) => {
									getZones(e.value);
									setValue('zone_code', { label: '', value: '' });
									field.onChange(e);
								}}
							/>
						)}
					/>

					{renderStateInput()}
					<AppInput
						id={'ttg-address-city-input'}
						label={'City'}
						{...register('entry_city', { required: true, minLength: 3, maxLength: 32 })}
						minLength={3}
						data-ttg-max-length={32}
						errors={errors}
						onBlur={(e) => trimInputValue('entry_city', e)}
					/>
					<AppInput
						id={'ttg-address-postal-code-input'}
						label={'Zip or postal code'}
						{...register('entry_postcode', {
							required: true,
							minLength: 4,
							maxLength: 10,
							pattern: {
								value: UtilityService.zipCodeRegex,
								message: 'You must enter a valid Zip or postal code',
							},
						})}
						minLength={4}
						data-ttg-max-length={10}
						errors={errors}
						onBlur={(e) => trimInputValue('entry_postcode', e)}
					/>
					<AppPhoneNumber
						id={'ttg-address-phone-input'}
						{...register('entry_phone', {
							maxLength: 32,
							pattern: {
								value: UtilityService.phoneNumberRegex,
								message: 'You must enter a valid phone number',
							},
						})}
						name={'entry_phone'}
						control={control}
						data-ttg-max-length={32}
						errors={errors}
						onBlur={(e) => trimInputValue('entry_phone', e)}
					/>
				</div>
				<div className='flex pt-6 border-t-1 border-gray-200 '>
					<AppButton
						className={'btn btn-secondary w-1/2 mr-2'}
						type='button'
						text={'Cancel'}
						disabled={loading}
						onClick={closeModal}
						id={'ttg-address-cancel-btn'}
					/>
					<AppButton
						className={'btn btn-primary w-1/2'}
						type='submit'
						text={!!address.address_book_id ? 'Save' : 'Add address'}
						loading={loading}
						disabled={!isDirty}
						id={'ttg-address-submit-btn'}
					/>
				</div>
			</form>
		</AppSlideOver>
	);
};

export default AddressForm;
