import React from 'react';

const RadioButton = ({ id, onClick, checked, label, direction = 'flex-col', labelClass = 'text-gray-900' }) => {
	return (
		<div id={id} className={`flex flex-1 hover:bg-gray-50 cursor-pointer p-2 ${direction}`} onClick={onClick}>
			<div
				className={`flex w-6 h-6 rounded-full ${
					checked ? 'border-4 border-rose-500' : 'border-2 border-gray-300'
				}`}
			/>
			<p className={labelClass} dangerouslySetInnerHTML={{ __html: label }}></p>
		</div>
	);
};

export default RadioButton;
