import React from 'react';
import UtilityService from '../../services/UtilityService';
import { AppIcon } from './index';
import dayjs from 'dayjs';

const MonthNavigator = ({ date, onChange }) => {
	const onNavigate = (val) => {
		const newDate = dayjs(date).add(val, 'month');
		onChange(newDate);
	};

	return (
		<div className={'flex flex-1 items-center'}>
			<p className={'flex flex-1 ttg-text-lg text-gray-800 font-medium'}>
				{UtilityService.formatDate(date, 'MMMM')}
			</p>
			<div className={'flex items-center'}>
				<AppIcon
					id={'ttg-month-navigator-prev'}
					icon={'arrow-left-s-line'}
					className={'text-2xl text-gray-400 cursor-pointer'}
					onClick={() => onNavigate(-1)}
				/>
				<AppIcon
					id={'ttg-month-navigator-next'}
					icon={'arrow-right-s-line'}
					className={'text-2xl text-gray-400 cursor-pointer'}
					onClick={() => onNavigate(1)}
				/>
			</div>
		</div>
	);
};

export default MonthNavigator;
