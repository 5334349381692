import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UtilityService from '../../services/UtilityService';
import { AccountLayout } from '../../components/Layout';
import { AppBreadcrumbs, AppIcon, AppLoading, AppVideoPlayer } from '../../components/App';
import RouteNames from '../../router/RouteNames';
import VideoService from '../../services/VideoService';
import { VideoDetailsSection, VideoStartSubscriptionModal } from '../../components/Video';
import dayjs from 'dayjs';

const Details = () => {
	const playerRef = React.useRef('player');

	const [canPlay, setCanPlay] = React.useState(false);
	const [isStartingSub, setIsStartingSub] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [details, setDetails] = React.useState({ started: null });

	const location = useLocation();
	const navigate = useNavigate();

	const _getVideoDetails = React.useCallback(() => {
		const { id } = UtilityService.getQueryParams(location.search);
		setLoading(true);
		VideoService.getDetails(id)
			.then((response) => {
				setDetails(response);
			})
			.catch(() => {
				navigate(RouteNames.VideoList, { replace: true });
			})
			.finally(() => setLoading(false));
	}, [location, navigate]);

	React.useEffect(() => {
		_getVideoDetails();
	}, [_getVideoDetails]);

	const stopVideo = () => {
		playerRef.current.player.player.stop();
	};

	const playVideo = () => {
		playerRef.current.player.player.play();
	};

	React.useEffect(() => {
		setCanPlay(!!details.started);
	}, [details.started]);

	const onPlay = async () => {
		if (!details.started) {
			setCanPlay(false);
			return stopVideo();
		}
		await VideoService.audit(details.id, 'play');
	};

	const onPause = async () => {
		if (!!details.started) {
			await VideoService.audit(details.id, 'pause');
		}
	};

	if (loading || !details.id) {
		return <AppLoading />;
	}

	return (
		<AccountLayout loading={loading} sidebar={false}>
			<div className={'mx-4 mt-4 -mb-4'}>
				<AppBreadcrumbs items={[{ name: 'Video', to: RouteNames.VideoList }, { name: 'Video Details' }]} />
			</div>

			<div className='ttg-account-section flex-col'>
				<h1 className={'ttg-text-2xl text-gray-900 mb-4'}>{details.title}</h1>
				<div className={'bg-gray-900 mb-4'}>
					<AppVideoPlayer
						url={`https://player.vimeo.com/video/${details.vimeo_id}`}
						height={'436px'}
						loadingClass={'text-white'}
						controls={true}
						canPlay={canPlay}
						ref={playerRef}
						onPlay={onPlay}
						onPause={onPause}
						onClick={() => {
							setIsStartingSub(true);
						}}
					/>
				</div>

				<VideoDetailsSection title={'Description'}>
					<p className={'ttg-text-sm text-gray-800'}>{details.description}</p>
				</VideoDetailsSection>

				<VideoDetailsSection title={'Streaming video downloads'}>
					{details.downloads &&
						details.downloads.map((download) => {
							return (
								<div
									id={'ttg-video-details-download-file'}
									key={download.file}
									className={'flex items-center cursor-pointer text-gray-800'}
									onClick={() => {
										window.open(VideoService.getFile(download.file), '_blank');
									}}
								>
									<div className='flex items-center hover:text-rose-600 border-b-1 border-white hover:border-rose-600'>
										<AppIcon icon={'file-pdf-line'} className={'pr-3'} />
										<span
											className={'ttg-text-sm'}
											dangerouslySetInnerHTML={{ __html: download.name }}
										/>
									</div>
								</div>
							);
						})}
				</VideoDetailsSection>

				<VideoDetailsSection title={'About this video'}>
					<p className={'ttg-text-sm text-gray-900 mb-2'}>
						Purchased: {UtilityService.formatDate(details.created, 'MMMM DD, YYYY')}
					</p>
					{!!details.started ? (
						<p className={'ttg-text-sm text-gray-900 mb-2'}>
							Initially Played: {UtilityService.formatDate(details.started, 'MMMM DD YYYY hh:mm A')}
						</p>
					) : (
						<p className='ttg-text-sm text-gray-900 mb-2'>This subscription hasn't started yet</p>
					)}
					{!!details.expires && (
						<p className={'ttg-text-sm text-gray-900 mb-2'}>
							Expire: {UtilityService.formatDate(details.expires, 'MMMM DD YYYY hh:mm A')}
						</p>
					)}
				</VideoDetailsSection>

				<VideoStartSubscriptionModal
					isOpen={isStartingSub}
					onCloseModal={() => setIsStartingSub(false)}
					video_id={details.id}
					onStartSuccess={() => {
						setIsStartingSub(false);
						playVideo();
					}}
					text={
						<p>
							The subscription for this video is time-limited. It will only be available for{' '}
							<span className={'font-semibold'}>{details.subscription_length}</span> following the initial
							viewing. <br />
							<br /> Are you sure you wish to start the subscription period for this video as of{' '}
							<span className={'font-semibold'}>
								{UtilityService.formatDate(
									dayjs().format('YYYY-MM-DD HH:mm:ss'),
									'MMMM DD YYYY, hh:mm A'
								)}
							</span>
							?
						</p>
					}
					setDetails={setDetails}
				/>
			</div>
		</AccountLayout>
	);
};

export default Details;
