export const assessmentText = {
	title: 'Ideal Team Player Self-Assessment',
	subtitle:
		'Take this quick self-assessment based on Patrick Lencioni’s new book, The Ideal Team Player, and see how well you embody the three essential virtues that make some people better team players than others.',
	cta: 'Take the Assessment',
	instructions:
		'Take this assessment to evaluate yourself relative to the three virtues of an ideal team player. Use the scale below to indicate how each statement applies to how you think your teammates may see you and your actions on the team.',
};

export const surveyText = {
	title: 'Organizational Health Survey',
	subtitle:
		"Take this quick organizational health survey based on Patrick Lencioni's best-selling book, The Advantage: Why Organizational Health Trumps Everything Else in Business, and get a snapshot of your organization's overall health, as well as advice on how to start addressing this critical question.",
	cta: 'Take the Survey',
	instructions:
		"Use the scale below to indicate how each statement applies to your organization. Please use the entire scale to represent your most accurate response, and be as honest as possible. While it is important to be thoughtful, don't agonize over each response. The survey should not take more than 5 minutes.",
};
