import React from 'react';
import { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';

import Router from './router';
import UtilityService from './services/UtilityService';
import config from './constants/config';
import { AssessmentContextLayout } from './providers/AssessmentProvider';
import { NotificationContextLayout } from './providers/NotificationProvider';
import { CustomerContextLayout } from './providers/CustomerProvider';

const App = ({ ...props }) => {
	React.useEffect(() => {
		const wasSet = UtilityService.setCookieIfPresent(window.location);
		if (wasSet) {
			window.open(window.location.href.split('?')[0], '_self');
		}
	}, []);

	React.useEffect(() => {
		document.addEventListener('click', UtilityService.updateAuthCookieExpiration);
		return () => document.removeEventListener('click', UtilityService.updateAuthCookieExpiration);
	}, []);

	React.useEffect(() => {
		if (!Cookies.get('checkout_application_id')) {
			Cookies.set('checkout_application_id', config.appId);
		}
	}, []);

	return (
		<>
			<Toaster
				position='top-right'
				toastOptions={{
					className: 'ttg-toaster',
					success: {
						duration: 5000,
					},
				}}
				containerStyle={{
					top: 50,
				}}
			/>
			<NotificationContextLayout>
				<CustomerContextLayout>
					<AssessmentContextLayout>
						<Router {...props} />
					</AssessmentContextLayout>
				</CustomerContextLayout>
			</NotificationContextLayout>
		</>
	);
};

export default App;