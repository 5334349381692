import React from 'react';
import { AppButton, AppModalComponent } from '../../App';
import LicenseService from '../../../services/LicenseService';
import { useCustomer } from '../../../providers/CustomerProvider';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const CancelTransfer = ({ transfer, isOpen, onClose }) => {
	const { setLicenses } = useCustomer();
	const [cancelTransferLoading, setCancelTransferLoading] = React.useState(false);

	const _cancel = () => {
		setCancelTransferLoading(true);
		LicenseService.cancelTransfer({id: transfer.id})
			.then(() => {
				setLicenses((prev) => ({
					...prev,
					licenses: (prev.licenses -= -1 * transfer.licenses),
					used_licenses: (prev.used_licenses += -1 * transfer.licenses),
				}));
				onClose();
			})
			.finally(() => setCancelTransferLoading(false));
	};

	return (
		<AppModalComponent isOpen={isOpen} closeModal={onClose} style={customStyles}>
			<div className='bg-white p-6' style={{ maxWidth: '612px' }}>
				<div id='ttg-transfer-licenses-prompting-screen' className='flex flex-1 flex-col ml-4'>
					<h1 className={'ttg-text-lg text-gray-900 font-medium mb-2'}>Cancel license transfer</h1>
					<p className={'ttg-text-sm text-gray-500 mb-4'}>
						Are you sure you want to cancel transferring{' '}
						<span className='font-medium'>{transfer.licenses}</span> licenses to {transfer.main_email}?
					</p>
					<div className={'flex justify-end'}>
						<AppButton
							id={'ttg-cancel-transfer-no-button'}
							text={'No'}
							className={'btn btn-secondary mr-4'}
							onClick={onClose}
							loading={cancelTransferLoading}
						/>
						<AppButton
							id={'ttg-cancel-transfer-yes-button'}
							text={'Yes'}
							onClick={_cancel}
							loading={cancelTransferLoading}
						/>
					</div>
				</div>
			</div>
		</AppModalComponent>
	);
};

export default CancelTransfer;
