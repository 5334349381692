import config from '../constants/config';

export const useSSOParams = {
	linkedinClientId: config.linkedinClientId,
	linkedinRedirectUri: config.linkedinRedirectUri,
	allowedAuthProviders: config.allowedAuthProviders,
	googleClientId: config.googleClientId,
	microsoftClientId: config.microsoftClientId,
	microsoftRedirectUri: config.microsoftRedirectUri,
	termsOfServiceUrl: `${config.ttg_url}/terms-of-service/`,
	privacyUrl: `${config.ttg_url}/privacy/`,
	dataPrivacyUrl: `${config.ttg_url}/data-privacy/`,
	appId: config.appId,
	apiEndpoint: config.ttg_url,
	redirectPaths: [`/login/${config.AUTH_PROVIDERS.LINKEDIN}`, `/login/${config.AUTH_PROVIDERS.MICROSOFT}`],
};
