import React from 'react';
import Tabs from './Tabs';
import NotificationsList from './NotificationsList';
import NoData from './NoData';
import TtgTeamNotificationsList from './TtgTeamNotificationsList';
import NotificationService from '../../services/NotificationService';
import { AppIcon } from '../App';
import UtilityService from '../../services/UtilityService';
import { useNotifications } from '../../providers/NotificationProvider';

const TYPES = {
	today: 'today',
	older: 'older',
	ttg: 'ttg',
};

const Notifications = ({ toggleWidgetVisibility }) => {
	const [activeTab, setActiveTab] = React.useState(0);
	const [markingAsRead, setMarkingAsRead] = React.useState(false);
	const { notifications, setNotifications } = useNotifications();
	const { loading, today, later, ttg, unreadLocal, unreadGlobal, unreadAll } = notifications;

	const _setToday = (data) => setNotifications((prev) => ({ ...prev, today: data }));
	const _setLater = (data) => setNotifications((prev) => ({ ...prev, later: data }));
	const _setTtg = (data) => setNotifications((prev) => ({ ...prev, ttg: data }));
	const _setUnreadLocal = (data) => setNotifications((prev) => ({ ...prev, unreadLocal: data }));
	const _setUnreadGlobal = (data) => setNotifications((prev) => ({ ...prev, unreadGlobal: data }));
	const _setUnreadAll = (data) => setNotifications((prev) => ({ ...prev, unreadAll: data }));

	const onStatusChange = (notification, callback, type, forceRead) => {
		const _today = UtilityService.clone([...today]);
		const _later = UtilityService.clone([...later]);
		const _ttg = UtilityService.clone([...ttg]);
		if (type === TYPES.today) {
			const i = _today.findIndex(({ id }) => notification.id === id);
			_today[i].read = forceRead !== undefined ? forceRead : !_today[i].read;
			callback(_today);
		} else if (type === TYPES.older) {
			const i = _later.findIndex(({ id }) => notification.id === id);
			_later[i].read = forceRead !== undefined ? forceRead : !_later[i].read;
			callback(_later);
		} else {
			const i = _ttg.findIndex(({ id }) => notification.id === id);
			_ttg[i].read = !_ttg[i].read;
			callback(_ttg);
		}
		const unReadCount = [..._today, ..._later, ..._ttg].filter(({ read }) => !read).length;
		const unreadLocalCount = [..._today, ..._later, ..._ttg].filter(
			({ type, read }) => type === 'local' && !read
		).length;
		const unreadGlobalCount = [..._today, ..._later, ..._ttg].filter(
			({ type, read }) => type === 'global' && !read
		).length;

		_setUnreadAll(unReadCount);
		_setUnreadLocal(unreadLocalCount);
		_setUnreadGlobal(unreadGlobalCount);
	};

	const markAllAsRead = () => {
		setMarkingAsRead(true);
		NotificationService.markAllAsRead().then(
			() => {
				setMarkingAsRead(false);
				_setToday(NotificationService.setNotificationsAsRead(today));
				_setLater(NotificationService.setNotificationsAsRead(later));
				_setTtg(NotificationService.setNotificationsAsRead(ttg));
				_setUnreadAll(0);
				_setUnreadLocal(0);
				_setUnreadGlobal(0);
			},
			() => {
				setMarkingAsRead(false);
			}
		);
	};

	const renderLoading = (
		<div id={'ttg-notifications-loader'} className={'flex items-center justify-center'}>
			<AppIcon icon={'loading'} className={'animate-spin'} />
		</div>
	);

	const renderMarkAllAsRead = () => {
		if (markingAsRead) {
			return renderLoading;
		}
		if (unreadAll > 0) {
			return (
				<p
					id={'ttg-notifications-mark-all-as-read'}
					onClick={markAllAsRead}
					className='flex text-gray-600 cursor-pointer hover:underline'
				>
					Mark all as read
				</p>
			);
		}
		return null;
	};

	const renderContent = () => {
		if (activeTab === 0) {
			return (
				<div className={'mx-4'}>
					<div className={'flex justify-end my-4'}>{renderMarkAllAsRead()}</div>
					<TtgTeamNotificationsList
						notifications={ttg}
						onStatusChange={(notification) => onStatusChange(notification, _setTtg, TYPES.ttg)}
					/>
					<div className='flex flex-1 border-b-2 border-gray-300 mt-4' />
					<div className='flex items-center justify-center mt-4 mb-4'>{renderFooter()}</div>
				</div>
			);
		}
		if (today.length + later.length === 0) {
			if (loading) {
				return <div className='my-4'>{renderLoading}</div>;
			}
			return (
				<NoData
					icon={'notification-4-line'}
					message={'You have no notifications from the last days.'}
					className={'my-4'}
				/>
			);
		}
		return (
			<div className={'mx-4'}>
				{today.length > 0 && (
					<div className={`flex my-4 ${today.length === 0 ? 'justify-end' : ''}`}>
						<p className='flex flex-1 text-gray-500 font-medium'>TODAY</p>
						{renderMarkAllAsRead()}
					</div>
				)}
				<NotificationsList
					notifications={today}
					toggleWidgetVisibility={toggleWidgetVisibility}
					onStatusChange={(notification, forceRead) =>
						onStatusChange(notification, _setToday, TYPES.today, forceRead)
					}
				/>
				{later.length > 0 && (
					<div className={'flex my-4'}>
						<p className='flex flex-1 text-gray-600 font-medium'>OLDER</p>
						{today.length === 0 && renderMarkAllAsRead()}
					</div>
				)}
				<NotificationsList
					notifications={later}
					toggleWidgetVisibility={toggleWidgetVisibility}
					onStatusChange={(notification, forceRead) =>
						onStatusChange(notification, _setLater, TYPES.older, forceRead)
					}
				/>
				<div className='flex flex-1 border-b-2 border-gray-300 mt-4' />
				<div className='flex items-center justify-center mt-4 mb-4'>{renderFooter()}</div>
			</div>
		);
	};

	const renderFooter = () => {
		if (loading) {
			return renderLoading;
		}

		return <NoData icon={'flag-line'} message={"That's all of your notifications from the last days."} />;
	};

	return (
		<div>
			<div className={'pt-4 px-4'}>
				<p className={'text-gray-800 text-lg mb-6 font-medium block'}>Notifications</p>
				<Tabs
					active={activeTab}
					tabs={[
						{ title: 'Table Group Team', unread: unreadGlobal },
						{ title: 'Assessments', unread: unreadLocal },
					]}
					onClick={setActiveTab}
				/>
			</div>

			<div className='scrollable-content'>{renderContent()}</div>
		</div>
	);
};

export default Notifications;
