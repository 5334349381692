import React from 'react';

const EditSection = ({ containerClassName, label, children }) => {
	return (
		<div className={`flex flex-col md:flex-row ${containerClassName}`}>
			<p className={'flex ttg-text-sm text-gray-700 font-medium w-40 mb-4'}>{label}</p>
			<div className={'flex flex-1 flex-col'}>{children}</div>
		</div>
	);
};

export default EditSection;
