import config from './constants/config';

const cookieYesScriptURL = `https://cdn-cookieyes.com/client_data/${config.cookieyes_key}/script.js`;

const cookieYesScript = document.createElement('script');
cookieYesScript.id = 'cookieyes';
cookieYesScript.type = 'text/javascript';
cookieYesScript.src = cookieYesScriptURL;

document.head.appendChild(cookieYesScript);
