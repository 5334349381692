import Images from '../../assets/Images';
import UtilityService from '../../services/UtilityService';

const Intro = ({ first_name, last_name }) => {
	const date = UtilityService.formatNow('MMMM D, YYYY');

	return (
		<div className={'rounded-md bg-gray-200 mt-4 p-5 flex-wrap flex-col shadow-xl'}>
			<div className={'inline-flex'}>
				<img src={Images.itp_assessment.book} className={`w-4/12 py-10 mx-0 px-0`} alt={'itp-book'} />
				<div className={'py-10 mt-24 px-5 flex-row uppercase survey-font'}>
					<div className={'ttg-text-2xl text-red-900 font-bold'}>Ideal team player:</div>
					<div className={'ttg-text-2xl text-red-900 font-bold '}>Self-Assessment Report</div>
				</div>
			</div>
			<div className={'p-5 mt-8 inline-flex'}>
				<div className={'flex-row w-8/12 mt-5 survey-font'}>
					<div className={'ttg-text-xl text-gray-500 font-medium'}>
						{first_name} {last_name}
					</div>
					<div className={'ttg-text-xl text-gray-500 font-medium my-8 w-56'}>{date}</div>
				</div>
				<img src={Images.itp_assessment.venSmall} alt={'ven'} className={`w-4/12 ml-44`}></img>
			</div>
		</div>
	);
};

export default Intro;
