import React from 'react';
import { AppButton, AppModalComponent } from '../App';
import VideoService from '../../services/VideoService';
import dayjs from 'dayjs';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const StartSubscriptionModal = ({ isOpen, text, onStartSuccess, onCloseModal, video_id, setDetails }) => {
	const [loading, setLoading] = React.useState(false);

	const confirm = () => {
		setLoading(true);
		VideoService.startSubscription(video_id)
			.then(() => {
				setDetails((prev) => ({ ...prev, started: dayjs().toDate() }));
				onStartSuccess();
			})
			.finally(() => setLoading(false));
	};

	return (
		<AppModalComponent id={'ttg-delete-address-modal-id'} isOpen={isOpen} style={customStyles}>
			<div className='bg-white p-6' style={{ maxWidth: '512px' }}>
				<h1 className={'ttg-text-lg text-gray-900 font-medium'}>Start Streaming Video Subscription?</h1>
				<div className={'ttg-text-sm text-gray-500 mt-2 mb-4'}>{text}</div>
				<div className='flex justify-end'>
					<AppButton
						className={'btn btn-secondary mr-4'}
						text={'No'}
						onClick={() => onCloseModal()}
						id={'ttg-do-not-start-video-subscription-btn'}
						loading={loading}
					/>
					<AppButton
						className={'btn btn-primary'}
						text={'Yes'}
						onClick={() => confirm()}
						id={'ttg-start-video-subscription-btn'}
						loading={loading}
					/>
				</div>
			</div>
		</AppModalComponent>
	);
};

export default StartSubscriptionModal;
