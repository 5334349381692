import React from 'react';
import { AppButton, AppIcon } from '../../App';
import { AccountLayout } from '../../Layout';

import AssessmentService from '../../../services/AssessmentService';

import { useCaptchaProvider } from '../../../providers/CaptchaProvider';

const Request = ({ nextStep, goToStep, email }) => {
	const { sendAction, loading } = useCaptchaProvider();

	const sendEmail = async () =>
		sendAction((captcha) => AssessmentService.sendFindAssessmentEmail({ email, captcha })).then(
			(response) => response?.data && nextStep()
		);

	return (
		<AccountLayout sidebar={false}>
			<div className={'ttg-find-assessment-step'}>
				<div className={'ttg-find-assessment-step-content'}>
					<AppIcon icon={'file-shield-2-line'} className={'ttg-find-assessment-request-icon'} />
					<span className={'ttg-find-assessment-step-title'}>The Five Dysfunctions of a Team Assessment</span>

					<span className={'ttg-find-assessment-step-subtitle'}>
						<p>We have located the team(s) of which you are a member.</p>
						<p>
							Due to privacy laws, we need to email you a link that will provide you access to the
							complete list of teams you are a member of.
						</p>
						<p>
							Please click the button below to request the email to be sent to the address you entered on
							the prior screen.
						</p>
					</span>

					<div className={'ttg-find-assessment-step-actions'}>
						<AppButton
							text={'Request the list of your assessments'}
							loading={loading}
							onClick={() => sendEmail()}
						/>
						<a onClick={() => goToStep(1)} className={'ttg-find-assessment-step-actions-back'}>
							{' '}
							{`< Back to Find Your Assessments`}
						</a>
					</div>
				</div>
			</div>
		</AccountLayout>
	);
};

export default Request;
