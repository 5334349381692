import React from 'react';
import { AppIcon } from '.';

const Alert = ({ title, children }) => {
	return (
		<div className='app-alert flex items-row p-4 pl-2 bg-red-50'>
			<div className='flex mx-2 flex items-center justify-center min-w-5 min-h-5 max-w-5 max-h-5 rounded-full bg-red-500 mt-1'>
				<AppIcon icon={'close-line'} className={'text-white'} />
			</div>
			<div className='flex flex-col'>
				{!!title && <span className='text-sm font-medium text-yellow-900 mb-2'>{title}</span>}
				<div className='text-sm font-normal text-yellow-700'>{children}</div>
			</div>
		</div>
	);
};

export default Alert;
