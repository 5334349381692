import React from 'react';
import { AppButton } from '../../components/App';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
	const navigate = useNavigate();

	return (
		<div className='container mx-auto p-6 text-center'>
			<h2 className='ttg-text-5xl text-gray-800 font-semibold mt-12 text-center'>
				4<span className={'text-rose-500'}>0</span>4
			</h2>
			<h2 className='text-3xl text-gray-800 text-primary font-semibold my-3 text-center'>Page Not Found</h2>

			<p className='ttg-text-lg text-gray-600 font-normal mb-4 mx-auto text-center max-w-3xl'>
				We're sorry. The page you requested cannot be found. The address may have been typed incorrectly or the
				page may have been moved. If the problem persists, please contact our office at{' '}
				<a className={'underline text-rose-500'} href='tel:925.299.9700'>
					925.299.9700
				</a>{' '}
				or via email to{' '}
				<a className={'underline text-rose-500'} href='mailto:support@tablegroup.com'>
					support@tablegroup.com
				</a>
			</p>

			<AppButton text={'Go Back'} onClick={() => navigate(-1)} />
		</div>
	);
};

export default NotFound;
