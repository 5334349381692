import React from 'react';
import { AssessmentLayout } from '../../components/Layout';
import { AppButton, AppIcon, AppTable, AppTooltip } from '../../components/App';
import UtilityService from '../../services/UtilityService';
import ListFilters from '../../components/Assessments/Overview/ListFilters';
import AssessmentsInfo from '../../components/Assessments/Overview/AssessmentsInfo';
import { useLocation, useNavigate } from 'react-router-dom';
import NotificationService from '../../services/NotificationService';
import RouteNames from '../../router/RouteNames';
import ConfirmIndividualAssessmentModal from '../../components/Assessments/Setup/ConfirmIndividualAssessmentModal';
import NotEnoughLicensesModal from '../../components/Assessments/Setup/NotEnoughLicensesModal';
import { Link } from 'react-router-dom';
import AssessmentService from '../../services/AssessmentService';
import { useAssessments } from '../../providers/AssessmentProvider';
import { useCustomer } from '../../providers/CustomerProvider';

const initialFilters = {
	having: '',
	sort_key: 'created',
	sort_dir: 'desc',
	page: 0,
	limit: 10,
};

const List = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const [filters, setFilters] = React.useState(initialFilters);
	const [confirmIndividualModal, setConfirmIndividualModal] = React.useState(false);
	const [hasEnoughLicenses, setHasEnoughLicenses] = React.useState(false);

	const { setSetupAssessment, loading, setLoading } = useAssessments();
	const [assessments, setAssessments] = React.useState([]);
	const [meta, setMeta] = React.useState({});
	const [assessmentCounts, setAssessmentCounts] = React.useState({});
	const { licenses: { licenses }, customer } = useCustomer();

	const _getAssessments = React.useCallback(() => {
		setLoading(true);
		AssessmentService.getAssessments(filters)
			.then(({ meta, assessments: newAssessments }) => {
				setAssessments((prev) => (meta.page === 0 ? newAssessments : [...prev, ...newAssessments]));
				setMeta(meta);
			})
			.finally(() => setLoading(false));
	}, [filters]);

	React.useEffect( () => {
		AssessmentService.getAssessmentCounts().then(({ assessment_counts, assessment_status_count }) => {
			setAssessmentCounts(AssessmentService.setAssessmentCounts(assessment_counts, assessment_status_count));
		});
	}, []);

	React.useEffect(() => {
		_getAssessments();
	}, [_getAssessments]);

	React.useEffect(() => {
		setSetupAssessment(AssessmentService.resetTeamSetup(customer));
		if (location.state && location.state.savedForLater) {
			NotificationService.custom(
				'Assessment saved for later',
				location.state.individual
					? 'You have an unfinished individual assessment setup.'
					: 'You have an unfinished team assessment setup.',
				'save-line'
			);
			navigate(location.pathname, { replace: true });
		}
	}, []);

	const onSort = (col, sort_dir) => {
		const newFilters = {
			...filters,
			sort_key: col.key,
			sort_dir: sort_dir,
			page: 0,
		};
		setFilters(newFilters);
	};

	return (
		<AssessmentLayout>
			<div className='assessments-overview-header'>
				<div className='flex items-start'>
					<h1 className='title'>Assessments Overview</h1>
					<AppIcon
						className={'text-xl text-rose-500 cursor-pointer ml-1 mt-1'}
						icon={'question-line'}
						data-for={`assessments-title-tooltip`}
						data-tip
					/>
					<AppTooltip id={`assessments-title-tooltip`} placement={'right'}>
						Below is a listing of all assessments for your organization as well as a brief status summary.
						<br />
						To view the details for an assessment click its team or member name.
					</AppTooltip>
				</div>
			</div>
			<div className='flex flex-1 gap-4 mx-4 flex-col-reverse lg:flex-row mt-4'>
				<div className='flex flex-1 shadow-sm bg-white p-6 rounded-sm xl:w-3/4 lg:w-2/3'>
					<AssessmentsInfo
						filters={filters}
						setFilters={(key, value) => {
							UtilityService.updateStateMethod(setFilters, key, value);
						}}
						assessmentCounts={assessmentCounts}
					/>
				</div>
				<div className='flex flex-col shadow-sm bg-white p-6 rounded-sm xl:w-1/4 lg:w-1/3'>
					<div className='flex flex-1 flex-col'>
						<AppButton
							className={'btn btn-primary large'}
							text={'Team Assessment Setup'}
							onClick={() => {
								navigate(RouteNames.TeamAssessmentSetup);
							}}
							id={'ttg-team-setup-header-btn'}
							disabled={licenses < 2}
						/>
						<AppButton
							className={'btn btn-secondary my-4'}
							text={'Individual Assessment'}
							onClick={() => setConfirmIndividualModal(true)}
							id={'ttg-individual-setup-action'}
							disabled={licenses < 1}
						/>
					</div>
					<p className={'ttg-text-sm text-gray-900 text-center'}>
						You have{' '}
						<span className={'font-medium'} id={'ttg-assessments-header-licenses'}>
							{licenses}
						</span>{' '}
						licenses left in your account.
					</p>
				</div>
			</div>
			<div className='ttg-assessment-section flex-col assessments-overview-main-table'>
				<AppTable
					identifier='team_id'
					columns={[
						{
							name: 'Team Or member name',
							key: 'name',
							customRender: 'renderName',
							sortable: true,
							identifier: true,
						},
						{ name: 'Company', key: 'company_name', sortable: true, customRender: 'renderCompany' },
						{ name: 'Date created', key: 'created', customRender: 'renderDate', sortable: true },
						{ name: 'Status', key: 'status', customRender: 'renderStatus', sortable: true },
					]}
					onItemClick={(item) => {
						if (item.setup_complete !== -1) {
							return navigate(`${RouteNames.AssessmentDetails}?id=${item.team_id}`);
						}

						if (item.team_type === 'team') {
							if (licenses > 1) {
								return navigate(`${RouteNames.TeamAssessmentSetup}?id=${item.team_id}`);
							}
							return setHasEnoughLicenses(true);
						}

						if (licenses > 0) {
							return navigate(`${RouteNames.IndividualAssessment}?id=${item.team_id}`);
						}
						setHasEnoughLicenses(true);
					}}
					renderName={(item) => {
						const getHref = () => {
							if (item.setup_complete !== -1) {
								return `${RouteNames.AssessmentDetails}?id=${item.team_id}`;
							}

							if (item.team_type === 'team') {
								if (licenses > 1) {
									return `${RouteNames.TeamAssessmentSetup}?id=${item.team_id}`;
								}
								return '';
							}

							if (licenses > 0) {
								return `${RouteNames.IndividualAssessment}?id=${item.team_id}`;
							}

							return '';
						};

						return (
							<div className='flex flex-row items-center'>
								<AppIcon
									icon={item.team_type === 'individual' ? 'user-fill' : 'team-fill'}
									className='text-2xl text-rose-500 mr-3'
								/>
								<div className='flex flex-col'>
									<Link
										className='text-gray-900 text-sm font-medium assessment-overview-field'
										to={getHref()}
										onClick={(e) => e.preventDefault()}
									>
										{item.name}
									</Link>
								</div>
							</div>
						);
					}}
				renderCompany={(item) => {
						return <p className='assessment-overview-field'>{item.company_name}</p>;
					}}
					renderDate={(item) => UtilityService.formatDate(item.created, 'MM/DD/YYYY | hh:mm A')}
					renderStatus={(item) => {
						return (
							<div className='font-medium'>
								{item.status === 'Scheduled'
									? `Scheduled for ${UtilityService.formatDate(
											item.scheduled_process_at,
											'MM/DD/YYYY | hh:mm A',
											true
									  )} ${item.scheduled_process_timezone}`
									: item.status}
							</div>
						);
					}}
					noDataIcon={licenses === 0 ? 'file-shield-2-line' : 'file-text-line'}
					noDataMessage={
						licenses === 0 && assessmentCounts.all === 0 ? (
							<p className='flex flex-col items-center text-center'>
								You have 0 licenses in your account.
								<br />
								Click the button below to get some licenses in order to create your first assessment.
								<AppButton
									className={'btn btn-primary my-4'}
									text={'Purchase Licenses'}
									icon={'share-box-fill'}
									onClick={() => window.open(RouteNames.OnlineTeamAssessment, '_self')}
									id={'ttg-team-setup-btn'}
								/>
							</p>
						) : licenses === 1 && assessmentCounts.all === 0 ? (
							<p className='flex flex-col items-center text-center'>
								You have 1 license in your account.
								<br />
								In order to create a team assessment you’ll need 2 or more licenses.
								<br />
								Click the button below to get some licenses in order to create your first assessment.
								<AppButton
									className={'btn btn-primary my-4'}
									text={'Purchase Licenses'}
									icon={'share-box-fill'}
									onClick={() => window.open(RouteNames.OnlineTeamAssessment, '_self')}
									id={'ttg-team-setup-btn'}
								/>
							</p>
						) : assessmentCounts.all === 0 ? (
							<p className='flex flex-col items-center text-center'>
								You don’t have any Assessments. <br />
								Create your first assessment by clicking the button below.
								<AppButton
									className={'btn btn-primary my-4'}
									text={'Team Assessment Setup'}
									icon={'team-line'}
									iconRight={false}
									onClick={() => navigate(RouteNames.TeamAssessmentSetup)}
									id={'ttg-team-setup-btn'}
								/>
							</p>
						) : (
							<p>There are no assessments.</p>
						)
					}
					isActionAvailable={(action, item) => {
						if (action === 'Retest') {
							return item.status === 'Complete' && item.version === 2 && item.team_type === 'team';
						}
						if (action === 'Continue Setup') {
							return item.setup_complete === -1;
						}
						if (action === 'View Details') {
							return item.setup_complete !== -1;
						}
						return true;
					}}
					actions={[
						{ label: 'Retest', action: 'onRetest' },
						{ label: 'View Details', action: 'onViewDetails' },
						{ label: 'Continue Setup', action: 'onContinueSetup' },
					]}
					actionTooltip={'Retest: Assess your team again and receive a Progress Report'}
					data={assessments}
					loading={loading}
					onRetest={(item) => {
						if (licenses > 1) {
							return navigate(`${RouteNames.TeamAssessmentSetup}?retest=${item.team_id}`);
						}
						setHasEnoughLicenses(true);
					}}
					onViewDetails={(item) => {
						navigate(`${RouteNames.AssessmentDetails}?id=${item.team_id}`);
					}}
					onContinueSetup={(item) => {
						if (item.team_type === 'team') {
							if (licenses > 1) {
								return navigate(`${RouteNames.TeamAssessmentSetup}?id=${item.team_id}`);
							}
							return setHasEnoughLicenses(true);
						}
						if (licenses > 0) {
							return navigate(`${RouteNames.IndividualAssessment}?id=${item.team_id}`);
						}
						setHasEnoughLicenses(true);
					}}

					filters={filters}
					setFilters={setFilters}
					assessmentCounts={assessmentCounts}
					renderFilters={ListFilters}
					canLoadMore={assessments.length < meta.totalResults}
					onLoadMore={() => {
						setFilters({
							...filters,
							page: filters.page + 1,
						});
					}}
					onSort={onSort}
				/>
			</div>
			<ConfirmIndividualAssessmentModal
				isOpen={confirmIndividualModal}
				onClose={() => setConfirmIndividualModal(false)}
			/>
			<NotEnoughLicensesModal isOpen={hasEnoughLicenses} onClose={() => setHasEnoughLicenses(false)} />
		</AssessmentLayout>
	);
};

export default List;
