import React from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { AppButton, AppDropdown, AppInput, AppLabel, AppRadioGroup } from '../App';
import RouteNames from '../../router/RouteNames';
import { assessmentText, surveyText } from '../../constants/SurveyConstants';
import UtilityService from '../../services/UtilityService';
import ReCaptcha from '../App/ReCaptcha';
import { cta } from './constants';
import CustomerCompanySizes from '../../constants/CompanySizes';
import CustomerBusinessRoles from '../../constants/CustomerBusinessRoles';
import CustomerIndustries from '../../constants/IndustryTypes';
import SurveyService from '../../services/SurveyService';
import { useSurveys } from '../../providers/SurveyProvider';

const SurveyForm = ({ surveyType, nextStep }) => {
	const { surveyDetails, setSurveyDetails } = useSurveys();
	const [optIn, setOptIn] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const captchaRef = React.useRef('captcha');

	React.useEffect(() => {
		setSurveyDetails((prev) => ({
			...prev,
			...(surveyType === 'survey' ? surveyText : assessmentText),
		}));
	}, []);

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		control,
		watch,
		trigger,
		setError,
	} = useForm({
		defaultValues: {
			...surveyDetails
		},
		reValidateMode: 'onSubmit',
		mode: 'onSubmit',
	});

	const { isSubmitted, isDirty } = useFormState({ control });
	const trimInputValue = (name, e) => {
		setValue(name, e.target.value.trim(), {
			shouldValidate: isSubmitted,
			shouldDirty: true,
		});
	};

	const companyWatch = watch('company');
	const roleWatch = watch('role');
	const companySizeWatch = watch('company_size');
	const industryWatch = watch('industry');
	const isCompanyFilled = companyWatch?.toString()?.length >= 2; // Company must have at least 2 characters
	const isRoleFilled = Object.keys(roleWatch || {}).length > 0;
	const isIndustryFilled = Object.keys(industryWatch || {}).length > 0;
	const isCompanySizeFilled = Object.keys(companySizeWatch || {}).length > 0;

	const controllerFields = {
		role: isRoleFilled,
		industry: isIndustryFilled,
		company_size: isCompanySizeFilled,
	};
	const validateFields = () => {
		// Rule 1: If Company is filled, validate all dependent fields
		if (isCompanyFilled) {
			let unfilledField = false;
			Object.entries(controllerFields).forEach(([key, value]) => {
				if (!value) {
					setError(key, { type: 'required', message: 'is required' });
					unfilledField = true;
				} else {
					clearErrors(key);
				}
			});
			return !unfilledField;
		}

		// Rule 2: If Company Size is filled, validate Company and Role
		if (isCompanySizeFilled) {
			let unfilledField = false;
			Object.entries(controllerFields).forEach(([key, value]) => {
				if (!value) {
					setError(key, { type: 'required', message: 'is required' });
					unfilledField = true;
				} else {
					clearErrors(key);
				}
			});
			return !unfilledField;
		}

		// Rule 3: If Industry is filled, validate Company, Role, and Company Size
		if (isIndustryFilled) {
			let unfilledField = false;
			Object.entries(controllerFields).forEach(([key, value]) => {
				if (!value) {
					setError(key, { type: 'required', message: 'is required' });
					unfilledField = true;
				} else {
					clearErrors(key);
				}
			});

			return !unfilledField;
		}
		return true;
	};

	const validateCompany = (companyValue) => {
		if (companyValue?.toString()?.length === 0 && (isRoleFilled || isIndustryFilled || isCompanySizeFilled)) {
			return 'is required';
		}

		if (companyValue?.toString()?.length < 2 && (isRoleFilled || isIndustryFilled || isCompanySizeFilled)) {
			return 'should be at least 2 characters';
		}
		validateFields();
	};

	const validateRole = (roleValue) => validateFields();
	const validateIndustry = (industryValue) => validateFields();
	const validateCompanySize = (companySizeValue) => validateFields();

	const submit = async (e) => {
		await trigger();
		const recaptchaValue = await captchaRef.current.getCaptchaValue();
		setLoading(true);
		setSurveyDetails((prev) => ({ ...prev, ...e, title: surveyDetails.title }));
		
		SurveyService.getSurvey({ ...e, captcha: recaptchaValue }, surveyType).then(({ data }) => {
			setSurveyDetails(prev => ({ ...prev, ...data }));
			nextStep();
		}).finally(() => {
			setLoading(false);
		});
	};

	const renderOptInLabel = () => (
		<div>
			I’d like to receive helpful content from{' '}
			<a className={'text-rose-500 hover:text-rose-600'} target='_blank' href='https://www.tablegroup.com/'>
				The Table Group
			</a>{' '}
			and{' '}
			<a className={'text-rose-500 hover:text-rose-600'} target='_blank' href='https://www.workinggenius.com/'>
				Working Genius
			</a>
		</div>
	);

	return (
		<div className='ttg-layout'>
			<div className={'ttg-survey'}>
				<div className={'ttg-text-2xl font-bold p-5 text-center'}>{surveyDetails?.title}</div>
				<div className={'leading-lg'}>{surveyType === 'survey' ? cta.survey : cta.assessment}</div>
				<div className={'ttg-text-xl font-bold pt-5 mt-5 text-center w-full'}>{surveyDetails?.cta}</div>
			</div>

			<form
				onSubmit={(event) => handleSubmit(submit)(event)}
				id='ttg-survey-form'
				autoComplete='autocomplete_off_hack_xfr4!k'
			>
				<div className='ttg-survey'>
					<div className={'mb-7'}>
						<AppInput
							id='ttg-survey-first-name'
							label='First Name'
							errors={errors}
							data-ttg-max-length={96}
							{...register('first_name', {
								validate: (value) => {
									if (value?.toString().length === 0) {
										return 'is required';
									}
									if (value?.toString().length < 2) {
										return 'should be at least 2 characters';
									}
									return true;
								},
							})}
							onBlur={(e) => trimInputValue('first_name', e)}
							minLength={2}
						/>
					</div>

					<div className={'my-7'}>
						<AppInput
							id='ttg-survey-last-name'
							label='Last Name'
							errors={errors}
							data-ttg-max-length={96}
							{...register('last_name', {
								validate: (value) => {
									if (value?.toString().length === 0) {
										return 'is required';
									}
									if (value?.toString().length < 2) {
										return 'should be at least 2 characters';
									}
									return true;
								},
							})}
							onBlur={(e) => trimInputValue('last_name', e)}
							minLength={2}
						/>
					</div>

					<div className={'mt-7 mb-0'}>
						<AppInput
							id='ttg-survey-email'
							label='Email address'
							errors={errors}
							data-ttg-max-length={96}
							{...register('email', {
								validate: (value) => {
									if (value?.toString().length === 0) {
										return 'is required';
									}
									if (!UtilityService.emailRegex.test(value)) {
										return 'You must enter an email address';
									}
									return true;
								},
							})}
							onBlur={(e) => trimInputValue('email', e)}
						/>
					</div>
					<div className={'mt-7 mb-0'}>
						<AppInput
							id='ttg-survey-company'
							label='Company Name'
							errors={errors}
							data-ttg-max-length={96}
							data-ttg-min-length={3}
							{...register('company', {
								validate: (value) => validateCompany(value),
							})}
							onBlur={(e) => {
								trimInputValue('company', e);
							}}
							minLength={3}
						/>
					</div>
					<div className={'mt-7 mb-0'}>
						<Controller
							control={control}
							name='role'
							{...register('role', {
								validate: (value) => validateRole(value),
							})}
							render={({ field, formState, fieldState }) => {
								if (isCompanyFilled && !isRoleFilled && formState.isSubmitted) {
									formState.errors.role = { type: 'validate', message: 'is required' };
								} else {
									delete formState.errors.role;
								}
								return (
									<AppDropdown
										{...field}
										id={'ttg-survey-role-input'}
										label={'Role'}
										options={CustomerBusinessRoles}
										errors={formState.errors}
										containerClassName={'w-full'}
										isSearchable={true}
									/>
								);
							}}
						/>
					</div>
					<div className={'mt-7 mb-0'}>
						<Controller
							control={control}
							name='industry'
							{...register('industry', {
								validate: (value) => validateIndustry(value),
							})}
							render={({ field, formState, fieldState }) => {
								if (isCompanyFilled && !isIndustryFilled && formState.isSubmitted) {
									formState.errors.industry = { type: 'validate', message: 'is required' };
								} else {
									delete formState.errors.industry;
								}
								return (
									<AppDropdown
										{...field}
										id={'ttg-survey-industry-input'}
										label={'Industry'}
										options={CustomerIndustries}
										errors={formState.errors}
										containerClassName={'w-full'}
										isSearchable={true}
									/>
								);
							}}
						/>
					</div>
					<div className={'mt-7 mb-0'}>
						<Controller
							control={control}
							name='company_size'
							{...register('company_size', {
								validate: (value) => validateCompanySize(value),
							})}
							render={({ field, formState, fieldState }) => {
								if (isCompanyFilled && !isCompanySizeFilled && formState.isSubmitted) {
									formState.errors.company_size = { type: 'validate', message: 'is required' };
								} else {
									delete formState.errors.company_size;
								}
								return (
									<AppDropdown
										{...field}
										id={'ttg-survey-company-size-input'}
										label={'Company Size'}
										options={CustomerCompanySizes}
										errors={formState.errors}
										containerClassName={'w-full'}
										isSearchable={true}
										onBlur={() => validateFields()}
									/>
								);
							}}
						/>
					</div>
					<div id={'ttg-survey-opt-in'}>
						<AppLabel label={renderOptInLabel()} hasError={!!errors['opt_in']} />

						<AppRadioGroup
							{...register('opt_in', {
								required: 'is required',
							})}
							name={'opt_in'}
							errors={errors}
							value={optIn}
							options={[
								{
									label: 'Yes',
									value: 1,
								},
								{
									label: 'No',
									value: 0,
								},
							]}
							onChange={(selectedOption) => {
								setOptIn(selectedOption);
								setValue('opt_in', selectedOption);
								clearErrors('opt_in');
							}}
						/>
					</div>

					<div className={'pt-5 pb-7'}>
						<AppButton
							type='submit'
							id='ttg-survey-button'
							text={'Submit'}
							loading={loading}
							className={'btn btn-primary uppercase md:w-auto h-10 box-border text-sm min-w-0 max-w-md'}
						/>
						<ReCaptcha ref={captchaRef} />
					</div>

					<div className='text-sm font-medium my-6' id={'ttg-reset-login-link'}>
						<span>
							Please note: We will not share your information with anyone. For more information see our
							<a href={RouteNames.Privacy} className='text-rose-500 ml-2' id={'ttg-reset-login-link'}>
								Privacy Policy
							</a>
						</span>
					</div>
				</div>
			</form>
		</div>
	);
};

export default SurveyForm;
