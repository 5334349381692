import React from 'react';

const ReadMore = ({ className, moreClassName, text, length = 150 }) => {
	const [isReadMore, setIsReadMore] = React.useState(true);

	return (
		<p className={className}>
			{isReadMore ? text.slice(0, length) : text}
			{isReadMore && text.length > length && (
				<span
					id={'ttg-read-more-toggle'}
					onClick={() => setIsReadMore(false)}
					className={`cursor-pointer ${moreClassName}`}
				>
					...[More]
				</span>
			)}
		</p>
	);
};

export default ReadMore;
