import React from 'react';
import { AppBreadcrumbs, AppButton, AppDropdownButton, AppNotice } from '../../App';
import RouteNames from '../../../router/RouteNames';
import UtilityService from '../../../services/UtilityService';
import EditTeamAssessmentSidebar from './EditTeamAssessmentSidebar';
import EditIndividualAssessmentSidebar from './EditIndividualAssessmentSidebar';
import ScheduleAssessmentSidebar from './ScheduleAssessmentSidebar';
import CancelScheduleAssessmentModal from './CancelScheduleAssessmentModal';
import EditScheduleDateSidebar from './EditScheduleDateSidebar';
import BulkConfirmDeleteActionModal from './BulkConfirmDeleteActionModal';
import HeaderTexts from './HeaderTexts';
import { useNavigate } from 'react-router-dom';
import NotEnoughLicensesModal from '../Setup/NotEnoughLicensesModal';
import AssessmentService from '../../../services/AssessmentService';
import { useCustomer } from '../../../providers/CustomerProvider';
import NotificationService from '../../../services/NotificationService';

const Header = ({ details }) => {
	const [isEditingTeam, setIsEditingTeam] = React.useState(false);
	const [isEditingIndividual, setIsEditingIndividual] = React.useState(false);
	const [isEditingSchedules, setIsEditingSchedules] = React.useState(false);
	const [isEditingScheduleDate, setIsEditingScheduleDate] = React.useState(false);
	const [isCancelingScheduleDate, setIsCancelingScheduleDate] = React.useState(false);
	const [isDeleting, setIsDeleting] = React.useState(false);
	const [shouldShowModalToPurchaseMore, setShouldShowModalToPurchaseMore] = React.useState(false);
	const [viewingReport, setViewingReport] = React.useState(false);
	const [viewingProgressReport, setViewingProgressReport] = React.useState(false);
	const [sendingReport, setSendingReport] = React.useState(false);
	const [sendingProgressReport, setSendingProgressReport] = React.useState(false);

	const navigate = useNavigate();

	const { customer, licenses: { licenses } } = useCustomer();

	const loadersProp = {
		viewingReport,
		viewingProgressReport,
		sendingReport,
		sendingProgressReport,
	};

	const onSchedule = () => {
		setIsEditingSchedules(true);
	};

	const onViewReport = () => {
		setViewingReport(true);
		AssessmentService.viewReport(details.assessment_data.team_id).finally(() => setViewingReport(false));
	};

	const onViewProgressReport = () => {
		setViewingProgressReport(true);
		AssessmentService.viewProgress(details.assessment_data.team_id).finally(() => setViewingProgressReport(false));
	};

	const onSendReport = () => {
		setSendingReport(true);
		AssessmentService.sendReport(details.assessment_data.team_id)
			.then(() => NotificationService.success('Assessment report sent successfully'))
			.finally(() => setSendingReport(false));
	};

	const onSendProgressReport = () => {
		setSendingProgressReport(true);
		AssessmentService.sendProgress(details.assessment_data.team_id).finally(() => setSendingProgressReport(false));
	};

	const onEdit = () => {
		if (details.isTeamAssessment) {
			return setIsEditingTeam(true);
		}
		setIsEditingIndividual(true);
	};

	const completedRosterEmails = React.useMemo(() => {
		return details?.rosters?.filter(({ status }) => status === 'Completed').map(({ email_address }) => email_address);
	}, [details]);

	const notCompletedRosterEmails = React.useMemo(() => {
		return details?.rosters?.filter(({ status }) => status !== 'Completed').map(({ email_address }) => email_address);
	}, [details]);

	const notCompletedIds = React.useMemo(() => {
		return details?.rosters?.filter(({ status }) => status !== 'Completed').map(({ person_id }) => person_id);
	}, [details]);

	const actions = React.useMemo(() => {
		const _actions = [];
		if (details.assessment_data?.status !== 'Complete') {
			_actions.push({
				id: 'ttg-assessment-details-schedule-button',
				text: 'Schedule Reminders',
				icon: 'notification-4-line',
				onClick: onSchedule,
			});
			_actions.push({
				id: 'ttg-assessment-details-edit-button',
				text: 'Edit Information',
				icon: 'pencil-line',
				onClick: onEdit,
			});
			_actions.push({
				id: 'ttg-assessment-details-delete-button',
				text: completedRosterEmails?.length > 0 ? 'End Assessment and Send Report' : 'Delete Assessment',
				icon: 'delete-bin-line',
				onClick: () => {
					setIsDeleting(true);
				},
			});
		} else {
			if (details.isRetest && details.assessment_data.version === details.retest_of.version) {
				_actions.push({
					id: 'ttg-assessment-details-view-report-button',
					text: 'View Progress Report',
					icon: 'file-pdf-line',
					onClick: onViewProgressReport,
					'data-load-prop': 'viewingProgressReport',
					disabled: !!details.assessment_data.disable_tar,
				});
				_actions.push({
					id: 'ttg-assessment-details-send-report-button',
					text: 'Send Progress Report',
					icon: 'mail-send-line',
					onClick: onSendProgressReport,
					'data-load-prop': 'sendingProgressReport',
					disabled: !!details.assessment_data.disable_tar,
				});
			}
			if (
				details.isTeamAssessment ||
				customer.customers_email_address.toLowerCase() === details.rosters[0]?.email_address.toLowerCase()
			) {
				_actions.push({
					id: 'ttg-assessment-details-view-assessment-report-button',
					text: 'View Assessment Report',
					icon: 'file-pdf-line',
					onClick: onViewReport,
					'data-load-prop': 'viewingReport',
					disabled: !!details.assessment_data.disable_tar,
				});
			}
			_actions.push({
				id: 'ttg-assessment-details-send-assessment-report-button',
				text: 'Send Assessment Report',
				icon: 'mail-send-line',
				onClick: onSendReport,
				'data-load-prop': 'sendingReport',
				disabled: !!details.assessment_data.disable_tar,
			});

			if (details.isTeamAssessment && details.assessment_data.version === 2) {
				_actions.push({
					id: 'ttg-assessment-details-schedule-reminder-button',
					text: 'Schedule a reminder to retest the team',
					icon: 'notification-4-line',
					onClick: () => {
						setIsEditingSchedules(true);
					},
				});

				_actions.push({
					id: 'ttg-assessment-details-retest-button',
					text: 'Retest this team assessment',
					icon: 'restart-line',
					onClick: () => {
						if (licenses >= 2) {
							return navigate(`${RouteNames.TeamAssessmentSetup}?retest=${details.assessment_data.team_id}`);
						}
						setShouldShowModalToPurchaseMore(true);
					},
				});
			}
		}
		return _actions;
	}, [details, completedRosterEmails]);

	const renderActions = () => {
		if (actions.length > 2) {
			let [mainAction, ...dropdownActions] = actions;
			dropdownActions = dropdownActions.map((a) => {
				return {
					id: a.id,
					label: a.text,
					icon: a.icon,
					action: a.onClick,
					disabled: a.disabled,
					disabledText: a.disabledText,
				};
			});

			return (
				<div className={'flex flex-row items-center gap-4'}>
					<div className='flex flex-1'>
						<AppButton
							{...mainAction}
							className={'btn btn-primary w-full'}
							loading={loadersProp[actions[0]['data-load-prop']]}
							iconRight={false}
						/>
					</div>
					<div>
						<AppDropdownButton
							id={'ttg-assessment-more-actions'}
							icon={'more-2-line'}
							actions={dropdownActions}
							buttonClassName={'btn btn-secondary'}
							loading={sendingReport || viewingReport || sendingProgressReport}
							closeOnAction={true}
						/>
					</div>
				</div>
			);
		}
		return (
			<>
				<AppButton
					{...actions[0]}
					className={'btn btn-primary'}
					loading={loadersProp[actions[0]['data-load-prop']]}
					iconRight={false}
				/>
				{!!actions[1] && (
					<AppButton
						{...actions[1]}
						className={'btn btn-secondary'}
						iconRight={false}
						loading={loadersProp[actions[1]['data-load-prop']]}
					/>
				)}
			</>
		);
	};

	return (
		<div className={'mt-6'}>
			<div className={'mx-4'}>
				<AppBreadcrumbs
					items={[
						{ name: 'Assessments', to: RouteNames.Assessments },
						{ name: details.assessment_data?.name },
					]}
				/>
			</div>

			{!!details.assessment_data?.affected && (
				<div className='flex flex-col md:flex-row m-4 gap-4'>
					<AppNotice
						containerClass={'flex flex-1 shadow-sm bg-white p-6 rounded-sm'}
						isDismissable={true}
						icon={'error-warning-line'}
						actions={[
							{
								id: 'dismiss',
								text: 'Dismiss',
								dismiss: true,
							},
						]}
						title={`Notice`}
						description={
							<p>
								This team was affected by our recent database issue. We are in the process of rebuilding
								and restoring this team’s view in your console. <br />
								<br />
								Your console will be back to normal soon, and{' '}
								<a href={'mailto:dani@tablegroup.com'} className={'underline hover:text-rose-500'}>
									please click here to contact us if you have any questions
								</a>{' '}
								.
							</p>
						}
					/>
				</div>
			)}

			<div className='flex flex-col md:flex-row m-4 gap-4'>
				{!!details.isScheduled && (
					<AppNotice
						containerClass={'flex flex-1 shadow-sm bg-white p-6 rounded-sm'}
						isDismissable={true}
						icon={'calendar-line'}
						actions={[
							{
								id: 'edit',
								text: 'Edit Schedule',
								color: 'text-gray-800',
								action: () => {
									setIsEditingScheduleDate(true);
								},
							},
							{
								id: 'cancel',
								text: 'Cancel Schedule',
								color: 'text-red-800',
								action: () => {
									setIsCancelingScheduleDate(true);
								},
							},
						]}
						title={`Assessment Scheduled`}
						description={
							<ul className='list-disc ml-3'>
								<li>
									Scheduled for{' '}
									{UtilityService.formatDate(
										details.assessment_data.scheduled_process_at,
										'MMMM DD YYYY, hh:mm A',
										true
									)}{' '}
									{details.assessment_data.raw_timezone_abbv}
								</li>
							</ul>
						}
					/>
				)}

				{details.reminders && details.reminders?.length > 0 && (
					<AppNotice
						containerClass={'flex flex-1 shadow-sm bg-white p-6 rounded-sm'}
						isDismissable={true}
						icon={'calendar-line'}
						actions={[
							{
								id: 'edit',
								text: 'Edit Reminders',
								color: 'text-gray-800',
								action: () => {
									setIsEditingSchedules(true);
								},
							},
							{
								id: 'dismiss',
								text: 'Dismiss',
								dismiss: true,
							},
						]}
						title={`There ${details.reminders?.length > 1 ? 'are' : 'is'} ${details.reminders?.length}${
							details.assessment_data?.status === 'Complete' ? ' Retest' : ' '
						} ${details.reminders?.length > 1 ? 'Reminders' : 'Reminder'} Scheduled for this Assessment`}
						description={
							<ul className='list-disc ml-3'>
								{details.reminders.map((reminder, index) => (
									<li key={index}>
										Scheduled for{' '}
										{UtilityService.formatDate(
											reminder.raw_process_at,
											'MMMM DD YYYY, hh:mm A',
											true
										)}{' '}
										{reminder.raw_timezone_abbv
											? reminder.raw_timezone_abbv
											: UtilityService.getTimezoneAbbv(reminder.timezone.value)}
									</li>
								))}
							</ul>
						}
					/>
				)}
			</div>

			<div className={'flex flex-1 flex-col md:flex-row mb-6 mx-4'}>
				<p
					className={'flex flex-1 ttg-text-2xl font-medium text-gray-900 items-center'}
					id={'ttg-assessment-details-team-name'}
				>
					{details.assessment_data?.name}
				</p>
				<div>
					<div
						className={`flex gap-4 ${
							details.assessment_data?.status === 'Complete' ? 'flex-row' : 'flex-col'
						} md:flex-row mt-4 md:mt-0`}
					>
						{renderActions()}
					</div>
				</div>
			</div>

			<HeaderTexts details={details} />

			<EditTeamAssessmentSidebar
				isOpen={isEditingTeam}
				onClose={() => setIsEditingTeam(false)}
				assessment={details}
			/>
			<EditIndividualAssessmentSidebar
				isOpen={isEditingIndividual}
				onClose={() => setIsEditingIndividual(false)}
				assessment={details}
			/>
			<ScheduleAssessmentSidebar
				isOpen={isEditingSchedules}
				onClose={() => setIsEditingSchedules(false)}
				assessment={details}
			/>
			<EditScheduleDateSidebar
				isOpen={isEditingScheduleDate}
				onClose={() => setIsEditingScheduleDate(false)}
				assessment={details}
			/>
			<CancelScheduleAssessmentModal
				isOpen={isCancelingScheduleDate}
				onClose={() => setIsCancelingScheduleDate(false)}
				assessment={details}
			/>
			<BulkConfirmDeleteActionModal
				isOpen={isDeleting}
				onClose={() => setIsDeleting(false)}
				assessment={details}
				data={{
					emails: notCompletedRosterEmails,
					noticeEmails: completedRosterEmails,
					willAssessmentBeDeleted: completedRosterEmails?.length === 0,
					willAssessmentBeClosed: true,
					person_ids: notCompletedIds,
				}}
				setDeletingMembers={setIsDeleting}
			/>
			<NotEnoughLicensesModal
				isOpen={shouldShowModalToPurchaseMore}
				onClose={() => setShouldShowModalToPurchaseMore(false)}
			/>
		</div>
	);
};

export default Header;
