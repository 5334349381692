import RouteNames from '../router/RouteNames';

const basePath = RouteNames.AssetsBasePath;
const Images = {
	logo: `${basePath}/assets/logo.svg`,
	logoWhite: `${basePath}/assets/logo-white.svg`,
	tacLogo: `${basePath}/assets/tacLogo.svg`,
	maLogo: `${basePath}/assets/maLogo.svg`,
	capaLogo: `${basePath}/assets/capaLogo.svg`,
	videosLogo: `${basePath}/assets/videosLogo.svg`,
	wgacLogo: `${basePath}/assets/wgacLogo.svg`,
	wgcrLogo: `${basePath}/assets/wgcrLogo.svg`,
	wgtagLogo: `${basePath}/assets/wgtagLogo.svg`,
	whiteSmallTTG: `${basePath}/assets/whiteSmallTTG.svg`,
	dysfunctions: `${basePath}/assets/dysfunctions.png`,
	report: {
		pyramid: `${basePath}/assets/report/pyramid.svg`,
		pyramidGrayedOut: `${basePath}/assets/report/pyramid-grayed-out.svg`,
		chatBox: `${basePath}/assets/report/chatbox.svg`,
		pageTriangle: `${basePath}/assets/report/pageTriangle.svg`,
		fullMarkColorLogo: `${basePath}/assets/report/Table-Group-Logo-Fullmark-Color.jpg`,
		gears: `${basePath}/assets/report/gears.svg`,
		emblem: `${basePath}/assets/report/emblem.svg`,
		flag: `${basePath}/assets/report/flag.svg`,
		checkmark: `${basePath}/assets/report/checkmark.svg`,
		close: `${basePath}/assets/report/close.svg`,
		exclamation: `${basePath}/assets/report/exclamation.svg`,
		puzzlePieces: `${basePath}/assets/report/puzzle-pieces.svg`,
		lightbulb: `${basePath}/assets/report/lightbulb.svg`,
		arrow: `${basePath}/assets/report/arrow.svg`,
		the5d: {
			1: `${basePath}/assets/report/01.svg`,
			2: `${basePath}/assets/report/02.svg`,
			3: `${basePath}/assets/report/03.svg`,
			4: `${basePath}/assets/report/04.svg`,
			5: `${basePath}/assets/report/05.svg`,
		},
	},
	itp_assessment: {
		book: `${basePath}/assets/itpAssessment/book.png`,
		venSmall: `${basePath}/assets/itpAssessment/venn-small.png`,
		ven: `${basePath}/assets/itpAssessment/venn.png`,
		humble: {
			low: `${basePath}/assets/itpAssessment/humble/low.png`,
			medium: `${basePath}/assets/itpAssessment/humble/medium.png`,
			high: `${basePath}/assets/itpAssessment/humble/high.png`,
		},
		hungry: {
			low: `${basePath}/assets/itpAssessment/hungry/low.png`,
			medium: `${basePath}/assets/itpAssessment/hungry/medium.png`,
			high: `${basePath}/assets/itpAssessment/hungry/high.png`,
		},
		smart: {
			low: `${basePath}/assets/itpAssessment/smart/low.png`,
			medium: `${basePath}/assets/itpAssessment/smart/medium.png`,
			high: `${basePath}/assets/itpAssessment/smart/high.png`,
		},
	},
	ohsPdf: {
		logo: `${basePath}/assets/ohsSurvey/TG_logo.jpg`,
		book: `${basePath}/assets/ohsSurvey/book.jpg`,
		disciplines: {
			framework: `${basePath}/assets/ohsSurvey/disciplines/framework.png`,
			legend: `${basePath}/assets/ohsSurvey/disciplines/legend.png`,
			large: {
				1: {
					high: `${basePath}/assets/ohsSurvey/disciplines/large/1/high.png`,
					low: `${basePath}/assets/ohsSurvey/disciplines/large/1/low.png`,
					medium: `${basePath}/assets/ohsSurvey/disciplines/large/1/medium.png`,
				},
				2: {
					high: `${basePath}/assets/ohsSurvey/disciplines/large/2/high.png`,
					low: `${basePath}/assets/ohsSurvey/disciplines/large/2/low.png`,
					medium: `${basePath}/assets/ohsSurvey/disciplines/large/2/medium.png`,
				},
				3: {
					high: `${basePath}/assets/ohsSurvey/disciplines/large/3/high.png`,
					low: `${basePath}/assets/ohsSurvey/disciplines/large/3/low.png`,
					medium: `${basePath}/assets/ohsSurvey/disciplines/large/3/medium.png`,
				},
				4: {
					high: `${basePath}/assets/ohsSurvey/disciplines/large/4/high.png`,
					low: `${basePath}/assets/ohsSurvey/disciplines/large/4/low.png`,
					medium: `${basePath}/assets/ohsSurvey/disciplines/large/4/medium.png`,
				},
			},
		},
	},
};

export default Images;
