import React from 'react';
import NotificationService from '../../services/NotificationService';
import { AppIcon, AppTooltip } from '../App';

const MarkAsReadToggle = ({ notification, onComplete }) => {
	const [loading, setLoading] = React.useState(false);

	const isRead = notification.read;

	const changeNotificationReadStatus = () => {
		setLoading(true);
		NotificationService.toggleMarkAsRead({ id: notification.id, read: !isRead }).then(
			() => {
				onComplete();
				setLoading(false);
			},
			(error) => {
				setLoading(false);
				NotificationService.error(error.message);
			}
		);
	};

	return (
		<div onClick={changeNotificationReadStatus} style={{ width: '12px', minWidth: '12px', marginTop: '4px' }}>
			{loading ? (
				<div className={'relative'} id={'ttg-notifications-mark-as-read-loading'}>
					<p
						className='absolute top-0 flex flex-col items-center justify-center'
						style={{ right: '-2px', top: '-8px' }}
					>
						<AppIcon className={`animate-spin text-primary text-base`} icon={'loader-4-line'} />
					</p>
				</div>
			) : (
				<>
					<p
						className={`${
							isRead ? 'bg-gray-300' : 'bg-rose-500'
						} hover:bg-red-500 w-3 h-3 rounded-full cursor-pointer`}
						style={{ right: '5px' }}
						data-for={`notifications-mark-as-read-${notification.id}`}
						data-tip
					/>
					<AppTooltip id={`notifications-mark-as-read-${notification.id}`} placement={'left'}>
						<p className={'text-white bg-gray-800 text-center text-tiny font-normal'}>
							{isRead ? 'Mark as unread' : 'Mark as read'}
						</p>
					</AppTooltip>
				</>
			)}
		</div>
	);
};

export default MarkAsReadToggle;
