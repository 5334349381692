import React from 'react';
import Notifications from './Notifications';
import { AppOutsideAlerter, AppIcon } from '../App';
import { useNotifications } from '../../providers/NotificationProvider';
import { useCustomer } from '../../providers/CustomerProvider';

const Widget = () => {
	const [isOpen, setIsOpen] = React.useState(false);
	const { customer } = useCustomer();
	const { notifications: { unreadAll } } = useNotifications();

	if (!customer || !customer.customers_email_address) {
		return null;
	}

	return (
		<AppOutsideAlerter handleClickOutsideFunction={() => setIsOpen(false)}>
			<div className='notifications-container'>
				<button
					id={'ttg-header-notifications'}
					className={'nav-item relative cursor-pointer block'}
					onClick={() => setIsOpen(!isOpen)}
				>
					<span className='relative'>
						<AppIcon icon='notification-2-line' className='mx-1 ttg-text-xl text-rose-500' />
						{!!unreadAll && <span className='badge'>{unreadAll}</span>}
					</span>
				</button>
				<div className={`notification-content ${isOpen ? 'opened' : ''}`}>
					<div className='md:hidden absolute right-0 top-0 mt-4 mr-4'>
						<AppIcon
							id={'ttg-notifications-mobile-close'}
							icon={'close-fill'}
							className={'text-rose-500 text-2xl cursor-pointer hover:text-gray-300'}
							onClick={() => setIsOpen(false)}
						/>
					</div>
					<Notifications toggleWidgetVisibility={setIsOpen} />
				</div>
			</div>
		</AppOutsideAlerter>
	);
};

export default Widget;
