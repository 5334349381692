import UtilityService from './UtilityService';
import ApiService from './ApiService';
import NotificationService from './NotificationService';
import RouteNames from '../router/RouteNames';
import Cookies from 'js-cookie';
import CustomerBusinessRoles from '../constants/CustomerBusinessRoles';

const login = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post('v1/user/login', payload)
			.then((response) => {
				const { token, customer } = response.data;
				UtilityService.setAuthCookie(token);
				resolve(customer);
			})
			.catch((error) => {
				UtilityService.setAuthCookie('');
				reject(error);
			});
	});
};

export const forgotPassword = ({ email, captcha }) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/user/forgot_password`, { email, captcha }).then(resolve, reject);
	});
};

export const verifyLink = (token) => {
	return new Promise((resolve, reject) => {
		ApiService.get(`v1/user/verify_reset_password?token=${token}`)
			.then(() => {
				resolve();
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const verifyChangeEmail = (token) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/user/verify_email_change`, { token })
			.then(({ data }) => {
				UtilityService.setAuthCookie(data.token);
				resolve(data.token);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const signup = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post('v1/user/register', payload)
			.then(() => {
				resolve();
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const getSignupReferral = (token) => {
	return new Promise((resolve, reject) => {
		ApiService.get('/v1/user/registration_referral', {
			params: {
				token: token,
			},
		})
			.then(({ data }) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const getCustomerData = () => {
	return new Promise((resolve, reject) => {
		const authCookie = UtilityService.getAuthCookie();
		if (!authCookie) {
			reject();
			return;
		}
		ApiService.get('v1/user', {
			headers: {
				'Cache-Control': 'no-cache',
				Pragma: 'no-cache',
				Expires: '0',
			},
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const update = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.put('v1/user', payload)
			.then(({ data }) => {
				resolve({
					customers_firstname: data.first_name,
					customers_lastname: data.last_name,
					customers_email_address: data.email,
					entry_company: data.company,
					customers_telephone: data.phone,
					customers_fax: data.fax,
					default_timezone: data.default_timezone,
					email_change_request: data.email_change_request,
					default_email_format: data.default_email_format,
					comparison_reminders: data.comparison_reminders,
					customers_role: data.role,
				});
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const updatePassword = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.put(`v1/user/password`, payload)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const resetPassword = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/user/reset_password`, payload)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const resendEmailChangeConfirmation = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post('/v1/user/email_change', payload).then(resolve, reject);
	});
};

const undoEmailChangeConfirmation = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post('/v1/user/undo_email_change', payload).then(resolve, reject);
	});
};

const getCapaProInfo = () => {
	return new Promise((resolve, reject) => {
		ApiService.get('v1/capa_pro/member')
			.then(({ data }) => {
				const member = data.member;
				if (!member.state) {
					member.state = '';
				}
				resolve(member);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const updateCapaData = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post('v1/capa_pro/member', payload).then(resolve, reject);
	});
};

const getStripePortalLink = () => {
	return new Promise((resolve, reject) => {
		ApiService.get('/v1/user/stripe_billing_portal').then(({ data }) => {
			resolve(data.data);
		}, reject);
	});
};

const getLicensesNumber = () => {
	return new Promise((resolve, reject) => {
		ApiService.get('/v1/user/assessments/licenses').then(({ data }) => {
			resolve(data);
		}, reject);
	});
};

const verifyUserEmail = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/user/verify_user_email`, payload)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const logout = () => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/user/logout`)
			.then(() => {
				resolve();
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const prepareCustomerPayload = (customer) => ({
	first_name: customer.customers_firstname,
	last_name: customer.customers_lastname,
	email: customer.customers_email_address,
	company: customer.entry_company,
	role: CustomerBusinessRoles.find((roles) => roles.value === customer.customers_role) ?? null,
	phone: customer.customers_telephone,
	fax: customer.customers_fax,
	default_timezone: UtilityService.getTimezoneByValue(customer.default_timezone),
	default_email_format: customer.default_email_format,
	comparison_reminders: customer.comparison_reminders,
});

const showEmailChangeSentNotification = () => {
	NotificationService.success(
		'Email successfully resent',
		'We have resent the verification process in your email address.',
		{ duration: 10000 }
	);
};

const CustomerService = {
	login,
	forgotPassword,
	verifyLink,
	signup,
	getSignupReferral,
	getCustomerData,
	update,
	updatePassword,
	resetPassword,
	resendEmailChangeConfirmation,
	undoEmailChangeConfirmation,
	getCapaProInfo,
	updateCapaData,
	getStripePortalLink,
	getLicensesNumber,
	verifyUserEmail,
	logout,
	prepareCustomerPayload,
	showEmailChangeSentNotification,
};

export default CustomerService;
