import React from 'react';
import { AppButton, AppInput } from '../../../App';
import { useForm, useFormState } from 'react-hook-form';
import { AssessmentSetupIndividualSetupNotice, AssessmentSetupNavigation, DeleteDraftAssessment } from '../../index';
import { useNavigate } from 'react-router-dom';
import RouteNames from '../../../../router/RouteNames';
import UtilityService from '../../../../services/UtilityService';
import AssessmentService from '../../../../services/AssessmentService';
import { useAssessments } from '../../../../providers/AssessmentProvider';
import NotificationService from '../../../../services/NotificationService';

const Form = ({ nextStep }) => {
	const [submittedForLater, setSubmittedForLater] = React.useState(false);
	const [settingUp, setSettingUp] = React.useState(false);

	const navigate = useNavigate();

	const { setupAssessment, setSetupAssessment, setNewAssessmentId } = useAssessments();

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		getValues,
		control,
		setError,
	} = useForm({
		defaultValues: setupAssessment.individual,
	});

	const { isSubmitted } = useFormState({ control });

	const trimInputValue = (name, e) => {
		setValue(name, e.target.value.trim(), {
			shouldValidate: isSubmitted || submittedForLater,
			shouldDirty: true,
		});
	};

	const _next = async (payload) => {
		setSettingUp(true);
		setSetupAssessment(prev => ({ ...prev, individual: payload }));
		AssessmentService.setupIndividual({
			id: setupAssessment.id,
			...payload,
		})
			.then(() => {
				setNewAssessmentId(setupAssessment.id);
				nextStep();
			})
			.finally(() => setSettingUp(false));
	};

	const _schedule = async () => {
		setSubmittedForLater(true);
		const payload = getValues();

		if (!payload.name) {
			setError('name', {
				type: 'required',
				message: 'You must enter a full name.',
			});
			document.getElementById('ttg-individual-setup-full-name')?.focus();
			document
				.getElementById('ttg-individual-setup-full-name')
				?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
			return;
		}

		if (payload.participant && !UtilityService.isValidEmail(payload.participant)) {
			setError('participant', {
				type: 'required',
				message: 'You must enter a valid email address',
			});
			document.getElementById('ttg-individual-setup-main-email')?.focus();
			document
				.getElementById('ttg-individual-setup-main-email')
				?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
			return;
		}

		await AssessmentService.draftIndividual({
			id: setupAssessment.id,
			...payload,
		})
			.then(() =>
				navigate(RouteNames.Assessments, {
					state: { savedForLater: true, individual: true },
				})
			)
			.catch((error) => {
				NotificationService.error(error.message, 'Error saving assessment for later');
				setSubmittedForLater(false);
			});
	};

	return (
		<div>
			<AssessmentSetupIndividualSetupNotice />
			<h1 className='ttg-text-2xl text-gray-900 font-medium m-4'>Individual Assessment Setup</h1>
			<div className='ttg-assessment-section flex-col'>
				<form onSubmit={handleSubmit(_next)} id={'ttg-individual-assessment-setup-form'}>
					<div>
						<h1 className={'ttg-text-lg font-medium text-gray-900'}>Company Details</h1>
						<p className={'ttg-text-sm text-gray-500'}>
							Enter the company details. This information will appear on the final report.
						</p>

						<div className='short-form mt-4'>
							<AppInput
								id={'ttg-individual-setup-company-name'}
								label={'Company Name'}
								errors={errors}
								{...register('company_name')}
								onBlur={(e) => trimInputValue('company_name', e)}
							/>
						</div>
					</div>

					<div className='my-8 border-t-1 border-gray-200' />

					<div>
						<h1 className={'ttg-text-lg font-medium text-gray-900'}>Participant Name</h1>
						<p className={'ttg-text-sm text-gray-500'}>
							Please enter the full name of the person taking the assessment. This name will appear on the
							final report.
						</p>
						<div className='short-form mt-4'>
							<AppInput
								id={'ttg-individual-setup-full-name'}
								label={'Full Name'}
								errors={errors}
								{...register('name', {
									required: 'You must enter a full name.',
								})}
								onBlur={(e) => trimInputValue('name', e)}
							/>
						</div>
					</div>

					<div className='my-8 border-t-1 border-gray-200' />

					<div>
						<h1 className={'ttg-text-lg font-medium text-gray-900'}>Participant Email</h1>
						<p className={'ttg-text-sm text-gray-500'}>
							Please enter the e-mail address of person taking the assessment. <br />
							<span className='font-medium'>Note:</span> This person will be the{' '}
							<span className='font-medium'>ONLY</span> person that receives the final report, as we
							maintain the confidentiality of all responses.
						</p>
						<div className='short-form mt-4'>
							<AppInput
								id={'ttg-individual-setup-main-email'}
								label={'Email Address'}
								errors={errors}
								{...register('participant', {
									required: 'You must enter an email address.',
									maxLength: 96,
									validate: (value) =>
										UtilityService.isValidEmail(value) || 'You must enter a valid email address',
								})}
								onBlur={(e) => trimInputValue('participant', e)}
							/>
						</div>

						<AssessmentSetupNavigation
							backText={'Cancel'}
							onBack={() => navigate(-1)}
							nextText={'Finish Setup And Send Assessment'}
							loading={settingUp}
						>
							<AppButton
								id={'ttg-individual-setup-save-for-later'}
								type={'button'}
								className={'btn btn-secondary mt-2 md:mt-0'}
								text={'Save for later'}
								icon={'save-line'}
								iconRight={false}
								onClick={_schedule}
								loading={settingUp}
							/>
							<DeleteDraftAssessment />
						</AssessmentSetupNavigation>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Form;
