const Discipline = ({ discipline, score }) => {
	return (
		<div className={'px-2 font-medium'}>
			<div className={'ttg-text-lg text-red-700 survey-font'}>{discipline.header}</div>
			<div className={'py-2 ttg-text-sm font-medium survey-font'}>{discipline[score]}</div>
			<div className={'ttg-text-sm'}>{discipline.body}</div>
		</div>
	);
};

export default Discipline;
