const { ReadableUserRoles, USER_ROLE } = require('@ttg/app-interfaces-lib');

const CustomerBusinessRoles = Object.entries(ReadableUserRoles).map(([value, label]) => ({
	value,
	label,
}));

export const USER_ROLE_ENUM = USER_ROLE;

export default CustomerBusinessRoles;
