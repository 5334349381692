export interface PaginatedArgs {
	page: number;
	limit: number;
	sort?: "asc" | "desc";
}

export const DefaultPaginatedArgs: PaginatedArgs = {
  page: 1,
  limit: 25,
};

export const DefaultFilters = {
  orderBy: 'createdAt',
  sort: 'desc'
}

export interface PageResponse {
	page: number;
	limit: number;
	totalPages: number;
  totalItems: number;
}

export type PaginatedResponse<TData, TKey extends string> = {
	[K in TKey]: TData;
} & PageResponse;
