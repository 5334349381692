export enum AUTH_PROVIDER_ENUM {
  PASSWORD = 'PASSWORD',
  GOOGLE = 'GOOGLE',
  MICROSOFT = 'MICROSOFT',
  LINKEDIN = 'LINKEDIN',
}

export const AuthProvidersEnum: string[] = Object.values(AUTH_PROVIDER_ENUM);

type AuthProviderType = {
  [key in AUTH_PROVIDER_ENUM]: string;
};

export const AUTH_PROVIDERS: AuthProviderType = {
  [AUTH_PROVIDER_ENUM.PASSWORD]: 'PASSWORD',
  [AUTH_PROVIDER_ENUM.GOOGLE]: 'GOOGLE',
  [AUTH_PROVIDER_ENUM.MICROSOFT]: 'MICROSOFT',
  [AUTH_PROVIDER_ENUM.LINKEDIN]: 'LINKEDIN',
};
