import React from 'react';
import { AppPdfPage } from '../../../App';
import UtilityService from '../../../../services/UtilityService';

const Areas = ({ title, details, questions, page, description, icon, iconClassName, showMetaInfo = true }) => {
	const { child, parent } = details;

	const childCompletedAt = React.useMemo(() => {
		return UtilityService.formatDate(child.current_team.completed, 'MM/DD/YYYY');
	}, [details]);

	const parentCompletedAt = React.useMemo(() => {
		return UtilityService.formatDate(parent.current_team.completed, 'MM/DD/YYYY');
	}, [details]);

	const renderText = (question) => {
		if (!showMetaInfo) {
			return '';
		}
		if (question.diff > 0) {
			return `Your team's score improved by ${question.diff.toFixed(2)} pt${
				question.diff === 1 ? 's' : ''
			} for this statement.`;
		}
		return `Your team's score declined by ${Math.abs(question.diff).toFixed(2)} pt${
			Math.abs(question.diff) === 1 ? 's' : ''
		} for this statement.`;
	};

	return (
		<AppPdfPage page={page}>
			<div className='AreaPage'>
				<div className='AreaPage__layout'>
					<div className='AreaPage__heading'>
						<div className='AreaHeading'>
							<div className='AreaHeading__title'>{title}</div>
							<div className='AreaHeading__iconContainer'>
								<img src={icon} alt={''} className={iconClassName} />
							</div>
						</div>
					</div>
					<div className='AreaPage__content'>
						<div className='AreaPage__summary'>
							<div className='Blurb'>
								<div className='Blurb__content' dangerouslySetInnerHTML={{ __html: description }} />
							</div>
						</div>

						<div className='AreaPage__questionReview'>
							{questions.map((question) => (
								<div className='AreaQuestion' key={question.question.sort}>
									<div className='AreaQuestion__number'>
										{UtilityService.padNumber(question.question.sort, '0')}.
									</div>
									<div className='AreaQuestion__content'>
										<div
											className='AreaQuestion__label'
											dangerouslySetInnerHTML={{ __html: question.question.question }}
										/>
										<div className='AreaQuestion__meta'>
											<div className='AreaQuestion__metaCol'>
												Behavior: <strong>{question.question.category}</strong>
												<br />
												<span className='AreaQuestion__metaCol--small'>
													{renderText(question)}
												</span>
											</div>
											<div className='AreaQuestion__metaCol'>
												Avg. Score {childCompletedAt}:{' '}
												<strong>{question.childScore.toFixed(2)}</strong>
												<br />
												Avg. Score {parentCompletedAt}:{' '}
												<strong>{question.parentScore.toFixed(2)}</strong>
												<br />
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</AppPdfPage>
	);
};

export default Areas;
