import React from 'react';
import { AccountLayout } from '../../components/Layout';
import { AppButton, AppDropdown, AppInput, AppNotice, AppPhoneNumber } from '../../components/App';
import { Link } from 'react-router-dom';
import RouteNames from '../../router/RouteNames';
import { Controller, useForm, useFormState } from 'react-hook-form';
import timezones from '../../constants/TimeZones';
import UtilityService from '../../services/UtilityService';
import CustomerService from '../../services/CustomerService';
import NotificationService from '../../services/NotificationService';
import CustomerBusinessRoles, { USER_ROLE_ENUM } from '../../constants/CustomerBusinessRoles';
import config from '../../constants/config';
import { capitalize } from 'lodash';
import { useCustomer } from '../../providers/CustomerProvider';

const Account = () => {
	const [loadingAction, setLoadingAction] = React.useState(false);
	const { customer, setCustomer, loading: updating, setLoading } = useCustomer();
	
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		control,
		watch,
		clearErrors,
		reset,
	} = useForm({
		defaultValues: CustomerService.prepareCustomerPayload(customer),
	});

	const { isDirty, isSubmitted } = useFormState({ control });
	const companyValue = watch('company');

	React.useEffect(() => {
		if (!companyValue) {
			clearErrors('role');
		}
	}, [companyValue]);

	const _update = (_customer) => {
		const hasUpdatedEmail = _customer.email !== customer.customers_email_address;
		_customer.default_timezone = _customer.default_timezone.value;
		_customer.role = _customer?.role?.value || USER_ROLE_ENUM.INCOMPLETE;

		setLoading(true);
		CustomerService.update(_customer)
			.then((response) => {
				setCustomer((prev) => ({ ...prev, ...response }));
				reset(CustomerService.prepareCustomerPayload(response));
				if (hasUpdatedEmail) {
					NotificationService.success(
						'Verify email address',
						'We have sent the verification process in your email address.',
						{ duration: 10000 }
					);
				}
				NotificationService.success('Changes Successfully saved!');
			})
			.finally(() => setLoading(false));
	};

	const _resetForm = () => {
		reset(CustomerService.prepareCustomerPayload(customer));
	};

	React.useEffect(() => {
		_resetForm();
	}, [customer]);

	const trimInputValue = (name, e) => {
		setValue(name, e.target.value.trim(), {
			shouldValidate: isSubmitted,
			shouldDirty: true,
		});
	};

	const resendEmailChangeConfirmation = (email) => {
		setLoadingAction(true);
		CustomerService.resendEmailChangeConfirmation({ new_email: email })
			.then(() => CustomerService.showEmailChangeSentNotification())
			.finally(() => setLoadingAction(false));
	};

	const undoEmailChangeConfirmation = (email) => {
		setLoadingAction(true);
		CustomerService.undoEmailChangeConfirmation({ new_email: email })
			.then(() =>
				setCustomer((prev) => ({
					...prev,
					email_change_request: null,
				}))
			)
			.finally(() => setLoadingAction(false));
	};

	const isPendingEmailVerification = !!customer.email_change_request;

	return (
		<AccountLayout>
			{isPendingEmailVerification && (
				<AppNotice
					loading={loadingAction}
					containerClass={'ttg-account-section'}
					title={'Update Pending Verification'}
					description={`We just need you to check your email <b>${customer.email_change_request}</b> and to verify it’s you and complete the update.`}
					actions={[
						{
							id: 'ttg-account-resend-change-email-action',
							text: 'Resend email',
							action: () => resendEmailChangeConfirmation(customer.email_change_request),
						},
						{
							id: 'ttg-account-undo-change-email-action',
							text: 'Undo email change',
							action: () => undoEmailChangeConfirmation(customer.email_change_request),
						},
					]}
				/>
			)}

			<div className='ttg-account-section'>
				<form id={'ttg-account-details-form'} onSubmit={handleSubmit(_update)}>
					<h1 className='ttg-text-2xl mb-1'>Account Details</h1>
					<p className={'ttg-text-sm text-gray-500 mb-6'}>
						Please note: to edit your address details, please edit your{' '}
						<Link
							id={'ttg-account-address-book-link'}
							className={'underline hover:text-rose-500'}
							to={RouteNames.AddressBook}
						>
							Address Book
						</Link>
						.
					</p>
					<AppInput
						id={'ttg-account-first-name-input'}
						label={'First name'}
						errors={errors}
						data-ttg-max-length={32}
						{...register('first_name', {
							required: 'You must enter a first name.',
							maxLength: 32,
						})}
						onBlur={(e) => trimInputValue('first_name', e)}
					/>
					<AppInput
						id={'ttg-account-last-name-input'}
						label={'Last name'}
						errors={errors}
						data-ttg-max-length={32}
						{...register('last_name', {
							required: 'You must enter a last name.',
							maxLength: 32,
						})}
						onBlur={(e) => trimInputValue('last_name', e)}
					/>
					<AppInput
						id={'ttg-account-email-input'}
						label={'Email address'}
						data-ttg-max-length={96}
						errors={errors}
						disabled={
							isPendingEmailVerification ||
							customer.customers_login_method !== config.AUTH_PROVIDERS.PASSWORD
						}
						labelTooltip={
							customer.customers_login_method !== config.AUTH_PROVIDERS.PASSWORD
								? `Your account is connected through a single sign-on (SSO) method with ${capitalize(
										customer.customers_login_method
								  )}. You cannot change your email address.`
								: 'This is your current email address. You can edit it by typing a new email address in the input field below.'
						}
						{...register('email', {
							required: 'You must enter an email address.',
							maxLength: 96,
							pattern: {
								value: UtilityService.emailRegex,
								message: 'You must enter a valid email address',
							},
						})}
						onBlur={(e) => trimInputValue('email', e)}
					/>
					<AppInput
						id={'ttg-account-company-input'}
						label={'Company name'}
						errors={errors}
						data-ttg-max-length={64}
						{...register('company', {
							maxLength: 64,
						})}
						onBlur={(e) => trimInputValue('company', e)}
					/>
					<Controller
						control={control}
						name={'role'}
						rules={{ required: companyValue ? 'You must select a role' : false }}
						render={({ field, formState }) => (
							<AppDropdown
								{...field}
								id={'ttg-account-role-update-input'}
								placeholder=''
								label={'Role'}
								options={CustomerBusinessRoles}
								errors={formState.errors}
								shouldShowErrorMessage
								isClearable
								containerClassName={'w-full'}
								isSearchable={false}
								styles={{
									option: (provided, { isFocused }) => {
										return {
											...provided,
											backgroundColor: isFocused ? 'bg-blue-200 !important' : 'white !important',
											color: 'text-gray-700',
										};
									},
								}}
							/>
						)}
					/>

					<AppPhoneNumber
						{...register('phone', {
							maxLength: 32,
							pattern: {
								value: UtilityService.phoneNumberRegex,
								message: 'You must enter a valid phone number',
							},
						})}
						name={'phone'}
						control={control}
						data-ttg-max-length={32}
						initialValue={customer.customers_telephone}
						errors={errors}
					/>

					<Controller
						control={control}
						name={'default_timezone'}
						render={({ field }) => (
							<AppDropdown
								{...field}
								id={'ttg-account-timezone-input'}
								label={'Default timezone'}
								options={timezones}
								actionIcon={'navigation-fill'}
								tooltip={'Use my location'}
								onAction={() => {
									setValue(
										'default_timezone',
										UtilityService.getTimezoneByValue(UtilityService.getLocalTimezone()),
										{
											shouldDirty: true,
										}
									);
								}}
								actionId={'ttg-account-location-action'}
							/>
						)}
					/>

					<AppButton
						id={'ttg-account-details-submit-button'}
						type={'submit'}
						text={'Save changes'}
						loading={updating}
						disabled={!isDirty}
					/>
					{isDirty && (
						<AppButton
							id={'ttg-account-details-cancel-button'}
							type={'button'}
							text={'Cancel'}
							className={'btn btn-secondary ml-4'}
							loading={updating}
							onClick={_resetForm}
						/>
					)}
				</form>
			</div>
		</AccountLayout>
	);
};

export default Account;
