import React from 'react';
import { AccountLayout } from '../../Layout';
import { AppButton, AppIcon, AppLoading } from '../../App';
import { useLocation, useNavigate } from 'react-router-dom';
import RouteNames from '../../../router/RouteNames';
import UtilityService from '../../../services/UtilityService';
import AssessmentService from '../../../services/AssessmentService';
import { useCustomer } from '../../../providers/CustomerProvider';

const List = () => {
	const [assessments, setAssessments] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState(null);

	const navigate = useNavigate();
	const location = useLocation();

	const { customer } = useCustomer();
	const { token } = UtilityService.getQueryParams(location.search);

	const getAssessments = () => {
		setLoading(true);
		AssessmentService.find({ token })
			.then((response) => {
				setAssessments(response);
			})
			.catch((error) => setError(error))
			.finally(() => setLoading(false));
	};

	React.useEffect(() => {
		if (!assessments) {
			getAssessments();
		}
	}, [assessments]);

	const takeAssessment = (assessment) => {
		const { team_slug, team_roster_slug } = assessment;

		if (team_slug && team_roster_slug) {
			return navigate(`${RouteNames.TakeAssessment}?t=${assessment.team_slug}&r=${assessment.team_roster_slug}`);
		}

		return navigate(`${RouteNames.TakeAssessment}?t=${assessment.team_id}&p=${assessment.person_id}`);
	};

	if (loading) {
		return <AppLoading />;
	}

	if (error) {
		return (
			<div
				id={'ttg-take-assessment-error'}
				className={'flex justify-center items-center flex-col mx-auto mt-12'}
				style={{ maxWidth: '550px' }}
			>
				<AppIcon icon={'information-line'} className={'text-7xl text-red-500'} />

				<span className={'flex  flex-col items-center text-center gap-4'}>
					<p className='ttg-text-sm text-gray-800'>{error?.message}</p>

					<AppButton
						text={'Go to Find Your Assessments'}
						onClick={() => navigate(`${RouteNames.FindAssessment}`)}
					/>

					<p className='ttg-text-sm text-gray-800'>
						{' '}
						If you are having trouble, please contact{' '}
						<a className='text-rose-500 font-medium' href='mailto:support@tablegroup.com'>
							support@tablegroup.com
						</a>
						.
					</p>
				</span>
			</div>
		);
	}

	if (assessments?.completed_teams && assessments?.completed_teams.length) {
		return (
			<AccountLayout sidebar={false}>
				<div className='m-4 mb-8'>
					<h1 className={'ttg-text-2xl text-gray-900 font-medium'}>
						The Five Dysfunctions of a Team Assessment
					</h1>
					<p className={'text-gray-500 ttg-text-sm pt-4 pb-4'}>
						Below is a listing of the team or teams of which you are a member, and that have yet to be
						completed. To begin the assessment process for a team, please click on the corresponding link.
					</p>
					<p className={'ttg-text-lg font-medium text-gray-900 mb-2'}>All Teams are Currently Complete</p>
					<p className={'ttg-text-sm text-gray-500 py-2'}>
						All teams associated with{' '}
						<span className='font-semibold' id={'ttg-completed-assessments-email-id'}>
							{assessments?.email}
						</span>{' '}
						are marked complete.
					</p>
					<p className={'ttg-text-sm text-gray-500 py-2'} id={'ttg-completed-assessments-info-text'}>
						According to our records, you have completed Assessments for the following teams:
					</p>
				</div>
				<div className={'mx-4'} id={'ttg-find-assessment-assessments-section'}>
					<div className='w-full grid md:grid-cols-2 lg:grid-cols-3 gap-4'>
						{assessments?.completed_teams.map((a, key) => {
							return (
								<div
									className={'flex flex-row items-center border-1 bg-white border-gray-200 p-2'}
									key={key}
								>
									<AppIcon
										icon={a.team_type === 'team' ? 'team-fill' : 'user-fill'}
										className={'text-2xl text-rose-500 ml-1 mr-2'}
									/>
									<div className={'overflow-hidden'}>
										<p className={'ttg-text-lg text-gray-800 font-medium truncate'}>{a.name}</p>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</AccountLayout>
		);
	}

	return (
		<AccountLayout sidebar={false}>
			<div className='mx-4 mt-4'>
				<h1 className={'ttg-text-2xl text-gray-900 font-semibold'}>
					The Five Dysfunctions of a Team Assessment
				</h1>
				<p className={'text-gray-500 ttg-text-sm pt-4 pb-2'}>
					Below is a listing of the team or teams of which you are a member, and that have yet to be
					completed. To begin the assessment process for a team, please click on the corresponding link.
				</p>
			</div>
			<div className={'mx-4'} id={'ttg-find-assessment-assessments-section'}>
				<div className='w-full grid md:grid-cols-2 lg:grid-cols-3 gap-4'>
					{assessments &&
						assessments?.available_teams.map((assessment, index) => {
							return (
								<div
									className={'flex flex-row items-center border-1 bg-white border-gray-200 p-2'}
									key={index}
								>
									<AppIcon
										icon={assessment.team_type === 'team' ? 'team-fill' : 'user-fill'}
										className={'text-2xl text-rose-500 ml-1 mr-2'}
									/>
									<div className={'overflow-hidden'}>
										<p className={'ttg-text-lg text-gray-800 font-medium truncate'}>
											{assessment.name}
										</p>
										{assessment.setup_complete ? (
											<p
												id={'ttg-find-assessment-list-take-button'}
												key={index}
												className={'ttg-text-sm text-rose-500 cursor-pointer hover:underline'}
												onClick={() => {
													takeAssessment(assessment);
												}}
											>
												Take Assessment
											</p>
										) : (
											<p className={'ttg-text-sm text-gray-800'}>Setup Incomplete</p>
										)}
									</div>
								</div>
							);
						})}
				</div>
			</div>
			{assessments && assessments?.licenses_available && (
				<div className='ttg-account-section flex-col' id={'ttg-find-assessment-unused-licenses-section'}>
					<div className='flex flex-col justify-center w-full md:w-2/3 lg:w-5/12 mx-auto'>
						<AppIcon icon={'file-shield-2-line'} className={'text-7xl text-gray-400 mx-auto'} />
						<p className={'text-center ttg-text-lg text-gray-800 font-medium'}>You have Unused Licenses</p>
						<p className={'text-center ttg-text-sm text-gray-800 mt-6'}>
							According to our records, you have unused licenses that are currently not assigned to any
							teams. To use these Assessment licenses, please click the button below to login to your
							Administrator's Console.
						</p>
					</div>
					<div className={'flex justify-center mt-6'}>
						<AppButton
							text={customer && customer.customers_email_address ? 'Go to My Assessments' : 'Login'}
							onClick={() => {
								if (customer && customer.customers_email_address) {
									return navigate(RouteNames.Assessments);
								}
								window.open(RouteNames.Login, '_self');
							}}
						/>
					</div>
				</div>
			)}
		</AccountLayout>
	);
};

export default List;
