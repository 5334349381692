import React from 'react';
import config from '../../../constants/config';
import { AppButton } from '../../App';
import AssessmentService from '../../../services/AssessmentService';

const Progress = ({ currentStep, totalSteps, questions, onProgress, title, setQuestions, setCheckboxQuestions, scales }) => {
	const progress = React.useMemo(() => {
		if (onProgress) {
			return onProgress(currentStep, questions, totalSteps);
		}
		if (currentStep <= 2) {
			return '0%';
		}
		if (currentStep === totalSteps) {
			return '100%';
		}

		let AnsweredQuestions = 0;
		let NrOfQuestions = 0;

		questions.forEach((q) => {
			NrOfQuestions += q.length ?? 1;
		});

		const step = currentStep - 2;

		[...questions].splice(0, step).forEach((q) => {
			if (q.length) {
				AnsweredQuestions += q.filter(({ answer }) => answer > -1).length;
			} else {
				AnsweredQuestions += 1;
			}
		});

		return `${Math.ceil((AnsweredQuestions * 100) / NrOfQuestions)}%`;
	}, [currentStep, totalSteps, questions, onProgress]);
	if (!onProgress) {
		if (currentStep === 1) {
			return null;
		}
	}

	return (
		<div className={`mx-4 mt-4 ${config.name}`} id={'ttg-take-assessment-progress'}>
			{!onProgress && (
				<AppButton
					id={'ttg-take-assessment-progress-auto-fill'}
					text={'Set Random Answers'}
					onClick={() => {
						setQuestions(prev => AssessmentService.prepareRandomAnswers(prev, scales));
						setCheckboxQuestions(prev => prev.map(question => ({
							...question,
							answer: Array.from({ length: question.options.length })
								.map((_, i) => Math.random() > 0.5 ? i + 1 : null)
								.filter(Boolean)
						})));
					}}
					className={'btn btn-primary mb-4 hide-on-prod'}
				/>
			)}

			<div style={{ width: progress }} className={'transition duration-500 ease-in-out'}>
				<p className={'text-right text-rose-500 ttg-text-sm'}>
					{progress !== '100%' && <span id={'ttg-take-assessment-progress-value'}>{progress}</span>}
				</p>
			</div>
			<div className={'bg-gray-200'}>
				<div style={{ width: progress }} className={'transition duration-500 ease-in-out bg-rose-500 h-2'} />
			</div>
			<h1 className={'ttg-text-2xl text-center font-medium text-gray-900 my-6'}>
				{title || 'The Five Dysfunctions of a Team Assessment'}
			</h1>
		</div>
	);
};

export default Progress;
