import React from 'react';
import { AppBreadcrumbs, AppButton, AppIcon, AppTable } from '../../components/App';
import { AssessmentLayout } from '../../components/Layout';
import LicensesInfo from '../../components/Assessments/Licenses/LicensesInfo';
import TransferLicenses from '../../components/Assessments/Licenses/TransferLicenses';
import UtilityService from '../../services/UtilityService';
import RouteNames from '../../router/RouteNames';
import { useNavigate } from 'react-router-dom';
import LicensesFilters from '../../components/Assessments/Licenses/LicensesFilters';
import NotEnoughLicensesModal from '../../components/Assessments/Setup/NotEnoughLicensesModal';
import CancelTransfer from '../../components/Assessments/Licenses/CancelTransfer';
import AssessmentService from '../../services/AssessmentService';
import { useCustomer } from '../../providers/CustomerProvider';

const initialFilters = {
	search: '',
	sort_key: 'created',
	sort_dir: 'desc',
	page: 0,
	limit: 10,
};

const Licenses = () => {
	const navigate = useNavigate();

	const { licenses: { licenses } } = useCustomer()
	const [licensesList, setLicensesList] = React.useState([]);
	const [metaLicensesList, setMetaLicensesList] = React.useState([]);
	const [loadingLicenses, setLoadingLicenses] = React.useState(false);
	const [statusFilterLicenses, setStatusFilterLicenses] = React.useState('');
	const [searchFilterLicenses, setSearchFilterLicenses] = React.useState('');

	const [filters, setFilters] = React.useState(initialFilters);
	const [hasEnoughLicenses, setHasEnoughLicenses] = React.useState(false);
	const [transferLicensesModal, setTransferLicensesModal] = React.useState(false);
	const [cancelTransferModal, setCancelTranferModal] = React.useState(false);
	const [cancelingTransfer, setCancelingTransfer] = React.useState({});

	const _getLicenses = React.useCallback(() => {
		setLoadingLicenses(true);
		AssessmentService.getLicenseUsageByAssessment(filters)
			.then(({ meta, licensesList }) => {
				setLicensesList((prev) => (meta.page === 0 ? licensesList : [...prev, ...licensesList]));
				setMetaLicensesList(meta);
			})
			.finally(() => setLoadingLicenses(false));
	}, [filters]);

	React.useEffect(() => {
		_getLicenses();
	}, [_getLicenses]);

	const onSort = (col, sort_dir) => {
		const newFilters = {
			...filters,
			sort_key: col.key,
			sort_dir: sort_dir,
			page: 0,
		};
		setFilters(newFilters);
	};

	return (
		<AssessmentLayout>
			<div className={'mt-8 mx-4'}>
				<AppBreadcrumbs items={[{ name: 'Assessments', to: RouteNames.Assessments }, { name: 'Licenses' }]} />
			</div>
			<div className='ttg-assessment-section'>
				<div className='licenses-overview-main-table flex flex-col w-full'>
					<div className='licenses-overview-header'>
						<h1 className='title'>License Usage Overview</h1>
						<div className='licenses-overview-header-actions'>
							<AppButton
								className={'btn btn-secondary mr-6'}
								text={'Purchase Additional Licenses'}
								icon={'share-box-fill'}
								onClick={() => window.open(RouteNames.OnlineTeamAssessment, '_self')}
								id={'ttg-licenses-purchase-btn'}
							/>
							<AppButton
								className={'btn btn-primary my-4 md:my-0'}
								text='Transfer Licenses'
								icon='share-box-fill'
								onClick={() => setTransferLicensesModal(true)}
								id={'ttg-licenses-transfer-btn'}
							/>
						</div>
					</div>
					<LicensesInfo />
					<hr className='text-gray-200 my-8' />
					<AppTable
						identifier={'local_id'}
						title={'License usage'}
						info={'Below is a table containing your license usage by assessment.'}
						columns={[
							{
								name: 'Team Or member name',
								key: 'name',
								customRender: 'renderName',
								sortable: true,
								identifier: true,
							},
							{ name: 'Date created', key: 'created', customRender: 'renderDate', sortable: true },
							{
								name: 'Licenses',
								key: 'licenses',
								sortable: true,
							},
							{ name: 'Status', key: 'status', customRender: 'renderStatus', sortable: true },
						]}
						onItemClick={(item) => {
							if (item.status !== 'Pending' && item.status !== 'Transferred') {
								if (item.setup_complete === 0) {
									if (item.team_type === 'team') {
										if (licenses > 1) {
											navigate(`${RouteNames.TeamAssessmentSetup}?id=${item.team_id}`);
										} else {
											setHasEnoughLicenses(true);
										}
									} else {
										if (licenses > 0) {
											navigate(`${RouteNames.IndividualAssessment}?id=${item.team_id}`);
										} else {
											setHasEnoughLicenses(true);
										}
									}
								} else {
									navigate(`${RouteNames.AssessmentDetails}?id=${item.team_id}`);
								}
							}
						}}
						renderName={(item) => {
							return (
								<div className='flex flex-row items-center mr-2'>
									<AppIcon
										icon={
											item.team_type === 'team'
												? 'team-fill'
												: item.status === 'Pending'
												? 'mail-send-fill'
												: 'user-fill'
										}
										className='text-2xl text-rose-500 mr-3'
									/>
									<div className='flex flex-col'>
										<p className='text-gray-900 text-sm font-medium licenses-overview-field'>
											{item.name}
										</p>
										<p className='text-gray-500 text-sm font-normal licenses-overview-field'>
											{item.main_email ? item.main_email : ''}
										</p>
									</div>
								</div>
							);
						}}
						renderDate={(item) =>
							item.created ? UtilityService.formatDate(item.created, 'MM/DD/YYYY | hh:mm A') : 'N/A'
						}
						renderStatus={(item) => {
							return <div className='font-medium'>{item.status}</div>;
						}}
						noDataIcon={'file-shield-2-line'}
						noDataMessage={
							licenses === 0 ? (
								<p className='flex flex-col items-center text-center'>
									You have 0 licenses in your account.
									<br />
									Click the button below to get some licenses in order to create your first
									assessment.
									<AppButton
										className={'btn btn-primary my-4'}
										text={'Purchase Licenses'}
										icon={'share-box-fill'}
										onClick={() => window.open(RouteNames.OnlineTeamAssessment, '_self')}
										id={'ttg-0-licenes-btn-id'}
									/>
								</p>
							) : (
								<p>There are no licenses.</p>
							)
						}
						isActionAvailable={(action, item) => {
							if (action === 'Cancel') {
								return item.status === 'Pending';
							}
							return true;
						}}
						loading={loadingLicenses}
						actions={[{ label: 'Cancel', action: 'onCancel' }]}
						actionTooltip={'Cancel license transfer'}
						onCancel={(item) => {
							setCancelingTransfer({
								...item,
							});
							setCancelTranferModal(true);
						}}
						data={licensesList}
						filters={filters}
						setFilters={setFilters}
						setStatusFilterLicenses={setStatusFilterLicenses}
						setSearchFilterLicenses={setSearchFilterLicenses}
						searchFilterLicenses={searchFilterLicenses}
						renderFilters={LicensesFilters}
						onSort={onSort}
						canLoadMore={licensesList.length < metaLicensesList.totalResults}
						onLoadMore={() => {
							setFilters({
								...filters,
								page: filters.page + 1,
							});
						}}
						pagination={metaLicensesList}
					/>
				</div>
			</div>
			<NotEnoughLicensesModal isOpen={hasEnoughLicenses} onClose={() => setHasEnoughLicenses(false)} />
			<TransferLicenses
				isOpen={transferLicensesModal}
				onClose={() => {
					setTransferLicensesModal(false);
				}}
				updateFilters={() => {
					setFilters({
						...filters,
						search: searchFilterLicenses,
						status: statusFilterLicenses,
					});
				}}
			/>
			<CancelTransfer
				isOpen={cancelTransferModal}
				onClose={() => {
					setCancelTranferModal(false);
					setFilters({
						...filters,
						search: searchFilterLicenses,
						status: statusFilterLicenses,
					});
				}}
				transfer={cancelingTransfer}
			/>
		</AssessmentLayout>
	);
};

export default Licenses;