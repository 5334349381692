import React from 'react';
import { AppPdfWrapper } from '../../../App';
import CoverPage from './CoverPage';

const Report = () => {
	return (
		<AppPdfWrapper>
			<CoverPage />
		</AppPdfWrapper>
	);
};

export default Report;
