import Images from '../../assets/Images';

const IconSmall = ({ humbleDetails, hungryDetails, smartDetails }) => {
	return (
		<div>
			<div className={'inline-flex bg-blend-overlay'}>
				<img
					className={'w-15 mt-4 ml-10 absolute'}
					src={Images.itp_assessment.humble[humbleDetails.icon]}
					alt={humbleDetails.icon}
				/>
				<div className={'survey-font'}>
					<img
						className={'mt-8 w-3/12 ttg-text-base font-bold ml-15 absolute'}
						src={Images.itp_assessment.ven}
						alt='ven'
					/>
					<div className={`absolute mt-18 ttg-text-base font-bold ml-20 itp-pdf-${humbleDetails.color}`}>
						{humbleDetails.score}
					</div>
					<div className={`absolute mt-18 ttg-text-base font-bold ml-56 itp-pdf-${hungryDetails.color}`}>
						{hungryDetails.score}
					</div>
					<div className={`absolute mt-48 ttg-text-base font-bold ml-40 itp-pdf-${smartDetails.color}`}>
						{smartDetails.score}
					</div>
				</div>

				<img
					className={'w-15 mt-4 ml-56 absolute'}
					src={Images.itp_assessment.hungry[hungryDetails.icon]}
					alt={hungryDetails.icon}
				/>
			</div>
			<img
				className={'w-15 mt-56 itp-pdf-icon-small absolute'}
				src={Images.itp_assessment.smart[smartDetails.icon]}
				alt={smartDetails.icon}
			/>
		</div>
	);
};

export default IconSmall;
